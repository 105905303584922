import i18n from "../../i18n";
import React from "react";

import DataManager from "../DataManager";

import "../../assets/scss/FolderSelector.scss";
import folderIcon from "../../assets/img/icon-folder-figure.svg";
import allIcon from "../../assets/img/icon-folder.svg";

export default class FolderSelector extends React.Component<
  {
    onSelect: (folderId: string) => void;
  },
  any
> {
  constructor(props: any) {
    super(props);
  }

  render() {
    return (
      <div className="folder-selector">
        <div
          className="folder-selector-item no-folder"
          onClick={() => {
            this.props.onSelect("");
          }}
        >
          <img src={allIcon} />
          <div className="folder-selector-item-text">
            {i18n.t("my_transcripts")}
          </div>
        </div>
        {DataManager.instance.folderList.map((folder) => {
          return (
            <div
              key={folder.eid}
              className="folder-selector-item"
              onClick={() => {
                this.props.onSelect(folder.eid);
              }}
            >
              <img src={folderIcon} />
              <div className="folder-selector-item-text">{folder.name}</div>
            </div>
          );
        })}
      </div>
    );
  }
}
