import dayjs from "dayjs";
import { action } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import { Translation } from "react-i18next";
import { Link } from "react-router-dom";
import { Popup } from "semantic-ui-react";
import i18n from "../../../i18n";
import DataManager, {
  PaymentInfo,
  PaymentType,
  PaymentState,
} from "../../DataManager";
import { Styles } from "../../GlobalDefine";
import {
  PopupMenu,
  PopupMenuItem,
  NoRecorderIndicator,
} from "../../ui/Component";
import Utils from "../../Utils";
import LoadingPage from "../LoadingPage";

import arrowIcon from "../../../assets/img/icon-arrow-down-active.svg";

//Purchase History Tab
@observer
export default class PurchaseHistoryTab extends React.Component<any, any> {
  componentDidMount() {
    DataManager.instance.queryPaymentList();
    DataManager.instance.updateCurrentLocation(
      location.pathname,
      location.search,
    );
  }

  @action
  updateOffset(add: boolean) {
    let payment = DataManager.instance.payment;
    payment.listOffset = Math.max(
      0,
      Math.min(payment.listOffset + (add ? 1 : -1), payment.maxOffset),
    );
    DataManager.instance.queryPaymentList();
  }

  private getQuota(type: "valid" | "free") {
    const quota =
      type === "valid"
        ? DataManager.instance.quotaDetail.valid_quota
        : DataManager.instance.quotaDetail.free_quota;
    const constInMin = dayjs.duration(quota * 1000).asMinutes();
    return Utils.padNum(Math.floor(constInMin), 2);
  }

  private getExpireDate(type: "valid" | "free") {
    const until =
      type === "valid"
        ? DataManager.instance.quotaDetail.valid_until
        : DataManager.instance.quotaDetail.free_until;
    return dayjs(until).format("YYYY/MM/DD HH:mm");
  }

  private getPaymentContent(
    payment: PaymentInfo,
    key: number,
  ): React.ReactElement {
    let type = i18n.t("unknown");
    switch (payment.type) {
      case PaymentType.Buy:
        type = i18n.t("purchase_form_website", { ns: "paymentHistory" });
        break;
      case PaymentType.Coupon:
        type = i18n.t("enter_coupon_code", { ns: "paymentHistory" });
        break;
      case PaymentType.Free:
        type = i18n.t("complimentary", { ns: "paymentHistory" });
        break;
      case PaymentType.Refund:
        type = i18n.t("refund", { ns: "paymentHistory" });
        break;
    }

    const ms = payment.quota * 1000;
    let quota =
      Utils.padNum(Math.floor(dayjs.duration(ms).asHours()), 2) +
      dayjs.utc(ms).format(" : mm : ss");
    let payFailed =
      payment.type === PaymentType.Buy && payment.state === PaymentState.Failed;

    return (
      <Translation ns="paymentHistory">
        {(t) => (
          <div className="table-content" key={key}>
            <div className="title">
              <div>{t("transaction_date")}：</div>
              <div>{t("category")}：</div>
              <div>{t("details")}：</div>
              <div>{t("hours")}：</div>
              <div>{t("amount")}：</div>
            </div>
            <div className="content">
              <div>{dayjs.unix(payment.ctime).format("YYYY/MM/DD HH:mm")}</div>
              <div>{type}</div>
              <div className={payFailed ? "red" : ""}>
                {payFailed ? t("transaction_failed") : payment.desc || ""}
              </div>
              <div>{payFailed ? "--:--:--" : quota}</div>
              <div>{0 === payment.price ? "---" : payment.price}</div>
            </div>
          </div>
        )}
      </Translation>
    );
  }

  private getPager(): React.ReactElement {
    const payments = DataManager.instance.payment;
    const start = Math.min(
      payments.listOffset * payments.listSize,
      payments.totalCnt,
    );
    const end = Math.min(start + payments.listSize, payments.totalCnt);

    const startPage = 1;
    const endPage = payments.maxOffset + 1;
    const prevPage = Math.max(1, payments.listOffset);
    const nextPage = Math.min(payments.maxOffset + 1, payments.listOffset + 2);
    const currentPage = payments.listOffset + 1;

    if (start === end) {
      return null;
    }

    return (
      <Translation ns="paymentHistory">
        {(t) => (
          <div className="list-pager">
            <Popup
              basic
              on="click"
              size="mini"
              style={Styles.popupContainer}
              trigger={
                <div className="current-page number">
                  {start === end
                    ? ""
                    : `${start + 1}-${end}（${t("total_of_payments", {
                        totalCnt: payments.totalCnt,
                      })}）`}
                </div>
              }
            >
              <PopupMenu className="list-order-options">
                <PopupMenuItem
                  className={`order-option${startPage === currentPage ? " disabled" : ""}`}
                >
                  {startPage === currentPage ? (
                    i18n.t("newest")
                  ) : (
                    <a
                      draggable={false}
                      onClick={() => {
                        DataManager.instance.payment.listOffset = 0;
                        DataManager.instance.queryPaymentList();
                      }}
                    >
                      {i18n.t("newest")}
                    </a>
                  )}
                </PopupMenuItem>
                <PopupMenuItem
                  className={`order-option${endPage === currentPage ? " disabled" : ""}`}
                >
                  {endPage === currentPage ? (
                    i18n.t("oldest")
                  ) : (
                    <a
                      draggable={false}
                      onClick={() => {
                        DataManager.instance.payment.listOffset =
                          DataManager.instance.payment.maxOffset;
                        DataManager.instance.queryPaymentList();
                      }}
                    >
                      {i18n.t("oldest")}
                    </a>
                  )}
                </PopupMenuItem>
              </PopupMenu>
            </Popup>
            {prevPage === currentPage ? (
              <img src={arrowIcon} className="prev-page" />
            ) : (
              <a
                onClick={() => {
                  this.updateOffset(false);
                }}
              >
                <img src={arrowIcon} className="prev-page" />
              </a>
            )}
            {nextPage === currentPage ? (
              <img src={arrowIcon} className="next-page" />
            ) : (
              <a
                onClick={() => {
                  this.updateOffset(true);
                }}
              >
                <img src={arrowIcon} className="next-page" />
              </a>
            )}
          </div>
        )}
      </Translation>
    );
  }

  render() {
    let isLoading =
      DataManager.instance.reqList.payment === undefined ||
      DataManager.instance.quotaDetail === undefined;
    let noRecord = DataManager.instance.payment.totalCnt == 0;

    return (
      <Translation ns="paymentHistory">
        {(t) => (
          <div id="payment-history-tab">
            <div className="current-plan-container">
              <h5 className="current-plan-title">{t("current_plan")}</h5>
              {isLoading ? (
                <div className="loading-file-indicator">
                  <LoadingPage />
                </div>
              ) : (
                <div className="current-plan-row">
                  <div className="quota-container valid-quota">
                    <h2 className="quota">
                      <span className="quota-number">
                        {this.getQuota("valid")}
                      </span>{" "}
                      mins
                    </h2>
                    <h5 className="description">{t("valid_quota")}</h5>
                    <p className="valid-until">
                      {t("valid_until", {
                        expireDate: this.getExpireDate("valid"),
                      })}
                    </p>
                    <Link className="add-quota" to="/account?tab=2" />
                  </div>
                  <div className="quota-container free-quota">
                    <h2 className="quota">
                      <span className="quota-number">
                        {this.getQuota("free")}
                      </span>{" "}
                      mins
                    </h2>
                    <h5 className="description">{t("free_quota")}</h5>
                    <p className="valid-until">
                      {t("valid_until", {
                        expireDate: this.getExpireDate("free"),
                      })}
                    </p>
                  </div>
                </div>
              )}
            </div>
            {isLoading ? (
              <div className="loading-file-indicator">
                <LoadingPage />
              </div>
            ) : noRecord ? (
              <NoRecorderIndicator message={t("no_purchase_record")} />
            ) : (
              <>
                <h5 className="payment-history-details-title">
                  {t("purchase_details")}
                </h5>
                <div className={`payment-history-list-table`}>
                  <div className="table-header">
                    <p className="body01">{t("transaction_date")}</p>
                    <p className="body01">{t("category")}</p>
                    <p className="body01">{t("details")}</p>
                    <p className="body01">{t("hours")}</p>
                    <p className="body01">{t("amount")}</p>
                  </div>
                  {DataManager.instance.payment.list.map((payment, idx) => {
                    return this.getPaymentContent(payment, idx);
                  })}
                </div>

                {this.getPager()}
              </>
            )}
          </div>
        )}
      </Translation>
    );
  }
}
