const speakerIconColors: Array<string> = [
  // New colors:
  "#F9AE56",
  "#FF8875",
  "#EAE37F",
  "#7BDDAD",
  "#5885FF",
  "#4FC3F7",
  "#F9D172",
  "#FFAB91",
  "#A0C765",
  "#9EDDC9",
  "#7BA1FF",
  "#81C0D0",
  "#E0AF76",
  "#D8887C",
  "#7CA73B",
  "#86BCA2",
  "#738CCF",
  "#65AFD1",
  "#FFE698",
  "#E9C555",
  "#8B9F6D",
  "#4AA593",
  "#90A8CA",
  "#327180",
  "#DB984B",
  "#CEB35F",
  "#67774F",
  "#4A95A5",
  "#4A6FA5",
  "#3D7B96",
  "#C46D07",
  "#F68663",
  "#528209",
  "#12A65D",
  "#3B53B2",
  "#0085A7",

  // Old colors:
  "#4fc3f7",
  //"#f48fb1",
  "#72d572",
  //"#b39ddb",
  "#9a92a9",
  "#ffe082",
  "#bcaaa4",
  "#91a7ff",
  "#ffab91",
  //"#d4a89b",
  "#ed5565",
  "#0fa3b1",
  "#aab2bd",
  "#4a6fa5",
  "#90a8ca",
  //"#c77699",
  "#73ac71",
  "#81c0d0",
  "#37c1c1",
  "#a0c765",
];

export const Color = {
  orange: "#f7931d",
  darkOrange: "#ea8b1a",
  darkerOrange: "#de841a",
  lightOrange: "#eacdae",
  pink: "#f4cfc6",
  orangeRed: "#ff613a",
  lightestGray: "#ecf0f5",
  lighterGray: "#dadfe5",
  lightGray: "#d8dce0",
  gray: "#f5f7fa",
  darkGray: "#e6e9ed",
  darkerGray: "#ccd1d9",
  darkestGray: "#afb4bb",
  white: "#fdfdfd",
  pureWhite: "#ffffff",
  black: "#2e333a",
  blue: "#1e82ea",
  darkBlue: "#1e7bdc",
  darkerBlue: "#1965b4",
  ligthBlue: "#b4cce5",
  lighterBlue: "#bcdaf9",
  lightBlack: "#656d78",
  red: "#ff613a",
  darkRed: "#e2502c",
  darkerRed: "#c34526",
  lightRed: "#f4cfc6",
  yellow: "#f6bb42",
  lightYellow: "#f8cb72",
  darkYellow: "#f4a810",
  darkerYellow: "#db960a",
  green: "#8cc152",
  lightGreen: "#b4d68f",
  darkGreen: "#75a93d",
  darkerGreen: "#5b832f",

  info: "#37bc9b",
  warning: "#4fc1e9",
  error: "#ed5564",
  transparent: "transparent",
};

export const Color2 = {
  mainOrange: "#f7931d",
  Orange125: "#de7800",
  Orange75: "#f9ae56",
  Orange25: "#fdebd5",
  red: "#ff4b4b",
  red75: "#ff8875",
  red25: "#ffe6db",
  blue: "#1151ff",
  blue75: "#5885ff",
  blue25: "#d5e0ff",
  yellow: "#ffc700",
  yellow75: "#ffdd66",
  yellow50: "#ffe698",
  yellow25: "#fff8e7",
  green: "#00d76d",
  green75: "#7bddad",
  green25: "#d5f2ea",
  dark: "#120a02",
  black100: "#453d34",
  black80: "#645a50",
  black75: "#c0bcb7",
  black50: "#eae8e4",
  black25: "#f6f5f3",
  b20: "#96938F",
  b10: "#C0BCB7",
  w20: "#eae8e4",
  w10: "#F5F4F2",
  white: "#ffffff",
  transparent: "transparent",
};

export const hex2RGBA = (hex: string, alpha: number) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return `rgba(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}, ${alpha})`;
};

export const Dimen = {
  margin: "20px",
  uploadPageMaxWidth: "980px",
  borderRadius: "4px",
};

export const Styles = {
  popupContainer: {
    padding: "8px 0px",
    border: "0px",
    borderRadius: "8px",
    background: Color2.white,
    boxShadow: "0 2px 10px 0 var(--black50)",
    overflow: "hidden",
  },
} satisfies { [key: string]: React.CSSProperties };

export enum ButtonColor {
  Default, // mostly for cancel
  Orange, // mostly for main action
  lightOrange, // mostly for second action
  Blue,
  Red,
  Gray,
  GrayOutline,
  Yellow,
  Green,
  White,
}

export const getButtonColors = (btnColor: ButtonColor) => {
  let bg, textColor, border;

  switch (btnColor) {
    case ButtonColor.Default:
      bg = {
        normal: "transparent",
        hover: Color2.black50,
        active: Color2.black50,
        disabled: "transparent",
      };
      textColor = {
        normal: Color2.black80,
        disabled: Color2.black50,
      };
      break;
    case ButtonColor.Orange:
      bg = {
        normal: Color2.mainOrange,
        hover: Color2.Orange125,
        active: Color2.Orange125,
        disabled: Color2.Orange25,
      };
      textColor = {
        normal: Color2.white,
      };
      break;
    case ButtonColor.lightOrange:
      bg = {
        normal: Color2.Orange25,
        hover: hex2RGBA(Color2.Orange75, 0.5),
        active: hex2RGBA(Color2.Orange75, 0.5),
        disabled: Color2.Orange25,
      };
      textColor = {
        normal: Color2.mainOrange,
        disabled: Color2.white,
      };
      break;
    case ButtonColor.Blue:
      bg = {
        normal: Color.blue,
        hover: Color.darkBlue,
        active: Color.darkerBlue,
        disabled: Color.ligthBlue,
      };
      textColor = {
        normal: Color2.white,
      };
      break;
    case ButtonColor.Red:
      bg = {
        normal: Color.red,
        hover: Color.darkRed,
        active: Color.darkerRed,
        disabled: Color.lightRed,
      };
      textColor = {
        normal: Color2.white,
      };
      break;
    case ButtonColor.Gray:
      bg = {
        normal: Color2.black50,
        hover: Color2.black75,
        active: Color2.black75,
        disabled: Color2.black25,
      };
      textColor = {
        normal: Color2.black80,
      };
      break;
    case ButtonColor.GrayOutline:
      bg = {
        normal: Color2.white,
        hover: Color2.w20,
        active: Color2.w20,
        disabled: Color2.w20,
      };
      textColor = {
        normal: Color2.b20,
      };
      border = {
        normal: {
          width: "1px",
          color: Color2.b20,
        },
        disabled: {
          width: "1px",
          color: Color2.b10,
        },
      };
      break;
    case ButtonColor.Yellow:
      bg = {
        normal: Color.yellow,
        hover: Color.darkYellow,
        active: Color.darkerYellow,
        disabled: Color.lightYellow,
      };
      textColor = {
        normal: Color2.white,
      };
      break;
    case ButtonColor.Green:
      bg = {
        normal: Color.green,
        hover: Color.darkGreen,
        active: Color.darkerGreen,
        disabled: Color.lightGreen,
      };
      textColor = {
        normal: Color2.white,
      };
      break;
    case ButtonColor.White:
      bg = {
        normal: Color2.white,
        hover: Color2.Orange25,
        active: Color2.Orange25,
        disabled: Color2.white,
      };
      textColor = {
        normal: Color2.Orange125,
        disabled: Color2.Orange25,
      };
      border = {
        normal: {
          width: "1px",
          color: Color2.Orange125,
        },
        disabled: {
          width: "1px",
          color: Color2.Orange25,
        },
      };
      break;
  }

  return {
    bg,
    textColor,
    border,
  };
};

export const getUserIconColorFromName = (name: string): string => {
  let code = 0;
  for (let i = 0; i < name.length; i++) {
    code += name.charCodeAt(i) * (i + 1);
  }
  return getUserIconColor(code);
};

export const getUserIconColor = (charCode: number): string => {
  return speakerIconColors[charCode % speakerIconColors.length];
};

export enum MessageBoxColor {
  Default,
  Orange,
  Red,
}

export const getMessageBoxColors = (color: MessageBoxColor) => {
  const buttonColors = {
    bg: {
      normal: Color2.black100,
      hover: Color2.black75,
      active: Color2.black75,
      disabled: Color2.black25,
    },
    textColor: {
      normal: Color2.white,
    },
  };

  switch (color) {
    case MessageBoxColor.Default:
      return {
        color: Color2.white,
        background: Color2.black100,
        buttonColors: buttonColors,
      };
    case MessageBoxColor.Orange:
      return {
        color: Color2.white,
        background: Color2.mainOrange,
        buttonColors: buttonColors,
      };
    case MessageBoxColor.Red:
      return {
        color: Color2.white,
        background: Color2.red,
        buttonColors: buttonColors,
      };
  }
};
