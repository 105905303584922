import React from "react";
import { Translation, TFunction } from "react-i18next";
import i18n from "../../i18n";

import Utils from "../Utils";
import DeleteAccountWindow from "../ui/DeleteAccountWindow";
import User from "../User";
import DeleteAccountErrorAlert from "../ui/DeleteAccountErrorAlert";
import DataManager from "../DataManager";

import "../../assets/scss/DeleteAccountPage.scss";
import Config from "../Config";

enum DeleteAccountStage {
  verification = 0,
  notes = 1,
  confirmDelete = 2,
}

interface State {
  stage: DeleteAccountStage;
  checked: boolean;
  reason: string;
  isWindowShow: boolean;
  isError: boolean;
  recordsNumber: number;
}

export default class DeleteAccountPage extends React.Component<any, State> {
  static urlParam = "/delete-account/";

  constructor(props: any) {
    super(props);
    this.state = {
      stage: DeleteAccountStage.verification,
      checked: false,
      reason: "",
      isWindowShow: false,
      recordsNumber: 0,
      isError: false,
    };
  }

  getContent(t: TFunction<"account">) {
    if (this.state.stage === DeleteAccountStage.verification) {
      return (
        <div className="profile-container">
          <div className="profile-information">
            <span className="title">{t("account")}</span>
            <div className="row">
              <span className="key">{t("sign_in_via")}</span>
              {User.instance.profile.id.startsWith("google") ? (
                <span>Gmail</span>
              ) : (
                <span>Apple</span>
              )}
            </div>
            <div className="row">
              <span className="key">Email</span>
              <span>{User.instance.profile.email}</span>
            </div>
          </div>
          <div className="divider" />
          <div className="usage">
            <span className="title">{t("usage")}</span>
            <div className="row">
              <span className="key">{t("join_yating_since")}</span>
              <span>
                {i18n.t("day", {
                  day: Math.floor(
                    (Date.now() - User.instance.createdTime) /
                      (60 * 60 * 24 * 1000),
                  ),
                })}
              </span>
            </div>
            <div className="row">
              <span className="key">{t("you_have_recorded")}</span>
              <span>
                {t("number_of_recording", { number: this.state.recordsNumber })}
              </span>
            </div>
          </div>

          {User.instance.isSubscribed && (
            <>
              <div className="divider" />
              <div className="usage">
                <span className="title">{t("subscription")}</span>
                <div className="row">
                  <span className="key">{t("subscription_plan_title")}</span>
                  <span>{User.instance.subscription.productName}</span>
                </div>
                <div className="row">
                  <span className="key">{t("status")}</span>
                  <span>
                    {User.instance.isSubscriptionCancel
                      ? t("cancelled")
                      : t("active")}
                  </span>
                </div>
              </div>
              {!User.instance.isSubscriptionCancel && (
                <div
                  style={{
                    color: "#e22",
                    textAlign: "center",
                    padding: "1rem",
                    border: "solid 1px #e22",
                    borderRadius: "1rem",
                    margin: "1rem 0",
                    backgroundColor: "#e881",
                  }}
                >
                  {i18n.t("please_remove_subscription", { ns: "account" })}
                  <div>
                    <a href={Config.AccountCenterPlanUrl}>{t("plan_page")}</a>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      );
    } else if (this.state.stage === DeleteAccountStage.notes) {
      return (
        <div className="important-note">
          <p className="title">{t("note_for_deleting_your_account")}</p>
          <div className="notes">
            <p>{t("please_read_notes")}</p>
            <li>{t("note_1")}</li>
            <li>{t("note_2")}</li>
            <li>{t("note_3")}</li>
          </div>
          <label className="checkbox-container">
            {t("i_have_read")}
            <input
              type="checkbox"
              id="important-note-checkbox"
              value={0}
              checked={this.state.checked}
              onChange={() => {
                this.setState({ checked: !this.state.checked }, () => {
                  if (this.state.checked) {
                    Utils.analyticsEvent({
                      category: "Delete Account",
                      action: "Read Notes",
                    });
                  }
                });
              }}
            />
            <span className="checkmark"></span>
          </label>
        </div>
      );
    } else if (this.state.stage === DeleteAccountStage.confirmDelete) {
      return (
        <div className="reason-of-deleting">
          <p className="title">{t("tell_the_reason")}</p>
          <textarea
            placeholder={t("reason_textarea_placeholder")}
            value={this.state.reason}
            onChange={(event) => this.setState({ reason: event.target.value })}
          ></textarea>
        </div>
      );
    }
  }

  getButtonSection() {
    if (this.state.stage === DeleteAccountStage.verification) {
      return (
        <div className="button-container">
          <button
            className="continue-btn"
            onClick={() => {
              Utils.analyticsEvent({
                category: "Delete Account",
                action: "Verification",
              });
              this.setState({ stage: DeleteAccountStage.notes });
            }}
            disabled={
              User.instance.isSubscribed && !User.instance.isSubscriptionCancel
            }
          >
            {i18n.t("continue")}
          </button>
        </div>
      );
    } else if (this.state.stage === DeleteAccountStage.notes) {
      return (
        <div className="button-container space-between">
          <button
            className="decline-btn"
            onClick={() => {
              this.setState({
                stage: DeleteAccountStage.verification,
                checked: false,
                reason: "",
              });
            }}
          >
            {i18n.t("cancel")}
          </button>
          <button
            className="continue-btn"
            onClick={() => {
              Utils.analyticsEvent({
                category: "Delete Account",
                action: "Continued Notes",
              });
              this.setState({ stage: DeleteAccountStage.confirmDelete });
            }}
            disabled={!this.state.checked}
          >
            {i18n.t("continue")}
          </button>
        </div>
      );
    } else if (this.state.stage === DeleteAccountStage.confirmDelete) {
      return (
        <div className="button-container space-between">
          <button
            className="decline-btn"
            onClick={() => {
              this.setState({
                stage: DeleteAccountStage.verification,
                checked: false,
                reason: "",
              });
            }}
          >
            {i18n.t("cancel")}
          </button>
          <button
            className="continue-btn"
            onClick={() => {
              Utils.analyticsEvent({
                category: "Delete Account",
                action: "Submitted Reason",
              });
              this.setState({ isWindowShow: true });
            }}
            disabled={this.state.reason.length === 0}
          >
            {i18n.t("continue")}
          </button>
        </div>
      );
    }
  }

  componentDidMount(): void {
    DataManager.instance.getVoiceCount().then((voiceCount) => {
      this.setState({
        recordsNumber: voiceCount,
      });
    });
  }

  render() {
    return (
      <Translation ns="account">
        {(t) => (
          <div id="delete-account-page">
            <div className="title-container">
              <p className="title">{t("delete_account")}</p>
            </div>
            <div className="content-container">
              <div className="progress-bar">
                <div className="line" />
                <div
                  className={`circle-container ${
                    this.state.stage === DeleteAccountStage.verification
                      ? "active"
                      : "completed"
                  }`}
                >
                  <div className="circle">
                    {this.state.stage > DeleteAccountStage.verification ? (
                      <span className="checkmark">
                        <div className="checkmark_stem"></div>
                        <div className="checkmark_kick"></div>
                      </span>
                    ) : (
                      1
                    )}
                  </div>

                  <p className="text">{t("verification")}</p>
                </div>
                <div
                  className={`circle-container ${
                    this.state.stage === DeleteAccountStage.notes
                      ? "active"
                      : this.state.stage === DeleteAccountStage.confirmDelete
                        ? "completed"
                        : ""
                  }`}
                >
                  <div className="circle">
                    {this.state.stage > DeleteAccountStage.notes ? (
                      <span className="checkmark">
                        <div className="checkmark_stem"></div>
                        <div className="checkmark_kick"></div>
                      </span>
                    ) : (
                      2
                    )}
                  </div>
                  <p className="text">{t("important_notes")}</p>
                </div>
                <div
                  className={`circle-container ${
                    this.state.stage === DeleteAccountStage.confirmDelete &&
                    "active"
                  }`}
                >
                  <div className="circle">3</div>
                  <p className="text">{t("confirmation")}</p>
                </div>
              </div>
              {this.getContent(t)}
              {this.getButtonSection()}
            </div>
            <DeleteAccountWindow
              show={this.state.isWindowShow}
              onClose={() => {
                this.setState({
                  isWindowShow: false,
                  stage: DeleteAccountStage.verification,
                  checked: false,
                  reason: "",
                });
              }}
              onDeleteAccount={() => {
                User.instance.postDeleteUser(this.state.reason).catch((err) => {
                  this.setState({
                    isError: true,
                  });
                });
              }}
            />
            <DeleteAccountErrorAlert
              show={this.state.isError}
              onClose={() =>
                this.setState({
                  isError: false,
                })
              }
            />
          </div>
        )}
      </Translation>
    );
  }
}
