import qs from "qs";
import React from "react";
import { Translation } from "react-i18next";
import i18n from "../../../i18n";
import DataManager, { Plan, Plans } from "../../DataManager";
import YatingInput from "../../ui/MainInput";
import { RedeemDlg } from "../../ui/RedeemDlg";
import { ShoppingListDlg } from "../../ui/ShoppingListDlg";
import YatingButton from "../../ui/YatingButton";
import User from "../../User";
import Utils, { InvoiceType } from "../../Utils";

import Config from "../../Config";

export default class PlanTab extends React.Component<
  any,
  {
    processing: boolean;
    coupon: string;
    redeemDlgConfig: {
      open: boolean;
      hide: () => void;
      config?: any;
    };
    shoppingListDlgConfig: {
      open: boolean;
      hide: () => void;
      pay: (config: {
        plan: string;
        count: number;
        invoice: {
          type: number;
          [key: string]: any;
        };
      }) => void;
      plan: Plan;
    };
    [key: string]: any;
  }
> {
  couponAreaRef = React.createRef<HTMLDivElement>();

  constructor(props: any) {
    super(props);

    this.state = {
      processing: false,
      coupon: "",
      redeemDlgConfig: {
        open: false,
        hide: () => {
          this.setState((prevState) => ({
            redeemDlgConfig: {
              ...prevState.redeemDlgConfig,
              open: false,
              config: {},
            },
          }));
        },
      },
      shoppingListDlgConfig: {
        open: false,
        hide: () => {
          this.setState((prevState) => ({
            shoppingListDlgConfig: {
              ...prevState.shoppingListDlgConfig,
              open: false,
              plan: Plans[0],
            },
          }));
        },
        pay: this.pay.bind(this),
        plan: Plans[0],
      },
    };
  }

  handleChange = (e: React.ChangeEvent<{ value: string }>) => {
    this.setState({
      coupon: e.target.value,
    });
  };

  showShoppingList(plan: Plan) {
    this.setState((prevState) => ({
      shoppingListDlgConfig: {
        ...prevState.shoppingListDlgConfig,
        open: true,
        plan: plan,
      },
    }));
  }

  pay(config: {
    plan: string;
    count: number;
    invoice: {
      type: InvoiceType;
      detail: any;
    };
  }) {
    Utils.analyticsEvent({
      category: "Conversion",
      action: "Select plan",
      label: config.plan,
      count: config.count,
      invoice_type: config.invoice.type,
    });

    Utils.getApi("post", "/payment/order")
      .send({ ...config })
      .then((res) => {
        if (res.body.form) {
          // Run script tags in innerHTML content https://ghinda.net/article/script-tags/
          document.write(res.body.form);
        }
      });
  }

  redeem() {
    if ("" === this.state.coupon) {
      return;
    }

    Utils.getApi("put", "/coupon/redeem")
      .send({
        id: this.state.coupon,
      })
      .then((res) => {
        this.setState({
          coupon: "",
        });

        this.setState((prevState) => ({
          redeemDlgConfig: {
            ...prevState.redeemDlgConfig,
            config: res.body,
            open: true,
          },
        }));

        if (res.body.success) {
          DataManager.instance.queryQuotaDetail();
        }
      });

    Utils.analyticsEvent({
      category: "Conversion",
      action: "Redeem coupon",
      label: this.state.coupon,
    });
  }

  componentDidMount() {
    Utils.analyticsPageView("/payment");
    DataManager.instance.updateCurrentLocation(
      location.pathname,
      location.search,
    );

    let params = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    });

    if (params.coupon) {
      this.setState({
        coupon: params.coupon as string,
      });
      window.scrollTo(0, this.couponAreaRef.current.offsetTop);
    }
  }

  plans() {
    return (
      <Translation ns="payment">
        {(t) => (
          <>
            <div className="plans-container">
              <div className={`plan planSubscription`}>
                <div className="plan-badge">
                  <div className="color-rec" />
                  <p className="badge-wording">{t("subscription")}</p>
                </div>
                <div className={`origin-price`}>
                  {User.instance.isSubscribed ? i18n.t("subscribed") : "NEW"}
                </div>
                <p className="value-duration-container">
                  <span className="value">{t("subscription_price")}</span>
                  <span className="duration">
                    / {t("subscription_interval")}
                  </span>
                </p>
                <div className="hint">{t("subscription_desc")}</div>
                <YatingButton
                  size="large"
                  variant="primary"
                  color="main"
                  // onClick={() => {
                  //   window.location.href = Config.AccountCenterPlanUrl;
                  // }}
                  disabled
                >
                  COMING SOON
                  {/* {User.instance.isSubscribed
                    ? i18n.t("see_plans")
                    : i18n.t("use_immediately")} */}
                </YatingButton>
              </div>
              {Plans.map((plan) => {
                return (
                  <div
                    key={plan.id}
                    className={`plan plan${plan.id}`}
                    data-plan={plan.id}
                  >
                    <div className="plan-badge">
                      <div className="color-rec" />
                      <p className="badge-wording">
                        {t(plan.discountWording as any)}
                      </p>
                    </div>
                    <div
                      className={`origin-price ${"A" == plan.id ? "hide" : ""}`}
                    >
                      <del>NT${plan.originPrice}</del>
                    </div>
                    <p className="value-duration-container">
                      <span className="value">NT${plan.finalPrice}</span>
                      <span className="duration">
                        / {t("price_duration", { duration: plan.duration })}
                      </span>
                    </p>
                    <div className="hint">{t("valid_date_description")}</div>
                    <YatingButton
                      size="large"
                      variant="primary"
                      color={plan.color}
                      onClick={this.showShoppingList.bind(this, plan)}
                    >
                      {i18n.t("use_immediately")}
                    </YatingButton>
                  </div>
                );
              })}
            </div>

            <div className="plan contact-us">
              <p className="bulk-order">{t("bulk_order")}</p>
              <YatingButton
                color="main"
                variant="text"
                size="large"
                onClick={() => {
                  let url =
                    "https://docs.google.com/forms/d/e/1FAIpQLScgEUWDJOOxhWs1K37_2LpWo9qfk7o8dQTl4Hy5UvSxIFNg3g/viewform";
                  window.open(
                    `${url}?usp=pp_url&entry.1505676694=${User.instance.profile.name}&entry.1349431871=${User.instance.profile.email}`,
                    "_blank",
                  );
                  Utils.sendAnalyticsOutboundClickEvent({
                    target: {
                      href: url,
                    },
                  });
                }}
              >
                {t("contact_us")}
              </YatingButton>
            </div>
          </>
        )}
      </Translation>
    );
  }

  render() {
    return (
      <Translation ns="payment">
        {(t) => (
          <React.Fragment>
            <div id="plan-tab">
              <div className="inner">
                <h2 className="hide">{t("solutions")}</h2>
                <div className="plans">{this.plans()}</div>
                <div className="caution">
                  <h5>
                    <b>{t("important_notes")}</b>
                  </h5>
                  <ul>
                    <li>{t("notice_calculated_in_second")}</li>
                    <br />
                    <ul>
                      {t("notice_exceed_quota")}
                      <li style={{ marginLeft: "32px", listStyle: "circle" }}>
                        {t("notice_exceed_quota_upload")}
                      </li>
                      <li style={{ marginLeft: "32px", listStyle: "circle" }}>
                        {t("notice_exceed_quota_streaming")}
                      </li>
                    </ul>
                    <br />

                    <li>{t("notice_time_is_not_transferable")}</li>
                    <br />
                    <li>
                      {t("notice_consumer_protection")}
                      <br />
                      {t("notice_no_7_days_refund")}
                    </li>
                  </ul>
                </div>
              </div>
              <ShoppingListDlg
                open={this.state.shoppingListDlgConfig.open}
                pay={this.state.shoppingListDlgConfig.pay.bind(this)}
                hide={this.state.shoppingListDlgConfig.hide.bind(this)}
                plan={this.state.shoppingListDlgConfig.plan}
              />

              <div
                ref={this.couponAreaRef}
                className="redeem-voucher-container"
              >
                <h5 className="redeem-voucher-title">{t("redeem_voucher")}</h5>
                <div className="redeem-input-container">
                  <YatingInput
                    placeholder={t("entry_coupon_code")}
                    value={this.state.coupon}
                    onChange={this.handleChange.bind(this)}
                  />
                  <YatingButton
                    className="redeem-submit-button"
                    size="large"
                    color="main"
                    variant="primary"
                    onClick={this.redeem.bind(this)}
                    disabled={this.state.processing || "" === this.state.coupon}
                  >
                    {i18n.t("submit")}
                  </YatingButton>
                </div>
              </div>
              <RedeemDlg
                open={this.state.redeemDlgConfig.open}
                hide={this.state.redeemDlgConfig.hide.bind(this)}
                config={this.state.redeemDlgConfig.config}
              />
            </div>
          </React.Fragment>
        )}
      </Translation>
    );
  }
}
