import Utils from "../Utils";
import Logger from "../Logger";
import i18n from "../../i18n";

interface Opt {
  numFrames: number;
  numChannels: number;
  sampleRate: number;
  bytesPerSample: number;
}

export interface Device {
  deviceId: string;
  groupId: string;
  label: string;
}

export class AudioController {
  private onAudioProcess: (data: Int16Array, avgVolume: number) => void;
  private onUserMedia: (stream: MediaStream) => void;
  private onUserMediaError: (err: unknown) => void;
  private onDeviceNotSupport: (msg: string, errName: string) => void;
  private onDeviceDetected: (devices: Device[]) => void;

  private stream?: MediaStream;
  private mediaAudioSource?: MediaStreamAudioSourceNode;
  private scriptNode?: ScriptProcessorNode;
  private audioContext?: AudioContext;

  // private voiceChunks: Float32Array[];
  private voiceChunks16: Int16Array[];
  private recordingLength: number;

  private sampleRate: number = 16000;
  private audioType: string = "audio/wave";
  private pause: boolean = false;
  private isAudioIn: boolean = false;

  public constructor(
    onAudioProcess: (data: Int16Array, avgVolume: number) => void,
    onUserMedia: (stream: MediaStream) => void,
    onUserMediaError: (err: unknown) => void,
    onDeviceNotSupport: (msg: string, errName: string) => void,
    onDeviceDetected: (devices: Device[]) => void,
  ) {
    this.onAudioProcess = onAudioProcess;
    this.onUserMedia = onUserMedia;
    this.onUserMediaError = onUserMediaError;
    this.onDeviceNotSupport = onDeviceNotSupport;
    this.onDeviceDetected = onDeviceDetected;
    this.handleUserMedia = this.handleUserMedia.bind(this);
    this.handleUserMediaError = this.handleUserMediaError.bind(this);
  }

  public checkAudioIn(): boolean {
    return this.isAudioIn;
  }

  public closeAudioIn(): void {
    if (this.stream) {
      this.stream.getAudioTracks().map((track) => track.stop());
      this.stream = null;
    }
    if (this.scriptNode) {
      this.scriptNode.onaudioprocess = () => {};
      this.scriptNode.disconnect();
      this.scriptNode = null;
    }
    if (this.audioContext) {
      this.audioContext.close();
      this.audioContext = null;
    }
    this.pause = false;
    this.isAudioIn = false;

    setTimeout(() => {
      this.onAudioProcess(new Int16Array(0), 0);
    }, 100);
  }

  public async openAudioIn(deviceId: string = ""): Promise<void> {
    try {
      if (this.stream) {
        return;
      }

      if (deviceId) {
        this.stream = await navigator.mediaDevices.getUserMedia({
          audio: { deviceId: deviceId },
        });
        this.pause = false;
        this.isAudioIn = true;
        this.handleUserMedia();
      } else {
        if (
          typeof (navigator.mediaDevices || {}).enumerateDevices === "function"
        ) {
          // get permission first
          // device.label is empty before permission granted
          await navigator.mediaDevices.getUserMedia({
            audio: true,
          });

          const deviceList: Device[] = [];
          const defaultList: Device[] = [];
          const devices = await navigator.mediaDevices.enumerateDevices();
          devices.forEach((device) => {
            if (device.kind === "audioinput") {
              if (device.deviceId === "default") {
                defaultList.push({
                  deviceId: device.deviceId,
                  groupId: device.groupId,
                  label: device.label,
                });
              } else {
                deviceList.push({
                  deviceId: device.deviceId,
                  groupId: device.groupId,
                  label: device.label,
                });
              }
            }
          });
          // use to handle duplicate device which has default as deviceId
          // if they are same would have the same groupId
          defaultList.forEach((device) => {
            let exist = false;
            for (let i = 0; i < deviceList.length; i++) {
              if (deviceList[i].groupId === device.groupId) {
                exist = true;
                break;
              }
            }
            if (!exist) {
              deviceList.push(device);
            }
          });
          this.onDeviceDetected(deviceList);
          if (deviceList.length == 1) {
            this.stream = await navigator.mediaDevices.getUserMedia({
              audio: true,
            });
            this.pause = false;
            this.isAudioIn = true;
            this.handleUserMedia();
          } else if (deviceList.length == 0) {
            this.onDeviceNotSupport(
              i18n.t("alert_enable_microphone_setting", {
                ns: "liveTranskribe",
              }),
              "NoMic",
            );
          }
        } else {
          if (Utils.isIOS) {
            this.onDeviceNotSupport(
              i18n.t("alert_unable_to_enable_microphone_safari", {
                ns: "liveTranskribe",
              }),
              "BrowserNotSupport_iOS",
            );
          } else {
            this.onDeviceNotSupport(
              i18n.t("alert_unable_to_enable_microphone_chrome", {
                ns: "liveTranskribe",
              }),
              "BrowserNotSupport",
            );
          }
        }
      }
    } catch (err) {
      Logger.error(`audio controller user media error ${err}`);
      this.handleUserMediaError(err);
    }
  }

  public pauseAudio(): void {
    this.pause = true;
  }

  public resumeAudio(): void {
    this.pause = false;
  }

  public setAudioDeviceId(deviceId: string): void {
    this.pause = false;
    this.openAudioIn(deviceId);
  }

  private handleUserMedia(): void {
    const bufferSize = 2048;
    // this.voiceChunks = [];
    this.voiceChunks16 = [];
    this.recordingLength = 0;
    let AudioContext =
      (window as any).AudioContext ||
      (window as any).webkitAudioContext ||
      (window as any).mozAudioContext;
    this.audioContext = new AudioContext({ sampleRate: this.sampleRate });
    this.mediaAudioSource = this.audioContext.createMediaStreamSource(
      this.stream,
    );
    this.scriptNode = this.audioContext.createScriptProcessor(bufferSize, 1, 1);
    this.scriptNode.onaudioprocess = (e) => {
      if (!this.pause) {
        const data = e.inputBuffer.getChannelData(0);

        const buffer = this.toPCM16Buffer(data, this.audioContext.sampleRate);
        const avgVolume = this.getAmplitude(data);

        // this.voiceChunks.push(new Float32Array(e.inputBuffer.getChannelData(0)));
        this.voiceChunks16.push(buffer);
        this.recordingLength += bufferSize;

        this.onAudioProcess(buffer, avgVolume);
      }
    };
    this.mediaAudioSource.connect(this.scriptNode);
    this.scriptNode.connect(this.audioContext.destination);

    this.onUserMedia(this.stream);
  }

  private handleUserMediaError(err: unknown): void {
    this.onUserMediaError(err);
  }

  private toPCM16Buffer(buffer: Float32Array, sampleRate: number): Int16Array {
    const sampleRateRatio = sampleRate / 16000;
    const newLength = Math.round(buffer.length / sampleRateRatio);
    const result = new Int16Array(newLength);
    let offsetResult = 0;
    let offsetBuffer = 0;
    while (offsetResult < result.length) {
      const nextOffsetBuffer = Math.round((offsetResult + 1) * sampleRateRatio);
      let accum = 0,
        count = 0;
      for (
        let i = offsetBuffer;
        i < nextOffsetBuffer && i < buffer.length;
        i++
      ) {
        accum += buffer[i];
        count++;
      }

      const sample = accum / count;

      // sample is float, convert to PCM value
      const s = Math.max(-1, Math.min(1, sample));
      result[offsetResult] = s < 0 ? s * 0x8000 : s * 0x7fff;

      offsetResult++;
      offsetBuffer = nextOffsetBuffer;
    }
    return result;
  }

  private getAmplitude(buffer: Float32Array): number {
    const basePower = 0.0001;
    let totalPower = 0;
    for (let i = 0; i < buffer.length; i++) {
      totalPower += buffer[i] * buffer[i];
    }
    const avgPower = totalPower / buffer.length;
    let rms = 20.0 * Math.log10(avgPower / basePower);
    rms = rms > 0 ? rms : 0;
    return rms;
  }

  public getWavBlob(): Blob {
    const sampleRate = this.sampleRate;

    // const data = this.flattenArray(this.voiceChunks, this.recordingLength);
    const data = this.flattenInt16Array(
      this.voiceChunks16,
      this.recordingLength,
    );

    // we create our wav file
    const buffer = new ArrayBuffer(44 + data.length * 2);
    // const buffer = new ArrayBuffer(44 + interleaved.length);
    const view = new DataView(buffer);

    // RIFF chunk descriptor
    this.writeUTFBytes(view, 0, "RIFF");
    view.setUint32(4, 44 + data.length, true);
    this.writeUTFBytes(view, 8, "WAVE");
    // FMT sub-chunk
    this.writeUTFBytes(view, 12, "fmt ");
    view.setUint32(16, 16, true); // chunkSize
    view.setUint16(20, 1, true); // wFormatTag
    view.setUint16(22, 1, true); // wChannels: mono (2 channels)
    view.setUint32(24, sampleRate, true); // dwSamplesPerSec
    view.setUint32(28, sampleRate * 2, true); // dwAvgBytesPerSec
    view.setUint16(32, 4, true); // wBlockAlign
    view.setUint16(34, 16, true); // wBitsPerSample
    // data sub-chunk
    this.writeUTFBytes(view, 36, "data");
    view.setUint32(40, data.length * 2, true);

    // write the PCM samples
    let index = 44;

    // for float 32
    // let volume = 1;
    // for (var i = 0; i < data.length; i++) {
    //     view.setInt16(index, data[i] * (0x7FFF * volume), true);
    //     index += 2;
    // }

    // for Float32Array
    for (var i = 0; i < data.length; i++) {
      view.setInt16(index, data[i], true);
      index += 2;
    }

    // our Int16Array
    const blob = new Blob([view], { type: this.audioType });
    return blob;
  }

  private flattenInt16Array(
    channelBuffer: Int16Array[],
    recordingLength: number,
  ) {
    const result = new Int16Array(recordingLength);
    if (channelBuffer) {
      let offset = 0;
      for (let i = 0; i < channelBuffer.length; i++) {
        let buffer = channelBuffer[i];
        result.set(buffer, offset);
        offset += buffer.length;
      }
    }
    return result;
  }

  private flattenArray(channelBuffer: Float32Array[], recordingLength: number) {
    const result = new Float32Array(recordingLength);
    let offset = 0;
    for (let i = 0; i < channelBuffer.length; i++) {
      let buffer = channelBuffer[i];
      result.set(buffer, offset);
      offset += buffer.length;
    }
    return result;
  }

  private writeUTFBytes(view: DataView, offset: number, string: string) {
    for (var i = 0; i < string.length; i++) {
      view.setUint8(offset + i, string.charCodeAt(i));
    }
  }
}
