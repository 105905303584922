import { observable, action } from "mobx";
import * as superagent from "superagent";
import { MessageBoxColor } from "./GlobalDefine";
import DataManager from "./DataManager";
import Utils, { SiteMessage } from "./Utils";
import i18n from "../i18n";
import Config from "./Config";

const acceptFormat = "audio/*, video/*";
const maxSize = Config.MaxUploadFileSizeGB * 1024 * 1024 * 1024;
const maxUploadQueue = 100;

export enum FileUploadStatus {
  Idle = 1,
  NoFile,
  OverSize,
  TooManyInQueue,
  WrongFormat,
  Pending,
  Uploading,
  UploadInvalid,
  UploadFailed,
  UploadCancelled,
  UploadSuccess,
}

export default class FileUploader {
  private static _instance: FileUploader = null;

  static get acceptFormat(): string {
    return acceptFormat;
  }

  static get maxGB(): number {
    return Config.MaxUploadFileSizeGB;
  }

  static get instance(): FileUploader {
    if (!FileUploader._instance) {
      FileUploader._instance = new FileUploader();
    }

    return FileUploader._instance;
  }

  @observable uploadingFiles?: File[];
  @observable uploading?: {
    lang: string[];
    status: FileUploadStatus[];
    progress: number[];
  };
  private uploadingFilesReqs?: superagent.SuperAgentRequest[];
  private currentProcressIndex = -1;

  public addUploadFiles(lang: string, files?: FileList) {
    if (!files) {
      return;
    }
    if (
      maxUploadQueue <
      files.length + (this.uploadingFiles ? this.uploadingFiles.length : 0)
    ) {
      //show error in UI
      SiteMessage.instance.showMessage(
        i18n.t("alert_upload_limit", { maxUploadQueue }),
        MessageBoxColor.Red,
      );
      Utils.analyticsEvent({
        category: "Upload",
        action: "Upload too many files",
        label: `${files.length}/${maxUploadQueue}`,
        type: "file",
        language: lang,
      });
    } else {
      Utils.analyticsEvent({
        category: "Upload",
        action: "Upload files",
        value: files.length,
        type: "file",
        language: lang,
      });
      this.UploadAcceptedFiles(lang, files);
    }
  }

  public cancel(idx: number) {
    if (this.uploadingFilesReqs && this.uploadingFilesReqs[idx]) {
      this.uploadingFilesReqs[idx].abort();
    } else if (
      this.uploading &&
      this.uploading.status &&
      (this.uploading.status[idx] === FileUploadStatus.Idle ||
        this.uploading.status[idx] === FileUploadStatus.Pending)
    ) {
      this.uploading.status[idx] = FileUploadStatus.UploadCancelled;
      this.uploading = JSON.parse(JSON.stringify(this.uploading));
    }
  }

  public cancelAll() {
    if (this.uploadingFiles) {
      for (let i = 0; i < this.uploadingFiles.length; i++) {
        this.cancel(i);
      }
    }
  }

  public reset() {
    this.cancelAll();
    this.uploadingFiles = undefined;
    this.uploadingFilesReqs = undefined;
    this.uploading = undefined;
    this.currentProcressIndex = -1;
    DataManager.instance.queryVoiceList();
  }

  public get isCompleted(): boolean {
    if (!this.uploadingFiles) {
      return true;
    }

    let isCompleted = true;
    for (let i = 0; i < this.uploadingFiles.length; i++) {
      if (this.uploading.status[i] === FileUploadStatus.Uploading) {
        isCompleted = false;
      } else if (
        this.uploading.status[i] !== FileUploadStatus.Idle &&
        this.uploading.status[i] !== FileUploadStatus.Pending
      ) {
      } else {
        isCompleted = false;
      }
    }

    return isCompleted;
  }

  public get progressInPercent(): number {
    // return 0 ~ 100
    if (!this.uploadingFiles) {
      return 0;
    }

    let progress = 0;
    for (let i = 0; i < this.uploadingFiles.length; i++) {
      if (this.uploading.status[i] === FileUploadStatus.Uploading) {
        progress += this.uploading.progress[i] ? this.uploading.progress[i] : 0;
      } else if (
        this.uploading.status[i] !== FileUploadStatus.Idle &&
        this.uploading.status[i] !== FileUploadStatus.Pending
      ) {
        progress += 100;
      }
    }

    progress = progress / this.uploadingFiles.length;
    return progress;
  }

  public get failedCount(): number {
    if (!this.uploadingFiles) {
      return 0;
    }

    let failedCount = 0;
    for (let i = 0; i < this.uploadingFiles.length; i++) {
      if (
        this.uploading.status[i] === FileUploadStatus.OverSize ||
        this.uploading.status[i] === FileUploadStatus.TooManyInQueue ||
        this.uploading.status[i] === FileUploadStatus.WrongFormat ||
        this.uploading.status[i] === FileUploadStatus.UploadInvalid ||
        this.uploading.status[i] === FileUploadStatus.UploadFailed
      ) {
        failedCount += 1;
      }
    }
    return failedCount;
  }

  @action
  private async UploadAcceptedFiles(lang: string, files: FileList) {
    let newFiles = Array.from(files);
    let newUploadingFilesReqs = [];
    let newStatus: FileUploadStatus[] = [];
    let newProgress: number[] = [];
    let newLang: string[] = [];
    for (let i = 0; i < files.length; i++) {
      newStatus[i] = FileUploadStatus.Idle;
      newProgress[i] = 0;
      newLang[i] = lang;
      newUploadingFilesReqs[i] = null;
    }

    let runProcess = false;
    if (this.uploadingFiles && this.uploadingFiles.length > 0) {
      if (this.currentProcressIndex >= this.uploadingFiles.length) {
        runProcess = true;
      }

      // append upload
      this.uploadingFilesReqs = this.uploadingFilesReqs.concat(
        newUploadingFilesReqs,
      );
      this.uploadingFiles = this.uploadingFiles.concat(newFiles);
      this.uploading.status = this.uploading.status.concat(newStatus);
      this.uploading.progress = this.uploading.status.concat(newProgress);
      this.uploading.lang = this.uploading.lang.concat(newLang);
      this.uploading = JSON.parse(JSON.stringify(this.uploading));
    } else {
      // new upload
      this.uploadingFilesReqs = newUploadingFilesReqs;
      this.uploadingFiles = newFiles;
      this.uploading = {
        lang: newLang,
        status: newStatus,
        progress: newProgress,
      };

      this.currentProcressIndex = 0;
      runProcess = true;
    }

    if (runProcess) {
      while (this.currentProcressIndex < this.uploadingFiles.length) {
        if (
          this.uploading.status[this.currentProcressIndex] ===
          FileUploadStatus.UploadCancelled
        ) {
        } else {
          const file = this.uploadingFiles[this.currentProcressIndex];
          const fileLang = this.uploading.lang[this.currentProcressIndex];
          // check file format
          let fileStatus = this.checkFileFormat(fileLang, file);
          this.uploading.status[this.currentProcressIndex] = fileStatus;
          if (fileStatus === FileUploadStatus.Pending) {
            // upload
            await this.uploadFile(fileLang, file, this.currentProcressIndex);
          } else {
            this.uploading = JSON.parse(JSON.stringify(this.uploading));
          }
        }
        this.currentProcressIndex += 1;
      }
    }
  }

  private checkFileFormat(lang: string, file: File): FileUploadStatus {
    let pass = true;
    let status = FileUploadStatus.Pending;
    let gaLabel = "";
    if (file.size > maxSize) {
      pass = false;
      status = FileUploadStatus.OverSize;
      gaLabel = "oversize";
    } else if ((file.type as string).includes("wma")) {
      pass = false;
      status = FileUploadStatus.WrongFormat;
      gaLabel = `not audio format: ${file.type}`;
    }

    if (!pass) {
      Utils.analyticsEvent({
        category: "Upload",
        action: "Upload rejected",
        label: gaLabel,
        value: file.size,
        type: "file",
        language: lang,
      });
    }

    return status;
  }

  @action
  private async uploadFile(
    lang: string,
    file: File,
    idx: number,
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      let formData = new FormData();
      formData.append("file-single", file);
      formData.append("lang", lang);

      let req = Utils.getApi("post", "/upload/file")
        .timeout({
          response: 100 * 60 * 1000, //等 100 分鐘
          deadline: 100 * 60 * 1000,
        })
        .send(formData);

      this.uploadingFilesReqs[idx] = req;
      console.log("req on prohress");
      req
        .on("progress", (event: superagent.ProgressEvent) => {
          this.uploading.progress[idx] = event.percent;
          this.uploading = JSON.parse(JSON.stringify(this.uploading));
        })
        .then((res: any) => {
          DataManager.instance.queryVoiceList();

          if (!res.body.success || res.body.invalid.length != 0) {
            this.uploading.status[idx] =
              res.body.errCode === -1
                ? FileUploadStatus.TooManyInQueue
                : FileUploadStatus.UploadInvalid;

            Utils.analyticsEvent({
              category: "Upload",
              action: "Upload failed",
              label: "include invalid files",
              status: FileUploadStatus[this.uploading.status[idx]],
              value: file.size,
              type: "file",
              language: lang,
            });
            Utils.analyticsUserPropertiesAddValue([
              {
                property: `Upload failed - file - ${lang}`,
                value: 1,
              },
            ]);
          } else {
            this.uploading.status[idx] = FileUploadStatus.UploadSuccess;

            Utils.analyticsEvent({
              category: "Upload",
              action: "Upload success",
              value: file.size,
              type: "file",
              language: lang,
              duration:
                res.body.valid &&
                res.body.valid.length === 1 &&
                res.body.valid[0].duration >= 0
                  ? res.body.valid[0].duration
                  : -1,
            });
            Utils.analyticsUserPropertiesAddValue([
              {
                property: `Upload success - file - ${lang}`,
                value: 1,
              },
            ]);
          }
        })
        .catch((err) => {
          if (err.code === "ABORTED") {
            Utils.analyticsEvent({
              category: "Upload",
              action: "Upload canncelled",
              value: file.size,
              type: "file",
              error: err.code,
              language: lang,
            });
            this.uploading.status[idx] = FileUploadStatus.UploadCancelled;
          } else {
            Utils.analyticsEvent({
              category: "Upload",
              action: "Upload failed",
              value: file.size,
              type: "file",
              error: err.code,
              language: lang,
            });
            Utils.analyticsUserPropertiesAddValue([
              {
                property: `Upload failed - file - ${lang}`,
                value: 1,
              },
            ]);
            this.uploading.status[idx] = FileUploadStatus.UploadFailed;
          }
        })
        .finally(() => {
          this.uploadingFilesReqs[idx] = null;
          this.uploading = JSON.parse(JSON.stringify(this.uploading));
          resolve();
        });

      this.uploadingFilesReqs[idx] = req;
      this.uploading.status[idx] = FileUploadStatus.Uploading;
      this.uploading = JSON.parse(JSON.stringify(this.uploading));
    });
  }
}
