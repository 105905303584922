import React from "react";
import Dropdown from "semantic-ui-react/dist/commonjs/modules/Dropdown";
import Modal from "semantic-ui-react/dist/commonjs/modules/Modal";
import {
  ModalHeader,
  ModalActions,
  UserIcon,
  ActionButton,
} from "../ui/Component";
import { ButtonColor, getUserIconColorFromName } from "../GlobalDefine";
import Voice, {
  UserProfile,
  Permission,
  getPermissionName,
  ShareInfo,
} from "../Voice";
import User from "../User";
import Utils from "../Utils";
import Logger from "../Logger";
import Config from "../Config";
import i18n from "../../i18n";
import { Translation } from "react-i18next";

import "../../assets/scss/ShareSettingWindow.scss";
import cancelIcon from "../../assets/img/icon-cancel-dark.svg";
import urlIcon from "../../assets/img/icon-link.svg";

class ShareToUsers extends React.Component<
  {
    readOnly: boolean;
    onValueChanged: (emails: string[], sharePermission: Permission) => void;
  },
  {
    emails: string[];
    emailInput: string;
    emailError: string;
    sharePermission: Permission;
  }
> {
  private isComponentMounted: boolean;

  constructor(props: any) {
    super(props);
    this.state = {
      emails: [],
      emailInput: "",
      emailError: undefined,
      sharePermission: Permission.Edit,
    };
  }

  componentDidMount() {
    this.isComponentMounted = true;
  }

  componentWillUnmount() {
    this.isComponentMounted = false;
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (
      prevState.emails != this.state.emails ||
      prevState.sharePermission != this.state.sharePermission
    ) {
      this.props.onValueChanged(this.state.emails, this.state.sharePermission);
    }
  }

  public reset() {
    if (this.isComponentMounted) {
      this.setState({
        emails: [],
        emailInput: "",
        emailError: undefined,
        sharePermission: Permission.Edit,
      });
    }
  }

  private async emailOnBlur() {
    var value = this.state.emailInput.trim();
    if (value && (await this.isValidEmail(value))) {
      this.setState({
        emails: [...this.state.emails, value],
        emailInput: "",
        emailError: undefined,
      });
    }
  }

  private emailKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
    if (["Enter", "Tab", ","].includes(event.key)) {
      event.preventDefault();

      this.addEmail();
    }
  }

  private async addEmail() {
    const value = this.state.emailInput.trim();
    if (value && (await this.isValidEmail(value))) {
      this.setState({
        emails: [...this.state.emails, value],
        emailInput: "",
        emailError: undefined,
      });
    }
  }

  private emailChange(event: React.ChangeEvent<HTMLInputElement>) {
    this.setState({
      emailInput: event.target.value,
      emailError: undefined,
    });
  }

  private emailPaste(event: React.ClipboardEvent<HTMLInputElement>) {}

  private async checkExistUser(email: string) {
    let isExist = false;
    try {
      const res = await Utils.getApi(
        "post",
        "/external/checkJudicialEmail",
      ).send({
        email: email,
      });
      if (res.body.isExist) {
        isExist = true;
      }
    } catch (err) {
      Logger.error(
        `error occur when check valid user in shareSettingWindow, err: `,
        err,
      );
    }
    return isExist;
  }

  private async isValidEmail(email: string) {
    let error = undefined;

    if (this.state.emails.includes(email)) {
      error = i18n.t("email_has_entered", { email, ns: "share" });
    }

    if (!Utils.isValidEmail(email)) {
      error = i18n.t("invalid_email", { email, ns: "share" });
    }

    if (Config.CheckUserExistBeforeShare) {
      const isExist = await this.checkExistUser(email);
      if (!isExist) {
        error = i18n.t("user_is_not_valid", { ns: "share" });
      }
    }

    if (error) {
      this.setState({ emailError: error });
      return false;
    }

    return true;
  }

  private removeEmail(email: string) {
    const index = this.state.emails.indexOf(email);
    if (index > -1) {
      let newEmails = this.state.emails.slice();
      newEmails.splice(index, 1);
      this.setState({
        emails: newEmails,
      });
    }
  }

  render() {
    return (
      <Translation ns="share">
        {(t) => (
          <div className="share-to-user-container">
            {this.props.readOnly ? (
              <div className="share-readonly-message">
                {i18n.t("request_for_access", { ns: "share" })}
              </div>
            ) : (
              <>
                <div className="share-email-input-container small-scroller hide-scroller">
                  <div className="label">{i18n.t("add")}:</div>
                  <div className="input-and-help-text-container">
                    {this.state.emailError && (
                      <div className="email-input-error">
                        {this.state.emailError}
                      </div>
                    )}
                    <input
                      className={
                        "fix-child-style share-email-input" +
                        (this.state.emailError ? " has-error" : "")
                      }
                      placeholder={t("enter_email")}
                      value={this.state.emailInput}
                      onBlur={this.emailOnBlur.bind(this)}
                      onKeyDown={this.emailKeyDown.bind(this)}
                      onChange={this.emailChange.bind(this)}
                      onPaste={this.emailPaste.bind(this)}
                    />
                  </div>

                  <button
                    className="add-button"
                    disabled={this.state.emailInput.length == 0}
                  >
                    {i18n.t("add")}
                  </button>

                  <div className="fix-child-style permission-selector">
                    <Dropdown
                      selection
                      fluid
                      direction="left"
                      options={[
                        {
                          text: getPermissionName(Permission.Read),
                          value: Permission[Permission.Read],
                        },
                        {
                          text: getPermissionName(Permission.Edit),
                          value: Permission[Permission.Edit],
                        },
                      ]}
                      value={Permission[this.state.sharePermission]}
                      onChange={(event, data) => {
                        let value = data.value.toString();
                        this.setState({
                          sharePermission:
                            Permission[value as keyof typeof Permission],
                        });
                      }}
                    />
                  </div>
                </div>
                <div>
                  {this.state.emails.map((email) => (
                    <div className="share-email-item" key={email}>
                      {email}
                      <button
                        type="button"
                        className="button"
                        onClick={() => this.removeEmail(email)}
                      >
                        <img src={cancelIcon} />
                      </button>
                    </div>
                  ))}
                </div>
              </>
            )}
          </div>
        )}
      </Translation>
    );
  }
}

class SharedUser extends React.Component<{
  readOnly: boolean;
  name: string;
  email: string;
  isYou: boolean;
  picture?: string;
  isOwner?: boolean;
  isLoading?: boolean;
  upward: boolean;
  permission?: Permission;
  onRevokeShare?: () => void;
  onUpdateShare?: (newPermission: Permission) => void;
}> {
  render() {
    return (
      <Translation>
        {(t) => (
          <div className="shared-user-item">
            <UserIcon
              imgUrl={this.props.picture}
              name={this.props.name}
              email={this.props.email}
              size="34px"
            />
            <div className="user-info-container">
              <div className="name">{`${this.props.name}${
                this.props.isYou ? ` (${t("you")})` : ""
              }`}</div>
              <div className="email">{this.props.email}</div>
            </div>
            <div className="shared-permission-container fix-child-style">
              {this.props.isOwner ? (
                <span className="string">{t("owner")}</span>
              ) : this.props.readOnly ? (
                <span className="string">
                  {getPermissionName(this.props.permission)}
                </span>
              ) : (
                <Dropdown
                  selection
                  fluid
                  direction="left"
                  upward={this.props.upward}
                  options={[
                    {
                      text: getPermissionName(Permission.Read),
                      value: Permission[Permission.Read],
                    },
                    {
                      text: getPermissionName(Permission.Edit),
                      value: Permission[Permission.Edit],
                    },
                    {
                      text: t("remove"),
                      value: "Revoke",
                      className: "red",
                    },
                  ]}
                  value={Permission[this.props.permission]}
                  loading={this.props.isLoading}
                  disabled={this.props.isLoading}
                  onChange={(event, data) => {
                    if ("Revoke" == data.value.toString()) {
                      this.props.onRevokeShare();
                    } else {
                      const value = data.value.toString();
                      this.props.onUpdateShare(
                        Permission[value as keyof typeof Permission],
                      );
                    }
                  }}
                />
              )}
            </div>
          </div>
        )}
      </Translation>
    );
  }
}

class SharedUsers extends React.Component<
  {
    readOnly: boolean;
    isOwner: boolean;
    owner: UserProfile;
    shared: ShareInfo[];
    updatingShared: string[];
    onRevokeShare: (share: ShareInfo) => void;
    onUpdateShare: (share: ShareInfo, newPermission: Permission) => void;
  },
  {
    isScrolling: boolean;
  }
> {
  private isComponentMounted: boolean;
  private resetScoller: number;

  constructor(props: any) {
    super(props);
    this.state = {
      isScrolling: false,
    };
  }

  componentDidMount() {
    this.isComponentMounted = true;
  }

  componentWillUnmount() {
    this.isComponentMounted = false;
  }

  render() {
    return (
      <>
        <div
          className={`shared-users-container small-scroller${this.state.isScrolling ? "" : " hide-scroller"}`}
          onScroll={(event) => {
            if (this.resetScoller) {
              clearTimeout(this.resetScoller);
            }
            this.setState({
              isScrolling: true,
            });
            setTimeout(() => {
              if (this.isComponentMounted) {
                this.setState({
                  isScrolling: false,
                });
              }
            }, 1000);
          }}
        >
          <SharedUser
            readOnly={this.props.readOnly}
            name={
              this.props.owner.name && this.props.owner.name.length > 0
                ? this.props.owner.name
                : this.props.owner.email
            }
            email={this.props.owner.email}
            picture={this.props.owner.picture}
            isYou={this.props.isOwner}
            isOwner={true}
            upward={false}
          />

          {this.props.shared.map((shared, idx) => {
            let name =
              shared.userName && shared.userName.length > 0
                ? shared.userName
                : shared.userEmail;
            let loading = this.props.updatingShared.includes(
              shared.userId || shared.userEmail,
            );
            return (
              <SharedUser
                key={idx}
                readOnly={this.props.readOnly}
                name={name}
                email={shared.userEmail}
                picture={shared.userPicture}
                isYou={
                  shared.userId && User.instance.profile.id === shared.userId
                }
                isOwner={false}
                isLoading={loading}
                upward={idx >= 1}
                permission={shared.permission}
                onRevokeShare={() => {
                  this.props.onRevokeShare(shared);
                }}
                onUpdateShare={(permission) => {
                  this.props.onUpdateShare(shared, permission);
                }}
              />
            );
          })}
        </div>
      </>
    );
  }
}

class ShareByUrl extends React.Component<
  {
    readOnly: boolean;
    file_eid: string;
    enableUrlShare: boolean;
    startTime: number;
    onChangeUrlShare: (enable: boolean) => void;
    onCopyToClipboard: (startTime: number) => void;
  },
  {
    enableStartTime: boolean;
    startTime: number;
  }
> {
  private _urlInput: React.RefObject<HTMLInputElement>;

  constructor(props: any) {
    super(props);
    this._urlInput = React.createRef<HTMLInputElement>();
    this.state = {
      enableStartTime: false,
      startTime: props.startTime,
    };
  }

  shouldComponentUpdate(nextProps: any, nextState: any) {
    if (nextProps.startTime != this.props.startTime) {
      this.setState({
        startTime: nextProps.startTime,
      });
    }
    return true;
  }

  private onBlurSrarttime(event: React.ChangeEvent<HTMLInputElement>) {
    let value = event.target.value;
    let result = 0;
    if (value.length < 1) {
    } else if (value.match(/[0-9]+(:[0-9]+){0,2}/i)) {
      let values = value.split(":");
      for (let i = 1; i <= Math.min(3, values.length); i++) {
        result +=
          Number.parseInt(values[values.length - i]) * Math.pow(60, i - 1);
      }
    }

    event.target.value = this.getTimeString(result);
    this.setState(
      {
        startTime: result,
      },
      () => {
        this.copyUrlToClipboard();
      },
    );
  }

  private onKeyDownSrarttime(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.keyCode == 13) {
      // enter
      (event.target as HTMLElement).blur();
    }
  }

  private getShareUrl(): string {
    let url = `${window.location.protocol}//${window.location.host}/file`;
    url += `?eid=${this.props.file_eid}`;
    if (this.state.enableStartTime && this.state.startTime > 0) {
      url += `&t=${this.state.startTime}`;
    }
    return url;
  }

  private getTimeString(time: number): string {
    const result = [];
    result.push(
      Math.floor(time / 3600)
        .toString()
        .padStart(2, "0"),
    );
    result.push(
      Math.floor((time % 3600) / 60)
        .toString()
        .padStart(2, "0"),
    );
    result.push((time % 60).toString().padStart(2, "0"));
    return result.join(":");
  }

  private copyUrlToClipboard() {
    if (this._urlInput.current) {
      this._urlInput.current.select();
      this._urlInput.current.setSelectionRange(0, 99999);
      document.execCommand("copy");
      this.props.onCopyToClipboard(this.state.startTime);
    }
  }

  render() {
    return (
      <Translation ns="share">
        {(t) => (
          <div className="share-url-container">
            <div className="title">{t("get_link")}</div>
            <div className="copy-link">
              <input
                readOnly
                ref={this._urlInput}
                value={this.getShareUrl()}
                className="share-url-input"
              />
              <div
                className="clcikable"
                onClick={() => {
                  this.copyUrlToClipboard();
                }}
              >
                {t("copy_link")}
              </div>
            </div>
            <div style={{ padding: "4px 0px 0px 0px" }}>
              <input
                type="checkbox"
                className="share-starttime-checkbox"
                checked={this.state.enableStartTime}
                onChange={(event) => {
                  this.setState({
                    enableStartTime: event.target.checked,
                  });
                }}
              />
              <span
                style={{
                  color: this.state.enableStartTime ? "" : "#babec2",
                }}
              >
                {t("start_at")}
              </span>
              <input
                type="text"
                className={`share-starttime-input${this.state.enableStartTime ? "" : " disabled"}`}
                disabled={!this.state.enableStartTime}
                defaultValue={this.getTimeString(this.state.startTime)}
                onBlur={this.onBlurSrarttime.bind(this)}
                onKeyDown={this.onKeyDownSrarttime.bind(this)}
              />
            </div>
            <div className="share-url-permission-setting">
              <img src={urlIcon} />
              <div className="permission-selector">
                <Dropdown
                  inline
                  direction="left"
                  options={[
                    { text: t("limitation"), value: false },
                    { text: i18n.t("viewer"), value: true },
                  ]}
                  value={this.props.enableUrlShare}
                  disabled={this.props.readOnly}
                  onChange={(event, data) => {
                    this.props.onChangeUrlShare(data.value as boolean);
                  }}
                />
                <div className="message">
                  {this.props.enableUrlShare
                    ? t("anyone_with_the_link_can_view")
                    : t("only_added_can_view")}
                </div>
              </div>
            </div>
          </div>
        )}
      </Translation>
    );
  }
}

export default class ShareSettingWindow extends React.Component<
  {
    show: boolean;
    file?: Voice;
    startTime: number;
    ownerPage: string;
    onClose: () => void;
  },
  {
    emails: string[]; // user inputed emails to add share
    sharePermission: Permission; // permission setting for adding share
    shareMessage: string; // message to send for adding share
    message: string; // message to shown
  }
> {
  private isComponentMounted: boolean;
  private _emailInput: React.RefObject<ShareToUsers>;

  constructor(props: any) {
    super(props);
    this._emailInput = React.createRef<ShareToUsers>();
    this.state = {
      emails: [],
      sharePermission: Permission.Edit,
      shareMessage: "",
      message: "",
    };
  }

  componentDidMount() {
    this.isComponentMounted = true;
  }

  componentWillUnmount() {
    this.isComponentMounted = false;
  }

  private reset() {
    if (this.isComponentMounted) {
      this.setState({
        emails: [],
        sharePermission: Permission.Edit,
        shareMessage: "",
        message: "",
      });
    }
  }

  private showMessage(message: string) {
    this.setState({
      message: message,
    });
    setTimeout(
      function () {
        this.setState({
          message: "",
        });
      }.bind(this),
      1500,
    );
  }

  render() {
    if (!this.props.file) {
      return null;
    }
    let isAddingShares = this.state.emails.length > 0;

    return (
      <React.Fragment>
        <Translation ns="share">
          {(t) => (
            <Modal open={this.props.show} size="tiny" dimmer="blurring">
              <ModalHeader
                title={t("share_setting")}
                textAlign="left"
                onClose={() => {
                  this.reset();
                  this.props.onClose();
                }}
              />
              <ShareToUsers
                ref={this._emailInput}
                readOnly={!this.props.file.canEditShare}
                onValueChanged={(
                  emails: string[],
                  sharePermission: Permission,
                ) => {
                  this.setState({
                    emails: emails,
                    sharePermission: sharePermission,
                  });
                }}
              />
              {isAddingShares ? (
                <>
                  <textarea
                    className="small-scroller send-option-message"
                    placeholder={t("message")}
                    value={this.state.shareMessage}
                    onChange={(event) => {
                      if (this.isComponentMounted) {
                        this.setState({
                          shareMessage: event.target.value,
                        });
                      }
                    }}
                  />
                  <ModalActions
                    actions={[
                      {
                        color: ButtonColor.Orange,
                        content: this.props.file.isAddingShare
                          ? i18n.t("saving")
                          : i18n.t("share"),
                        disabled: this.props.file.isAddingShare,
                        onClick: () => {
                          this.props.file
                            .addShares(
                              this.state.emails,
                              this.state.sharePermission,
                              this.props.ownerPage,
                              this.state.shareMessage,
                            )
                            .then((success) => {
                              if (success) {
                                this._emailInput.current.reset();
                                this.setState({
                                  shareMessage: "",
                                });
                              } else {
                              }
                            });
                          this.forceUpdate();
                        },
                      },
                    ]}
                  />
                </>
              ) : (
                <>
                  <SharedUsers
                    readOnly={!this.props.file.canEditShare}
                    isOwner={this.props.file.is_owner}
                    owner={this.props.file.owner}
                    shared={this.props.file.shared}
                    updatingShared={this.props.file.updatingShared}
                    onRevokeShare={(share) => {
                      this.props.file
                        .revokeShare(share, this.props.ownerPage)
                        .then(() => {
                          this.forceUpdate();
                        });
                      this.forceUpdate();
                    }}
                    onUpdateShare={(share, newPermission) => {
                      this.props.file
                        .updateShare(share, newPermission, this.props.ownerPage)
                        .then(() => {
                          this.forceUpdate();
                        });
                      this.forceUpdate();
                    }}
                  />
                  {Config.EnableShareByURL && (
                    <ShareByUrl
                      readOnly={!this.props.file.canEditShare}
                      file_eid={this.props.file.eid}
                      enableUrlShare={
                        this.props.file.accessByUrlPermission >= Permission.Read
                      }
                      startTime={this.props.startTime}
                      onChangeUrlShare={(enable) => {
                        this.props.file
                          .changeAccessByUrlPermission(
                            enable ? Permission.Read : Permission.None,
                            this.props.ownerPage,
                          )
                          .then(() => {
                            this.forceUpdate();
                          });
                        this.forceUpdate();
                      }}
                      onCopyToClipboard={(startTime: number) => {
                        this.showMessage(i18n.t("copied_to_clipboard"));
                        Utils.analyticsEvent({
                          category: "Share",
                          action: "Copied Share Link",
                          value: startTime,
                          page: this.props.ownerPage,
                          duration: this.props.file.duration,
                          file_name: this.props.file.name,
                        });
                      }}
                    />
                  )}
                </>
              )}
              {this.state.message.length > 0 ? (
                <div className="share-message-box">{this.state.message}</div>
              ) : (
                ""
              )}
            </Modal>
          )}
        </Translation>
      </React.Fragment>
    );
  }
}
