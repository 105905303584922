import { observable, computed } from "mobx";
import Utils from "./Utils";
import Logger from "./Logger";

// Sync with backend streaming.ts (StreamingTaskInfo)
export interface StreamingTaskInfo {
  key: string;
  rtmpUrl: string;
  rtmpKey: string;
  asrLang: string;
  ytStreamKey: string;
  ytIngestionUrl: string;
  fbStreamKey: string;
}

export enum StreamState {
  STOP = "STOP",
  RUNNING = "RUNNING",
  RESTARTING = "RESTARTING",
  STARTING = "STARTING",
}

interface CcStateDto {
  sessionId: string;
  state: StreamState;
}

interface ProxyStateDto {
  sessionId: string;
  fb?: boolean;
  yt?: boolean;
}

// Sync with backend streaming.ts (StreamingTaskInfo)
export interface StreamingTaskStatus {
  cc: CcStateDto;
  proxy: ProxyStateDto;
}

export default class StreamingTask {
  @observable info: StreamingTaskInfo;
  @observable state: StreamingTaskStatus;

  constructor(info: StreamingTaskInfo) {
    this.info = info;
  }

  @computed
  public get key(): string {
    return this.info?.key;
  }

  @computed
  public get ytStreamKey(): string {
    return this.info?.ytStreamKey;
  }

  @computed
  public get ytIngestionUrl(): string {
    return this.info?.ytIngestionUrl;
  }

  @computed
  public get fbStreamKey(): string {
    return this.info?.fbStreamKey;
  }

  @computed
  public get asrLang(): string {
    return this.info?.asrLang;
  }

  @computed
  public get rtmpUrl(): string {
    return this.info?.rtmpUrl;
  }

  @computed
  public get rtmpKey(): string {
    return this.info?.rtmpKey;
  }

  @computed
  public get ccStatus(): string {
    return this.state?.cc.state;
  }

  @computed
  public get ytConnectionStatus(): boolean {
    return this.state?.proxy.yt;
  }

  @computed
  public get fbConnectionStatus(): boolean {
    return this.state?.proxy.fb;
  }

  public updateStatus(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let req = Utils.getApi("post", "/streaming/state").send({
        key: this.key,
      });

      let success = false;
      req
        .then((res) => {
          if (res.body.success) {
            this.state = res.body.data;
            success = true;
          }
        })
        .catch((err) => {
          Logger.error(`get streaming task status error ${err}`, {
            errorMessage: err.message,
            errorName: err.name,
          });
        })
        .finally(() => {
          resolve(success);
        });
    });
  }

  public restartYoutubeConnection(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let req = Utils.getApi("put", "/streaming/proxy").send({
        key: this.key,
        yt: {
          restart: true,
        },
      });

      let success = false;
      req
        .then((res) => {
          success = res.body.success;
        })
        .catch((err) => {
          Logger.error(
            `streaming task restart youtube connection error ${err}`,
            {
              errorMessage: err.message,
              errorName: err.name,
            },
          );
        })
        .finally(() => {
          resolve(success);
        });
    });
  }

  public restartCcConnection(): Promise<boolean> {
    return new Promise((resolve, _) => {
      let request = Utils.getApi("put", "/streaming/cc").send({
        key: this.key,
        command: {
          restart: true,
        },
      });

      let success = false;
      request
        .then((response) => {
          success = response.body.success;
        })
        .catch((error) => {
          Logger.error(`streaming task restart cc error ${error}`, {
            errorMessage: error.message,
            errorName: error.name,
          });
        })
        .finally(() => {
          resolve(success);
        });
    });
  }
}
