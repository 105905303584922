import React from "react";

export const CrownIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 14 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.1949 9.63824H1.79971H1.61011V11.4166H3.33181H10.6628H12.3899V9.63824H12.1949Z"
      fill="currentColor"
    />
    <path
      d="M13.5 0.0415649L9.95238 4.68009L7 0.0434538L4.04762 4.68009L0.5 0.0415649L1.6101 8.4428H3.33181H10.6628H12.3899L13.5 0.0415649Z"
      fill="currentColor"
    />
  </svg>
);
