import React from "react";

import loadingGif from "../../assets/img/animation-loading-all.gif";

export default class LoadingPage extends React.Component {
  render() {
    return (
      <div className="loading-gif-container">
        <img src={loadingGif} />
      </div>
    );
  }
}
