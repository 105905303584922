const PUB_PATH = process.env.PUBLIC_PATH || "/";
const PUBLIC_HOST = process.env.PUBLIC_HOST || "asr.yating.tw";
const DISABLE_TRACK_USER_EVENT = process.env.TRACK_USER_EVENT === "true";
const DISABLE_LOG = process.env.DISABLE_LOG === "true";
const UNIVERSAL_LINK_URL =
  process.env.UNIVERSAL_LINK_URL || "https://applink-asr.yating.tw";
const ART_STUDIO_SONG_URL = process.env.ART_STUDIO_SONG_URL;
const STUDIO_VIDEO_URL = process.env.STUDIO_VIDEO_URL;
const STUDIO_AVATAR_URL = process.env.STUDIO_AVATAR_URL;
const LANDING_PAGE_URL =
  process.env.LANDING_PAGE_URL || "https://studio.yating.tw/intro/transkribera";

const maxUploadFileSizeGB = parseInt(process.env.MAX_UPLOAD_FILE_SIZE_GB) || 4;

const SHARE_BY_URL_FEATURE =
  process.env.SHARE_BY_URL_FEATURE === "false" ? false : true;
const CHECK_USER_EXIST_BEFORE_SHARE =
  process.env.CHECK_USER_EXIST_BEFORE_SHARE === "true";
const HIDE_DELETE_TRANSCRIBE_BUTTON =
  process.env.HIDE_DELETE_TRANSCRIBE_BUTTON === "false" ? false : true;
const ADD_PROMOTION_WHEN_EXPORT =
  process.env.ADD_PROMOTION_WHEN_EXPORT === "false" ? false : true;
const LIVE_TRANSCRIPT_TRANSLATION =
  process.env.LIVE_TRANSCRIPT_TRANSLATION === "false" ? false : true;

const APP_NAME = process.env.APP_NAME || "yating";

const LIVE_TRANSCRIPT_COMPLETION_FLOW_JUDICIAL =
  process.env.LIVE_TRANSCRIPT_COMPLETION_FLOW_JUDICIAL === "true";
const ENABLE_TRANSFER_EID = process.env.ENABLE_TRANSFER_EID === "true";
const DISABLE_ACCOUNT_WIDGET = process.env.DISABLE_ACCOUNT_WIDGET === "true";
const FIREBASE_PROJECT_ID =
  process.env.FIREBASE_PROJECT_ID || "yating-dev-bf26e";
const FIREBASE_APP_ID =
  process.env.FIREBASE_APP_ID || "1:208256991599:web:288f84481c5d86613b689a";
const FIREBASE_API_KEY =
  process.env.FIREBASE_API_KEY || "AIzaSyAuENJU1d3foG8q-32_36d3QlowCaf-cpQ";
const FIREBASE_MESSAGE_SENDER_ID =
  process.env.FIREBASE_MESSAGE_SENDER_ID || "208256991599";
const FIREBASE_MEASUREMENT_ID =
  process.env.FIREBASE_MEASUREMENT_ID || "G-H5QPV1TY2E";
const ACCOUNT_CENTER_URL =
  process.env.ACCOUNT_CENTER_URL || "https://studio.yating.tw/account/not-yet";

export default class Config {
  static get isDev() {
    return !process.env.NODE_ENV || process.env.NODE_ENV === "development";
  }

  static get baseName(): string {
    return Config.isDev ? "/" : PUB_PATH;
  }

  static get CookieDomain(): string {
    if (!Config.isDev && PUBLIC_HOST.includes("yating.tw")) {
      return "yating.tw";
    } else {
      return undefined;
    }
  }

  static get trackUserEvent(): boolean {
    if (Config.isDev) {
      return false;
    } else {
      return !DISABLE_TRACK_USER_EVENT;
    }
  }

  static get log(): boolean {
    if (Config.isDev) {
      return false;
    } else {
      return !DISABLE_LOG;
    }
  }

  static get loginPageUrl(): string {
    return `${PUBLIC_HOST}${PUB_PATH}login`;
  }

  static get universalLinkURL(): string {
    return UNIVERSAL_LINK_URL;
  }

  static get artStudioSongUrl(): string {
    return ART_STUDIO_SONG_URL;
  }

  static get studioVideoUrl(): string {
    return STUDIO_VIDEO_URL;
  }

  static get studioAvatarUrl(): string {
    return STUDIO_AVATAR_URL;
  }

  static get landingPageUrl(): string {
    return LANDING_PAGE_URL;
  }

  static get appleStoreUrl(): string {
    return "https://apps.apple.com/tw/app/雅婷逐字稿/id1343659994";
  }

  static get APP_NAME(): string {
    return APP_NAME;
  }

  static get MaxUploadFileSizeGB(): number {
    return maxUploadFileSizeGB;
  }

  static get EnableShareByURL(): boolean {
    return SHARE_BY_URL_FEATURE;
  }

  static get CheckUserExistBeforeShare(): boolean {
    return CHECK_USER_EXIST_BEFORE_SHARE;
  }

  static get HideDeleteTranscribeButton(): boolean {
    return HIDE_DELETE_TRANSCRIBE_BUTTON;
  }

  static get AddPromotionWhenExport(): boolean {
    return ADD_PROMOTION_WHEN_EXPORT;
  }

  static get LiveTranscribeLanguageSelector(): boolean {
    return LIVE_TRANSCRIPT_TRANSLATION;
  }

  static get EnableBackToListWhenLiveStreamingFinished(): boolean {
    return LIVE_TRANSCRIPT_COMPLETION_FLOW_JUDICIAL;
  }

  static get BodyCSSClass(): string {
    return this.APP_NAME != "yating" ? this.APP_NAME : "";
  }

  static get EnableTransferEid(): boolean {
    return ENABLE_TRANSFER_EID;
  }

  static get DisableAccountWidget(): boolean {
    return DISABLE_ACCOUNT_WIDGET;
  }

  static get FirebaseProjectId(): string {
    return FIREBASE_PROJECT_ID;
  }

  static get FirebaseAppId(): string {
    return FIREBASE_APP_ID;
  }

  static get FirebaseApiKey(): string {
    return FIREBASE_API_KEY;
  }

  static get FirebaseMessagingSenderId(): string {
    return FIREBASE_MESSAGE_SENDER_ID;
  }
  static get FirebaseMeasurementId(): string {
    return FIREBASE_MEASUREMENT_ID;
  }

  static get AccountCenterUrl(): string {
    return ACCOUNT_CENTER_URL;
  }

  static get AccountCenterPlanUrl(): string {
    return `${ACCOUNT_CENTER_URL}/plan`;
  }
}
