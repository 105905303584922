import React from "react";
import { observer } from "mobx-react";
import Dropdown from "semantic-ui-react/dist/commonjs/modules/Dropdown";
import classnames from "classnames";

import DataManager from "../../DataManager";
import Utils from "../../Utils";

import { ButtonColor, MessageBoxColor } from "../../GlobalDefine";
import { ActionButton, SiteMessageBox } from "../../ui/Component";
import PlatformSelectionView from "./PlatformSelectionView";
import StreamingEndView from "./StreamingEndView";
import { RTMPInfoModal } from "./StreamingModal";
import StreamStatusView from "./StreamStatusView";
import i18n from "../../../i18n";

import "../../../assets/scss/StreamingTaskPage.scss";
import loadingImg from "../../../assets/img/animation-loading-all.gif";
import questionImg from "../../../assets/img/icon-question.svg";
import copyImg from "../../../assets/img/icon-copy.svg";
import logoImg from "../../../assets/img/icon-logo.svg";
const tutorialUrl = "https://yating.tw/youtube-live-subtitle/";

import { observable, action, computed } from "mobx";
import { Translation } from "react-i18next";
import i18next from "i18next";

export class StreamingPageState {
  @observable isYTSelected: boolean = true;
  @observable isFBSelected: boolean = false;
  @observable isPlatformSelectionCompleted: boolean = true;
  @observable showRTMPInfoModal: boolean = false;
  @observable isStreamConflict: boolean = false;

  @action
  selectYT() {
    this.isYTSelected = !this.isYTSelected;
  }

  @action
  selectFB() {
    this.isFBSelected = !this.isFBSelected;
  }

  @action
  completePlatformSelection() {
    this.isPlatformSelectionCompleted = true;
  }

  @action
  setRTMPModelVisible(visible: boolean) {
    this.showRTMPInfoModal = visible;
  }

  @action
  setConflictStream(isConflict: boolean) {
    this.isStreamConflict = isConflict;
  }
}

export type streamingStateOption = {
  pageState: StreamingPageState;
};

interface State {
  isLoadingStreamingTask: boolean;
  isCreatingStreamingTask: boolean;
  isDeletingStreamingTask: boolean;
  isDeletedStreamingTask: boolean;

  pageMessage: string;

  ytStreamKey: string;
  ytIngestionUrl: string;
  fbStreamKey: string;
  asrLang: string;
}

@observer
export default class StreamingTaskPage extends React.Component<any, State> {
  static urlParam = "/streamingtask";
  private isComponentMounted: boolean;

  private _rtmpInfoUrlInput: React.RefObject<HTMLInputElement>;
  private _rtmpInfoKeyInput: React.RefObject<HTMLInputElement>;
  streamingPageState: StreamingPageState;

  constructor(props: any) {
    super(props);
    this._rtmpInfoUrlInput = React.createRef<HTMLInputElement>();
    this._rtmpInfoKeyInput = React.createRef<HTMLInputElement>();
    this.streamingPageState = new StreamingPageState();
    this.state = {
      isLoadingStreamingTask: false,
      isCreatingStreamingTask: false,
      isDeletingStreamingTask: false,
      isDeletedStreamingTask: false,
      pageMessage: "",
      ytStreamKey: "",
      ytIngestionUrl: "",
      fbStreamKey: "",
      asrLang: "zhen",
    };
  }

  componentDidMount() {
    this.isComponentMounted = true;
    this.loadStreamingTaskTillSuccess();
    Utils.analyticsPageView("/streamingtask");
  }

  componentWillUnmount(): void {
    this.isComponentMounted = false;
  }

  private loadStreamingTaskTillSuccess() {
    if (!this.isComponentMounted) {
      return;
    }
    this.setState({
      isLoadingStreamingTask: true,
    });
    DataManager.instance.queryStreamingTask().then((success) => {
      if (success) {
        this.setState({
          isLoadingStreamingTask: false,
        });
        if (DataManager.instance.streamingTask) {
          this.keepUpdateStreamingTaskStatus();
        }
      } else {
        setTimeout(() => {
          this.loadStreamingTaskTillSuccess();
        }, 1000);
      }
    });
  }

  private keepUpdateStreamingTaskStatus() {
    if (!this.isComponentMounted || !DataManager.instance.streamingTask) {
      return;
    }

    DataManager.instance.streamingTask.updateStatus().then((success) => {
      setTimeout(
        () => {
          this.keepUpdateStreamingTaskStatus();
        },
        success ? 5000 : 1000,
      );
    });
  }

  private showPageMessage(message: string) {
    this.setState({
      pageMessage: message,
    });
    setTimeout(
      function () {
        this.setState({
          pageMessage: "",
        });
      }.bind(this),
      1500,
    );
  }

  private getYTSettings(): React.ReactElement {
    let streamingtask = DataManager.instance.streamingTask;
    if (
      !this.streamingPageState.isYTSelected &&
      (!streamingtask ||
        !streamingtask.ytStreamKey ||
        streamingtask.ytStreamKey.length == 0)
    ) {
      return null;
    }

    return (
      <Translation ns="streaming">
        {(t) => (
          <>
            <div className="setting">
              <div className="title">
                <div className="title-text">{t("youtube_stream_key")}</div>
                <a href={tutorialUrl} target="_blank">
                  <img src={questionImg} />
                </a>
              </div>
              <div className="value">
                <input
                  type="text"
                  className={classnames("setting-input", "stream-key", {
                    disabled: streamingtask,
                  })}
                  placeholder={t("enter_youtube_stream_key")}
                  value={
                    streamingtask
                      ? streamingtask.ytStreamKey
                      : this.state.ytStreamKey
                  }
                  disabled={streamingtask != undefined}
                  onChange={(event) => {
                    this.setState({
                      ytStreamKey: event.target.value.trim(),
                    });
                  }}
                />
              </div>
            </div>
            <div className="setting">
              <div className="title">
                <div className="title-text">
                  {t("youtube_captions_ingestion_url")}
                </div>
                <a href={tutorialUrl} target="_blank">
                  <img src={questionImg} />
                </a>
              </div>
              <div className="value">
                <input
                  type="text"
                  className={classnames("setting-input", "ingestion-url", {
                    disabled: streamingtask,
                  })}
                  placeholder={t("enter_youtube_captions_ingestion_url")}
                  value={
                    streamingtask
                      ? streamingtask.ytIngestionUrl
                      : this.state.ytIngestionUrl
                  }
                  disabled={streamingtask != undefined}
                  onChange={(event) => {
                    this.setState({
                      ytIngestionUrl: event.target.value.trim(),
                    });
                  }}
                />
              </div>
            </div>
          </>
        )}
      </Translation>
    );
  }

  private getASRSettings(): React.ReactElement {
    let streamingtask = DataManager.instance.streamingTask;
    return (
      <Translation ns="streaming">
        {(t) => (
          <div className="setting">
            <div className="title">
              <div className="title-text">{t("subtitle_language")}</div>
            </div>
            <div className="value" id="asr-lang">
              <Dropdown
                selection
                fluid
                direction="left"
                // TODO: Show options from api response
                // options={DataManager.instance.asrModels.liveStream?.map((asrModel) => {
                //     return {
                //         text: asrModel.name,
                //         value: asrModel.id,
                //     };
                // })}

                // TODO: Remove hardcoded options
                options={[
                  {
                    text: t("chineseAndEnglish"),
                    value: "zhen",
                  },
                  {
                    text: t("chineseAndTaiwanese"),
                    value: "zhtw",
                  },
                ]}
                value={
                  streamingtask ? streamingtask.asrLang : this.state.asrLang
                }
                disabled={streamingtask != undefined}
                onChange={(event, data) => {
                  let value = data.value.toString();
                  this.setState({
                    asrLang: value,
                  });
                }}
              />
            </div>
          </div>
        )}
      </Translation>
    );
  }

  private getActionButton(): React.ReactElement {
    let streamingtask = DataManager.instance.streamingTask;
    const invalidYtSetting =
      !streamingtask &&
      this.streamingPageState.isYTSelected &&
      (this.state.ytStreamKey.length < 15 ||
        !this.state.ytIngestionUrl.startsWith(
          "http://upload.youtube.com/closedcaption?cid=",
        ));
    const invalidFbSetting =
      !streamingtask &&
      this.streamingPageState.isFBSelected &&
      this.state.fbStreamKey.length < 15;
    const isActioning =
      this.state.isDeletingStreamingTask || this.state.isCreatingStreamingTask;
    return (
      <Translation ns="streaming">
        {(t) => (
          <ActionButton
            color={streamingtask ? ButtonColor.Red : ButtonColor.Orange}
            disabled={invalidYtSetting || invalidFbSetting || isActioning}
            content={
              streamingtask
                ? this.state.isDeletingStreamingTask
                  ? t("ending")
                  : t("ended")
                : this.state.isCreatingStreamingTask
                  ? t("building")
                  : t("built")
            }
            style={{
              textAlign: "center",
              margin: "0px",
              marginTop: "36px",
            }}
            onClick={() => {
              if (!streamingtask) {
                this.setState({
                  isCreatingStreamingTask: true,
                });
                Utils.analyticsEvent({
                  category: "Stream Task",
                  action: "Start",
                  lang: this.state.asrLang,
                  yt_stream_key: this.state.ytStreamKey,
                  yt_ingestion_url: this.state.ytIngestionUrl,
                  fb_stream_key: this.state.fbStreamKey,
                });
                DataManager.instance
                  .createStreamingTask(
                    this.state.asrLang,
                    this.state.ytStreamKey,
                    this.state.ytIngestionUrl,
                    this.state.fbStreamKey,
                  )
                  .then((response) => {
                    this.setState({
                      isCreatingStreamingTask: false,
                    });
                    this.streamingPageState.setRTMPModelVisible(
                      response.success,
                    );
                    if (
                      response.success &&
                      DataManager.instance.streamingTask
                    ) {
                      this.keepUpdateStreamingTaskStatus();
                    } else {
                      this.showPageMessage(t("use_new_stream_key"));
                    }
                  });
              } else {
                this.setState({
                  isDeletingStreamingTask: true,
                });
                Utils.analyticsEvent({
                  category: "Stream Task",
                  action: "Finish",
                  lang: this.state.asrLang,
                  yt_stream_key: this.state.ytStreamKey,
                  yt_ingestion_url: this.state.ytIngestionUrl,
                  fb_stream_key: this.state.fbStreamKey,
                });
                DataManager.instance.deleteStreamingTask().then((success) => {
                  this.setState({
                    isDeletingStreamingTask: false,
                    isDeletedStreamingTask: success,
                  });
                });
              }
            }}
          />
        )}
      </Translation>
    );
  }

  private getRTMPSettings(): React.ReactElement {
    let streamingtask = DataManager.instance.streamingTask;
    if (!streamingtask) {
      return null;
    }

    return (
      <Translation ns="streaming">
        {(t) => (
          <>
            <div className="setting">
              <div className="title">
                <div className="title-text">{t("caption_ingestion_url")}</div>
                <a href={tutorialUrl} target="_blank">
                  <img src={questionImg} />
                </a>
              </div>
              <div className="value">
                <input
                  ref={this._rtmpInfoUrlInput}
                  type="text"
                  className={`setting-input copyable`}
                  id="rtmp-url"
                  readOnly
                  value={streamingtask?.rtmpUrl}
                />
                <img
                  src={copyImg}
                  onClick={() => {
                    if (this._rtmpInfoUrlInput.current) {
                      this._rtmpInfoUrlInput.current.select();
                      this._rtmpInfoUrlInput.current.setSelectionRange(
                        0,
                        99999,
                      );
                      document.execCommand("copy");
                      this.showPageMessage(i18n.t("copied_to_clipboard"));
                    }
                  }}
                />
              </div>
            </div>
            <div className="setting">
              <div className="title">
                <div className="title-text">{t("stream_key")}</div>
                <a href={tutorialUrl} target="_blank">
                  <img src={questionImg} />
                </a>
              </div>
              <div className="value">
                <input
                  ref={this._rtmpInfoKeyInput}
                  type="text"
                  className={`setting-input copyable`}
                  id="rtmp-key"
                  readOnly
                  value={streamingtask?.rtmpKey}
                />
                <img
                  src={copyImg}
                  onClick={() => {
                    if (this._rtmpInfoKeyInput.current) {
                      this._rtmpInfoKeyInput.current.select();
                      this._rtmpInfoKeyInput.current.setSelectionRange(
                        0,
                        99999,
                      );
                      document.execCommand("copy");
                      this.showPageMessage(i18n.t("copied_to_clipboard"));
                    }
                  }}
                />
              </div>
            </div>
          </>
        )}
      </Translation>
    );
  }

  render() {
    const { isLoadingStreamingTask, isDeletedStreamingTask } = this.state;

    const { isPlatformSelectionCompleted } = this.streamingPageState;

    if (isLoadingStreamingTask) {
      // Loading
      return (
        <div id="streaming-task-page" className="dark-background">
          <div className="loading-container">
            <img src={loadingImg} style={{ height: "85px" }} />
          </div>
        </div>
      );
    }

    if (isDeletedStreamingTask) {
      // finished page
      return <StreamingEndView />;
    }

    let streamingtask = DataManager.instance.streamingTask;
    if (!streamingtask && !isPlatformSelectionCompleted) {
      // init page: ask streaming platform
      return <PlatformSelectionView pageState={this.streamingPageState} />;
    }

    return (
      <>
        <div id="streaming-task-page">
          <div id="setting-page">
            <div className="header">
              <p className="title-text">
                {i18n.t("yating_streaming_subtitle", { ns: "streaming" })}
              </p>
            </div>
            <div className="settings">
              {this.getYTSettings()}
              {this.getASRSettings()}
              {this.getActionButton()}
              {this.getRTMPSettings()}
              <StreamStatusView />
            </div>
          </div>
          <div className="footer">
            <div className="logo-container">
              <img src={logoImg} />
              <div className="logo-text">{i18n.t("app_name")}</div>
            </div>
          </div>
        </div>

        <RTMPInfoModal pageState={this.streamingPageState} />
        {this.state.pageMessage.length === 0 ? null : (
          <SiteMessageBox
            color={MessageBoxColor.Default}
            message={this.state.pageMessage}
          />
        )}
      </>
    );
  }
}
