import React from "react";

import User from "../User";
import { InvoiceType } from "../Utils";

import i18n from "../../i18n";
import { Translation } from "react-i18next";

import { ButtonColor, Color, Dimen, getButtonColors } from "../GlobalDefine";
import { CustomButton } from "../ui/Component";

import Modal from "semantic-ui-react/dist/commonjs/modules/Modal";
import Input from "semantic-ui-react/dist/commonjs/elements/Input";
import Table from "semantic-ui-react/dist/commonjs/collections/Table";
import TableHeader from "semantic-ui-react/dist/commonjs/collections/Table/TableHeader";
import TableRow from "semantic-ui-react/dist/commonjs/collections/Table/TableRow";
import TableHeaderCell from "semantic-ui-react/dist/commonjs/collections/Table/TableHeaderCell";
import TableBody from "semantic-ui-react/dist/commonjs/collections/Table/TableBody";
import TableCell from "semantic-ui-react/dist/commonjs/collections/Table/TableCell";
import Form from "semantic-ui-react/dist/commonjs/collections/Form";
import FormField from "semantic-ui-react/dist/commonjs/collections/Form/FormField";
import Radio from "semantic-ui-react/dist/commonjs/addons/Radio";
import Divider from "semantic-ui-react/dist/commonjs/elements/Divider";
import Label from "semantic-ui-react/dist/commonjs/elements/Label";
import { Plan } from "../DataManager";

export function ShoppingListDlg(props: {
  open: boolean;
  hide: () => void;
  pay: (config: {
    plan: string;
    count: number;
    invoice: {
      type: InvoiceType;
      [key: string]: any;
    };
  }) => void;
  plan: Plan;
}) {
  if (!props.open) {
    return null;
  }

  const [count, setCount] = React.useState(1);
  const [invoice, setInvoice] = React.useState<{
    type: InvoiceType;
    detail: any;
  }>({
    type: InvoiceType.store,
    detail: { email: User.instance.profile.email || "" },
  });

  const Header = (props: { children: string }) => (
    <div
      style={{
        fontWeight: "bold",
        fontSize: "20px",
        color: Color.darkBlue,
        marginBottom: Dimen.margin,
      }}
    >
      {props.children}
    </div>
  );
  const onChangeInvoiceType = ((e: any, v: { value: InvoiceType }) => {
    setInvoice({ ...invoice, type: v.value });
  }).bind(this);
  const onChangeInvoiceDetail = ((e: any) => {
    const name = e.target.name;
    const value = e.target.value;

    setInvoice({
      ...invoice,
      detail: {
        ...invoice.detail,
        [name]: value,
      },
    });
  }).bind(this);

  const { open, hide, plan } = props;

  return (
    <Translation ns="payment">
      {(t) => (
        <Modal
          open={open}
          onClose={hide}
          closeOnDimmerClick={false}
          closeOnEscape={false}
          style={{ padding: Dimen.margin }}
          size="large"
        >
          <React.Fragment>
            <Header>{t("order_summary")}</Header>
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHeaderCell width={7}>{t("name")}</TableHeaderCell>
                  <TableHeaderCell width={2}>{t("unit_price")}</TableHeaderCell>
                  <TableHeaderCell width={1}>{t("quantity")}</TableHeaderCell>
                </TableRow>
              </TableHeader>
              <TableBody>
                <TableRow>
                  <TableCell>{t(plan.desc)}</TableCell>
                  <TableCell>
                    {t("price", { price: plan.finalPrice })}
                  </TableCell>
                  <TableCell>
                    <Input
                      type="number"
                      value={count}
                      onChange={(e) => {
                        setCount(parseInt(e.target.value) || 0);
                      }}
                      min="1"
                      step="1"
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <Form
              onSubmit={() => {
                props.pay({
                  plan: `plan_${plan.id}`,
                  count: count,
                  invoice: invoice,
                });
              }}
            >
              <Header>{t("email_address_for_invoices")}</Header>
              <FormField>
                <Input
                  label={i18n.t("email")}
                  name="email"
                  value={invoice.detail.email}
                  type="email"
                  maxLength="80"
                  onChange={onChangeInvoiceDetail}
                  required
                  autoComplete="off"
                />
              </FormField>
              <Header>{t("invoice_info")}</Header>
              <FormField>
                <Radio
                  label={t("electronic_invoice_carrier")}
                  value={InvoiceType.store}
                  checked={invoice.type === InvoiceType.store}
                  onChange={onChangeInvoiceType}
                />
              </FormField>
              <FormField>
                <Radio
                  label={t("company_tax_id_invoice_will_be_emailed")}
                  value={InvoiceType.company}
                  checked={invoice.type === InvoiceType.company}
                  onChange={onChangeInvoiceType}
                />
              </FormField>
              {invoice.type === InvoiceType.company ? (
                <FormField>
                  <Input
                    label={t("company_tax_id")}
                    name="customerIdentifier"
                    value={invoice.detail.customerIdentifier || ""}
                    onChange={onChangeInvoiceDetail}
                    required
                    autoComplete="off"
                    pattern="^(\d{8}|)$"
                  />
                </FormField>
              ) : null}
              {invoice.type === InvoiceType.company ? (
                <FormField>
                  <Input
                    label={t("company_name")}
                    name="customerName"
                    value={invoice.detail.customerName || ""}
                    onChange={onChangeInvoiceDetail}
                    required
                    autoComplete="off"
                    maxLength="60"
                  />
                </FormField>
              ) : null}
              {invoice.type === InvoiceType.company ? (
                <FormField>
                  <Input
                    label={t("company_address")}
                    name="customerAddr"
                    value={invoice.detail.customerAddr || ""}
                    onChange={onChangeInvoiceDetail}
                    required
                    autoComplete="off"
                    maxLength="100"
                  />
                </FormField>
              ) : null}
              <FormField>
                <Radio
                  label={t("citizen_digital_certificate_barcode_carrier")}
                  value={InvoiceType.person}
                  checked={invoice.type === InvoiceType.person}
                  onChange={onChangeInvoiceType}
                />
              </FormField>
              {invoice.type === InvoiceType.person ? (
                <FormField>
                  <Input
                    label={t("serial_number")}
                    name="pCarruerNum"
                    value={invoice.detail.pCarruerNum || ""}
                    placeholder={t(
                      "citizen_digital_certificate_barcode_carrier_placeholder",
                    )}
                    onChange={onChangeInvoiceDetail}
                    required
                    autoComplete="off"
                    pattern="[A-Z]{2}[0-9]{14}"
                  />
                </FormField>
              ) : null}
              <FormField>
                <Radio
                  label={t("mobile_barcode_carrier")}
                  value={InvoiceType.cellphone}
                  checked={invoice.type === InvoiceType.cellphone}
                  onChange={onChangeInvoiceType}
                />
              </FormField>
              {invoice.type === InvoiceType.cellphone ? (
                <FormField>
                  <Input
                    label={t("serial_number")}
                    name="cCarruerNum"
                    value={invoice.detail.cCarruerNum || ""}
                    placeholder={t("mobile_barcode_carrier_placeholder")}
                    onChange={onChangeInvoiceDetail}
                    required
                    autoComplete="off"
                    pattern="/[0-9,A-Z,\+,\-,\.]{7}"
                  />
                </FormField>
              ) : null}
              <FormField>
                <Radio
                  label={t("donate_invoice")}
                  value={InvoiceType.donation}
                  checked={invoice.type === InvoiceType.donation}
                  onChange={onChangeInvoiceType}
                />
              </FormField>
              {invoice.type === InvoiceType.donation ? (
                <FormField>
                  <Input
                    label={
                      <Label
                        href="https://www.einvoice.nat.gov.tw/APCONSUMER/BTC603W/"
                        target="_blank"
                      >
                        {t("recipient_company_id_lookup")}
                      </Label>
                    }
                    name="loveCode"
                    value={invoice.detail.loveCode || ""}
                    onChange={onChangeInvoiceDetail}
                    required
                    autoComplete="off"
                    pattern="^([Xx]\d{2,6}|\d{3,7}|)$"
                  />
                </FormField>
              ) : null}
              <Divider />
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                {(() => {
                  const { bg, textColor } = getButtonColors(
                    ButtonColor.Default,
                  );

                  return (
                    <CustomButton
                      width="auto"
                      minWidth="80px"
                      height="40px"
                      radius="20px"
                      bg={bg}
                      textColor={textColor}
                      onClick={hide}
                    >
                      {i18n.t("cancel")}
                    </CustomButton>
                  );
                }).apply(this)}
                <div
                  style={{
                    flexGrow: 1,
                    textAlign: "right",
                    margin: `0px ${Dimen.margin}`,
                  }}
                >
                  {t("order_total")} : {t("price", { price: plan.finalPrice })}
                </div>
                {(() => {
                  const { bg, textColor } = getButtonColors(ButtonColor.Orange);

                  return (
                    <CustomButton
                      width="auto"
                      minWidth="120px"
                      height="40px"
                      radius="20px"
                      bg={bg}
                      textColor={textColor}
                      disabled={0 >= count}
                    >
                      {t("checkout")}
                    </CustomButton>
                  );
                }).apply(this)}
              </div>
            </Form>
          </React.Fragment>
        </Modal>
      )}
    </Translation>
  );
}
