import React from "react";
import { observer } from "mobx-react";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import Popup from "semantic-ui-react/dist/commonjs/modules/Popup";

import { Styles } from "../GlobalDefine";
import { UserIcon, AnimatedArrow } from "../ui/Component";
import FeedbackWindow from "../ui/FeedbackWindow";
import Utils from "../Utils";
import User from "../User";
import DataManager, { AccountTab } from "../DataManager";
import { Translation } from "react-i18next";

import "../../assets/scss/SideBar.scss";
import Config from "../Config";
import { CrownIcon } from "./Icons";

interface AccountWidgetProps {
  //
}

interface AccountWidgetState {
  isDesktop: boolean;
  open: "open" | "close" | "init"; // side-bar open status (in mobile mode)

  openAccountMenu: "open" | "close" | "init-close"; // open status for account menu
  uploadFileLang: string; // choose language for upload file

  showFeedback: boolean; // open feedback window
}

@observer
export default class AccountWidget extends React.Component<
  AccountWidgetProps,
  AccountWidgetState
> {
  private isComponentMounted: boolean;

  constructor(props: any) {
    super(props);

    this.state = {
      isDesktop: Utils.isDesktop,
      open: "init",
      openAccountMenu: "init-close",
      uploadFileLang: "zh",
      showFeedback: false,
    };
  }

  componentDidMount() {
    this.isComponentMounted = true;
    window.addEventListener("resize", this.onResize.bind(this));
    this.onResize();
  }

  componentWillUnmount() {
    this.isComponentMounted = false;
    window.removeEventListener("resize", this.onResize.bind(this));
  }

  private onResize() {
    if (this.isComponentMounted) {
      this.setState({
        isDesktop: Utils.isDesktop,
      });
    }
  }

  private getAccountPanelUI(): React.ReactElement {
    const profile = User.instance.profile;
    const isSubscribed = User.instance.isSubscribed;
    const name =
      profile.name && profile.name.length > 0 ? profile.name : profile.email;
    const email = profile.email;

    const trigger = (
      <button className={`account-container ${this.state.openAccountMenu}`}>
        <UserIcon
          imgUrl={profile.picture}
          name={profile.name}
          email={profile.email}
          size="34px"
        />
        <span
          style={{
            marginLeft: "0.5rem",
            color: "#555",
            fontSize: "14px",
            maxWidth: "100px",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {profile.name || (
            <Translation>{(t) => t("user_profile")}</Translation>
          )}
        </span>
        <AnimatedArrow
          open={this.state.openAccountMenu}
          openDegree="upside-down"
        />
        {isSubscribed && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "1.5rem",
              height: "1.5rem",
              border: "solid 1px #FFD600",
              color: "#FFD600",
              borderRadius: 999,
              backgroundColor: "#FFFBE5",
              fontSize: 12,
              marginRight: "0.25rem",
            }}
          >
            <CrownIcon />
          </div>
        )}
      </button>
    );
    const constInMin = dayjs
      .duration(DataManager.instance.quotaDetail.remaining_quota * 1000)
      .asMinutes();
    const quota = Utils.padNum(Math.floor(constInMin), 2);

    const content = (
      <Translation>
        {(t) => (
          <div className="account-popup-menu">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background: isSubscribed
                  ? "linear-gradient(108.15deg, #FF6D00 0%, #2C210B 46.76%, #000000 76.9%, #A2975D 97.41%)"
                  : "#5C2700",
                color: "white",
                minHeight: "44px",
                fontWeight: 600,
              }}
            >
              {isSubscribed ? (
                <>
                  <CrownIcon style={{ marginRight: "0.5rem" }} />
                  SUBSCRIBED
                </>
              ) : (
                "FREE PLAN"
              )}
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: "1rem 1rem 0 1rem",
                gap: "1rem",
              }}
            >
              <UserIcon
                imgUrl={profile.picture}
                name={profile.name}
                email={profile.email}
                size="42px"
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  overflow: "hidden",
                  minWidth: 0,
                  color: "#333",
                }}
              >
                <span style={{ fontSize: 18, fontWeight: 700 }}>{name}</span>
                <span style={{ fontSize: 14 }}>{email}</span>
              </div>
            </div>
            {/* {!isSubscribed && (
              <a
                href={Config.AccountCenterPlanUrl}
                style={{
                  color: "#D65C00",
                  margin: "0.5rem 0 0 4.5rem",
                  display: "inline-block",
                }}
              >
                {t("upgrade_subscription")}
              </a>
            )} */}
            <div className="divider" />
            <RemainingQuotaDesc />
            <div className="function-menu">
              <Link to={`/account?tab=${AccountTab.setting}`} draggable={false}>
                <div
                  className="menu-item"
                  onClick={() => {
                    this.setState({
                      open: "close",
                      openAccountMenu: "close",
                    });
                  }}
                >
                  {t("account_setting")}
                </div>
              </Link>

              <Link to={`/account?tab=${AccountTab.plans}`} draggable={false}>
                <div
                  className="menu-item"
                  onClick={() => {
                    this.setState({
                      open: "close",
                      openAccountMenu: "close",
                    });
                  }}
                >
                  {t("buy_time")}
                </div>
              </Link>

              <div
                className="menu-item"
                tabIndex={0}
                onClick={() => {
                  this.setState({
                    open: "close",
                    showFeedback: true,
                  });
                }}
              >
                {t("feedback")}
              </div>

              <a
                style={{ display: "block" }}
                href={Config.AccountCenterUrl}
                className="menu-item"
              >
                {t("account_center")}
              </a>
              <div
                className="menu-item"
                tabIndex={0}
                style={{ marginBottom: "1rem" }}
                onClick={(evt) => {
                  Utils.analyticsEvent({
                    category: "User",
                    action: "Logout",
                  });
                  User.logout();
                  evt.preventDefault();
                }}
              >
                {t("sign_out")}
              </div>
            </div>
          </div>
        )}
      </Translation>
    );

    return (
      <Popup
        basic
        on="click"
        open={this.state.openAccountMenu === "open"}
        size="small"
        position="bottom center"
        trigger={trigger}
        content={content}
        style={{
          padding: "0px",
          border: "none",
          borderRadius: "1rem",
          overflow: "hidden",
          margin: 0,
          zIndex: 3000,
        }}
        onOpen={() => {
          this.setState({
            openAccountMenu: "open",
          });
        }}
        onClose={() => {
          this.setState({
            openAccountMenu: "close",
          });
        }}
      />
    );
  }

  render() {
    return (
      <>
        <FeedbackWindow
          show={this.state.showFeedback}
          onCancel={() => {
            this.setState({
              showFeedback: false,
            });
          }}
          onSend={() => {
            this.setState({
              showFeedback: false,
            });
          }}
        />
        {User.isLogined &&
          User.instance.ready &&
          DataManager.instance.ready && <>{this.getAccountPanelUI()}</>}
      </>
    );
  }
}

function toMin(quotaSec: number) {
  return Utils.padNum(
    Math.floor(dayjs.duration(quotaSec * 1000).asMinutes()),
    2,
  );
}

function toDate(date: number | Date | string) {
  return dayjs(date).format("YYYY/MM/DD HH:mm");
}

function RemainingQuotaDesc() {
  const isSubscribed = User.instance.isSubscribed;

  const remaining_quota = toMin(
    DataManager.instance.quotaDetail.remaining_quota,
  );
  const ongoing_quota_number = DataManager.instance.quotaDetail.ongoing_quota;
  const ongoing_quota = toMin(-DataManager.instance.quotaDetail.ongoing_quota);
  const valid_quota = toMin(DataManager.instance.quotaDetail.valid_quota);
  const valid_until = toDate(DataManager.instance.quotaDetail.valid_until);
  const free_quota = toMin(DataManager.instance.quotaDetail.free_quota);
  const free_until = toDate(DataManager.instance.quotaDetail.free_until);
  const subscription_quota = toMin(
    DataManager.instance.quotaDetail.subscription_quota,
  );
  const subscription_until = toDate(User.instance.subscription?.expiredAt);

  return (
    <Translation>
      {(t) => (
        <Popup
          basic
          on={["click", "focus", "hover"]}
          size="small"
          position="top center"
          trigger={
            <button className="account-quota-info">
              {t("remaining_time")}
              <span style={{ marginLeft: "auto" }}>
                <span
                  style={{
                    fontSize: 18,
                    color: "#D65C00",
                    marginRight: "0.5rem",
                    fontWeight: 600,
                  }}
                >
                  {remaining_quota}
                </span>
                mins
              </span>
            </button>
          }
          style={{
            ...Styles.popupContainer,
            padding: "1rem",
            zIndex: 3000,
          }}
        >
          {ongoing_quota_number > 0 && (
            <QuotaEntry quota={ongoing_quota} title={t("ongoing_quota")} />
          )}
          {isSubscribed && (
            <QuotaEntry
              quota={subscription_quota}
              title={t("subscription_quota")}
              until={subscription_until}
            />
          )}

          <QuotaEntry
            quota={valid_quota}
            title={t("valid_quota")}
            until={valid_until}
          />
          <QuotaEntry
            quota={free_quota}
            title={t("free_quota")}
            until={free_until}
          />
          <div style={{ marginTop: "1rem", fontSize: "small" }}>
            {t("balance_upload_files", { quota: remaining_quota })}
          </div>
        </Popup>
      )}
    </Translation>
  );
}

function QuotaEntry(props: { title: string; quota: string; until?: string }) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        padding: "0.5rem 0",
        borderBottom: "solid 1px #eee",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "1rem",
          whiteSpace: "nowrap",
        }}
      >
        <span style={{ fontWeight: 600, fontSize: "larger", color: "#666" }}>
          {props.title}
        </span>
        <span style={{ marginLeft: "auto" }}>
          <span
            style={{
              fontSize: 18,
              color: "#D65C00",
              marginRight: "0.5rem",
              fontWeight: 600,
            }}
          >
            {props.quota}
          </span>
          mins
        </span>
      </div>
      {props.until && (
        <span style={{ textAlign: "right", fontSize: "small", color: "#999" }}>
          {props.until}
        </span>
      )}
    </div>
  );
}
