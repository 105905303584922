import React from "react";
import { ModalHeader, ModalActions } from "../ui/Component";
import Modal from "semantic-ui-react/dist/commonjs/modules/Modal";
import i18n from "../../i18n";
import { Color2, ButtonColor } from "../GlobalDefine";
import { LiveStreamingErrorType } from "../pipeline/AilabsAsr";
import Config from "../Config";
import "../../assets/scss/ErrorModal.scss";

const getErrorMessages = (errorType: LiveStreamingErrorType | undefined) => {
  if (errorType === LiveStreamingErrorType.CONNECTION_LIMIT_EXCEEDED) {
    return {
      title: i18n.t("detect-many-connection", { ns: "liveTranskribe" }),
      description: i18n.t("detect-many-connection-description", {
        ns: "liveTranskribe",
      }),
    };
  }
  if (errorType === LiveStreamingErrorType.QUOTA_NOT_ENOUGH) {
    return {
      title: i18n.t("alert_quota_not_enough", { ns: "liveTranskribe" }),
      description: i18n.t("alert_quota_not_enough_description", {
        ns: "liveTranskribe",
      }),
      buttonText: i18n.t("buy"),
    };
  }
  return {
    title: i18n.t("unknown_error"),
    description: i18n.t("alert_try_again_later"),
  };
};

export class ErrorModal extends React.Component<{
  show: boolean;
  errorType: LiveStreamingErrorType | undefined;
  onClose: () => void;
}> {
  constructor(props: any) {
    super(props);
  }

  render() {
    const { show, errorType } = this.props;
    const {
      title,
      description,
      buttonText = i18n.t("confirm"),
    } = getErrorMessages(errorType);
    const onClick = () => {
      if (errorType === LiveStreamingErrorType.QUOTA_NOT_ENOUGH) {
        window.location.replace(
          `${window.location.origin}${Config.baseName}account?tab=2`,
        );
      } else {
        this.props.onClose();
      }
    };

    return (
      <Modal open={show} size="tiny" dimmer="blurring">
        <ModalHeader title={title} textAlign="left" />
        <div className="error-modal-body" style={{ color: Color2.dark }}>
          {description}
        </div>
        <ModalActions
          actions={[
            {
              color: ButtonColor.Orange,
              content: buttonText,
              style: {
                margin: "0px 0px 0px 0px",
                width: "130px",
              },
              onClick: onClick,
            },
          ]}
        />
      </Modal>
    );
  }
}
