import React from "react";
import { observer } from "mobx-react";
import { ActionButton } from "../../ui/Component";
import { ButtonColor } from "../../GlobalDefine";
import { streamingStateOption } from "./StreamingTaskPage";
import { Translation } from "react-i18next";
import i18n from "../../../i18n";

import ytLogoImg from "../../../assets/img/you-tube-logo-2017.svg";
import fbLogoImg from "../../../assets/img/facebook-logo-2019.svg";

const PlatformSeletionView = observer(({ pageState }: streamingStateOption) => {
  let enableActionBtn = pageState.isYTSelected || pageState.isFBSelected;
  return (
    <Translation ns="streaming">
      {(t) => (
        <div id="streaming-task-page" className="dark-background">
          <div id="ask-platform-page">
            <div className="header">{t("yating_streaming_subtitle")}</div>
            <div className="description">{t("select_streaming_platform")}</div>
            <div className="platforms">
              <div
                className={`platform${pageState.isYTSelected ? " active" : ""}`}
                onClick={() => pageState.selectYT()}
              >
                <img src={ytLogoImg} />
              </div>
              <div
                className={`platform${pageState.isFBSelected ? " active" : ""}`}
                onClick={() => pageState.selectFB()}
              >
                <img src={fbLogoImg} />
              </div>
            </div>
            <ActionButton
              color={ButtonColor.Orange}
              disabled={!enableActionBtn}
              content={i18n.t("next")}
              style={{
                opacity: enableActionBtn ? 1 : 0.5,
              }}
              onClick={() => {
                if (enableActionBtn) {
                  pageState.completePlatformSelection();
                }
              }}
            />
          </div>
        </div>
      )}
    </Translation>
  );
});

export default PlatformSeletionView;
