import React from "react";

import { Color2 } from "../GlobalDefine";

import LoadingPage from "./LoadingPage";
import FilePageHeader from "../ui/FilePageHeader";
import FileInfoWidget from "../ui/FileInfoWidget";
import FileTranscriptionWidget from "../ui/FileTranscriptionWidget";
import FileSummaryWidget from "../ui/FileSummaryWidget";
import AudioPlayer from "../ui/AudioPlayer";
import SpeakerRematchWindow from "../ui/SpeakerRematchWindow";

import { FolderType } from "../DataManager";
import Voice, { Permission, ErrorReason } from "../Voice";
import User, { UserLevel } from "../User";
import Utils, { RedirectTo } from "../Utils";
import Logger from "../Logger";
import i18n from "../../i18n";
import { Translation } from "react-i18next";
import { GPTSummary, GPTSummaryStillPending } from "../ui/GPTSummary";

import "../../assets/scss/FilePage.scss";
import totopIcon from "../../assets/img/icon-totop-figure.svg";
import loadFailedImg from "../../assets/img/no-record-taxtile.png";

interface Props {
  isLogined: boolean;
  location: any;
  history?: any;
  folderId?: string;
  folderName?: string;
  folderType?: FolderType;
}

interface State {
  file?: Voice;
  isLoading: boolean; // if is loading file
  loadFailedMessage: string;

  currentFunction: Function;
  isEditing: boolean; // if is in editing
  searchedWord: string; // in search word

  onRematchSpeaker: boolean; // open speaker rematch UI
  onRenameSpeaker: boolean; // open rename speaker UI
}

enum Function {
  Transcription,
  Summary,
  AISummary,
}

export default class FilePage extends React.Component<Props, State> {
  static urlParam = "/file";
  private startTime: number;
  private prevScrollPosition: number;
  private isComponentMounted: boolean;
  private showTotopBtn: boolean;
  private isScrollMoreThanHalf: boolean;
  private focusedUtteranceIdx: number;
  private searchedIndexList: Array<number>;
  private currentSearchIndex: number;
  private extendEditingTime: number;
  private _container: React.RefObject<HTMLDivElement>;
  private _header: React.RefObject<FilePageHeader>;
  private _infoWidget: React.RefObject<FileInfoWidget>;
  private _transcriptionWidget: React.RefObject<FileTranscriptionWidget>;
  private _autoscrollBtn: React.RefObject<HTMLDivElement>;
  private _summaryWidget: React.RefObject<FileSummaryWidget>;
  private _audioPlayer: React.RefObject<AudioPlayer>;

  // for tracking
  private eventCount = {
    edit: 0, // clicked edit count
    undo: 0,
    redo: 0,
    search: 0,
    jump_sentence: 0, // clicked sentence count
    change: 0, // edited utterance count
    highlightChange: 0, // highlight changed utterance count
    speakerChange: 0, // speaker changed utterance count
    removeAutoSummary: 0,
    summaryAddTitle: 0,
    summaryRemoveTitle: 0,
    summaryUpdateTitle: 0,
    summaryRearrange: 0,
    summaryRemove: 0,
    totalScrollCount: 0,
  };

  constructor(props: any) {
    super(props);
    this.state = {
      file: null,
      isLoading: false,
      loadFailedMessage: undefined,
      currentFunction: Function.Transcription,
      isEditing: false,
      searchedWord: "",
      onRematchSpeaker: false,
      onRenameSpeaker: false,
    };
    this.focusedUtteranceIdx = -1;
    this.searchedIndexList = [];
    this.currentSearchIndex = -1;
    this.extendEditingTime = 0;
    this._container = React.createRef<HTMLDivElement>();
    this._header = React.createRef<FilePageHeader>();
    this._infoWidget = React.createRef<FileInfoWidget>();
    this._transcriptionWidget = React.createRef<FileTranscriptionWidget>();
    this._autoscrollBtn = React.createRef<HTMLDivElement>();
    this._summaryWidget = React.createRef<FileSummaryWidget>();
    this._audioPlayer = React.createRef<AudioPlayer>();
  }

  private get container(): HTMLElement {
    if (Utils.isDesktop) {
      return document.getElementById("main");
    } else {
      return this._container.current;
    }
  }

  componentDidMount() {
    this.isComponentMounted = true;
    this.startTime = Math.round(Date.now() / 1000);
    this.prevScrollPosition = 0;

    let eid = "";
    let t = "";
    if (this.props.location && this.props.location.search) {
      eid = Utils.getValueFromUrlQuery(this.props.location.search, "eid");
      t = Utils.getValueFromUrlQuery(this.props.location.search, "t");
    }

    let startTime = 0;
    if (t && t.length > 0) {
      startTime = Number.parseInt(t);
    }

    let file = Voice.getVoice(eid);
    if (file.isDetailedLoaded) {
      this.setState(
        {
          file: file,
        },
        () => {
          Utils.analyticsPageView(this.props.isLogined ? "/file" : "/s", {
            fileID: eid,
            audioURL: this.state.file.audioUrl.split("?")[0],
            t: t,
          });
          this.selectingUtteranceAtTime(startTime * 1000);
          this.updateSpeakerRematchProgressTillDone();
          this.updateSummaryProgressTillDone();
          this.updateAudioPlayerSearchpoints();
        },
      );
    } else {
      this.setState({
        isLoading: true,
      });
      file
        .loadDetail()
        .then((success) => {
          if (success) {
            if (this.isComponentMounted) {
              this.setState(
                {
                  file: file,
                  isLoading: false,
                },
                () => {
                  Utils.analyticsPageView(
                    this.props.isLogined ? "/file" : "/s",
                    {
                      fileID: eid,
                      audioURL: this.state.file.audioUrl.split("?")[0],
                      t: t,
                    },
                  );
                  this.selectingUtteranceAtTime(startTime * 1000);
                  this.updateSpeakerRematchProgressTillDone();
                  this.updateSummaryProgressTillDone();
                  this.updateAudioPlayerSearchpoints();
                },
              );
            }
          } else {
            location.reload();
          }
        })
        .catch((error) => {
          this.setState({
            isLoading: false,
          });
          if (error === ErrorReason.DataNotFound) {
            let message = User.isLogined
              ? i18n.t("no_access_file_or_has_been_deleted", { ns: "file" })
              : i18n.t("please_login_to_view", { ns: "file" });
            //alert(message);
            this.setState({
              loadFailedMessage: message,
            });
            if (User.isLogined) {
              RedirectTo.instance.redirectTo(RedirectTo.FrontPage);
            } else {
              let title = document.getElementById("no-logined-title");
              if (title) {
                title.innerText = i18n.t("to_access_the_transcript", {
                  ns: "file",
                });
              }

              let btn = document.getElementById("toggle-sidebar-btn");
              if (btn && !btn.classList.contains("open") && Utils.isDesktop) {
                setTimeout(() => {
                  btn.click();
                }, 500);
              }
            }
          }
        });
    }
  }

  componentWillUnmount() {
    this.isComponentMounted = false;
    this.leavePage();

    try {
      const playerEventCount = this._audioPlayer.current?.getEventCount();
      Utils.analyticsEvent({
        category: "Edit Page",
        action: "Leave page",
        is_owner: this.state.file.is_owner,
        permission: Permission[this.state.file.permission],
        edit_count: this.eventCount.edit,
        change_count: this.eventCount.change,
        highlight_change_count: this.eventCount.highlightChange,
        speaker_change_count: this.eventCount.speakerChange,
        remove_auto_summary_count: this.eventCount.removeAutoSummary,
        summary_add_title_count: this.eventCount.summaryAddTitle,
        summary_remove_title_count: this.eventCount.summaryRemoveTitle,
        summary_update_title_count: this.eventCount.summaryUpdateTitle,
        summary_rearrange_count: this.eventCount.summaryRearrange,
        summary_remove_count: this.eventCount.summaryRemove,
        undo_count: this.eventCount.undo,
        redo_count: this.eventCount.redo,
        play_count: playerEventCount.play,
        pause_count: playerEventCount.pause,
        jump_forward_count: playerEventCount.jump_forward,
        jump_backward_count: playerEventCount.jump_backward,
        Jump_time_count: playerEventCount.jump_time,
        Jump_time_search_icon_count: playerEventCount.jump_search_icon,
        set_speed_count: playerEventCount.set_speed,
        jump_search_count: this.eventCount.search,
        jump_sentence_count: this.eventCount.jump_sentence,
        duration: this.state.file.duration ? this.state.file.duration : -1, //-1 表示沒有voice 沒有時間長度
        file_name: this.state.file.name,
        audioURL: this.state.file.audioUrl.split("?")[0],
        session_duration: Math.round(Date.now() / 1000) - this.startTime,
        scroll_offset_count: this.eventCount.totalScrollCount,
      });
    } catch (err) {
      Logger.error(`get user events before leave edit page error ${err}`, {
        errorMessage: err.message,
        errorName: err.name,
      });
    }
  }

  componentDidUpdate() {
    this.checkScrolling(this.container);
  }

  public onbeforeunload(): boolean {
    if (this.state.isEditing && !this.state.file.isAllContentChangesSaved) {
      return true;
    } else if (this.state.isEditing) {
      this.leavePage();
    }

    return false;
  }

  public onOtterContainerScroll(event: React.UIEvent<HTMLDivElement>) {
    const target = event.target as HTMLDivElement;
    this.calculateTotalScrollCount(target);
    this.extendEditing();
    this.checkScrolling(target);
  }

  public handleKeyDownListener(e: KeyboardEvent): boolean {
    let pass = true;
    if (this._header.current) {
      pass = pass && this._header.current?.handleKeyDownListener(e);
    }
    if (this._transcriptionWidget.current) {
      pass =
        pass && this._transcriptionWidget.current?.handleKeyDownListener(e);
    }

    this.extendEditing();
    if (
      e.keyCode === 27 && // esc
      (this.state.onRematchSpeaker || this.state.onRenameSpeaker)
    ) {
      this.setState({
        onRematchSpeaker: false,
        onRenameSpeaker: false,
      });
    }

    if (this.state.currentFunction === Function.Transcription) {
      let takeAction = false;
      switch (e.keyCode) {
        case 82: // R
          if (e.ctrlKey) {
            this._audioPlayer.current?.rewind();
            takeAction = true;
          }
          break;
        case 37: //<-
          if (!this.state.isEditing) {
            this._audioPlayer.current?.rewind();
            takeAction = true;
          }
          break;
        case 70: // F
          if (e.ctrlKey) {
            this._audioPlayer.current?.forward();
            takeAction = true;
          }
          break;
        case 39: // ->
          if (!this.state.isEditing) {
            this._audioPlayer.current?.forward();
            takeAction = true;
          }
          break;
        case 32: //space
          if (e.ctrlKey) {
            this._audioPlayer.current?.togglePlay();
            takeAction = true;
          }
          break;
        case 27: // esc
          this._audioPlayer.current?.togglePlay();
          takeAction = true;
          break;
        case 49: // 1
        case 97: // 1 (number pad)
          if (!e.ctrlKey) {
            break;
          }
        case 112: // F1
          this._audioPlayer.current.speedDown();
          takeAction = true;
          break;
        case 50: // 2
        case 98: // 2 (number pad)
          if (!e.ctrlKey) {
            break;
          }
        case 113: // F2
          this._audioPlayer.current.speedUp();
          takeAction = true;
          break;
      }
      if (takeAction) {
        e.preventDefault();
        e.stopPropagation();
        pass = false;
      }
    }
    return pass;
  }

  public handleKeyUpListener(e: KeyboardEvent): boolean {
    let pass = true;
    if (this._header.current) {
      pass = pass && this._header.current?.handleKeyUpListener(e);
    }
    if (this._transcriptionWidget.current) {
      pass = pass && this._transcriptionWidget.current?.handleKeyUpListener(e);
    }

    return pass;
  }
  private calculateTotalScrollCount(target: HTMLDivElement) {
    this.eventCount.totalScrollCount += Math.abs(
      target.scrollTop - this.prevScrollPosition,
    );
    this.prevScrollPosition = target.scrollTop;
  }

  private leavePage(): void {
    if (this.state.isEditing) {
      this.state.file.completeEdit();
    }
  }

  private updateAudioPlayerSearchpoints() {
    if (this._audioPlayer.current) {
      const { searchPoints, searchPointColor } =
        this.getShearchPointsForAudioPlayer();
      this._audioPlayer.current.setState({
        searchPoint: searchPoints,
        searchPointColor: searchPointColor,
      });
    }
  }

  private checkScrolling(container: HTMLElement) {
    if (
      container &&
      !this.state.isEditing &&
      this.state.currentFunction === Function.Transcription
    ) {
      const screenHeight = container.clientHeight;
      const scrollPos = container.scrollTop;
      const totalHeight = container.scrollHeight;
      const show = totalHeight > screenHeight * 3;
      const reverse = scrollPos > totalHeight / 2;

      if (show !== this.showTotopBtn) {
        this.showTotopBtn = show;
        if (show) {
          this._autoscrollBtn.current?.classList.remove("hide");
        } else {
          this._autoscrollBtn.current?.classList.add("hide");
        }
      }
      if (reverse !== this.isScrollMoreThanHalf) {
        this.isScrollMoreThanHalf = reverse;
        if (reverse) {
          this._autoscrollBtn.current?.classList.add("reverse");
        } else {
          this._autoscrollBtn.current?.classList.remove("reverse");
        }
      }
    } else {
      this._autoscrollBtn.current?.classList.add("hide");
    }
  }

  // update audio, transcription, scroll, header
  private selectingUtteranceAtTime(
    time: number,
    scrollToUpperPage: boolean = false,
  ) {
    const idx = this.state.file.findUtteranceIndexAtTime(time);
    if (idx > -1 && this.focusedUtteranceIdx !== idx) {
      this.focusedUtteranceIdx = idx;
      this.scrollToUtterance(idx, scrollToUpperPage);
      this._transcriptionWidget.current?.setFocusedUtterance(idx);
      this._audioPlayer.current?.jumpToTime(
        this.state.file.utterances[idx].startTime / 1000,
        false,
      );
      this._header.current?.setState({
        focusedUtteranceTime: time / 1000,
      });
    }
  }

  // update transcription, scroll, header
  private onAudioUpdateSelectingUtteranceAtTime(
    time: number,
    autoScroll: boolean,
  ) {
    const idx = this.state.file.findUtteranceIndexAtTime(time);
    if (idx > -1 && this.focusedUtteranceIdx !== idx) {
      this.focusedUtteranceIdx = idx;
      if (autoScroll) {
        this.scrollToUtterance(idx);
      }
      this._transcriptionWidget.current?.setFocusedUtterance(idx);
      this._header.current?.setState({
        focusedUtteranceTime: time / 1000,
      });
    }
  }

  // update audio, scroll, header
  private onUserSelectingUtterance(idx: number) {
    const utterance = this.state.file.utterances[idx];
    if (idx > -1 && this.focusedUtteranceIdx !== idx) {
      this.focusedUtteranceIdx = idx;
      this.scrollToUtterance(idx);
      this._audioPlayer.current?.jumpToTime(utterance.startTime / 1000, false);
      this._header.current?.setState({
        focusedUtteranceTime: utterance.startTime / 1000,
      });
    }
  }

  private scrollToUtterance(idx: number, upperPage: boolean = false) {
    if (idx < 0 || idx >= this.state.file.utterances.length) {
      return;
    }

    // auto scroll
    if (this.container && this._transcriptionWidget.current) {
      const ele =
        this._transcriptionWidget.current.findElementOfUtteranceAtIndex(idx);
      const container = this.container;
      const paudioPlayerOffset = container === this._container.current ? 0 : 70;
      const scrollUp =
        (ele as HTMLElement).offsetTop - 200 - container.scrollTop;
      let scrollDown =
        (ele as HTMLElement).offsetTop +
        (ele as HTMLElement).clientHeight -
        200 -
        container.scrollTop -
        container.clientHeight +
        200 +
        paudioPlayerOffset;
      if (upperPage) {
        scrollDown += container.clientHeight / 2;
      }
      if (ele) {
        if (ele.getBoundingClientRect().top < 150 && scrollUp < 0) {
          // going up
          container.scrollBy({
            top: scrollUp,
            left: 0,
            behavior: "auto",
          });
        } else if (
          ele.getBoundingClientRect().bottom >
            container.clientHeight - 100 - paudioPlayerOffset &&
          scrollDown > 0
        ) {
          // going down
          container.scrollBy({
            top: scrollDown,
            left: 0,
            behavior: "auto",
          });
        }
      }
    }
  }

  private onSearch(searchValue: string): void {
    if (searchValue === this.state.searchedWord) {
      // value no changed, go next searched index
      if (
        this.searchedIndexList.length > 0 &&
        this.currentSearchIndex > -1 &&
        this.currentSearchIndex < this.searchedIndexList.length
      ) {
        this.currentSearchIndex++;
        if (this.currentSearchIndex === this.searchedIndexList.length) {
          this.currentSearchIndex = 0;
        }
        const focused = this.searchedIndexList[this.currentSearchIndex];
        this.selectingUtteranceAtTime(
          this.state.file.utterances[focused].startTime,
          true,
        );
      }
    } else {
      // update search result
      this.setState(
        {
          searchedWord: searchValue,
        },
        () => {
          this.updateAudioPlayerSearchpoints();
        },
      );
      this.searchedIndexList = [];
      const searchWord = searchValue.toUpperCase();
      if (searchWord.length > 0) {
        this.state.file.utterances.forEach((item, index) => {
          if (item.content.toUpperCase().indexOf(searchWord) > -1) {
            this.searchedIndexList.push(index);
          }
        });
      }
      if (this.searchedIndexList.length > 0) {
        // jump to first searched result
        this.currentSearchIndex = 0;
        const focused = this.searchedIndexList[this.currentSearchIndex];
        this.selectingUtteranceAtTime(
          this.state.file.utterances[focused].startTime,
          true,
        );
      } else {
        this.currentSearchIndex = -1;
      }
    }
  }

  private updateSpeakerRematchProgressTillDone() {
    if (
      !this.isComponentMounted ||
      this.state.file.speakerProcessProgress >= 100
    ) {
      return;
    }

    this.state.file.updateSpeakerDiarizeProgress().then((success) => {
      if (success) {
        // refresh progress indicator
        this._header.current?.forceUpdate();
        if (this.state.file.speakerProcessProgress >= 100) {
          // speakers may updated, refresh screen
          this.forceUpdate();
        }
      }
      setTimeout(() => {
        this.updateSpeakerRematchProgressTillDone();
      }, 10000);
    });
  }

  private updateSummaryProgressTillDone() {
    if (!this.isComponentMounted || this.state.file.summaryProgress >= 100) {
      return;
    }

    this.state.file.updateSummaryProgress().then((success) => {
      if (success) {
        if (this.state.file.summaryProgress >= 100) {
          // summary may updated, refresh screen
          this._transcriptionWidget.current?.forceUpdate();
        }
      }
      setTimeout(() => {
        this.updateSummaryProgressTillDone();
      }, 10000);
    });
  }

  private extendEditing() {
    if (
      !this.state.isEditing ||
      new Date().getTime() - this.extendEditingTime < 1000 * 60 * 2
    ) {
      return;
    }

    this.extendEditingTime = new Date().getTime();
    this.state.file.extendEditingToken().then();
  }

  private getShearchPointsForAudioPlayer() {
    let searchPoints: Array<number> = [];
    let searchPointColor: string = undefined;
    let userHighlights = this.state.file.userHighlightUtterances;
    if (this.state.searchedWord && this.state.searchedWord.trim().length > 0) {
      const keyword = this.state.searchedWord.trim().toUpperCase();
      this.state.file.utterances.forEach((item) => {
        if (item.content.toUpperCase().indexOf(this.state.searchedWord) > -1) {
          searchPoints.push(item.startTime / 1000);
        }
      });
    } else if (userHighlights && userHighlights.length > 0) {
      userHighlights.forEach((item) => {
        searchPoints.push(item.startTime / 1000);
        searchPointColor = Color2.yellow;
      });
    }

    return {
      searchPoints: searchPoints,
      searchPointColor: searchPointColor,
    };
  }

  private getSpeakerRematchUI(): React.ReactElement {
    return (
      <SpeakerRematchWindow
        show={this.state.onRematchSpeaker}
        onClose={() => {
          this.setState({
            onRematchSpeaker: false,
          });
        }}
        onConfirm={(selectedSpeakerCount?: number) => {
          this.setState({
            onRematchSpeaker: false,
          });
          this.state.file
            .speakerRematch(selectedSpeakerCount)
            .then((success) => {
              this.forceUpdate();
              if (success) {
                this.updateSpeakerRematchProgressTillDone();
              }
            });
        }}
      />
    );
  }

  render() {
    if (!this.state.file || !this.state.file.isDetailedLoaded) {
      if (this.state.isLoading) {
        return (
          <div className="message-container">
            <LoadingPage />
          </div>
        );
      }
      if (
        this.state.loadFailedMessage &&
        this.state.loadFailedMessage.length > 0
      ) {
        return (
          <div className="message-container">
            <div className="loading-failed-message">
              <div className="message">{this.state.loadFailedMessage}</div>
              <img src={loadFailedImg} />
            </div>
          </div>
        );
      }
      //TODO
      return null;
    }

    let hideAutoScrollBtn =
      this.state.isEditing ||
      !this.showTotopBtn ||
      this.state.currentFunction !== Function.Transcription;

    return (
      <div
        className={`
                    file-page
                    ${this.state.currentFunction === Function.Transcription ? " function-transcription" : ""}
                `.trim()}
      >
        <FilePageHeader
          ref={this._header}
          history={this.props.history}
          isLogined={this.props.isLogined}
          folderId={this.props.folderId}
          folderName={this.props.folderName}
          folderType={this.props.folderType}
          file={this.state.file}
          isEditing={this.state.isEditing}
          searchedWord={this.state.searchedWord}
          onEdit={() => {
            Utils.analyticsEvent({
              category: "Edit Page",
              action: "Click edit",
              is_owner: this.state.file.is_owner,
              permission: Permission[this.state.file.permission],
              duration: this.state.file.duration
                ? this.state.file.duration
                : -1, //-1 表示沒有voice 沒有時間長度
              file_name: this.state.file.name,
            });
            this.eventCount.edit++;
            this.extendEditingTime = new Date().getTime();
            this.setState({
              isEditing: true,
            });
          }}
          onEditCompleted={() => {
            try {
              const contentEventCount =
                this._transcriptionWidget.current?.getEventCount();
              const summaryEventCount =
                this._summaryWidget.current?.getEventCount();
              Utils.analyticsEvent({
                category: "Edit Page",
                action: "Complete edit",
                is_owner: this.state.file.is_owner,
                permission: Permission[this.state.file.permission],
                value: contentEventCount.change,
                highlightChange: contentEventCount.highlightChange,
                speakerChange: contentEventCount.speakerChange,
                removeAutoSummary: contentEventCount.removeAutoSummary,
                summaryAddTitle: summaryEventCount.addTitle,
                summaryRemoveTitle: summaryEventCount.removeTitle,
                summaryUpdateTitle: summaryEventCount.updateTitle,
                summaryRearrange: summaryEventCount.rearrange,
                summaryRemove: summaryEventCount.remove,
                undo: contentEventCount.undo,
                redo: contentEventCount.redo,
                duration: this.state.file.duration
                  ? this.state.file.duration
                  : -1, //-1 表示沒有voice 沒有時間長度
                file_name: this.state.file.name,
              });
              this.eventCount.change += contentEventCount.change;
              this.eventCount.highlightChange +=
                contentEventCount.highlightChange;
              this.eventCount.speakerChange += contentEventCount.speakerChange;
              this.eventCount.removeAutoSummary +=
                contentEventCount.removeAutoSummary;
              this.eventCount.summaryAddTitle +=
                summaryEventCount.summaryAddTitle;
              this.eventCount.summaryRemoveTitle +=
                summaryEventCount.summaryRemoveTitle;
              this.eventCount.summaryUpdateTitle +=
                summaryEventCount.summaryUpdateTitle;
              this.eventCount.summaryRearrange +=
                summaryEventCount.summaryRearrange;
              this.eventCount.summaryRemove += summaryEventCount.summaryRemove;
              this.eventCount.undo += contentEventCount.undo;
              this.eventCount.redo += contentEventCount.redo;

              this._transcriptionWidget.current?.resetEventCount();
              this._summaryWidget.current?.resetEventCount();
            } catch (err) {
              Logger.error(`get user events on edit completed error ${err}`, {
                errorMessage: err.message,
                errorName: err.name,
              });
            }
            this.setState({
              isEditing: false,
            });
          }}
          onSearch={(keyword) => {
            if (keyword && keyword.length > 0) {
              Utils.analyticsEvent({
                category: "Edit Page",
                action: "Search",
                is_owner: this.state.file.is_owner,
                permission: Permission[this.state.file.permission],
                label: keyword,
                duration: this.state.file.duration
                  ? this.state.file.duration
                  : -1, //-1 表示沒有voice 沒有時間長度
                file_name: this.state.file.name,
              });
              this.eventCount.search++;
            }
            this.onSearch(keyword || "");
          }}
          onLoadingStatusChanged={(isLoading) => {
            this.setState({
              isLoading: isLoading,
            });
          }}
        />
        <div
          ref={this._container}
          className={`file-page-content-container`}
          onScroll={(event) => {
            const target = event.target as HTMLDivElement;
            this.calculateTotalScrollCount(target);
            this.extendEditing();
            this.checkScrolling(target);
          }}
        >
          <FileInfoWidget
            ref={this._infoWidget}
            file={this.state.file}
            onClickTag={(tag) => {
              if (tag && tag.length > 0) {
                Utils.analyticsEvent({
                  category: "Edit Page",
                  action: "Clicked Tag",
                  is_owner: this.state.file.is_owner,
                  permission: Permission[this.state.file.permission],
                  label: tag,
                  duration: this.state.file.duration
                    ? this.state.file.duration
                    : -1, //-1 表示沒有voice 沒有時間長度
                  file_name: this.state.file.name,
                });
                this.eventCount.search++;
              }
              this.onSearch(tag || "");
            }}
            onUpdateSpeakerNames={(updates: { [key: number]: string }) => {
              // check if the same name, confirm with user
              let sameId = false;
              let speakers = this.state.file.speakers;
              Object.keys(updates).forEach((key, idx) => {
                const updatingId = Number.parseInt(key);
                const newName = updates[updatingId];
                Object.keys(speakers).map((key, idx) => {
                  const id = Number.parseInt(key);
                  if (id !== updatingId && speakers[id] === newName) {
                    sameId = true;
                  }
                });
              });
              if (
                sameId &&
                !confirm(i18n.t("merge_duplicate_speaker", { ns: "file" }))
              ) {
                this._infoWidget.current?.forceUpdate();
                return;
              }

              this.setState({
                isLoading: true,
              });
              this.state.file.updateSpeakerMap(updates).then(() => {
                this.setState({
                  isLoading: false,
                });
              });
            }}
            onRematchSpeaker={() => {
              Utils.analyticsEvent({
                category: "Speaker",
                action: "Open Rematch",
                is_owner: this.state.file.is_owner,
                permission: Permission[this.state.file.permission],
                duration: this.state.file.duration
                  ? this.state.file.duration
                  : -1, //-1 表示沒有voice 沒有時間長度
                file_name: this.state.file.name,
              });
              this.setState({
                onRematchSpeaker: true,
              });
            }}
          />
          <Translation ns="file">
            {(t) => (
              <div className="main-functions">
                <div
                  id="transcription-tab"
                  className={`function${
                    this.state.currentFunction === Function.Transcription
                      ? " selected"
                      : ""
                  }`}
                  onClick={() => {
                    this.setState(
                      {
                        currentFunction: Function.Transcription,
                      },
                      () => {
                        this.updateAudioPlayerSearchpoints();
                      },
                    );
                  }}
                >
                  {t("transcript")}
                </div>
                <div
                  id="ai-summary-tab"
                  className={`function${
                    this.state.currentFunction === Function.AISummary
                      ? " selected"
                      : ""
                  }`}
                  onClick={() => {
                    Utils.analyticsEvent({
                      category: "Navigation",
                      action: "Click summarization tab",
                    });
                    this.setState({
                      currentFunction: Function.AISummary,
                    });
                  }}
                >
                  {i18n.t("summarization")}
                </div>
              </div>
            )}
          </Translation>
          <div className="divider"></div>
          <FileTranscriptionWidget
            ref={this._transcriptionWidget}
            show={this.state.currentFunction === Function.Transcription}
            file={this.state.file}
            isEditing={this.state.isEditing}
            searchedWord={this.state.searchedWord}
            onSelectingUtterance={(idx) => {
              this.eventCount.jump_sentence++;
              this.onUserSelectingUtterance(idx);
              this.extendEditing();
            }}
            onSpeakerChanged={() => {
              // update info widget && summary
              this._infoWidget.current?.forceUpdate();
              this._summaryWidget.current?.forceUpdate();
              this.extendEditing();
            }}
            onContentChanged={() => {
              // update summary & audio search items
              this._summaryWidget.current?.forceUpdate();
              this.updateAudioPlayerSearchpoints();
              this.extendEditing();
            }}
            onHighlightChanged={() => {
              // update summary
              this._summaryWidget.current?.forceUpdate();
              this.setState({
                searchedWord: "",
              });
              this.extendEditing();
            }}
            // onSelectingEditType={(type) => {
            //     if (type !== EditType.Text) {
            //         this.setState({
            //             searchedWord: "",
            //         });
            //     }
            // }}
          />
          <FileSummaryWidget
            ref={this._summaryWidget}
            file={this.state.file}
            show={this.state.currentFunction === Function.Summary}
            isEditing={this.state.isEditing}
            container={this.container}
            onRunSummary={() => {
              this.updateSummaryProgressTillDone();
            }}
          />
          {this.state.currentFunction === Function.AISummary &&
            (this.state.file.summaryProgress < 100 ? (
              <GPTSummaryStillPending />
            ) : (
              <GPTSummary
                eid={this.state.file.eid}
                mask={!this.state.file?.is_premium}
              />
            ))}
          <div
            ref={this._autoscrollBtn}
            className={`to-top-btn ${this.isScrollMoreThanHalf ? "reverse" : ""} ${
              hideAutoScrollBtn ? "hide" : ""
            }`}
            onClick={() => {
              this.container?.scrollBy({
                top: this.isScrollMoreThanHalf
                  ? -this.container.scrollHeight
                  : this.container.scrollHeight,
                left: 0,
                behavior: "smooth",
              });
            }}
          >
            <img src={totopIcon} />
          </div>
        </div>
        <div
          className="audio-player-container"
          style={{
            display:
              this.state.currentFunction === Function.Transcription
                ? ""
                : "none",
          }}
        >
          <AudioPlayer
            ref={this._audioPlayer}
            src={this.state.file.audioUrl}
            showLoopControl={false}
            showVolumeControl={false}
            audioSilentClips={[]}
            onUpdate={(e) => {
              const audio = e.target as HTMLAudioElement;
              if (!audio.paused) {
                const currentTime = audio.currentTime;
                this.onAudioUpdateSelectingUtteranceAtTime(
                  currentTime * 1000,
                  false,
                );
              }
            }}
            onJump={(time) => {
              this.extendEditing();
              this.onAudioUpdateSelectingUtteranceAtTime(time * 1000, true);
            }}
          />
        </div>
        {!this.state.isLoading ? null : (
          <div className="loading-file-indicator">
            <LoadingPage />
          </div>
        )}
        {this.getSpeakerRematchUI()}
      </div>
    );
  }
}
