import React from "react";
import { useTranslation } from "react-i18next";
import Modal from "semantic-ui-react/dist/commonjs/modules/Modal";
import styled from "styled-components";
import { ASRModel } from "../DataManager";
import { ButtonColor } from "../GlobalDefine";
import { ModalActions, ModalHeader } from "../ui/Component";
import { CrownIcon } from "./Icons";
import Config from "../Config";

import langBg from "../../assets/img/whisper-lang-bg.jpg";

export function WhisperSelectModal(props: {
  show: boolean;
  models: ASRModel[];
  onCancel: () => void;
  onSelect: (id: string) => void;
}) {
  const [lang, setLang] = React.useState("");
  const [search, setSearch] = React.useState("");
  const { t } = useTranslation();
  const filteredModels = search
    ? props.models.filter((model) => {
        return model.name.toLowerCase().includes(search.toLowerCase());
      })
    : props.models;

  // reset selection when modal close or search changed
  React.useEffect(() => {
    setLang("");
  }, [props.show, search]);

  return (
    <Modal open={props.show} size="large" dimmer="blurring">
      <ModalHeader title={t("select")} textAlign="left" />
      <div
        style={{
          display: "grid",
          padding: "1rem",
          gridTemplateColumns: "repeat(auto-fill, minmax(120px,1fr))",
          gap: "0.25rem",
        }}
      >
        <SearchInput
          type="search"
          value={search}
          onChange={(e) => setSearch(e.currentTarget.value)}
          placeholder={t("search_language")}
        />
        {filteredModels.length === 0 && (
          <div style={{ padding: "0.5rem 1rem" }}>{t("no_result")}</div>
        )}
        {filteredModels.map((model) => {
          return (
            <Label
              key={model.id}
              style={{
                backgroundColor: model.id === lang ? "#f7931d44" : undefined,
              }}
              title={model.name}
            >
              <input
                type="radio"
                value={model.id}
                checked={lang === model.id}
                style={{
                  position: "absolute",
                  opacity: "0",
                  pointerEvents: "none",
                }}
                name="model-id"
                onChange={(e) => setLang(e.currentTarget.value)}
              />
              {model.name}
            </Label>
          );
        })}
      </div>
      <ModalActions
        actions={[
          {
            color: ButtonColor.Default,
            content: t("cancel"),
            onClick: props.onCancel,
          },
          {
            color: ButtonColor.Orange,
            content: t("next"),
            onClick: () => props.onSelect(lang),
            disabled: !lang,
          },
        ]}
      />
    </Modal>
  );
}

export function WhisperNotSubscriptModal(props: {
  show: boolean;
  onCancel: () => void;
}) {
  const { t } = useTranslation();

  return (
    <Modal open={props.show} size="large" dimmer="blurring">
      <ModalHeader title={t("upgrade_to_subscription")} textAlign="left" />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: "3rem",
          gap: "1rem",
          backgroundImage: `url(${langBg})`,
          textAlign: "center",
        }}
      >
        <div
          style={{
            width: "4rem",
            height: "4rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: 999,
            border: "solid currentColor 3px",
            fontSize: 32,
            color: "gold",
          }}
        >
          <CrownIcon />
        </div>
        <h2 style={{ color: "#FF6D00", textShadow: "0 0 4px #ffd70066" }}>
          {t("upgrade_to_subscription")}
        </h2>
        <p>{t("upgrade_whisper_desc")}</p>
        <a
          href={Config.AccountCenterPlanUrl}
          style={{
            border: "none",
            background: "#FF6D00",
            padding: "1rem 2rem",
            color: "white",
            borderRadius: "0.5rem",
            fontWeight: 600,
          }}
        >
          {t("upgrade_subscription")}
        </a>
      </div>
      <ModalActions
        actions={[
          {
            color: ButtonColor.Default,
            content: t("cancel"),
            onClick: props.onCancel,
          },
          {
            color: ButtonColor.Orange,
            content: t("upgrade_subscription"),
            onClick: () => {
              window.location.href = Config.AccountCenterPlanUrl;
            },
            style: {
              padding: "0 1rem",
            },
          },
        ]}
      />
    </Modal>
  );
}

const Label = styled("label")`
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  transition: background-color 0.1s;
  :hover,
  :focus-within {
    background: #2222;
  }
`;

const SearchInput = styled("input")`
  width: 100%;
  margin-bottom: 0.5rem;
  border-radius: 100px;
  padding: 0.5rem 1rem;
  border: none;
  background-color: #2221;
  grid-column: 1/-1;
  :focus {
    outline: none;
    background-color: #2222;
  }

  ::placeholder {
    color: #999;
  }
`;
