import React from "react";
import { Link } from "react-router-dom";
import { Modal } from "semantic-ui-react";
import i18n from "../../i18n";
import { Color } from "../GlobalDefine";
import { CustomButton } from "./Component";

export function RedeemDlg(props: {
  open: boolean;
  hide: () => void;
  config?: {
    success?: boolean;
    error?: {
      code: number;
    };
    info?: {
      id: string;
      quota: number; // 以秒為單位
      start: string;
      end: string;
      display: string;
      [key: string]: any;
    };
  };
}) {
  if (!props.open) {
    return null;
  }

  const errorMsgMap = new Map<number, string>([
    [101, i18n.t("voucher_not_found", { ns: "payment" })],
    [102, i18n.t("voucher_is_invalid", { ns: "payment" })],
    [103, i18n.t("voucher_has_expired", { ns: "payment" })],
    [104, i18n.t("out_of_stock", { ns: "payment" })],
    [105, i18n.t("voucher_has_been_redeemed", { ns: "payment" })],
    [106, i18n.t("redeem_error", { ns: "payment" })],
    [107, i18n.t("voucher_has_been_invalid", { ns: "payment" })],
  ]);
  const bgColor = props.config.success ? Color.info : Color.error;
  const dlgTitle = props.config.success
    ? i18n.t("successful_redeem", { ns: "payment" })
    : i18n.t("fail_redemption", { ns: "payment" });
  const dlgContent = props.config.success
    ? i18n.t("successful_redeem_description", {
        ns: "payment",
        display: props.config.info.display,
        quota: props.config.info.quota / 60,
      })
    : `${i18n.t("enter_voucher", { ns: "payment" })}「${props.config.info.id}」，${
        errorMsgMap.get(props.config.error.code) || errorMsgMap.get(106)
      }`;
  const btnText = props.config.success
    ? i18n.t("use_immediately")
    : i18n.t("close");
  const toUrl = props.config.success ? "/" : "/account?tab=2";

  return (
    <Modal
      open={props.open}
      onClose={props.hide}
      closeOnDimmerClick={true}
      closeOnEscape={true}
      size="mini"
    >
      <React.Fragment>
        <div
          style={{
            background: bgColor,
            color: Color.pureWhite,
            padding: "30px 40px",
          }}
        >
          <div
            style={{
              fontSize: "16px",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            {dlgTitle}
          </div>
          <div
            style={{
              fontSize: "14px",
              marginTop: "20px",
              lineHeight: 1.5,
            }}
          >
            {dlgContent}
          </div>
        </div>
        <div
          style={{
            height: "100px",
            background: Color.pureWhite,
            display: "flex",
            placeContent: "center",
            placeItems: "center",
          }}
        >
          <Link to={toUrl} replace>
            <CustomButton
              width="auto"
              minWidth="120px"
              height="40px"
              radius="20px"
              textColor={{
                normal: Color.pureWhite,
              }}
              bg={{
                normal: Color.orange,
                hover: Color.darkOrange,
                active: Color.darkerOrange,
              }}
              onClick={props.hide}
            >
              {btnText}
            </CustomButton>
          </Link>
        </div>
      </React.Fragment>
    </Modal>
  );
}
