/**
 * Source components https://github.com/lhz516/react-h5-audio-player
 */
import React from "react";
import Popup from "semantic-ui-react/dist/commonjs/modules/Popup";
import Switch from "@material-ui/core/Switch";
import { Icon } from "@iconify/react";
import volumeHigh from "@iconify/icons-mdi/volume-high";
import volumeMute from "@iconify/icons-mdi/volume-mute";
import repeat from "@iconify/icons-mdi/repeat";
import repeatOff from "@iconify/icons-mdi/repeat-off";
import Utils from "../Utils";
import Logger from "../Logger";
import { Color2 } from "../GlobalDefine";
import { Translation } from "react-i18next";
import i18n from "../../i18n";

import "../../assets/scss/AudioPlayer.scss";
import playIcon from "../../assets/img/icon-play-white.svg";
import pauseIcon from "../../assets/img/icon-pause.svg";
import fastForwardIcon from "../../assets/img/icon-fast-5-s.svg";
import rewindIcon from "../../assets/img/icon-back-5-s.svg";
import playSpeedIcon from "../../assets/img/icon-speed.svg";
import infoIcon from "../../assets/img/icon-get-info.svg";

const SKIP_SILENCE = "skipSilence";

interface Props {
  /**
   * HTML5 tag property
   */
  src?: string;
  autoPlay: boolean;
  preload: "auto" | "metadata" | "none";
  volume: number;
  loop: boolean;
  muted: boolean;
  crossOrigin?: string;
  mediaGroup?: string;

  /**
   * ui property
   */
  progressJumpStep: number;
  volumeJumpStep: number;
  showLoopControl: boolean;
  showVolumeControl: boolean;
  showJumpControls: boolean;
  showDownloadProgress: boolean;
  audioSilentClips?: Array<{ start: number; end: number }>;

  /**
   * on Audio property event
   */
  onAbort?: (e: Event) => void;
  onCanPlay?: (e: Event) => void;
  onCanPlayThrough?: (e: Event) => void;
  onEnded?: (e: Event) => void;
  onError?: (e: Event) => void;
  onListen?: (currentTime: number) => void;
  onPause?: (e: Event) => void;
  onPlay?: (e: Event) => void;
  onUpdate?: (e: Event) => void; // when audio is playing
  onJump?: (time: number) => void; // user manually click audio time bar
  onClickPrevious?: (e: React.SyntheticEvent) => void;
  onClickNext?: (e: React.SyntheticEvent) => void;
  onPlayError?: (err: Error) => void;
}

interface State {
  currentTimePos: string;
  mouseMovePos: number;
  currentVolumePos: string;
  isDraggingProgress: boolean;
  isDraggingVolume: boolean;
  isPlaying: boolean;
  speed: number;
  isLoopEnabled: boolean;
  downloadProgressArr: DownloadProgress[];
  skipSilence: boolean;
  disable: boolean;

  searchPoint?: Array<number>;
  searchPointColor?: string;
}

interface DownloadProgress {
  left: string;
  width: string;
}

interface TimePosInfo {
  currentTime: number;
  currentTimePos: string;
}

interface VolumePosInfo {
  currentVolume: number;
  currentVolumePos: string;
}

export default class AudioPlayer extends React.Component<Props, State> {
  static defaultProps = {
    autoPlay: false,
    progressJumpStep: 5000,
    volumeJumpStep: 0.1,
    loop: false,
    muted: false,
    preload: "metadata",
    volume: 1.0,
    showLoopControl: true,
    showVolumeControl: true,
    showJumpControls: true,
    showDownloadProgress: true,
  };

  static speedOptions = [
    { value: 0.5, label: "0.5" },
    { value: 0.75, label: "0.75" },
    { value: 1.0, label: "1x" },
    { value: 1.25, label: "1.25" },
    { value: 1.5, label: "1.5" },
    { value: 1.75, label: "1.75" },
    { value: 2.0, label: "2x" },
    { value: 3.0, label: "3x" },
  ];

  private addHeadingZero(num: number): string {
    return num > 9 ? num.toString() : `0${num}`;
  }

  private getPosX(event: TouchEvent | MouseEvent): number {
    let posX = 0;
    if (event instanceof MouseEvent) {
      posX = event.pageX || event.clientX;
    } else if (event instanceof TouchEvent) {
      posX = event.touches[0].pageX;
    }

    return posX;
  }

  private throttle: Function = (func: Function, limit: number) => {
    let inThrottle = false;
    return function <T>(arg: T): void {
      if (!inThrottle) {
        func(arg);
        inThrottle = true;
        setTimeout(() => (inThrottle = false), limit);
      }
    };
  };

  state: State;

  audio: HTMLAudioElement;

  volumeControl?: HTMLElement;

  progressBar?: HTMLElement;

  container?: HTMLElement;

  lastVolume: number; // To store the volume before clicking mute button

  timeOnMouseMove: number; // Audio"s current time while mouse is down and moving over the progress bar

  isComponentMounted: boolean;

  currentIgnoreIndex: number;

  // for tracking
  eventCount = {
    play: 0,
    pause: 0,
    jump_forward: 0,
    jump_backward: 0,
    jump_time: 0,
    jump_search_icon: 0,
    set_speed: 0,
  };

  constructor(props: Props) {
    super(props);
    const { volume, muted } = props;
    this.state = {
      currentTimePos: "0%",
      mouseMovePos: 0,
      currentVolumePos: muted ? "0%" : `${volume * 100}%`,
      isDraggingProgress: false,
      isDraggingVolume: false,
      isPlaying: false,
      speed: 1.0,
      isLoopEnabled: this.props.loop,
      downloadProgressArr: [],
      skipSilence: localStorage.getItem(SKIP_SILENCE)
        ? Boolean(localStorage.getItem(SKIP_SILENCE))
        : false,
      disable: true,
      searchPoint: [],
      searchPointColor: undefined,
    };
    this.audio = new Audio();
    this.lastVolume = volume;
    this.timeOnMouseMove = 0;
    this.isComponentMounted = false;
    this.currentIgnoreIndex = 0;
    this.clickTogglePlay = this.clickTogglePlay.bind(this);
    this.handleClickVolumeButton = this.handleClickVolumeButton.bind(this);
    this.handleVolumnControlMouseDown =
      this.handleVolumnControlMouseDown.bind(this);
    this.handleWindowMouseOrTouchMove =
      this.handleWindowMouseOrTouchMove.bind(this);
    this.handleWindowMouseOrTouchUp =
      this.handleWindowMouseOrTouchUp.bind(this);
    this.getCurrentVolume = this.getCurrentVolume.bind(this);
    this.handleMouseMoveProgressBar =
      this.handleMouseMoveProgressBar.bind(this);
    this.handleMouseDownProgressBar =
      this.handleMouseDownProgressBar.bind(this);
    this.handleClickLoopButton = this.handleClickLoopButton.bind(this);
    this.handleClickRewind = this.handleClickRewind.bind(this);
    this.handleClickForward = this.handleClickForward.bind(this);
    this.setJumpTime = this.setJumpTime.bind(this);
    this.jumpToTime = this.jumpToTime.bind(this);
    this.getCurrentProgress = this.getCurrentProgress.bind(this);
    this.togglePlay = this.togglePlay.bind(this);
  }

  public getEventCount(): any {
    return this.eventCount;
  }

  public setEnable(enable: boolean): void {
    if (this.isComponentMounted) {
      this.setState({
        disable: false,
      });
    }
  }

  public togglePlay(): void {
    if (this.audio.paused && this.audio.src) {
      const audioPromise = this.audio.play();
      this.calculateCurrentIgnoreIndex(this.audio.currentTime);
      audioPromise
        .then((a) => {
          // this.audio.currentTime is 0 (wrong) before load audio (in safari)
          // need to jump to current position when first loaded
          if (this.audio.currentTime === 0) {
            let realCurrent =
              (Number.parseFloat(this.state.currentTimePos) *
                this.audio.duration) /
              100;
            if (realCurrent > 0) {
              this.audio.currentTime = realCurrent;
            }
          }
        })
        .catch((err) => {
          Logger.error(`on play audio error ${err}`);
          const { onPlayError } = this.props;
          onPlayError && onPlayError(new Error(err));
        });
      this.eventCount.play++;
    } else if (!this.audio.paused) {
      this.audio.pause();
      this.eventCount.pause++;
    }
  }

  private clickTogglePlay(e: React.SyntheticEvent): void {
    e.stopPropagation();
    this.togglePlay();
  }

  private handleClickVolumeButton(): void {
    if (this.audio.volume > 0) {
      this.lastVolume = this.audio.volume;
      this.audio.volume = 0;
    } else {
      this.audio.volume = this.lastVolume;
    }
    if (this.isComponentMounted) {
      this.setState({
        currentVolumePos: `${(this.audio.volume * 100).toFixed(0)}%`,
      });
    }
  }

  private handleVolumnControlMouseDown(
    event: React.MouseEvent | React.TouchEvent,
  ): void {
    event.stopPropagation();
    const { currentVolume, currentVolumePos } = this.getCurrentVolume(
      event.nativeEvent,
    );
    this.audio.volume = currentVolume;
    if (this.isComponentMounted) {
      this.setState({ isDraggingVolume: true, currentVolumePos });
    }

    if (event.nativeEvent instanceof MouseEvent) {
      window.addEventListener("mousemove", this.handleWindowMouseOrTouchMove);
      window.addEventListener("mouseup", this.handleWindowMouseOrTouchUp);
    } else if (event.nativeEvent instanceof TouchEvent) {
      window.addEventListener("touchmove", this.handleWindowMouseOrTouchMove);
      window.addEventListener("touchend", this.handleWindowMouseOrTouchUp);
    }
  }

  private handleWindowMouseOrTouchMove(event: TouchEvent | MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();
    // Prevent Chrome drag selection bug
    const windowSelection: Selection | null = window.getSelection();
    if (windowSelection && windowSelection.type === "Range") {
      windowSelection.empty();
    }

    const { isDraggingVolume, isDraggingProgress } = this.state;
    if (isDraggingVolume) {
      const { currentVolume, currentVolumePos } = this.getCurrentVolume(event);
      this.audio.volume = currentVolume;
      if (this.isComponentMounted) {
        this.setState({ currentVolumePos });
      }
    } else if (isDraggingProgress) {
      const { currentTime, currentTimePos } = this.getCurrentProgress(event);
      this.timeOnMouseMove = currentTime;
      if (this.isComponentMounted) {
        this.setState({ currentTimePos });
      }
    }
  }

  private handleWindowMouseOrTouchUp(event: MouseEvent | TouchEvent): void {
    event.stopPropagation();
    if (this.isComponentMounted) {
      this.setState((prevState) => {
        if (prevState.isDraggingProgress && isFinite(this.timeOnMouseMove)) {
          this.audio.currentTime = this.timeOnMouseMove;
          this.calculateCurrentIgnoreIndex(this.timeOnMouseMove);
          this.props.onJump && this.props.onJump(this.audio.currentTime);
        }
        return { isDraggingVolume: false, isDraggingProgress: false };
      });

      this.eventCount.jump_time++;
    }

    if (event instanceof MouseEvent) {
      window.removeEventListener(
        "mousemove",
        this.handleWindowMouseOrTouchMove,
      );
      window.removeEventListener("mouseup", this.handleWindowMouseOrTouchUp);
    } else {
      window.removeEventListener(
        "touchmove",
        this.handleWindowMouseOrTouchMove,
      );
      window.removeEventListener("touchend", this.handleWindowMouseOrTouchUp);
    }
  }

  private getCurrentVolume(event: TouchEvent | MouseEvent): VolumePosInfo {
    if (!this.volumeControl) {
      return {
        currentVolume: this.audio.volume,
        currentVolumePos: this.state.currentVolumePos,
      };
    }
    const volumeBarRect = this.volumeControl.getBoundingClientRect();
    const maxRelativePos = volumeBarRect.width;
    const relativePos = this.getPosX(event) - volumeBarRect.left;
    let currentVolume;
    let currentVolumePos;

    if (relativePos < 0) {
      currentVolume = 0;
      currentVolumePos = "0%";
    } else if (relativePos > volumeBarRect.width) {
      currentVolume = 1;
      currentVolumePos = "100%";
    } else {
      currentVolume = relativePos / maxRelativePos;
      currentVolumePos = `${(relativePos / maxRelativePos) * 100}%`;
    }

    return { currentVolume, currentVolumePos };
  }

  private handleMouseMoveProgressBar(event: React.MouseEvent): void {
    let pos =
      event.pageX -
      (event.target as HTMLDivElement).getBoundingClientRect().left;
    this.setState({
      mouseMovePos: pos,
    });
  }

  /* Handle mouse click on progress bar event */
  private handleMouseDownProgressBar(
    event: React.MouseEvent | React.TouchEvent,
  ): void {
    event.stopPropagation();
    const isTouch = event.type.startsWith("touch");
    const { currentTime, currentTimePos } = this.getCurrentProgress(
      event.nativeEvent,
    );

    if (isFinite(currentTime)) {
      this.timeOnMouseMove = currentTime;
      if (this.isComponentMounted) {
        this.setState({ isDraggingProgress: true, currentTimePos });
      }
      if (isTouch) {
        window.addEventListener("touchmove", this.handleWindowMouseOrTouchMove);
        window.addEventListener("touchend", this.handleWindowMouseOrTouchUp);
      } else {
        window.addEventListener("mousemove", this.handleWindowMouseOrTouchMove);
        window.addEventListener("mouseup", this.handleWindowMouseOrTouchUp);
      }
    }
  }

  private handleClickLoopButton(): void {
    if (this.isComponentMounted) {
      this.setState((prevState) => ({
        isLoopEnabled: !prevState.isLoopEnabled,
      }));
    }
  }

  public speedUp(): void {
    for (let i = 0; i < AudioPlayer.speedOptions.length; i++) {
      let option = AudioPlayer.speedOptions[i];
      if (option.value > this.state.speed) {
        this.setSpeed(option.value);
        break;
      }
    }
  }

  public speedDown(): void {
    for (let i = AudioPlayer.speedOptions.length - 1; i >= 0; i--) {
      let option = AudioPlayer.speedOptions[i];
      if (option.value < this.state.speed) {
        this.setSpeed(option.value);
        break;
      }
    }
  }

  public get currentSpeed(): number {
    return this.state.speed;
  }

  private setSpeed(speed: number): void {
    this.audio.playbackRate = speed;
    this.setState({
      speed: speed,
    });
    this.eventCount.set_speed++;
  }

  public rewind() {
    this.setJumpTime(-this.props.progressJumpStep);
    this.eventCount.jump_backward++;
  }

  public forward() {
    this.setJumpTime(this.props.progressJumpStep);
    this.eventCount.jump_forward++;
  }

  private handleClickRewind = (): void => {
    this.rewind();
  };

  private handleClickForward = (): void => {
    this.forward();
  };

  private setJumpTime = (time: number): void => {
    const { duration, currentTime: prevTime } = this.audio;
    if (!isFinite(duration) || !isFinite(prevTime)) return;
    let currentTime = prevTime + time / 1000;
    if (currentTime < 0) {
      this.audio.currentTime = 0;
      currentTime = 0;
    } else if (currentTime > duration) {
      this.audio.currentTime = duration;
      currentTime = duration;
    } else {
      this.audio.currentTime = currentTime;
    }
    this.calculateCurrentIgnoreIndex(currentTime);

    if (this.isComponentMounted) {
      this.setState({
        currentTimePos: `${(currentTime / duration) * 100}%`,
      });
      this.props.onJump && this.props.onJump(currentTime);
    }
  };

  public jumpToTime = (time: number, triggerByUser: boolean): void => {
    const duration = this.audio.duration;
    var currentTime = this.audio.currentTime;
    if (time < 0) {
      currentTime = 0;
    } else if (time > duration) {
      currentTime = duration;
    } else {
      currentTime = time;
    }
    this.audio.currentTime = currentTime;
    this.calculateCurrentIgnoreIndex(currentTime);

    if (this.isComponentMounted) {
      this.setState({
        currentTimePos: `${(currentTime / duration) * 100}%`,
      });
      triggerByUser && this.props.onJump && this.props.onJump(currentTime);
    }
  };

  private getCurrentProgress(event: MouseEvent | TouchEvent): TimePosInfo {
    if (
      !this.audio.src ||
      !isFinite(this.audio.currentTime) ||
      !this.progressBar
    ) {
      return { currentTime: 0, currentTimePos: "0%" };
    }

    const progressBarRect = this.progressBar.getBoundingClientRect();
    const maxRelativePos = progressBarRect.width;
    let relativePos = this.getPosX(event) - progressBarRect.left;

    if (relativePos < 0) {
      relativePos = 0;
    } else if (relativePos > maxRelativePos) {
      relativePos = maxRelativePos;
    }
    const currentTime = (this.audio.duration * relativePos) / maxRelativePos;
    return {
      currentTime,
      currentTimePos: `${((relativePos / maxRelativePos) * 100).toFixed(2)}%`,
    };
  }

  private getDisplayTimeBySeconds(seconds: number): string {
    if (!isFinite(seconds)) {
      return "00:00:00";
    }

    const hour = this.addHeadingZero(Math.floor(seconds / 3600));
    const min = this.addHeadingZero(Math.floor((seconds % 3600) / 60));
    const sec = this.addHeadingZero(Math.floor(seconds % 60));
    return `${hour}:${min}:${sec}`;
  }

  private calculateCurrentIgnoreIndex(currentTime: number) {
    if (this.state.skipSilence) {
      this.currentIgnoreIndex = 0;
      for (let i = 0; i < this.props.audioSilentClips.length; i++) {
        const item = this.props.audioSilentClips[i];
        if (currentTime > item.end) {
          this.currentIgnoreIndex = i;
        } else if (currentTime <= item.start) {
          break;
        }
      }
    }
  }

  componentDidMount(): void {
    // audio player object
    const audio = this.audio;
    this.isComponentMounted = true;

    if (this.props.muted) {
      audio.volume = 0;
    } else {
      audio.volume = this.lastVolume;
    }

    audio.addEventListener("error", (e) => {
      this.props.onError && this.props.onError(e);
      this.setEnable(false);
    });

    // When enough of the file has downloaded to start playing
    audio.addEventListener("canplay", (e) => {
      this.props.onCanPlay && this.props.onCanPlay(e);
      this.setEnable(true);
    });

    // When enough of the file has downloaded to play the entire file
    audio.addEventListener("canplaythrough", (e) => {
      this.props.onCanPlayThrough && this.props.onCanPlayThrough(e);
      this.setEnable(true);
    });

    // When audio play starts
    audio.addEventListener("play", (e) => {
      if (this.isComponentMounted) {
        this.setState({ isPlaying: true });
        this.props.onPlay && this.props.onPlay(e);
      }
    });

    // When unloading the audio player (switching to another src)
    audio.addEventListener("abort", (e) => {
      if (this.isComponentMounted) {
        this.setState({
          isPlaying: false,
          currentTimePos: "0%",
        });
        this.props.onAbort && this.props.onAbort(e);
      }
    });

    // When the file has finished playing to the end
    audio.addEventListener("ended", (e) => {
      this.props.onEnded && this.props.onEnded(e);
    });

    // When the user pauses playback
    audio.addEventListener("pause", (e) => {
      if (!this.audio) return;
      if (this.isComponentMounted) {
        this.setState({ isPlaying: false });
        this.props.onPause && this.props.onPause(e);
      }
    });

    audio.addEventListener("progress", (e) => {
      this.setEnable(true);
      const audio = e.target as HTMLAudioElement;
      const downloadProgressArr: DownloadProgress[] = [];
      for (let i = 0; i < audio.buffered.length; i++) {
        const bufferedStart: number = audio.buffered.start(i);
        const bufferedEnd: number = audio.buffered.end(i);
        downloadProgressArr.push({
          left: `${Math.round((100 / audio.duration) * bufferedStart) || 0}%`,
          width: `${Math.round((100 / audio.duration) * (bufferedEnd - bufferedStart)) || 0}%`,
        });
      }

      if (this.isComponentMounted) {
        this.setState({ downloadProgressArr });
      }
    });

    audio.addEventListener(
      "timeupdate",
      this.throttle((e: Event) => {
        const { isDraggingProgress } = this.state;
        const audio = e.target as HTMLAudioElement;
        if (isDraggingProgress) return;

        const { duration } = audio;
        let { currentTime } = audio;
        if (
          this.state.skipSilence &&
          this.props.audioSilentClips &&
          this.props.audioSilentClips.length > 0 &&
          this.currentIgnoreIndex < this.props.audioSilentClips.length
        ) {
          const ignore = this.props.audioSilentClips[this.currentIgnoreIndex];
          let jumpTo = ignore.end;
          // the last segment -> set jumpTo to duration
          if (jumpTo === -1) {
            jumpTo = duration;
          }
          if (currentTime > ignore.start && currentTime < jumpTo) {
            currentTime = jumpTo;
            audio.currentTime = currentTime;
          } else if (currentTime >= jumpTo) {
            this.currentIgnoreIndex++;
          }
        }
        if (this.isComponentMounted) {
          this.setState({
            currentTimePos: `${((currentTime / duration) * 100 || 0).toFixed(2)}%`,
          });
        }
        this.props.onUpdate && this.props.onUpdate(e);
      }, 10),
    );
  }

  componentWillUnmount(): void {
    this.isComponentMounted = false;
  }

  componentDidUpdate(prevProps: Props, prevStats: State) {}

  private getLoopControlUI(): React.ReactElement {
    if (!this.props.showLoopControl) {
      return null;
    }
    return (
      <button
        aria-label={this.state.isLoopEnabled ? "Enable Loop" : "Disable Loop"}
        className="audio-button-clear audio-repeat-button"
        onClick={this.handleClickLoopButton}
      >
        <Icon icon={this.state.isLoopEnabled ? repeat : repeatOff} />
      </button>
    );
  }

  private getSpeedControlUI(): React.ReactElement {
    return (
      <Translation ns="file">
        {(t) => (
          <Popup
            on="click"
            pinned={true}
            basic
            position="top left"
            style={{
              width: "440px",
              maxWidth: "calc(100% - min(30px, 4vw))",
              borderRadius: "4px",
              background: "#ffffff",
            }}
            trigger={
              <button className="audio-button-clear audio-speed-button">
                <img src={playSpeedIcon} />
                <span>{this.state.speed + "x"}</span>
              </button>
            }
          >
            <div className="speed-popup-title">
              {t("adjust_playback_speed")}
            </div>
            <div className="speed-popup-group">
              {AudioPlayer.speedOptions.map((item, index) => {
                return (
                  <button
                    key={index}
                    className={
                      "speed-popup-button" +
                      (this.state.speed == item.value ? " active" : "")
                    }
                    onClick={() => {
                      let oldSpeed = this.currentSpeed;
                      this.setSpeed(item.value);
                      Utils.analyticsEvent({
                        category: "Edit Page",
                        action: "Click player speed",
                        label: item.label,
                        value: item.value,
                        oldSpeed: oldSpeed,
                      });
                    }}
                  >
                    {item.label}
                  </button>
                );
              })}
            </div>
            <div className="speed-popup-option" style={{ display: "none" }}>
              <div>{t("skip_silence")}</div>
              <Switch
                className="slide-bar"
                checked={this.state.skipSilence}
                onClick={() => {
                  this.setState(
                    {
                      skipSilence: !this.state.skipSilence,
                    },
                    () => {
                      this.calculateCurrentIgnoreIndex(this.audio.currentTime);
                      localStorage.setItem(
                        SKIP_SILENCE,
                        this.state.skipSilence.toString(),
                      );
                    },
                  );
                }}
              />
            </div>
          </Popup>
        )}
      </Translation>
    );
  }

  private getVolumeControlUI(): React.ReactElement {
    if (!this.props.showVolumeControl) {
      return null;
    }
    const { currentTime, duration, volume } = this.audio;
    return (
      <div className="audio-volume-container">
        <button
          aria-label={volume ? "Mute" : "Unmute"}
          onClick={this.handleClickVolumeButton}
          className="audio-button-clear audio-volume-button"
        >
          <Icon icon={volume ? volumeHigh : volumeMute} />
        </button>
        <div
          ref={(ref: HTMLDivElement): void => {
            this.volumeControl = ref;
          }}
          onMouseDown={this.handleVolumnControlMouseDown}
          onTouchStart={this.handleVolumnControlMouseDown}
          role="progressbar"
          aria-label="volume Control"
          aria-valuemin={0}
          aria-valuemax={100}
          aria-valuenow={Number((volume * 100).toFixed(0))}
          tabIndex={0}
          className="audio-volume-bar-area"
        >
          <div className="audio-volume-bar">
            <div
              className="audio-volume-indicator"
              style={{ left: this.state.currentVolumePos }}
            />
            <div
              className="audio-volume-progress"
              style={{ width: this.state.currentVolumePos }}
            />
          </div>
        </div>
      </div>
    );
  }

  private getInfoUI(): React.ReactElement {
    return (
      <Translation ns="file">
        {(t) => (
          <Popup
            on="click"
            pinned={true}
            basic
            position="top left"
            style={{
              padding: "24px 20px",
              color: "white",
              borderRadius: "8px",
              boxShadow: "0 8px 16px 0 rgba(0, 0, 0, 0.16)",
              background: Color2.black100,
              minWidth: "calc(min(100% - min(30px, 4vw), 430px))",
              maxWidth: "calc(100% - min(30px, 4vw))",
            }}
            trigger={
              <button className="audio-button-clear get-info-button">
                <img src={infoIcon} />
              </button>
            }
          >
            <div className="helper-overlay-title">
              <span>{t("shortcut_keys")}</span>
            </div>
            <div className="helper-overlay-content">
              <div className="helper-overlay-item">
                <div className="helper-overlay-action">
                  <span>{t("play_pause_switch")}</span>
                </div>
                <div className="helper-overlay-key">
                  <div>
                    <span className="btn">ctrl</span>+
                    <span className="btn">{t("space_key")}</span>
                  </div>
                  <div>{i18n.t("or")}</div>
                  <div>
                    <span className="btn">esc</span>
                  </div>
                </div>
              </div>
              <div className="helper-overlay-item">
                <div className="helper-overlay-action">
                  <span>{t("fast_forward_five_sec")}</span>
                </div>
                <div className="helper-overlay-key">
                  <div>
                    <span className="btn">ctrl</span>+
                    <span className="btn small">R</span>
                  </div>
                  <div>{i18n.t("or")}</div>
                  <div>
                    <span className="btn icon small">◄</span>
                  </div>
                </div>
              </div>
              <div className="helper-overlay-item">
                <div className="helper-overlay-action">
                  <span>{t("rewind_five_sec")}</span>
                </div>
                <div className="helper-overlay-key">
                  <div>
                    <span className="btn">ctrl</span>+
                    <span className="btn small">F</span>
                  </div>
                  <div>{i18n.t("or")}</div>
                  <div>
                    <span className="btn icon small">►</span>
                  </div>
                </div>
              </div>
              <div className="helper-overlay-item">
                <div className="helper-overlay-action">
                  <span>{t("slow_down")}</span>
                </div>
                <div className="helper-overlay-key">
                  <div>
                    <span className="btn">ctrl</span>+
                    <span className="btn small">1</span>
                  </div>
                  <div>{i18n.t("or")}</div>
                  <div>
                    <span className="btn icon small">F1</span>
                  </div>
                </div>
              </div>
              <div className="helper-overlay-item">
                <div className="helper-overlay-action">
                  <span>{t("speed_up")}</span>
                </div>
                <div className="helper-overlay-key">
                  <div>
                    <span className="btn">ctrl</span>+
                    <span className="btn small">2</span>
                  </div>
                  <div>{i18n.t("or")}</div>
                  <div>
                    <span className="btn icon small">F2</span>
                  </div>
                </div>
              </div>
              <div className="helper-overlay-item">
                <div className="helper-overlay-action">
                  <span>{t("undo_edit")}</span>
                </div>
                <div className="helper-overlay-key">
                  <div>
                    <span className="btn">
                      {Utils.isMacLike ? "command" : "ctrl"}
                    </span>
                    +<span className="btn small">Z</span>
                  </div>
                </div>
              </div>
              <div className="helper-overlay-item">
                <div className="helper-overlay-action">
                  <span>{t("cancel_undo")}</span>
                </div>
                <div className="helper-overlay-key">
                  <div>
                    <span className="btn">
                      {Utils.isMacLike ? "command" : "ctrl"}
                    </span>
                    +<span className="btn small">Y</span>
                  </div>
                  <div>{i18n.t("or")}</div>
                </div>
              </div>
              <div
                className="helper-overlay-item"
                style={{ border: "none", paddingTop: "0px" }}
              >
                <div className="helper-overlay-action">
                  <span></span>
                </div>
                <div className="helper-overlay-key">
                  <div>
                    <span className="btn">
                      {Utils.isMacLike ? "command" : "ctrl"}
                    </span>
                    +<span className="btn">shift</span>+
                    <span className="btn small">Z</span>
                  </div>
                </div>
              </div>
              <div className="helper-overlay-item">
                <div className="helper-overlay-action">
                  <span>{t("select_multiple_speakers")}</span>
                </div>
                <div className="helper-overlay-key">
                  <div>
                    <span className="btn">shift</span>
                  </div>
                  <div>{i18n.t("or")}</div>
                  <div>
                    <span className="btn">
                      {Utils.isMacLike ? "command" : "ctrl"}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </Popup>
        )}
      </Translation>
    );
  }

  render(): React.ReactNode {
    const {
      src,
      preload,
      autoPlay,
      crossOrigin,
      mediaGroup,
      showJumpControls,
      showDownloadProgress,
    } = this.props;
    const {
      currentTimePos,
      mouseMovePos,
      isPlaying,
      isLoopEnabled,
      downloadProgressArr,
      searchPoint,
      searchPointColor,
    } = this.state;
    const { currentTime, duration } = this.audio;

    return (
      <div
        role="group"
        tabIndex={0}
        aria-label="Audio Player"
        className={`audio-container${this.state.disable ? " disabled" : ""}`}
        ref={(ref: HTMLDivElement): void => {
          this.container = ref;
        }}
      >
        <audio
          src={src}
          controls={false}
          loop={isLoopEnabled}
          autoPlay={autoPlay}
          preload={preload}
          crossOrigin={crossOrigin}
          mediaGroup={mediaGroup}
          ref={(ref: HTMLAudioElement): void => {
            this.audio = ref;
          }}
        />
        <div className="audio-progress-container">
          <div
            className="audio-progress-inner-container"
            ref={(ref: HTMLDivElement): void => {
              this.progressBar = ref;
            }}
            aria-label="Audio Progress Control"
            aria-describedby="audio-current-time"
            role="progressbar"
            aria-valuemin={0}
            aria-valuemax={100}
            aria-valuenow={Number(currentTimePos.split("%")[0])}
            tabIndex={0}
            onMouseDown={this.handleMouseDownProgressBar}
            onTouchStart={this.handleMouseDownProgressBar}
            onMouseMove={this.handleMouseMoveProgressBar}
          >
            <div
              className={`audio-progress-bar ${
                showDownloadProgress ? "audio-progress-bar-show-download" : ""
              }`}
            >
              <div
                className="audio-progress-indicator"
                style={{ left: currentTimePos }}
              />
              {showDownloadProgress &&
                downloadProgressArr.map(({ left, width }, i) => (
                  <div
                    key={i}
                    className="audio-download-progress"
                    style={{ left, width }}
                  />
                ))}
              <div
                className="audio-hover-position"
                style={{ width: `${mouseMovePos}px` }}
              />
              <div
                className="audio-current-progress"
                style={{ width: `calc(${currentTimePos} + 7px)` }}
              />
              {searchPoint &&
                searchPoint.map((startTime, index) => (
                  <div
                    key={index}
                    className="audio-search-point"
                    style={{
                      left: `calc(${(startTime / duration) * 100}% + 5px)`,
                      background: searchPointColor,
                    }}
                  />
                ))}
            </div>
          </div>
        </div>
        <div className="audio-controls-section">
          <div className="audio-left-controls">
            <div
              id="audio-current-time"
              className="audio-time audio-current-time number"
            >
              {Utils.transferMilliSecondToTime(currentTime * 1000)}
            </div>
            {this.getLoopControlUI()}
            {this.getSpeedControlUI()}
          </div>
          <div className="audio-main-controls">
            {showJumpControls && (
              <button
                aria-label="Rewind"
                className="audio-button-clear audio-main-controls-button audio-rewind-button"
                onClick={this.handleClickRewind}
              >
                <img src={rewindIcon} />
              </button>
            )}
            <button
              aria-label={isPlaying ? "Pause" : "Play"}
              className="audio-button-clear audio-main-controls-button audio-play-pause-button"
              onClick={this.clickTogglePlay}
            >
              <img src={isPlaying ? pauseIcon : playIcon} />
            </button>
            {showJumpControls && (
              <button
                aria-label="Forward"
                className="audio-button-clear audio-main-controls-button audio-forward-button"
                onClick={this.handleClickForward}
              >
                <img src={fastForwardIcon} />
              </button>
            )}
          </div>
          <div className="audio-right-controls">
            <div className="audio-time audio-total-time number">
              {Utils.transferMilliSecondToTime((duration || 0) * 1000)}
            </div>
            {this.getVolumeControlUI()}
            {this.getInfoUI()}
          </div>
        </div>
        {this.state.disable ? <div className="audio-player-mask"></div> : ""}
      </div>
    );
  }
}
