import React from "react";

import { observer } from "mobx-react";
import Modal from "semantic-ui-react/dist/commonjs/modules/Modal";
import { ModalHeader, ModalActions } from "../../ui/Component";
import { ButtonColor } from "../../GlobalDefine";
import { streamingStateOption } from "./StreamingTaskPage";
import DataManager from "../../DataManager";
import i18n from "../../../i18n";
import { Translation } from "react-i18next";

import questionImg from "../../../assets/img/icon-question.svg";
const tutorialUrl = "https://yating.tw/youtube-live-subtitle/";

type State = {
  message: string;
};

@observer
export class RTMPInfoModal extends React.Component<
  streamingStateOption,
  State
> {
  rtmpUrlInput: React.RefObject<HTMLInputElement>;
  rtmpKeyInput: React.RefObject<HTMLInputElement>;
  constructor(props: streamingStateOption) {
    super(props);
    this.rtmpUrlInput = React.createRef();
    this.rtmpKeyInput = React.createRef();
    this.state = {
      message: "",
    };
  }

  showRTMPInfoModalMessage = (message: string) => {
    this.setState({ message });
    setTimeout(() => {
      this.setState({ message: "" });
    }, 1500);
  };

  onCopyClick = (textInput: React.RefObject<HTMLInputElement>) => {
    if (textInput.current) {
      textInput.current.select();
      textInput.current.setSelectionRange(0, 99999);
      document.execCommand("copy");
      this.showRTMPInfoModalMessage(i18n.t("copied_to_clipboard"));
    }
  };

  render() {
    const { pageState } = this.props;
    const streamingtask = DataManager.instance.streamingTask;
    const { message } = this.state;

    return (
      <Translation ns="streaming">
        {(t) => (
          <Modal open={pageState.showRTMPInfoModal} size="tiny">
            <ModalHeader
              title={t("caption_ingestion_url_is_generated")}
              textAlign="left"
            />
            <div className="rtmp-info-container">
              <div className="rtmp-info">
                <div className="title">
                  <div className="title-text">{t("caption_ingestion_url")}</div>
                  <a href={tutorialUrl} target="_blank">
                    <img src={questionImg} />
                  </a>
                </div>
                <div className="value">
                  <input
                    ref={this.rtmpUrlInput}
                    className="value-text"
                    readOnly
                    value={streamingtask?.rtmpUrl}
                  />
                  <div
                    className="clickable"
                    onClick={() => this.onCopyClick(this.rtmpUrlInput)}
                  >
                    {i18n.t("copy")}
                  </div>
                </div>
              </div>
              <div className="rtmp-info">
                <div className="title">
                  <div className="title-text">{t("stream_key")}</div>
                  <a href={tutorialUrl} target="_blank">
                    <img src={questionImg} />
                  </a>
                </div>
                <div className="value">
                  <input
                    ref={this.rtmpKeyInput}
                    className="value-text"
                    readOnly
                    value={streamingtask?.rtmpKey}
                  />
                  <div
                    className="clickable"
                    onClick={() => this.onCopyClick(this.rtmpKeyInput)}
                  >
                    {i18n.t("copy")}
                  </div>
                </div>
              </div>
            </div>
            <ModalActions
              actions={[
                {
                  color: ButtonColor.Orange,
                  content: i18n.t("confirm"),
                  onClick: () => {
                    pageState.setRTMPModelVisible(false);
                  },
                },
              ]}
            />

            {/* <Message { ...message.length > 0 ? 'floating' : 'hidden' } >{message}</Message> */}
            {message.length > 0 ? (
              <div className="rtmp-info-message-box">{message}</div>
            ) : null}
          </Modal>
        )}
      </Translation>
    );
  }
}
