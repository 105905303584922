import qs from "qs";
import React from "react";
import { Translation } from "react-i18next";
import i18n from "../../i18n";
import { AccountTab } from "../DataManager";
import YatingButton from "../ui/YatingButton";
import Utils from "../Utils";

import paymentSuccessImg from "../../assets/img/payment-success.gif";
import paymentFailImg from "../../assets/img/payment-fail.png";

import "../../assets/scss/PaymentResultPage.scss";

interface State {
  isPaymentSuccess: boolean;
}

export default class PaymentResultPage extends React.Component<any, State> {
  static urlParam = "/payment-history/";
  constructor(props: any) {
    super(props);
    this.state = {
      isPaymentSuccess: undefined,
    };
  }
  componentDidMount(): void {
    let params = qs.parse(location.search, { ignoreQueryPrefix: true });

    if ("true" === params.redirect && undefined !== params.success) {
      Utils.analyticsEvent({
        category: "Conversion",
        action: "Pay done",
        label: "true" === params.success ? "success" : "failure",
        productId: params.product,
        price: params.price,
        paymentId: params.id,
      });
      if ("true" === params.success) {
        this.setState({
          isPaymentSuccess: true,
        });

        Utils.analyticsRevenue({
          productId: params.product,
          price: params.price,
        });
        Utils.analyticsUserPropertiesAddValue([
          { property: "Paid count", value: 1 },
          { property: "Paid price", value: params.price },
          { property: `Paid_${params.product}`, value: 1 },
        ]);
      } else {
        this.setState({
          isPaymentSuccess: false,
        });
        alert(i18n.t("alert_transaction_fail", { ns: "paymentHistory" }));
      }
    }
  }
  render() {
    return (
      <Translation ns="paymentResult">
        {(t) => (
          <div className="payment-result-page">
            <h2 className="payment-result-title">
              {this.state.isPaymentSuccess
                ? t("transaction_succeeded")
                : t("transaction_failed")}
            </h2>
            <div className="payment-result-container">
              {this.state.isPaymentSuccess ? (
                <>
                  <img src={paymentSuccessImg} className="success-img" />
                  <p className="success-msg">{t("thank_for_your_purchase")}</p>
                  <p className="success-msg">{t("we_have_received_order")}</p>
                </>
              ) : this.state.isPaymentSuccess === false ? (
                <>
                  <img src={paymentFailImg} className="fail-img" />
                  <p className="fail-msg">{t("alert_payment_fail_reason")}</p>
                </>
              ) : (
                <></>
              )}
              <YatingButton
                color="main"
                variant="primary"
                size="large"
                className="switch-to-payment-list-button"
                onClick={() =>
                  (window.location.href = `/account?tab=${AccountTab.purchaseHistory}`)
                }
              >
                {t("go_to_purchase_history")}
              </YatingButton>
            </div>
          </div>
        )}
      </Translation>
    );
  }
}
