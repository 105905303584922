import React from "react";
import { Link } from "react-router-dom";
import { observer } from "mobx-react";
import dayjs from "dayjs";
import { faTimes } from "@fortawesome/free-solid-svg-icons/faTimes";
import Popup from "semantic-ui-react/dist/commonjs/modules/Popup";
import LoadingPage from "../page/LoadingPage";
import { UserIcon, ActionButton, ProgressBar, FontIcon } from "./Component";
import { ButtonColor, Color2, hex2RGBA, Styles } from "../GlobalDefine";
import DataManager from "../DataManager";
import Voice, {
  ProgressState,
  VoiceState,
  Permission,
  ErrorReason,
} from "../Voice";
import { VoiceList, FolderType } from "../DataManager";

import Utils from "../Utils";
import User, { UserLevel } from "../User";
import i18n from "../../i18n";
import { Translation } from "react-i18next";

import "dayjs/locale/zh-tw";

import docIcon from "../../assets/img/icon-document.svg";
import clockIcon from "../../assets/img/icon-time-figuare.svg";
import moreIcon from "../../assets/img/icon-more.svg";

import itemIconNormal from "../../assets/img/icon-select-empty.svg";
import itemIconHover from "../../assets/img/icon-select-hover.svg";
import itemIconSelected from "../../assets/img/icon-selected.svg";

interface FileItemProps {
  file: Voice;
  selected: boolean; // show selected style
  highlight: boolean; // show hihlighted style
  draging: boolean; // is draging or not

  selectable: boolean; // can be select or not
  clickable: boolean; // can be click to open or not, will fallback to select if can't be click
  editable: boolean; // can be edit or not
  dragable: boolean; // can be drag or not
  showOptions: boolean; // show option button or not
  canOpenShare: boolean; // can open share setting
  canExport: boolean; // can open export
  canRename: boolean; // open rename
  movable: boolean; // can be move or not
  deletable: boolean; // can be delete or not

  hideIcon?: boolean; // hide user icon
  customIcons?: {
    // custom icon at start
    normal: string;
    selected: string;
    hover: string;
  };
  showDeletedTime: boolean; // show file deleted time instead of file status
  onSelect: () => void;
  onDragStart: (event: React.DragEvent<HTMLDivElement>) => void;
  onDragEnd: (event: React.DragEvent<HTMLDivElement>) => void;
  onContextMenu: (clientX: number, clientY: number) => void;
  onCancelContextMenu: () => void;
  onCancel: () => void; // user cancelling process this file
  onDelete: () => void; // user deleting this file
  onMove: () => void; // user moving this file
  onShare: () => void; // open share setting window
  onExport: () => void; // open export
  onExportAudio: () => void; // export audio
  onRename: () => void; // open rename
}

interface FileItemStats {
  hover: boolean;
  mouseDown: boolean; // true when mouse down
  namePopup: boolean; // if the name is too long, then popup the whole name when hover
  openOptionMenu: boolean; // open staus for the option menu
}

const LONG_TOUCH_DURATION = 800; // in ms
const stateMap = new Map([
  [VoiceState.Completed, { text: i18n.t("completed"), color: Color2.black50 }],
  [VoiceState.Cancelled, { text: i18n.t("cancelled"), color: Color2.black75 }],
  [VoiceState.Deleted, { text: i18n.t("deleted"), color: Color2.black75 }],
  [VoiceState.Pending, { text: i18n.t("waiting"), color: Color2.blue75 }],
  [VoiceState.Failed, { text: i18n.t("fail"), color: Color2.red75 }],
  [VoiceState.Recording, { text: i18n.t("recording"), color: Color2.red75 }],
]);

class FileItem extends React.Component<FileItemProps, FileItemStats> {
  private isComponentMounted: boolean;
  private _link: React.RefObject<HTMLAnchorElement>;
  private _nameElement: React.RefObject<HTMLDivElement>;
  private allowClick: boolean;
  private longTouchTimer: ReturnType<typeof setTimeout>;
  private touchStartPosition: {
    x: number;
    y: number;
  };
  private canTouchStart: boolean;

  private get hasOptions(): boolean {
    return (
      this.props.showOptions &&
      (this.props.canOpenShare ||
        this.props.canExport ||
        this.props.canRename ||
        this.props.movable ||
        this.props.deletable)
    );
  }

  constructor(props: any) {
    super(props);
    this.state = {
      hover: false,
      mouseDown: false,
      namePopup: false,
      openOptionMenu: false,
    };

    this._link = React.createRef<HTMLAnchorElement>();
    this._nameElement = React.createRef<HTMLDivElement>();
    this.allowClick = false;
    this.canTouchStart = false;
  }

  componentDidMount() {
    this.isComponentMounted = true;
    window.addEventListener("resize", this.checkNamePopup.bind(this));
    this.checkNamePopup();
  }

  componentWillUnmount() {
    this.isComponentMounted = false;
    window.removeEventListener("resize", this.checkNamePopup.bind(this));
  }

  componentDidUpdate(prevProps: FileItemProps, prevStats: FileItemStats) {
    if (prevProps.file.name !== this.props.file.name) {
      this.checkNamePopup();
    }
    if (prevProps.selected && !this.props.selected) {
      this.setState({
        openOptionMenu: false,
      });
    }
  }

  private checkNamePopup() {
    if (!this._nameElement.current || !this.isComponentMounted) {
      return;
    }

    if (
      this._nameElement.current.offsetWidth <
        this._nameElement.current.scrollWidth ||
      this._nameElement.current.offsetHeight <
        this._nameElement.current.scrollHeight
    ) {
      this.setState({
        namePopup: true,
      });
    } else {
      this.setState({
        namePopup: false,
      });
    }
  }

  private onSelect(): boolean {
    if (this.props.selectable) {
      this.props.onSelect();
      return true;
    } else {
      return false;
    }
  }

  private triggerClick(): boolean {
    if (this.props.clickable && this._link.current) {
      this.allowClick = true;
      this._link.current.click();
      this.allowClick = false;
      return true;
    } else {
      return false;
    }
  }

  private getTags(): string {
    if (this.props.file.tags && this.props.file.tags.length > 0) {
      return " #" + this.props.file.tags.join(" #");
    } else {
      return "";
    }
  }

  private getCreateTime(): string {
    if (i18n.language === "zh-TW") {
      return dayjs(this.props.file.ctime)
        .locale("zh-tw")
        .format("YY/MM/DD ddd hh:mm A");
    }
    return dayjs(this.props.file.ctime).format("DD/MM/YY ddd hh:mm A");
  }

  private getTime(): string {
    return Utils.transferMilliSecondToTime(this.props.file.duration * 1000);
  }

  private getStatus(): React.ReactElement {
    if (
      this.props.file.state === VoiceState.Ongoing &&
      this.props.file.info.progress
    ) {
      switch (this.props.file.info.progress.state) {
        case ProgressState.Processing:
          const percent = Math.round(this.props.file.info.progress.progress);
          return (
            <>
              <div className="file-status-processing">
                <ProgressBar
                  style={{ width: "100%", margin: "0px" }}
                  percent={percent}
                />
                <div className="message number">{`${percent}%`}</div>
                <FontIcon
                  className="action-cancel"
                  icon={faTimes}
                  onClick={() => {
                    this.props.onCancel();
                  }}
                />
              </div>
            </>
          );
        case ProgressState.Connecting:
          return (
            <div style={{ color: Color2.blue75 }}>{i18n.t("starting")}</div>
          );
        case ProgressState.Finished:
          return <div style={{ color: Color2.blue75 }}>{i18n.t("ending")}</div>;
      }
    } else if (this.props.file.state === VoiceState.Ongoing) {
      return <div style={{ color: Color2.blue75 }}>{i18n.t("processing")}</div>;
    } else if (this.props.file.state === VoiceState.Pending) {
      if (
        this.props.file.info.errorCode &&
        this.props.file.info.errorCode.reason === ErrorReason.QuotaNotEnough
      ) {
        return (
          <div style={{ color: Color2.red75 }}>
            {i18n.t("insufficient_balance")}
          </div>
        );
      } else {
        const message = stateMap.get(this.props.file.state);
        if (message) {
          return (
            <div style={{ color: message.color }}>
              {this.props.file.info.error
                ? this.props.file.info.error
                : message.text}
            </div>
          );
        } else {
          return null;
        }
      }
    } else if (this.props.file.state === VoiceState.Completed) {
      let message = "";
      if (
        this.props.file.speakerProcessProgress &&
        this.props.file.speakerProcessProgress < 100
      ) {
        message += `${i18n.t("speaker_processing")}\n`;
      }
      if (
        this.props.file.summaryProgress &&
        this.props.file.summaryProgress < 100
      ) {
        message += i18n.t("summarizing");
      }
      message = message.trim();
      if (message.length > 0) {
        return <div style={{ color: Color2.green75 }}>{message}</div>;
      } else {
        return null;
      }
    } else if (this.props.file.state === VoiceState.Relabeling) {
      return (
        <div style={{ color: Color2.blue75 }}>
          {i18n.t("speaker_processing")}
        </div>
      );
    } else {
      const message = stateMap.get(this.props.file.state);
      if (message) {
        return (
          <div style={{ color: message.color }}>
            {this.props.file.info.error
              ? this.props.file.info.error
              : message.text}
          </div>
        );
      } else {
        return null;
      }
    }
  }

  private getOptions(): React.ReactElement {
    if (!this.hasOptions) {
      return null;
    }

    return (
      <Translation>
        {(t) => (
          <Popup
            hideOnScroll
            basic
            size="tiny"
            on="focus"
            position="left center"
            style={Styles.popupContainer}
            trigger={
              <div
                className={`file-item-option-container${
                  this.props.file.state !== VoiceState.Completed
                    ? " enable-in-mobile"
                    : ""
                }`}
              >
                <img
                  className="option-btn"
                  src={moreIcon}
                  onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    this.setState({
                      openOptionMenu: !this.state.openOptionMenu,
                    });
                    this.props.onCancelContextMenu();
                    this.onSelect();
                  }}
                  onDoubleClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                  }}
                  onTouchStart={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                  }}
                  onTouchEnd={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    this.setState({
                      openOptionMenu: !this.state.openOptionMenu,
                    });
                    this.props.onCancelContextMenu();
                    this.onSelect();
                  }}
                />
              </div>
            }
            onClose={() => {
              this.setState({
                openOptionMenu: false,
              });
            }}
            open={this.state.openOptionMenu}
          >
            <div className="file-item-option-menu">
              {!this.props.canOpenShare ? null : (
                <div
                  className="option-menu-item"
                  onClick={() => {
                    this.setState({
                      openOptionMenu: false,
                    });
                    this.props.onShare();
                  }}
                  onTouchStart={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                  }}
                  onTouchEnd={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    this.setState({
                      openOptionMenu: false,
                    });
                    this.props.onShare();
                  }}
                >
                  {t("share")}
                </div>
              )}
              {!this.props.canExport ? null : (
                <div
                  className="option-menu-item"
                  onClick={() => {
                    this.setState({
                      openOptionMenu: false,
                    });
                    this.props.onExport();
                  }}
                  onTouchStart={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                  }}
                  onTouchEnd={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    this.setState({
                      openOptionMenu: false,
                    });
                    this.props.onExport();
                  }}
                >
                  {t("export")}
                </div>
              )}

              <div
                className="option-menu-item"
                onClick={() => {
                  this.setState({
                    openOptionMenu: false,
                  });
                  this.props.onExportAudio();
                }}
                onTouchStart={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                }}
                onTouchEnd={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  this.setState({
                    openOptionMenu: false,
                  });
                  this.props.onExportAudio();
                }}
              >
                {t("export_audio")}
              </div>

              {!this.props.canRename ? null : (
                <div
                  className="option-menu-item"
                  onClick={() => {
                    this.setState({
                      openOptionMenu: false,
                    });
                    this.props.onRename();
                  }}
                  onTouchStart={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                  }}
                  onTouchEnd={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    this.setState({
                      openOptionMenu: false,
                    });
                    this.props.onRename();
                  }}
                >
                  {t("rename")}
                </div>
              )}
              {!this.props.movable ? null : (
                <div
                  className="option-menu-item"
                  onClick={() => {
                    this.setState({
                      openOptionMenu: false,
                    });
                    this.props.onMove();
                  }}
                  onTouchStart={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                  }}
                  onTouchEnd={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    this.setState({
                      openOptionMenu: false,
                    });
                    this.props.onMove();
                  }}
                >
                  {t("move")}
                </div>
              )}
              {!this.props.deletable ? null : (
                <div
                  className="option-menu-item"
                  onClick={() => {
                    this.setState({
                      openOptionMenu: false,
                    });
                    if (this.props.file.state === VoiceState.Ongoing) {
                      this.props.onCancel();
                    } else {
                      this.props.onDelete();
                    }
                  }}
                  onTouchStart={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                  }}
                  onTouchEnd={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    this.setState({
                      openOptionMenu: false,
                    });
                    if (
                      this.props.file.state === VoiceState.Ongoing ||
                      this.props.file.state === VoiceState.Pending
                    ) {
                      this.props.onCancel();
                    } else {
                      this.props.onDelete();
                    }
                  }}
                >
                  {this.props.file.state === VoiceState.Ongoing ||
                  this.props.file.state === VoiceState.Pending
                    ? t("cancel")
                    : t("delete")}
                </div>
              )}
            </div>
          </Popup>
        )}
      </Translation>
    );
  }

  private getInTrashDay(): React.ReactElement {
    const left = 30 - this.props.file.info.dtime;

    return (
      <div
        style={{
          color:
            left >= 5 ? Color2.black75 : left < 1 ? Color2.red : Color2.red75,
        }}
      >
        {left < 1
          ? i18n.t("with_in_one_day")
          : i18n.t("day", { day: Math.floor(left) })}
      </div>
    );
  }

  private storeFileListScrollPosition() {
    const scrollElement = Utils.isDesktop
      ? document.getElementById("main")
      : document.getElementsByClassName("file-list-page")[0];
    Utils.storeScrollPosition(
      "file-list-scroll-position",
      scrollElement.scrollTop,
    );
  }

  render() {
    let langShortName = DataManager.instance.getLanguageShortName(
      this.props.file.lang,
    );

    return (
      <div
        className={`file-item${this.props.dragable ? " dragable" : ""}${
          this.props.selected ? " selected" : ""
        }${this.props.highlight ? " highlight" : ""}${this.props.draging ? " draging" : ""}${
          this.state.mouseDown ? " mouse-down" : ""
        }`}
        onMouseEnter={() => {
          this.setState({
            hover: true,
          });
        }}
        onMouseLeave={() => {
          this.setState({
            hover: false,
            mouseDown: false,
          });
        }}
        onMouseDown={() => {
          this.setState({
            mouseDown: true,
          });
        }}
        onMouseUp={() => {
          this.setState({
            mouseDown: false,
          });
        }}
        onClick={(event) => {
          if (!this.allowClick && !this.canTouchStart) {
            event.preventDefault();
            event.stopPropagation();
            this.onSelect();
          }
        }}
        onDoubleClick={() => {
          this.triggerClick();
        }}
        onTouchStart={(event) => {
          this.canTouchStart = true;
          if (event.touches && event.touches.length > 0) {
            this.touchStartPosition = {
              x: event.touches[0].clientX,
              y: event.touches[0].clientY,
            };
          }
          event.persist();
          this.longTouchTimer = setTimeout(() => {
            this.longTouchTimer = undefined;
            if (event.touches && event.touches.length > 0) {
              this.setState({
                openOptionMenu: false,
              });
              this.onSelect();
              this.props.onContextMenu(
                event.touches[0].clientX,
                event.touches[0].clientY,
              );
            }
          }, LONG_TOUCH_DURATION);
        }}
        onTouchMove={(event) => {
          if (
            this.touchStartPosition &&
            event.touches &&
            event.touches.length > 0
          ) {
            let moved =
              Math.abs(this.touchStartPosition.x - event.touches[0].clientX) +
              Math.abs(this.touchStartPosition.y - event.touches[0].clientY);
            if (moved > 20) {
              this.touchStartPosition = undefined;
            }
          }
        }}
        onTouchEnd={() => {
          if (this.touchStartPosition && this.longTouchTimer) {
            if (!this.triggerClick()) {
              this.onSelect();
            }
          }
          if (this.longTouchTimer) {
            clearTimeout(this.longTouchTimer);
            this.longTouchTimer = undefined;
          }
        }}
        onTouchCancel={() => {
          this.touchStartPosition = undefined;
          if (this.longTouchTimer) {
            clearTimeout(this.longTouchTimer);
            this.longTouchTimer = undefined;
          }
        }}
        draggable={this.props.dragable}
        onDragStart={(event) => {
          this.setState({
            mouseDown: false,
          });
          this.props.onDragStart(event);
        }}
        onDragEnd={(event) => {
          this.setState({
            mouseDown: false,
          });
          this.props.onDragEnd(event);
        }}
        onContextMenu={(event) => {
          if (this.props.selectable) {
            event.stopPropagation();
            event.preventDefault();
            this.setState({
              openOptionMenu: false,
            });
            this.props.onContextMenu(event.clientX, event.clientY);
          }
        }}
      >
        {this.props.hideIcon === true ? null : this.props.customIcons ? (
          <UserIcon
            imgUrl={
              this.props.selected
                ? this.props.customIcons.selected
                : this.state.hover
                  ? this.props.customIcons.hover
                  : this.props.customIcons.normal
            }
            name={""}
            email={""}
          />
        ) : (
          <UserIcon
            imgUrl={
              this.props.file.is_owner ? docIcon : this.props.file.owner.picture
            }
            name={this.props.file.owner.name}
            email={this.props.file.owner.email}
          />
        )}
        <div className="file-info">
          <div className="file-meta">
            {langShortName.length === 0 ? null : (
              <div className="language">{langShortName}</div>
            )}
            <div className="file-create-time">{this.getCreateTime()}</div>
            <div className="file-time">
              <img src={clockIcon} />
              <div>{this.getTime()}</div>
            </div>
            {this.props.showDeletedTime ? (
              <div className="file-status small">{this.getInTrashDay()}</div>
            ) : this.props.file.state !== VoiceState.Completed ? (
              <div className="file-status small">{this.getStatus()}</div>
            ) : null}
          </div>
          <Popup
            hideOnScroll
            on="hover"
            position="top center"
            trigger={
              <div ref={this._nameElement} className="file-name webkit-box">
                {this.props.clickable ? (
                  <Link
                    ref={this._link}
                    to={`/file?eid=${this.props.file.eid}`}
                    onClick={(event) => {
                      if (this.canTouchStart && !this.allowClick) {
                        event.preventDefault();
                      }

                      this.storeFileListScrollPosition();
                    }}
                    onTouchStart={() => {
                      this.canTouchStart = true;
                    }}
                  >
                    <span className="name number">{this.props.file.name}</span>
                  </Link>
                ) : (
                  <span className="name number">{this.props.file.name}</span>
                )}
                <span className="tags number">{this.getTags()}</span>
              </div>
            }
            content={
              <div className="number">
                {this.props.file.name} {this.getTags()}
              </div>
            }
            style={{
              background: hex2RGBA(Color2.black80, 0.85),
            }}
            inverted
            basic
            wide="very"
            disabled={!this.state.namePopup}
          />
        </div>
        {this.props.showDeletedTime ? (
          <div className={`file-status`}>{this.getInTrashDay()}</div>
        ) : (
          <>
            <div
              className={`file-status${this.hasOptions ? " hide-hover" : ""}`}
            >
              {this.getStatus()}
            </div>
            {this.getOptions()}
          </>
        )}
      </div>
    );
  }
}

interface FileListTableProps {
  files: VoiceList;
  isLoading: boolean;
  movable: boolean;
  deletable: boolean;
  isTrashTable: boolean;
  onDelete: (selected: string[]) => void;
  onCancel: (voice: Voice) => void;
  onMove: (selected: string[]) => void;
  onDeleteForever: (selected: string[]) => void;
  onRecover: (selected: string[]) => void;
  onShare: (selected: Voice) => void;
  onExport: (selected: Voice) => void;
  onExportFiles: (files: Voice[]) => void;
  onExportAudio: (selected: Voice) => void;
  onExportAudios: (selected: Voice[]) => void;
  onRename: (selected: Voice) => void;
}

interface FileListTableState {
  selected: string[]; // selected file eids
  lastSelected?: string; // last selected file eid
  showLastSelected: boolean; // highlight last selected
  pressedShift: boolean; // if pressed shift key
  pressedCommand: boolean; // if pressed command / control key
  draging: boolean; // if is dragging files
  openContextMenuPosition?: {
    // user right clicked
    x: number;
    y: number;
  };
}

export default class FileListTable extends React.Component<
  FileListTableProps,
  FileListTableState
> {
  private isComponentMounted: boolean;

  constructor(props: any) {
    super(props);
    this.state = {
      selected: [],
      lastSelected: undefined,
      showLastSelected: false,
      pressedShift: false,
      pressedCommand: false,
      draging: false,
      openContextMenuPosition: undefined,
    };
  }

  componentDidMount() {
    this.isComponentMounted = true;
    window.onscroll = () => {
      if (this.isComponentMounted) {
        this.setState({
          openContextMenuPosition: undefined,
        });
      }
    };
  }

  componentWillUnmount() {
    this.isComponentMounted = false;
    window.onscroll = undefined;
  }

  componentDidUpdate(
    prevProps: FileListTableProps,
    prevStats: FileListTableState,
  ) {
    if (
      this.props.files.folderId !== prevProps.files.folderId ||
      this.props.files.listOffset !== prevProps.files.listOffset
    ) {
      // files is changed, reset
      this.setState({
        selected: [],
        lastSelected: undefined,
        showLastSelected: false,
        pressedShift: false,
        pressedCommand: false,
        draging: false,
        openContextMenuPosition: undefined,
      });
    }
  }

  public resetSelection() {
    this.setState({
      selected: [],
    });
  }

  public handleKeyDownListener(e: KeyboardEvent): boolean {
    if (e.keyCode === 16) {
      // shift
      this.setState({
        pressedShift: true,
      });
    } else if (e.keyCode === 91 || e.keyCode === 93 || e.keyCode === 17) {
      // command & control
      this.setState({
        pressedCommand: true,
      });
    }

    this.setState({
      showLastSelected: true,
    });

    return true;
  }

  public handleKeyUpListener(e: KeyboardEvent): boolean {
    if (e.keyCode === 16) {
      // shift
      this.setState({
        pressedShift: false,
      });
    } else if (e.keyCode === 91 || e.keyCode === 93) {
      // command
      this.setState({
        pressedCommand: false,
      });
    } else if (e.keyCode === 27) {
      // esc
      this.setState({
        selected: [],
      });
    }

    return true;
  }

  private shiftSelect(fileEid: string, idx: number) {
    const lastSelectedIndex = this.getLastSelectedIndex();
    const startIdx = Math.min(idx, lastSelectedIndex);
    const endIdx = Math.max(idx, lastSelectedIndex);
    const startEid = this.props.files.list[startIdx].eid;
    const endEid = this.props.files.list[endIdx].eid;
    let hasRemove = false;
    let selected = JSON.parse(JSON.stringify(this.state.selected));
    for (let i = startIdx + 1; i < endIdx; i++) {
      const eid = this.props.files.list[i].eid;
      if (!selected.includes(eid)) {
        if (this.isFileSelectableByEid(eid)) {
          selected.push(eid);
        }
      } else {
        Utils.removeFromArray(selected, eid);
        hasRemove = true;
      }
    }

    if (hasRemove) {
      if (!selected.includes(startEid)) {
        if (this.isFileSelectableByEid(startEid)) {
          selected.push(startEid);
        }
      } else {
        Utils.removeFromArray(selected, startEid);
      }
      if (!selected.includes(endEid)) {
        if (this.isFileSelectableByEid(endEid)) {
          selected.push(endEid);
        }
      } else {
        Utils.removeFromArray(selected, endEid);
      }
    } else if (startIdx + 1 === endIdx && selected.includes(fileEid)) {
      Utils.removeFromArray(selected, startEid);
      Utils.removeFromArray(selected, endEid);
    } else {
      if (
        !selected.includes(startEid) &&
        this.isFileSelectableByEid(startEid)
      ) {
        selected.push(startEid);
      }
      if (!selected.includes(endEid) && this.isFileSelectableByEid(endEid)) {
        selected.push(endEid);
      }
    }
    this.setState({
      selected: selected,
      showLastSelected: false,
      lastSelected: fileEid,
    });
  }

  private commandSelect(fileEid: string) {
    if (
      !this.state.selected.includes(fileEid) &&
      this.isFileSelectableByEid(fileEid)
    ) {
      this.state.selected.push(fileEid);
      this.setState({
        selected: this.state.selected,
        showLastSelected: false,
        lastSelected: fileEid,
      });
    } else {
      this.setState({
        selected: Utils.removeFromArray(this.state.selected, fileEid),
        showLastSelected: false,
        lastSelected: fileEid,
      });
    }
  }

  private onClickedSelectAll() {
    if (this.state.selected.length > 0) {
      this.resetSelection();
    } else {
      this.setState({
        selected: this.props.files.list.map((v) => {
          return v.eid;
        }),
      });
    }
  }

  private isFileSelectableByEid(file_eid: string): boolean {
    let file = this.props.files.list.find((file, idx) => {
      return file_eid === file.eid;
    });
    return file ? file.is_owner : false;
  }

  private isFileSelectable(file: Voice): boolean {
    return file.is_owner;
  }

  private getLastSelectedIndex(): number {
    return this.props.files.list.findIndex((file, idx) => {
      return this.state.lastSelected === file.eid;
    });
  }

  private getControlPanel(): React.ReactElement {
    if (this.props.isTrashTable && this.props.files.totalCnt > 0) {
      return (
        <Translation>
          {(t) => (
            <div className="file-list-control-container">
              <input
                readOnly
                id="select-all"
                type="checkbox"
                className={`select-all${this.state.selected.length > 0 ? " some" : ""}`}
                checked={
                  this.props.files.list.length === this.state.selected.length &&
                  this.state.selected.length > 0
                }
                onClick={() => {
                  this.onClickedSelectAll();
                }}
              />
              <label htmlFor={"select-all"}>
                <span></span>
              </label>
              {this.state.selected.length > 0 ? (
                <ActionButton
                  color={ButtonColor.Gray}
                  content={t("delete_permanently")}
                  style={{
                    margin: "0px 20px 0px 20px",
                    width: "160px",
                    height: "30px",
                    fontSize: "14px",
                  }}
                  onClick={(evt) => {
                    this.props.onDeleteForever(this.state.selected);
                  }}
                />
              ) : (
                ""
              )}
              {this.state.selected.length > 0 ? (
                <ActionButton
                  color={ButtonColor.Gray}
                  content={t("restore")}
                  style={{
                    width: "80px",
                    height: "30px",
                    fontSize: "14px",
                  }}
                  onClick={(evt) => {
                    this.props.onRecover(this.state.selected);
                  }}
                />
              ) : (
                ""
              )}
            </div>
          )}
        </Translation>
      );
    } else {
      return null;
    }
  }

  private getContextMenu() {
    if (!this.state.openContextMenuPosition) {
      return null;
    }

    const left = this.state.openContextMenuPosition.x;
    const top = this.state.openContextMenuPosition.y;
    const right = left + 1;
    const bottom = top + 1;

    // if only select one file, need to check its allow actions
    let oneFile: Voice = undefined;
    if (this.state.selected.length === 1) {
      oneFile = this.props.files.list.find((file, idx) => {
        return this.state.selected[0] === file.eid;
      });
    }

    let canExport = this.state.selected.length > 0;
    let files: Voice[] = [];
    this.state.selected.forEach((selected) => {
      let file = this.props.files.list.find((file, idx) => {
        return selected === file.eid;
      });
      if (!file || !file.canExport) {
        canExport = false;
      } else {
        files.push(file);
      }
    });

    return (
      <Translation>
        {(t) => (
          <Popup
            basic
            size="tiny"
            on={["focus", "click"]}
            context={{
              getBoundingClientRect: () => ({
                left,
                top,
                right,
                bottom,
                height: 0,
                width: 0,
              }),
            }}
            style={Styles.popupContainer}
            open={this.state.openContextMenuPosition !== undefined}
          >
            <div className="file-list-context-menu">
              {!(oneFile && oneFile.canOpenShare) ? null : (
                <div
                  className="context-menu-item"
                  onClick={() => {
                    this.setState({
                      openContextMenuPosition: undefined,
                    });
                    this.props.onShare(oneFile);
                  }}
                >
                  {t("share")}
                </div>
              )}
              {!(oneFile && oneFile.canExport) ? null : (
                <div
                  className="context-menu-item"
                  onClick={() => {
                    this.setState({
                      openContextMenuPosition: undefined,
                    });
                    this.props.onExport(oneFile);
                  }}
                >
                  {t("export")}
                </div>
              )}
              {oneFile || !canExport || files.length <= 1 ? null : (
                <div
                  className="context-menu-item"
                  onClick={() => {
                    this.setState({
                      openContextMenuPosition: undefined,
                    });
                    this.props.onExportFiles(files);
                  }}
                >
                  {t("export")}
                </div>
              )}
              {!(oneFile && oneFile.canEditName) ? null : (
                <div
                  className="context-menu-item"
                  onClick={() => {
                    this.setState({
                      openContextMenuPosition: undefined,
                    });
                    this.props.onRename(oneFile);
                  }}
                >
                  {t("rename")}
                </div>
              )}

              <div
                className="context-menu-item"
                onClick={() => {
                  this.setState({
                    openContextMenuPosition: undefined,
                  });
                  this.props.onExportAudios(files);
                }}
              >
                {t("export_audio")}
              </div>

              {!(!oneFile || oneFile.canMove) ? null : (
                <div
                  className="context-menu-item"
                  onClick={() => {
                    this.setState({
                      openContextMenuPosition: undefined,
                    });
                    this.props.onMove(this.state.selected);
                  }}
                >
                  {t("move")}
                </div>
              )}
              {!(!oneFile || oneFile.canDelete || oneFile.canCancel) ? null : (
                <div
                  className="context-menu-item"
                  onClick={() => {
                    this.setState({
                      openContextMenuPosition: undefined,
                    });
                    if (oneFile && oneFile.canCancel) {
                      this.props.onCancel(oneFile);
                    } else {
                      this.props.onDelete(this.state.selected);
                    }
                  }}
                >
                  {oneFile && oneFile.canCancel ? t("cancel") : t("delete")}
                </div>
              )}
            </div>
          </Popup>
        )}
      </Translation>
    );
  }

  private getDragingImg(selected: string[]): Element {
    const msg = i18n.t("total_number_of_transcripts", {
      totalCnt: selected.length,
    });
    let container = document.createElement("div");
    container.className = "draging-items";
    let box1 = document.createElement("div");
    box1.className = "draging-item";
    let box2 = document.createElement("div");
    box2.className = "draging-item";
    container.appendChild(box1);
    if (selected.length > 2) {
      container.appendChild(box2);
      box2.innerText = msg;
    } else {
      box1.innerText = msg;
    }
    document.body.appendChild(container);
    return container;
  }

  private removeDragingImg() {
    let eles = document.getElementsByClassName("draging-items");
    for (let i = 0; i < eles.length; i++) {
      eles[i].remove();
    }
  }

  render() {
    if (this.props.isLoading) {
      return (
        <div className="loading-file-indicator">
          <LoadingPage />
        </div>
      );
    } else {
      return (
        <>
          {this.getControlPanel()}
          <div
            className="file-list-table"
            onBlur={() => {
              this.setState({
                showLastSelected: false,
              });
            }}
          >
            {this.props.files.list.map((file, idx) => {
              return (
                <FileItem
                  key={idx}
                  file={file}
                  selected={this.state.selected.includes(file.eid)}
                  highlight={
                    this.state.showLastSelected &&
                    this.state.lastSelected &&
                    this.state.lastSelected === file.eid
                  }
                  draging={
                    this.state.draging && this.state.selected.includes(file.eid)
                  }
                  selectable={true}
                  clickable={
                    !this.props.isTrashTable &&
                    file.canOpen &&
                    !this.state.openContextMenuPosition
                  }
                  editable={!this.props.isTrashTable && file.canEdit}
                  dragable={
                    !this.props.isTrashTable && file.canMove && Utils.isDesktop
                  }
                  showOptions={this.state.selected.length < 2}
                  canOpenShare={!this.props.isTrashTable && file.canOpenShare}
                  canExport={!this.props.isTrashTable && file.canExport}
                  canRename={!this.props.isTrashTable && file.canEditName}
                  movable={file.canMove}
                  deletable={file.canDelete}
                  hideIcon={
                    this.props.files.folderId === "/" ||
                    this.props.files.folderType === FolderType.Folder
                  }
                  customIcons={
                    this.props.isTrashTable
                      ? {
                          normal: itemIconNormal,
                          hover: itemIconHover,
                          selected: itemIconSelected,
                        }
                      : undefined
                  }
                  showDeletedTime={this.props.isTrashTable}
                  onSelect={() => {
                    if (
                      !this.state.pressedShift &&
                      !this.state.pressedCommand
                    ) {
                      if (this.props.isTrashTable) {
                        this.commandSelect(file.eid);
                      } else if (this.isFileSelectable(file)) {
                        this.setState({
                          selected: [file.eid],
                          showLastSelected: false,
                          openContextMenuPosition: undefined,
                          lastSelected: file.eid,
                        });
                      }
                    } else if (
                      this.state.pressedShift &&
                      this.state.lastSelected
                    ) {
                      this.shiftSelect(file.eid, idx);
                    } else if (
                      this.state.pressedShift ||
                      this.state.pressedCommand
                    ) {
                      this.commandSelect(file.eid);
                    }
                  }}
                  onDragStart={(event) => {
                    let selected = this.state.selected;
                    if (!selected.includes(file.eid)) {
                      selected = [file.eid];
                    }
                    this.setState({
                      draging: true,
                      selected: selected,
                    });
                    this.removeDragingImg();
                    event.dataTransfer.setDragImage(
                      this.getDragingImg(selected),
                      20,
                      0,
                    );
                    event.dataTransfer.setData(
                      "file_eids",
                      selected.join(", "),
                    );
                  }}
                  onDragEnd={(event) => {
                    this.setState({
                      selected: [],
                      pressedShift: false,
                      pressedCommand: false,
                      draging: false,
                    });
                    this.removeDragingImg();
                  }}
                  onCancel={() => {
                    this.props.onCancel(file);
                  }}
                  onDelete={() => {
                    this.props.onDelete([file.eid]);
                  }}
                  onMove={() => {
                    this.props.onMove([file.eid]);
                  }}
                  onShare={() => {
                    Utils.analyticsEvent({
                      category: "Share",
                      action: "Open share setting",
                      page: "File List Page",
                      is_owner: file.is_owner,
                      permission: Permission[file.permission],
                      duration: file.duration ? file.duration : -1, //-1 表示沒有voice 沒有時間長度
                      file_name: file.name,
                    });
                    this.props.onShare(file);
                  }}
                  onExport={() => {
                    Utils.analyticsEvent({
                      category: "Export",
                      action: "Open export",
                      page: "File List Page",
                      is_owner: file.is_owner,
                      permission: Permission[file.permission],
                      duration: file.duration ? file.duration : -1, //-1 表示沒有voice 沒有時間長度
                      file_name: file.name,
                    });
                    this.props.onExport(file);
                  }}
                  onExportAudio={() => {
                    this.props.onExportAudio(file);
                  }}
                  onRename={() => {
                    this.props.onRename(file);
                  }}
                  onContextMenu={(clientX: number, clientY: number) => {
                    if (this.props.isTrashTable) {
                      return;
                    }
                    if (!this.isFileSelectable(file)) {
                      return;
                    }
                    if (!this.state.selected.includes(file.eid)) {
                      this.setState({
                        selected: [file.eid],
                      });
                    }
                    this.setState({
                      openContextMenuPosition: {
                        x: clientX,
                        y: clientY,
                      },
                    });
                  }}
                  onCancelContextMenu={() => {
                    this.setState({
                      openContextMenuPosition: undefined,
                    });
                  }}
                />
              );
            })}
          </div>
          {this.getContextMenu()}
        </>
      );
    }
  }
}
