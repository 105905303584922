import Utils from "../Utils";
import Logger from "../Logger";
import { Translations } from "../Voice";
import User from "../User";
import { VoiceLang } from "../DataManager";

export enum ASRLang {
  Default = "default",
  Taiwanese = "tw",
}

export function parseASRLang(lang: string): ASRLang {
  switch (lang) {
    case ASRLang.Taiwanese:
      return ASRLang.Taiwanese;
    default:
      return ASRLang.Default;
  }
}

interface AsrRawData {
  asr_state?: string;
  asr_sentence?: string;
  asr_confidence?: number;
  asr_begin_time?: number;
  asr_end_time?: number;
  asr_final?: boolean;
  translations?: Translations;
  asr_super_final?: boolean;
}

interface AsrRecognitionResultData {
  pipe?: AsrRawData;
}

interface ConfigOutput {
  detail?: string;
  pipeline?: string;
  status?: string;
  voice_eid?: string;
}

interface AsrStatusOutput {
  asr_status_code: number;
}

interface AuthOutput {
  auth_challenge?: string;
  auth_type?: string;
}

interface ErrorOutput {
  error: {
    error_code: LiveStreamingErrorType;
    description: string;
  };
}

export enum LiveStreamingErrorType {
  CONNECTION_LIMIT_EXCEEDED = "connection_limit_exceeded",
  QUOTA_NOT_ENOUGH = "remain_quota_not_enough",
  // TODO
  // PERMISSION_DENIED = "permission_denied",
}

type DataTypeFromServer =
  | AsrRecognitionResultData
  | AuthOutput
  | ConfigOutput
  | AsrStatusOutput
  | ErrorOutput;

class RetryOperation {
  private minTimeout: number = 1000;
  private maxTimeout: number = 10000;
  private factor: number = 2;
  private retryTimer: ReturnType<typeof setTimeout> = undefined;
  private retryCnt = 0;
  private maxRetryCnt = -1;
  private timeout = 0;

  public constructor(props: {
    minTimeout?: number;
    maxTimeout?: number;
    factor?: number;
    maxRetryCnt?: number;
  }) {
    this.minTimeout = props.minTimeout || this.minTimeout;
    this.maxTimeout = props.maxTimeout || this.maxTimeout;
    this.factor = props.factor || this.factor;
    this.maxRetryCnt = props.maxRetryCnt || this.maxRetryCnt;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public retry(fn: (retryCnt: number, timeout: number) => any) {
    if (this.maxRetryCnt < 0 || this.maxRetryCnt > this.retryCnt) {
      const curRetryCnt = this.retryCnt;
      const curTimeout = this.timeout;

      clearTimeout(this.retryTimer);

      this.retryTimer = setTimeout(() => {
        fn(curRetryCnt, curTimeout);
      }, this.timeout);
      this.retryCnt++;
      this.timeout =
        this.timeout == 0
          ? this.minTimeout
          : Math.min(this.timeout * this.factor, this.maxTimeout);
    }
  }

  public stop() {
    clearTimeout(this.retryTimer);
  }

  public reset() {
    this.retryCnt = 0;
    this.timeout = 0;
  }
}

export class AilabsAsr {
  private ws?: WebSocket;
  private wsConnected: boolean = false;
  private wsReady: boolean = false;
  private forceClose: boolean = false;
  private retryOperation: RetryOperation;

  private pcmQueue: Int16Array[] = [];

  private onSettingReady: () => void;
  private onPipelineReady: () => void;
  private onSentence: (
    s: string,
    begin_time: number,
    end_time: number,
    asr_final: boolean,
    translations?: Translations,
    superFinal?: boolean,
  ) => void;
  private onReconnect: () => void;
  private onAsrStatus: (data: any) => void;
  private onEnableASREnhanced: () => void;
  private onDefinedError: (errorType: LiveStreamingErrorType) => void;

  private asrServerUrl: string = "";
  private lang = VoiceLang.Chinese;

  private fileName = "";
  private fileEid: string = "";
  private audioDevice = "";
  private translation?: string = undefined;
  private sentPcmLength = 0;

  public constructor(
    onSettingReady: () => void,
    onPipelineReady: () => void,
    onSentence: (
      s: string,
      begin_time: number,
      end_time: number,
      asr_final: boolean,
      translations?: Translations,
      asrSuperFinal?: boolean,
    ) => void,
    onReconnect: () => void,
    onAsrStatus: (data: object) => void,
    onEnableASREnhanced: () => void,
    onDefinedError: (errorType: LiveStreamingErrorType) => void,
  ) {
    this.onSettingReady = onSettingReady;
    this.onPipelineReady = onPipelineReady;
    this.onSentence = onSentence;
    this.onReconnect = onReconnect;
    this.onAsrStatus = onAsrStatus;
    this.onEnableASREnhanced = onEnableASREnhanced;
    this.onDefinedError = onDefinedError;

    this.wsConnected = false;
    this.retryOperation = new RetryOperation({
      minTimeout: 1000,
      maxTimeout: 5000,
      maxRetryCnt: 30,
    });
    this.connect = this.connect.bind(this);
    this.getAsrServerUrl();
  }

  private getAsrServerUrl() {
    Utils.getApi("get", "/pipeline/server")
      .send()
      .then((res) => {
        if (res.body) {
          this.asrServerUrl = res.body.url;
          this.onSettingReady();
        }
      })
      .catch((err) => {
        Logger.error(`get asr server url error ${err}`);
      });
  }

  public get FileEid() {
    return this.fileEid;
  }

  public async connect(
    lang: VoiceLang,
    fileName?: string,
    audioDevice?: string,
    translation?: string,
  ): Promise<void> {
    this.lang = lang;
    this.fileName = fileName || this.fileName;
    this.audioDevice = audioDevice || this.audioDevice;
    this.translation = translation || this.translation;
    const webSocketUrl = new URL(this.asrServerUrl);
    const isEnableASREnhanced = await this.isEnableASREnhanced();
    if (isEnableASREnhanced) {
      webSocketUrl.searchParams.set("asr_enhanced", "true");
      this.onEnableASREnhanced();
    }
    return new Promise<void>((resolve): void => {
      if (!this.asrServerUrl) {
        throw new Error("[WebSocket] No WebSocket Server url.");
      }
      console.log(
        `[WebSocket] trying to establish a WebSocket to ${this.asrServerUrl}`,
      );
      this.ws = new WebSocket(webSocketUrl.toString());

      this.ws.onopen = (event: Event): void => {
        this.onOpen(event);
        resolve();
      };

      this.ws.onclose = (event: CloseEvent): void => {
        this.onClose(event);
      };

      this.ws.onerror = (event: Event): void => {
        console.error(`[WebSocket] error occurred: ${JSON.stringify(event)}`);
      };

      this.ws.onmessage = (event: MessageEvent): void => {
        this.onData(event);
      };
    });
  }

  private async isEnableASREnhanced() {
    const isValidLanguage =
      this.lang === VoiceLang.ChineseAndEnglish ||
      this.lang === VoiceLang.ChineseAndEnglishAndTaiwanese ||
      this.lang === VoiceLang.ChineseAndTaiwanese;

    const getLiveTrabskribeEnhancedPermission = async () => {
      try {
        const res = await Utils.getApi("get", "/personal/permission");
        if (res.body.success) {
          if (res.body.permissionInfo != null) {
            return res.body.permissionInfo.LiveTranskribeEnhanced === true;
          }
        }
      } catch (err) {
        console.error(`get personal permission error`, err);
        return false;
      }
    };

    const isValidPermission = await getLiveTrabskribeEnhancedPermission();
    return isValidLanguage && isValidPermission;
  }

  public disconnect() {
    if (this.ws) {
      this.forceClose = true;
      this.ws.close();
      this.wsConnected = false;
      this.wsReady = false;
    }
  }

  public requestClose() {
    this.ws.send(
      JSON.stringify({
        action: "stop_asr",
      }),
    );
  }

  private retry(): void {
    this.retryOperation.retry((currentAttempt, timeout) => {
      console.log(
        `reconnect to websocket times: ${currentAttempt} timeout: ${timeout}`,
      );
      this.onReconnect();
      this.connect(this.lang);
    });
  }

  public sendPcm(data: Int16Array): void {
    this.retryOperation.reset();
    if (this.wsReady) {
      if (!this.ws) {
        throw new Error("ws is undefined");
      }

      this.sentPcmLength += data.length;
      this.ws.send(data);
    } else {
      this.pcmQueue.push(data);
    }
  }
  public updateFileName(newName: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (this.fileEid.length > 0) {
        Utils.getApi("put", "/db/voice/update/name")
          .send(
            Object.assign({
              eid: this.fileEid,
              name: newName,
            }),
          )
          .then((res) => {
            if (res.body.success) {
              this.fileName = newName;
              resolve(true);
            } else {
              resolve(false);
            }
          })
          .catch((err) => {
            reject(err);
          });
      } else {
        resolve(false);
      }
    });
  }

  public useTranslantion(lang: string) {
    this.stopTranslantion();
    setTimeout(() => {
      this.translation = lang;
      if (this.wsConnected) {
        this.ws.send(
          JSON.stringify({
            action: "use_translation",
            translation: lang,
          }),
        );
      }
    }, 200);
  }

  public stopTranslantion() {
    this.translation = undefined;
    if (this.wsConnected) {
      this.ws.send(
        JSON.stringify({
          action: "stop_translation",
          translation: "all",
        }),
      );
    }
  }

  public get sentDataInMs() {
    return this.sentPcmLength / 16;
  }

  public get isWsConnected(): boolean {
    return this.wsConnected;
  }

  public get bufferedAmount(): number {
    if (this.ws) {
      return this.ws.bufferedAmount;
    } else {
      return -1;
    }
  }

  // websocket event
  private onOpen(event: Event): void {
    try {
      console.log(
        `[WebSocket] complete to establish a WebSocket to ${this.asrServerUrl} ${JSON.stringify(event)}`,
      );

      const deviceInfo = navigator.userAgent.match(/\([^)]+\)/);
      const msg = {
        token: User.token,
        lang: this.lang,
        device: deviceInfo.length > 0 ? deviceInfo[0] : "",
        mic: this.audioDevice,
        name: this.fileName,
        translation: this.translation,
      };

      this.ws.send(JSON.stringify(msg));

      this.wsConnected = true;
      this.sentPcmLength = 0;
      this.retryOperation.stop();
    } catch (err) {
      Logger.error(`asr websocket on open error ${err}`);
      this.handleError(err);
    }
  }

  private onClose(event: CloseEvent): void {
    if (event.wasClean !== true) {
      console.error(
        `[WebSocket] is closed abnormally. ${JSON.stringify(event)}`,
      );
    } else {
      console.log(`[WebSocket] is closed. ${JSON.stringify(event)}`);
    }
    this.wsConnected = false;
    this.wsReady = false;
    this.ws = undefined;

    // Reconnect
    if (!this.forceClose) {
      this.retry();
    }
  }

  private async onData(event: MessageEvent): Promise<void> {
    if (!Utils.IsJsonString(event.data)) {
      // ping pong
      return;
    }
    const data: DataTypeFromServer = JSON.parse(event.data);
    if (this.isRecognitionOutput(data)) {
      const asrData = data.pipe;
      if (asrData) {
        switch (asrData.asr_state) {
          case "first_chunk_received":
            break;
          case "utterance_begin":
            break;
          case "utterance_end":
            break;
        }

        this.onSentence(
          asrData.asr_sentence || "",
          asrData.asr_begin_time,
          asrData.asr_end_time,
          asrData.asr_final,
          asrData.translations,
          asrData.asr_super_final,
        );
      }
    } else if (this.isConfigOutput(data)) {
      if (data.status == "error") {
        this.handleError(data.detail || "(unknown error)");
      } else if (data.status == "ok") {
        if (data.voice_eid) {
          this.fileEid = data.voice_eid;
        }
        this.wsReady = true;

        if (0 !== this.pcmQueue.length) {
          this.pcmQueue.forEach((pcm) => this.sendPcm(pcm));
          this.pcmQueue = [];
        }

        this.onPipelineReady();
      }
    } else if (this.isAsrStatusOutput(data)) {
      this.onAsrStatus(data);
    } else if (this.isErrorOutput(data)) {
      this.forceClose = true;
      const errorType = data.error.error_code;
      this.handleError(errorType);
      if (errorType) {
        this.onDefinedError(errorType);
      }
    } else {
      // unhandle message;
    }
  }

  private isRecognitionOutput(
    response: DataTypeFromServer,
  ): response is AsrRecognitionResultData {
    if ((response as AsrRecognitionResultData).pipe !== undefined) {
      return true;
    }
    return false;
  }

  private isConfigOutput(
    response: DataTypeFromServer,
  ): response is ConfigOutput {
    if ((response as ConfigOutput).status !== undefined) {
      return true;
    }
    return false;
  }

  private isAsrStatusOutput(
    response: DataTypeFromServer,
  ): response is AsrStatusOutput {
    if ((response as AsrStatusOutput).asr_status_code !== undefined) {
      return true;
    }
    return false;
  }

  private isErrorOutput(response: DataTypeFromServer): response is ErrorOutput {
    if ((response as ErrorOutput).error !== undefined) {
      return true;
    }
    return false;
  }

  private handleError(err: string) {
    console.error("*** error:", err);
  }
}
