import superagent from "superagent";
import { observable, action } from "mobx";
import { saveAs } from "file-saver";
import { MessageBoxColor } from "./GlobalDefine";

import Config from "./Config";
import User from "./User";
import Logger from "./Logger";
import ReactGA from "react-ga";
import i18n from "../i18n";

ReactGA.initialize("UA-137596164-2", { debug: false }); // old GA account
// GTag & Amplitude 在 html 裡面已經初始化完成，而且變成 global 變數

export class SiteMessage {
  private static _instance: SiteMessage = null;

  static get instance() {
    if (!SiteMessage._instance) {
      SiteMessage._instance = new SiteMessage();
    }

    return SiteMessage._instance;
  }

  @observable message: {
    message: string;
    color: MessageBoxColor;
  } = undefined;

  @action
  public showMessage(message: string, color?: MessageBoxColor) {
    this.message = {
      message: message,
      color: color ? color : MessageBoxColor.Default,
    };
  }

  @action
  public clearMessage() {
    this.message = undefined;
  }
}

export class RedirectTo {
  private static _instance: RedirectTo = null;
  static FrontPage = `${Config.baseName}`;
  static FilePage = `${Config.baseName}file`;

  static get instance() {
    if (!RedirectTo._instance) {
      RedirectTo._instance = new RedirectTo();
    }

    return RedirectTo._instance;
  }

  @observable
  public redirect: string = undefined;

  @action
  public redirectTo(to: string) {
    this.redirect = to;
    setTimeout(() => {
      this.redirect = undefined;
    }, 500);
  }
}

export enum InvoiceType {
  company,
  store,
  person,
  cellphone,
  donation,
}

export default class Utils {
  static isUsingTouch = false;

  static getApi(
    method: "post" | "put" | "get" | "delete",
    api: string,
    withAuth: boolean = true,
  ) {
    let auth = withAuth && User.token && User.token.length > 0;
    let req: superagent.SuperAgentRequest;
    let url = `${Config.baseName}api/v1${api}`;

    if ("post" == method) {
      req = superagent.post(url);
    } else if ("put" == method) {
      req = superagent.put(url);
    } else if ("get" == method) {
      req = superagent.get(url);
    } else if ("delete" == method) {
      req = superagent.delete(url);
    }

    if (auth) {
      req.auth(User.token, { type: "bearer" });
    }

    // https://visionmedia.github.io/superagent/#error-handling
    req.on("error", (err: any) => {
      Logger.error("Call API Error", {
        status: err.status,
        label: err.message,
        url: url,
        method: method,
        withAuth: withAuth,
      });
      // UnAuthorized
      if (err && 401 === err.status && User.token && User.token.length > 0) {
        alert(i18n.t("alert_session_expired"));
        User.logout();
      }
    });

    return req;
  }

  static setAnalyticsUser(userId: string, email: string, name: string) {
    if (!Config.trackUserEvent) {
      return;
    }

    ReactGA.set({ userId: userId });
    (window as any).gtag("config", "G-GXBV8XQNZV", { user_id: userId });
    (window as any).gtag("set", "user_properties", {
      email: email,
      name: name,
    });
    (window as any).amplitude.getInstance().setUserId(userId);
    (window as any).amplitude.getInstance().setUserProperties({
      email: email,
      name: name,
    });
  }

  static analyticsUserPropertiesAddValue(
    add: Array<{
      property: string;
      value: number;
    }>,
  ) {
    if (!Config.trackUserEvent) {
      return;
    }

    var identify = new (window as any).amplitude.Identify();
    add.forEach(function (obj) {
      identify.add(obj.property, obj.value);
    });
    (window as any).amplitude.identify(identify);
  }

  static analyticsPageView(page: string, properties?: { [key: string]: any }) {
    if (!Config.trackUserEvent) {
      //Logger.log(`pageView ${page}`, properties);
      return;
    }

    if (properties == null) {
      properties = { page: page };
    } else {
      properties["page"] = page;
    }

    ReactGA.pageview(page);
    (window as any).gtag("config", "G-GXBV8XQNZV", {
      page_title: page,
      page_path: page,
    });
    (window as any).amplitude.getInstance().logEvent("PageView", properties);

    //Logger.log(`pageView ${page}`, properties);
  }

  static analyticsEvent(event: {
    category: string;
    action: string;
    label?: string;
    value?: number;
    [key: string]: any;
  }) {
    if (!Config.trackUserEvent) {
      // console.log(`event: [${event.category}][${event.action}]`, event);
      return;
    }

    ReactGA.event(event);
    (window as any).gtag("event", event.action, {
      event_category: event.category,
      event_label: event.label,
      value: event.value,
    });
    (window as any).amplitude
      .getInstance()
      .logEvent(`[${event.category}][${event.action}]`, event);
    // console.log(`event: [${event.category}][${event.action}]`, event);
  }

  static analyticsRevenue(revenue: { productId: string; price: number }) {
    if (!Config.trackUserEvent) {
      return;
    }

    var r = new (window as any).amplitude.Revenue()
      .setProductId(revenue.productId)
      .setPrice(revenue.price);
    (window as any).amplitude.logRevenueV2(r);
  }

  static sendAnalyticsOutboundClickEvent(event: any) {
    Utils.analyticsEvent({
      category: "Outbound",
      action: "Click",
      label: event.target.href,
      page: window.location.pathname,
    });
  }

  static padNum(num: number, size: number): string {
    if (typeof num !== "number") {
      return "";
    }

    let str = String(num);

    while (str.length < (size || 2)) {
      str = "0" + str;
    }

    return str;
  }

  static objCombine(dest: any, source: any) {
    if (typeof dest !== "object" || typeof source !== "object") {
      console.log("not match");
      return dest;
    }

    for (let key of Object.keys(source)) {
      if (key in dest) {
        dest[key] = source[key];
      }
    }
  }

  static isAborted(err: any) {
    return err.code && "ABORTED" === err.code;
  }

  static removeFileExtension(name: string) {
    if ("string" !== typeof name) {
      return "unknown";
    }

    let arr = name.split(".");
    let ret = name;

    if (2 <= arr.length) {
      ret = arr.slice(0, -1).join(".");
    }

    return ret;
  }

  static composeSentence(
    name: string,
    sentences: Array<{
      content: string;
      startTime: number;
      endTime: number;
    }>,
    format: string,
  ): {
    result: string;
    extension: string;
  } {
    let result = "";
    let extension = "txt";

    if ("webvtt" === format) {
      let count = 0;
      const toIsoTime = function (timestamp: number, millis_sep: string) {
        let hours = Math.floor(timestamp / 3600);
        let minutes = Math.floor((timestamp - hours * 3600) / 60);
        let seconds = Math.floor(timestamp - hours * 3600 - minutes * 60);
        let millis = Math.floor((timestamp - Math.floor(timestamp)) * 1000);

        let h = hours < 10 ? "0" + hours : String(hours);
        let m = minutes < 10 ? "0" + minutes : String(minutes);
        let s = seconds < 10 ? "0" + seconds : String(seconds);
        let l =
          millis < 10
            ? "00" + millis
            : millis < 100
              ? "0" + millis
              : String(millis);

        return h + ":" + m + ":" + s + millis_sep + l;
      };

      result =
        "WEBVTT - Transcribed from " +
        name +
        "\r\n" +
        "Kind: captions\r\n" +
        "Language: zh-TW\r\n\n";

      sentences.forEach((s) => {
        result +=
          "" +
          count +
          "\n" +
          toIsoTime(s.startTime / 1000, ".") +
          " --> " +
          toIsoTime(s.endTime / 1000, ".") +
          "\r\n" +
          s.content +
          "\r\n\n";
        count++;
      });
    } else {
      sentences.forEach((s) => {
        result += s.content + "\r\n";
      });
    }

    return {
      result: result,
      extension: extension,
    };
  }

  static getValueFromUrlQuery(search: string, key: string): string {
    let result = "";
    if (search) {
      const params = search.substr(1);
      params.split("&").forEach((param) => {
        const splitIndex = param.indexOf("=");
        if (splitIndex > 0) {
          const param_key = param.substring(0, splitIndex);
          const param_value = param.substring(splitIndex + 1);
          if (param_key === key) {
            result = param_value;
          }
        }
      });
    }
    return result;
  }

  static setValueToUrlQuery(
    search: string,
    key: string,
    value: string,
  ): string {
    let result = "";
    let pairs: string[] = [];
    let found = false;

    if (search && search.length > 0) {
      const params = search.substr(1);
      params.split("&").forEach((param) => {
        const splitIndex = param.indexOf("=");
        if (splitIndex > 0) {
          const param_key = param.substring(0, splitIndex);
          if (param_key === key) {
            found = true;
            if (value && value.length > 0) {
              pairs.push(`${key}=${value}`);
            }
          } else {
            pairs.push(param);
          }
        }
      });
    }

    if (!found && value && value.length > 0) {
      pairs.push(`${key}=${value}`);
    }
    if (pairs.length > 0) {
      result = `?${pairs.join("&")}`;
    }

    return result;
  }

  static getCookie(key: string) {
    let cookieArr = document.cookie.split(";");
    for (var i = 0; i < cookieArr.length; i++) {
      var cookiePair = cookieArr[i].split("=");
      if (key == cookiePair[0].trim()) {
        // Decode the cookie value and return
        return decodeURIComponent(cookiePair[1]);
      }
    }
    return null;
  }

  static IsJsonString(str: string) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  static isValidEmail(email: string) {
    return /[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/.test(email);
  }

  static removeFromArray<T>(array: T[], ele: T): T[] {
    const index = array.indexOf(ele);
    if (index > -1) {
      array.splice(index, 1);
    }
    return array;
  }

  static moveArrayElement<T>(array: T[], oldIdx: number, newIdx: number): T[] {
    if (newIdx >= array.length) {
      let k = newIdx - array.length + 1;
      while (k--) {
        array.push(undefined);
      }
    }
    array.splice(newIdx, 0, array.splice(oldIdx, 1)[0]);
    return array; // for testing
  }

  // format hh mm:ss
  static transferMilliSecondToTime(
    duration: number, // input time in ms
    clockFormat: boolean = false, // set true to format as hh:mm:ss
    includeMS: boolean = false, // set true to detail to ms
  ): string {
    let seconds = Math.floor(duration / 1000);
    const result = [];
    const hour = Math.floor(seconds / 3600);
    if (clockFormat) {
      result.push(
        Math.floor(seconds / 3600)
          .toString()
          .padStart(2, "0"),
      );
    }
    result.push(
      Math.floor((seconds % 3600) / 60)
        .toString()
        .padStart(2, "0"),
    );
    result.push((seconds % 60).toString().padStart(2, "0"));

    if (clockFormat) {
      if (includeMS) {
        return (
          result.join(":") + "," + (duration % 1000).toString().padStart(3, "0")
        );
      } else {
        return result.join(":");
      }
    } else {
      return `${hour > 0 ? `${hour}h ` : ""}${result.join(":")}`;
    }
  }

  static escapeHtml(unsafe: string): string {
    return unsafe
      .replace(/&/g, "&amp;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;")
      .replace(/"/g, "&quot;")
      .replace(/'/g, "&#039;");
  }

  static downloadResource(url: string, fileName?: string) {
    let fn = url.split("\\").pop().split("/").pop().split("?")[0];
    let extension = fn.split(".").pop();
    fetch(url, {
      headers: new Headers({
        Origin: location.origin,
      }),
      mode: "cors",
    })
      .then((response) => {
        if (response.status === 200) {
          return response.blob();
        }
      })
      .then((blob) => {
        if (blob) {
          saveAs(
            blob,
            fileName
              ? fileName.replace(/[\\/:"*?<>|]+/gi, "_") + "." + extension
              : fileName,
          );
        }
      })
      .catch((err) => {
        Logger.error("download resource Error", {
          status: err.status,
          label: err.message,
          url: url,
        });
      });
  }

  static handleScrollPosition(scrollElement: Element, sessionKey: string) {
    const position = sessionStorage.getItem(sessionKey);

    if (position) scrollElement.scrollTop = parseInt(position);
    this.storeScrollPosition(sessionKey, 0);
  }

  static storeScrollPosition(sessionKey: string, scrollPostion: number) {
    sessionStorage.setItem(sessionKey, String(scrollPostion));
  }

  static get isDesktop() {
    return document.body.clientWidth >= 1024;
  }

  static get isPhone() {
    return document.body.clientWidth <= 768;
  }

  static get isiPhoneLike() {
    return /(iPhone|iPod)/i.test(navigator.platform);
  }

  static get isIOS() {
    return (
      [
        "iPad Simulator",
        "iPhone Simulator",
        "iPod Simulator",
        "iPad",
        "iPhone",
        "iPod",
      ].includes(navigator.platform) ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    );
  }

  static get isAndroid() {
    return /android/i.test(navigator.userAgent);
  }

  static get isMacLike() {
    return /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform);
  }

  static get isWebInApp() {
    return /Yating/i.test(navigator.userAgent);
  }

  static showSpeaker(speaker: string) {
    return speaker.startsWith("語者") &&
      /^(\-|\+)?([0-9]+|Infinity)$/.test(speaker.slice(2))
      ? i18n.t("default_speaker_name", { ns: "file", id: speaker.slice(2) })
      : speaker;
  }
}
