import React from "react";
import { observer } from "mobx-react";
import { Link } from "react-router-dom";
import Modal from "semantic-ui-react/dist/commonjs/modules/Modal";
import Popup from "semantic-ui-react/dist/commonjs/modules/Popup";

import { ButtonColor, Color2, hex2RGBA, Styles } from "../GlobalDefine";
import {
  ActionButton,
  PopupLayeredMenu,
  PopupMenu,
  PopupMenuItem,
  ModalHeader,
  ModalActions,
  UserIcon,
  AnimatedArrow,
  PopupMenuItemProps,
} from "../ui/Component";
import LoadingPage from "../page/LoadingPage";
import FileUploader from "../UploadFile";
import UploadYoutubeWindow from "./UploadYoutubeWindow";
import AccountWidget from "../ui/AccountWidget";
import ExportWindow from "../ui/ExportWindow";
import FeedbackWindow from "../ui/FeedbackWindow";
import Utils from "../Utils";
import Config from "../Config";
import Logger from "../Logger";
import Voice from "../Voice";
import User, { UserLevel } from "../User";
import DataManager, { FolderInfo, FolderType, folderMap } from "../DataManager";
import { Translation } from "react-i18next";
import i18n from "../../i18n";
import { ErrorModal } from "./ErrorModal";
import { LiveStreamingErrorType } from "../pipeline/AilabsAsr";
import {
  WhisperNotSubscriptModal,
  WhisperSelectModal,
} from "./WhisperSelectModal";

import "../../assets/scss/SideBar.scss";

import closeIcon from "../../assets/img/icon-cancel.svg";

import addIconWhite from "../../assets/img/icon-add-white.svg";
import liveRecordingIcon from "../../assets/img/icon-recording.svg";
import uploadIcon from "../../assets/img/icon-upload.svg";

import folderIcon from "../../assets/img/icon-folder.svg";
import subfolderIcon from "../../assets/img/icon-folder-figure.svg";
import sharedwithmeIcon from "../../assets/img/icon-share.svg";
import recentIcon from "../../assets/img/icon-recent.svg";
import allIcon from "../../assets/img/icon-all.svg";
import trashIcon from "../../assets/img/icon-discard.svg";

import addIcon from "../../assets/img/icon-add.svg";
import moreIcon from "../../assets/img/icon-more.svg";
import logo from "../../assets/img/icon-logo.svg";
import navOpenIcon from "../../assets/img/icon-more-menu.svg";
import globeIcon from "../../assets/img/icon-globe.svg";

import iosQrcode from "../../assets/img/qrcode-download-app.svg";
import iosDownloadBtn from "../../assets/img/download-ios-white.svg";
import googleLogo from "../../assets/img/google-g-logo.svg";
import appleLogo from "../../assets/img/apple-logo-grey.svg";
import { StudioLogoLink } from "./StudioLogoLink";
import { StudioAppsButton } from "./StudioNav";

interface MenuItemProps {
  title: string;
  titleColor?: string;
  to?: {
    pathname: string;
    search: string;
  };
  isActive: boolean;
  open?: "open" | "close" | "init-open" | "init-close";
  addPadding?: number;
  customIcon?: any;
  customIconActive?: any;
  onClick?: () => void;
  onClickArrow?: (event: React.MouseEvent<HTMLImageElement>) => void;
  moreActions?: {
    title?: string;
    icon?: string;
    onClick: () => void;
    onClickArrow?: (event: React.MouseEvent<HTMLImageElement>) => void;
  }[];
}

interface MenuItemState {
  hover: boolean; // if this item is on hover
  enablePopup: boolean; // if the title is too long, then popup the whole title when hover
  openMoreActionsPopup: boolean; // if the more action popup is open
}

class MenuItem extends React.Component<MenuItemProps, MenuItemState> {
  private isComponentMounted: boolean;
  private _textElement: React.RefObject<HTMLDivElement>;

  constructor(props: any) {
    super(props);

    this._textElement = React.createRef<HTMLDivElement>();
    this.state = {
      hover: false,
      enablePopup: false,
      openMoreActionsPopup: false,
    };
  }

  componentDidMount() {
    this.isComponentMounted = true;
    window.addEventListener("resize", this.checkNamePopup.bind(this));
    this.checkNamePopup();
  }

  componentWillUnmount() {
    this.isComponentMounted = false;
    window.removeEventListener("resize", this.checkNamePopup.bind(this));
  }

  componentDidUpdate(prevProps: MenuItemProps, prevStats: MenuItemState) {
    if (prevProps.title !== this.props.title) {
      this.checkNamePopup();
    }
  }

  private checkNamePopup() {
    if (!this._textElement.current || !this.isComponentMounted) {
      return;
    }

    if (
      this._textElement.current.offsetWidth <
      this._textElement.current.scrollWidth
    ) {
      this.setState({
        enablePopup: true,
      });
    } else {
      this.setState({
        enablePopup: false,
      });
    }
  }

  private moveFiles(fileEids: string[]) {
    if (!this.props.to) {
      return;
    }
    if (
      this.props.to.pathname ===
        folderMap.get(FolderType.Uncategorized).pathname &&
      window.location.pathname ===
        `${Config.baseName}${folderMap
          .get(FolderType.Folder)
          .pathname.substring(1)}`
    ) {
      DataManager.instance.moveVoices(fileEids, "", "Side Bar", false);
    } else if (
      this.props.to.pathname === folderMap.get(FolderType.Deleted).pathname
    ) {
      DataManager.instance.deleteVoices(fileEids, "Side Bar", false);
    } else if (
      this.props.to.pathname === folderMap.get(FolderType.Folder).pathname
    ) {
      let folderId = Utils.getValueFromUrlQuery(this.props.to.search, "eid");
      DataManager.instance.moveVoices(fileEids, folderId, "Side Bar", false);
    } else {
      return;
    }
  }

  getContent() {
    let icon = this.props.customIcon ? this.props.customIcon : folderIcon;
    let activeIcon = this.props.customIconActive
      ? this.props.customIconActive
      : icon;

    return (
      <Link
        to={this.props.to || ""}
        draggable={false}
        className={`menu-item${this.props.isActive ? " active" : ""}${
          this.state.hover ? " hover" : ""
        }${
          this.props.addPadding > 0
            ? ` add-padding-${this.props.addPadding}`
            : ""
        }`}
        onClick={(event) => {
          this.props.onClick && this.props.onClick();
          if (!this.props.to) {
            event.preventDefault();
            event.stopPropagation();
          }
        }}
        onDragEnter={(e) => {
          this.setState({
            hover: true,
          });
        }}
        onDragLeave={(e) => {
          this.setState({
            hover: false,
          });
        }}
        onDragOver={(e) => {
          e.preventDefault();
        }}
        onDrop={(e) => {
          let file_eids = e.dataTransfer.getData("file_eids");
          this.moveFiles(file_eids.split(", "));
          this.setState({
            hover: false,
          });
        }}
      >
        {this.props.open ? (
          <AnimatedArrow
            open={this.props.open}
            onClick={(event) => {
              if (this.props.onClickArrow) {
                event.preventDefault();
                event.stopPropagation();
                this.props.onClickArrow(event);
              }
            }}
          />
        ) : null}
        <img src={this.props.isActive ? activeIcon : icon} draggable={false} />
        <div
          className="menu-item-text"
          ref={this._textElement}
          style={{
            color: this.props.titleColor ? this.props.titleColor : "",
          }}
        >
          {this.props.title}
        </div>
        {this.props.moreActions ? (
          this.props.moreActions.length === 1 ? (
            <img
              className="more-actions"
              draggable={false}
              src={
                this.props.moreActions[0].icon
                  ? this.props.moreActions[0].icon
                  : moreIcon
              }
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                this.props.moreActions[0].onClick();
              }}
            />
          ) : (
            <Popup
              hideOnScroll
              size="tiny"
              position="right center"
              on="focus"
              open={this.state.openMoreActionsPopup}
              onClose={() => {
                this.setState({
                  openMoreActionsPopup: false,
                });
              }}
              style={{
                ...Styles.popupContainer,
                zIndex: 3000,
              }}
              trigger={
                <img
                  className="more-actions"
                  draggable={false}
                  src={moreIcon}
                  onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    this.setState({
                      openMoreActionsPopup: !this.state.openMoreActionsPopup,
                    });
                  }}
                />
              }
              content={
                <PopupMenu
                  style={{
                    minWidth: "fit-content",
                    background: "none",
                  }}
                >
                  {this.props.moreActions.map((item, idx) => {
                    return (
                      <PopupMenuItem
                        key={idx}
                        style={{ minWidth: "120px" }}
                        onClick={(event) => {
                          event.preventDefault();
                          event.stopPropagation();
                          item.onClick();
                          this.setState({
                            openMoreActionsPopup: false,
                          });
                        }}
                      >
                        {item.icon ? (
                          <img
                            src={item.icon}
                            draggable={false}
                            style={{
                              width: "24px",
                              height: "24px",
                              position: "absolute",
                            }}
                          />
                        ) : (
                          ""
                        )}
                        <label
                          style={{
                            verticalAlign: "middle",
                            color: Color2.black80,
                            marginLeft: item.icon ? "30px" : "",
                            fontSize: "14px",
                            cursor: "pointer",
                          }}
                        >
                          {item.title}
                        </label>
                      </PopupMenuItem>
                    );
                  })}
                </PopupMenu>
              }
              basic
            />
          )
        ) : (
          ""
        )}
      </Link>
    );
  }

  render() {
    return (
      <Popup
        hideOnScroll
        on="hover"
        position="top right"
        trigger={this.getContent()}
        content={this.props.title}
        style={{
          background: hex2RGBA(Color2.black80, 0.85),
          zIndex: 3000,
        }}
        inverted
        basic
        disabled={!this.state.enablePopup}
      />
    );
  }
}

interface SideBarProps {
  folderList: FolderInfo[];
  currentFolder?: FolderType;
  currentFolderId: string;
  isLogined: boolean;
  isDataReady: boolean;
}

interface SideBarState {
  isDesktop: boolean;
  open: "open" | "close" | "init"; // side-bar open status (in mobile mode)

  openAccountMenu: "open" | "close" | "init-close"; // open status for account menu
  openNewFileMenu: boolean; // open status for add new file menu
  uploadFileLang: string; // choose language for upload file
  showUploadYoutube: boolean; // open youtube upload widget

  showAddForder: boolean; // open add folder widget
  openMyFolders: "open" | "close" | "init-open" | "init-close"; // open status for 我的逐字稿
  exportFolder?: {
    folder: FolderInfo;
    first_voice: Voice;
  };
  renameForder?: FolderInfo; // for re-name folder widget
  deleteForder?: FolderInfo; // for delete folder widget
  isRenamingFolder: boolean; // request loading status for re-name folder
  isLoadingFile: boolean;

  showFeedback: boolean; // open feedback window
  isOpenErrorModal: boolean;
  errorType: LiveStreamingErrorType | undefined;

  /** For whisper language select modal */
  showWhisperModal: boolean;
}

@observer
export default class SideBar extends React.Component<
  SideBarProps,
  SideBarState
> {
  private isComponentMounted: boolean;
  private _fileInput: React.RefObject<HTMLInputElement>;
  private _folderNameInput: React.RefObject<HTMLInputElement>;

  constructor(props: any) {
    super(props);
    this._fileInput = React.createRef<HTMLInputElement>();
    this._folderNameInput = React.createRef<HTMLInputElement>();

    this.state = {
      isDesktop: Utils.isDesktop,
      open: "init",
      openAccountMenu: "init-close",
      openNewFileMenu: false,
      uploadFileLang: "zh",
      showUploadYoutube: false,
      showAddForder: false,
      openMyFolders: "init-open",
      isRenamingFolder: false,
      isLoadingFile: false,
      showFeedback: false,
      isOpenErrorModal: false,
      errorType: undefined,
      showWhisperModal: false,
    };
  }

  componentDidMount() {
    this.isComponentMounted = true;
    window.addEventListener("resize", this.onResize.bind(this));
    this.onResize();
  }

  componentWillUnmount() {
    this.isComponentMounted = false;
    window.removeEventListener("resize", this.onResize.bind(this));
  }

  componentDidUpdate(prevProps: SideBarProps, prevStats: SideBarState) {
    if (
      prevProps.currentFolder !== this.props.currentFolder &&
      this.props.currentFolder &&
      this.props.currentFolder !== FolderType.Uncategorized
    ) {
      this.setState({
        openMyFolders: "init-open",
      });
    }
  }

  private onResize() {
    if (this.isComponentMounted) {
      this.setState({
        isDesktop: Utils.isDesktop,
      });
    }
  }

  public handleKeyUpListener(e: KeyboardEvent): boolean {
    if (e.keyCode === 13) {
      // enter
      this.folderNamingOnYes();
      this.folderDeletingOnYes();
    } else if (e.keyCode === 27) {
      // esc
      this.folderNamingOnCancel();
      this.folderDeletingOnCancel();
      this.setState({
        showFeedback: false,
      });
    }
    return true;
  }

  public resetFileInput() {
    if (this._fileInput.current) {
      this._fileInput.current.value = "";
    }
  }

  private updateFolderName(folder: FolderInfo, newName: string) {
    this.setState({
      isRenamingFolder: true,
    });
    DataManager.instance.renameFolder(folder.eid, newName).then((success) => {
      if (success) {
        this.setState({
          renameForder: undefined,
        });
      }
      this.setState({
        isRenamingFolder: false,
      });
    });
  }

  private getAddFileUI(): React.ReactElement {
    const hasPremiumUploadOptions =
      !!DataManager.instance.asrModels.uploadFilePremium?.length;
    return (
      <Translation>
        {(t) => (
          <div className="add-btn-container">
            {User.instance.isSubscribed ? (
              <WhisperSelectModal
                show={this.state.showWhisperModal}
                models={DataManager.instance.asrModels.uploadFilePremium}
                onSelect={(id: string) => {
                  this.setState(
                    {
                      uploadFileLang: id,
                      open: "close",
                      openNewFileMenu: false,
                    },
                    () => {
                      this._fileInput.current?.click();
                    },
                  );
                  Utils.analyticsEvent({
                    category: "New Recording",
                    action: "Click Whisper Upload File",
                    lang: id,
                  });
                  this.setState({ showWhisperModal: false });
                }}
                onCancel={() => {
                  this.setState({ showWhisperModal: false });
                }}
              />
            ) : (
              <WhisperNotSubscriptModal
                show={this.state.showWhisperModal}
                onCancel={() => {
                  this.setState({ showWhisperModal: false });
                }}
              />
            )}
            <Popup
              basic
              on="click"
              open={this.state.openNewFileMenu}
              size="small"
              position="bottom center"
              onOpen={() => {
                this.setState({
                  openNewFileMenu: true,
                });
              }}
              onClose={() => {
                setTimeout(() => {
                  this.setState({
                    openNewFileMenu: false,
                  });
                }, 200);
              }}
              style={{
                ...Styles.popupContainer,
                zIndex: 3000,
              }}
              trigger={
                <ActionButton
                  color={ButtonColor.Orange}
                  icon={addIconWhite}
                  content={t("create_new_transcript")}
                  style={{
                    textAlign: "left",
                    margin: "0px",
                    width: "100%",
                  }}
                />
              }
              content={
                <PopupLayeredMenu
                  className="add-file-menu"
                  items={[
                    {
                      text: t("instant_recording_free"),
                      icon: liveRecordingIcon,
                      style: {
                        zIndex: 3000,
                      },
                      subMenu: DataManager.instance.asrModels.live.map(
                        (asrModel) => {
                          return {
                            text: asrModel.name,
                            onClick: () => {
                              this.setState({
                                open: "close",
                                openNewFileMenu: false,
                              });
                              Utils.analyticsEvent({
                                category: "New Recording",
                                action: "Click New Recording",
                                lang: asrModel.id,
                              });
                              if (
                                DataManager.instance.quotaDetail
                                  .remaining_quota <= 0
                              ) {
                                this.setState({
                                  isOpenErrorModal: true,
                                  errorType:
                                    LiveStreamingErrorType.QUOTA_NOT_ENOUGH,
                                });
                                return;
                              }
                              const req = Utils.getApi(
                                "get",
                                "/db/user/lock/status",
                              ).send();
                              req
                                .then(() => {
                                  window.open(
                                    `./livetranskribe?lang=${asrModel.id}`,
                                  );
                                })
                                .catch((err) => {
                                  this.setState({
                                    isOpenErrorModal: true,
                                    errorType:
                                      LiveStreamingErrorType.CONNECTION_LIMIT_EXCEEDED,
                                  });
                                });
                            },
                          };
                        },
                      ),
                    },
                    // {
                    //     text: t("live_captioning_beta"),
                    //     icon: liveRecordingIcon,
                    //     divider: true,
                    //     onClick: () => {
                    //         this.setState({
                    //             open: "close",
                    //             openNewFileMenu: false,
                    //         });

                    //         window.open(`./streamingtask`);
                    //     },
                    // },
                    {
                      text: t("upload_audios"),
                      icon: uploadIcon,
                      style: {
                        zIndex: 3000,
                      },
                      subMenu: [
                        ...DataManager.instance.asrModels.uploadFile.map(
                          (asrModel, idx) => {
                            return {
                              text: asrModel.name,
                              onClick: () => {
                                this.setState(
                                  {
                                    uploadFileLang: asrModel.id,
                                    open: "close",
                                    openNewFileMenu: false,
                                  },
                                  () => {
                                    this._fileInput.current?.click();
                                  },
                                );
                                Utils.analyticsEvent({
                                  category: "New Recording",
                                  action: "Click Upload File",
                                  lang: asrModel.id,
                                });
                              },
                              divider:
                                hasPremiumUploadOptions &&
                                idx ===
                                  DataManager.instance.asrModels.uploadFile
                                    .length -
                                    1,
                            };
                          },
                        ),
                        // only populate upload premium when server response with valid options
                        ...(hasPremiumUploadOptions
                          ? ([
                              {
                                text: t("upload_more_language"),
                                icon: globeIcon,
                                badge: {
                                  color: "green",
                                  content: "NEW",
                                },
                                onClick: () => {
                                  this.setState({ showWhisperModal: true });
                                },
                              },
                            ] satisfies PopupMenuItemProps[])
                          : []),
                      ],
                      badge: {
                        color: "main",
                        content: t("high_accuracy"),
                      },
                    },
                    {
                      text: "Youtube",
                      icon: uploadIcon,
                      divider: true,
                      style: {
                        zIndex: 3000,
                      },
                      badge: {
                        color: "main",
                        content: t("high_accuracy"),
                      },
                      subMenu: DataManager.instance.asrModels.uploadYoutube.map(
                        (asrModel) => {
                          return {
                            text: asrModel.name,
                            onClick: () => {
                              this.setState({
                                uploadFileLang: asrModel.id,
                                open: "close",
                                openNewFileMenu: false,
                                showUploadYoutube: true,
                              });
                              Utils.analyticsEvent({
                                category: "New Recording",
                                action: "Click Import from Youtube",
                                lang: asrModel.id,
                              });
                            },
                          };
                        },
                      ),
                    },
                    {
                      text: t("video-dubbing"),
                      icon: liveRecordingIcon,
                      onClick: () => {
                        this.setState({
                          open: "close",
                          openNewFileMenu: false,
                        });
                        Utils.analyticsEvent({
                          category: "Redirect",
                          action: "Dub from Transkribera",
                        });

                        window.open(`${Config.studioVideoUrl}/project/create`);
                      },
                    },
                  ]}
                />
              }
            />
          </div>
        )}
      </Translation>
    );
  }

  private getFoldersUI(): React.ReactElement {
    return (
      <Translation>
        {(t) => (
          <div className="folder-container">
            <div>
              <MenuItem
                title={t("all_transcripts")}
                to={{
                  pathname: folderMap.get(FolderType.All).pathname,
                  search: "",
                }}
                isActive={this.props.currentFolder === FolderType.All}
                customIcon={allIcon}
                onClick={() => {
                  this.setState({
                    open: "close",
                  });
                }}
              />
              <MenuItem
                title={t("share_with_me")}
                to={{
                  pathname: folderMap.get(FolderType.Shared).pathname,
                  search: "",
                }}
                isActive={this.props.currentFolder === FolderType.Shared}
                customIcon={sharedwithmeIcon}
                onClick={() => {
                  this.setState({
                    open: "close",
                  });
                }}
              />
              <MenuItem
                title={t("recents")}
                to={{
                  pathname: folderMap.get(FolderType.Recent).pathname,
                  search: "",
                }}
                isActive={this.props.currentFolder === FolderType.Recent}
                customIcon={recentIcon}
                onClick={() => {
                  this.setState({
                    open: "close",
                  });
                }}
              />
            </div>
            <div className="folders">
              <MenuItem
                title={t("my_transcripts")}
                to={{
                  pathname: folderMap.get(FolderType.Uncategorized).pathname,
                  search: "",
                }}
                isActive={this.props.currentFolder === FolderType.Uncategorized}
                open={this.state.openMyFolders}
                customIcon={folderIcon}
                onClick={() => {
                  const sholdCloseSidebar =
                    this.props.currentFolder !== FolderType.Uncategorized;
                  this.setState({
                    open: sholdCloseSidebar ? "close" : "open",
                    openMyFolders:
                      this.props.currentFolder !== FolderType.Uncategorized
                        ? "open"
                        : this.state.openMyFolders === "close" ||
                            this.state.openMyFolders === "init-close"
                          ? "open"
                          : "close",
                  });
                }}
                onClickArrow={() => {
                  this.setState({
                    openMyFolders:
                      this.state.openMyFolders === "close" ||
                      this.state.openMyFolders === "init-close"
                        ? "open"
                        : "close",
                  });
                }}
                moreActions={[
                  {
                    icon: addIcon,
                    onClick: () => {
                      this.setState({
                        showAddForder: true,
                        openMyFolders: "init-open",
                      });
                    },
                  },
                ]}
              />
              {this.state.openMyFolders === "open" ||
              this.state.openMyFolders === "init-open" ? (
                <>
                  {this.props.folderList.length === 0 ? (
                    <MenuItem
                      title={t("create_new_folder")}
                      titleColor={Color2.black75}
                      isActive={false}
                      customIcon={addIcon}
                      onClick={() => {
                        this.setState({
                          openMyFolders: "open",
                          showAddForder: true,
                        });
                      }}
                    />
                  ) : null}
                  {this.props.folderList.length > 0
                    ? this.props.folderList.map((folder) => {
                        return (
                          <MenuItem
                            key={folder.eid}
                            title={folder.name}
                            customIcon={subfolderIcon}
                            addPadding={1}
                            to={{
                              pathname: folderMap.get(FolderType.Folder)
                                .pathname,
                              search: `?eid=${folder.eid}`,
                            }}
                            isActive={
                              this.props.currentFolder === FolderType.Folder &&
                              folder.eid === this.props.currentFolderId
                            }
                            onClick={() => {
                              this.setState({
                                open: "close",
                              });
                            }}
                            moreActions={this.getFolderActions(folder)}
                          />
                        );
                      })
                    : null}
                </>
              ) : null}
            </div>
            <div>
              <MenuItem
                title={t("trash_bin")}
                to={{
                  pathname: folderMap.get(FolderType.Deleted).pathname,
                  search: "",
                }}
                isActive={this.props.currentFolder === FolderType.Deleted}
                customIcon={trashIcon}
                onClick={() => {
                  this.setState({
                    open: "close",
                  });
                }}
              />
            </div>
          </div>
        )}
      </Translation>
    );
  }

  private getFolderActions(folder: FolderInfo) {
    let actions = [];
    actions.push({
      title: i18n.t("export"),
      onClick: () => {
        this.setState({
          isLoadingFile: true,
        });
        Utils.getApi("post", "/db/folder/voices")
          .send({
            folder_eid: folder.eid,
            offset: 0,
            limit: 1,
          })
          .then((res) => {
            if (res.body.success) {
              if (res.body.voices.length >= 1) {
                let v = Voice.getVoice(res.body.voices[0].eid);
                v.loadDetail(false).then((success) => {
                  this.setState({
                    isLoadingFile: false,
                  });
                  if (success) {
                    this.setState({
                      exportFolder: {
                        folder: folder,
                        first_voice: v,
                      },
                    });
                  }
                });
              } else {
                this.setState({
                  isLoadingFile: false,
                });
                alert(i18n.t("no_transcript_in_folder"));
              }
            }
          })
          .catch((err) => {
            this.setState({
              isLoadingFile: false,
            });
            Logger.error(`prepare to export folder error ${err}`, {
              folder_eid: folder.eid,
              folder_name: folder.name,
              errorMessage: err.message,
              errorName: err.name,
            });
          });
      },
    });
    if (User.instance.level >= UserLevel.AILabs) {
      actions.push({
        title: i18n.t("export_audio"),
        onClick: () => {
          this.setState({
            isLoadingFile: true,
          });
          Utils.getApi("post", "/db/folder/download/audio")
            .send({
              folder_eid: folder.eid,
            })
            .then((res) => {
              if (res.body.success) {
                // save audios
                res.body.data.forEach((data: any) => {
                  Utils.downloadResource(data.url, data.name);
                });
              }
            })
            .finally(() => {
              this.setState({
                isLoadingFile: false,
              });
            });
        },
      });
    }
    actions.push({
      title: i18n.t("rename"),
      onClick: () => {
        this.setState({
          renameForder: folder,
        });
      },
    });
    actions.push({
      title: i18n.t("delete"),
      onClick: () => {
        this.setState({
          deleteForder: folder,
        });
      },
    });

    return actions;
  }

  private getNotLoginedUI(): React.ReactElement {
    return (
      <Translation>
        {(t) => (
          <div className="no-logined-container">
            <div id="no-logined-title" className="title">
              {t("keep_transcript")}
            </div>
            <div
              className="login-btn"
              onClick={() => {
                Utils.analyticsEvent({
                  category: "User",
                  action: `Start to login`,
                  label: "google",
                  page: "Side Bar",
                });
                document.location.href = `${
                  Config.baseName
                }api/v1/user/auth/google?return=${encodeURIComponent(
                  document.location.href,
                )}`;
              }}
            >
              <img src={googleLogo} draggable={false} />
              <div>{t("sign_in_with_google")}</div>
            </div>
            <div
              className="login-btn"
              onClick={() => {
                Utils.analyticsEvent({
                  category: "User",
                  action: `Start to login`,
                  label: "apple",
                  page: "Side Bar",
                });
                document.location.href = `${
                  Config.baseName
                }api/v1/user/auth/apple?return=${encodeURIComponent(
                  document.location.href,
                )}`;
              }}
            >
              <img src={appleLogo} draggable={false} />
              <div>{t("sign_in_with_apple")}</div>
            </div>
          </div>
        )}
      </Translation>
    );
  }

  private get isFolderNamingOpen() {
    return this.state.renameForder !== undefined || this.state.showAddForder;
  }

  private folderNamingOnCancel() {
    if (!this.isFolderNamingOpen) {
      return;
    }
    this.setState({
      renameForder: undefined,
      showAddForder: false,
    });
  }

  private folderNamingOnYes() {
    if (!this.isFolderNamingOpen) {
      return;
    }

    const newName = this._folderNameInput.current?.value.trim();
    if (!newName || newName.length === 0) {
      return;
    }

    if (this.state.showAddForder) {
      DataManager.instance.createFolder(newName);
      this.setState({
        renameForder: undefined,
        showAddForder: false,
      });
    } else {
      this.updateFolderName(this.state.renameForder!, newName);
    }
  }

  private getFolderExportUI(): React.ReactElement {
    return (
      <ExportWindow
        show={this.state.exportFolder !== undefined}
        folder_eid={this.state.exportFolder?.folder.eid}
        folder_name={this.state.exportFolder?.folder.name}
        file={this.state.exportFolder?.first_voice}
        ownerPage={"Side Bar"}
        onClose={() => {
          this.setState({
            exportFolder: undefined,
          });
        }}
        onExport={() => {
          this.setState({
            exportFolder: undefined,
          });
        }}
      />
    );
  }

  private getFolderNamingUI(): React.ReactElement {
    return (
      <Translation>
        {(t) => (
          <Modal open={this.isFolderNamingOpen} size="tiny" dimmer="blurring">
            <ModalHeader
              title={
                this.state.showAddForder
                  ? t("create_new_folder")
                  : t("rename_folder")
              }
              textAlign={"left"}
            />
            <div className="folder-name-input-container ">
              <input
                ref={this._folderNameInput}
                type="text"
                placeholder={t("enter_folder_name")}
                className="name-input"
                defaultValue={
                  this.state.showAddForder
                    ? undefined
                    : this.state.renameForder?.name
                }
                onBlur={(event) => {
                  if (
                    !this.state.showAddForder &&
                    event.target.value.trim().length === 0
                  ) {
                    event.target.value = this.state.renameForder?.name;
                  }
                }}
              />
            </div>
            <ModalActions
              actions={[
                {
                  color: ButtonColor.Default,
                  content: t("cancel"),
                  disabled: this.state.isRenamingFolder,
                  onClick: () => {
                    this.folderNamingOnCancel();
                  },
                },
                {
                  color: ButtonColor.Orange,
                  content: this.state.showAddForder
                    ? t("add")
                    : this.state.isRenamingFolder
                      ? t("saving")
                      : t("confirm"),
                  disabled: this.state.isRenamingFolder,
                  onClick: () => {
                    this.folderNamingOnYes();
                  },
                },
              ]}
            />
          </Modal>
        )}
      </Translation>
    );
  }

  private get isConfirmFolderDeletingOpen() {
    return this.state.deleteForder !== undefined;
  }

  private folderDeletingOnCancel() {
    if (!this.isConfirmFolderDeletingOpen) {
      return;
    }
    this.setState({
      deleteForder: undefined,
    });
  }

  private folderDeletingOnYes() {
    if (!this.isConfirmFolderDeletingOpen) {
      return;
    }
    DataManager.instance.deleteFolder(this.state.deleteForder?.eid);
    this.setState({
      deleteForder: undefined,
    });
  }

  private getFolderDeletingUI(): React.ReactElement {
    return (
      <Translation>
        {(t) => (
          <Modal
            open={this.isConfirmFolderDeletingOpen}
            size="tiny"
            dimmer="blurring"
          >
            <ModalHeader title={t("delete_folder")} textAlign={"left"} />
            <div className="deleting-message-container">
              <div>{t("transcripts_in_folder_move_to_trash")}</div>
              <div>{t("alert_delete_folder")}</div>
              <div className="name number">{this.state.deleteForder?.name}</div>
            </div>
            <ModalActions
              actions={[
                {
                  color: ButtonColor.Default,
                  content: t("cancel"),
                  onClick: () => {
                    this.folderDeletingOnCancel();
                  },
                },
                {
                  color: ButtonColor.Orange,
                  content: t("delete"),
                  onClick: () => {
                    this.folderDeletingOnYes();
                  },
                },
              ]}
            />
          </Modal>
        )}
      </Translation>
    );
  }

  private getMobileVersionUI(): React.ReactElement {
    if (this.state.isDesktop) {
      return null;
    }

    return (
      <>
        <div className="nav-header-bar">
          <div className="background">
            <button
              className={`top-bar_toggle-btn ${this.state.open}`}
              id="toggle-sidebar-btn"
              draggable={false}
              onClick={() => {
                this.setState({
                  open: "open",
                });
              }}
            >
              <HamburgerIcon width={24} height={24} />
            </button>
            <StudioLogoLink />
            <StudioAppsButton />
          </div>
        </div>
        <div
          className={`mask ${this.state.open}`}
          onAnimationEnd={() => {
            if (this.state.open === "close") {
              this.setState({
                open: "init",
              });
            }
          }}
          onClick={() => {
            this.setState({
              open: "close",
            });
          }}
        ></div>
      </>
    );
  }

  render() {
    const sideBarState = this.state.isDesktop ? "" : this.state.open;

    return (
      <>
        <div className={`sidebar-container ${sideBarState}`}>
          <div className="background">
            <div className="close-btn">
              <img
                src={closeIcon}
                draggable={false}
                onClick={() => {
                  this.setState({
                    open: "close",
                  });
                }}
              />
            </div>
            {this.props.isLogined && this.props.isDataReady ? (
              <>
                <AccountWidget />
                {this.getAddFileUI()}
                {this.getFoldersUI()}
              </>
            ) : !this.props.isLogined ? (
              this.getNotLoginedUI()
            ) : (
              <LoadingPage />
            )}
          </div>
        </div>

        {!this.state.isLoadingFile ? null : (
          <div className="sidebar-loading-file">
            <LoadingPage />
          </div>
        )}

        {/* for upload files */}
        <input
          className="upload-file-input"
          ref={this._fileInput}
          type="file"
          accept={FileUploader.acceptFormat}
          multiple={true}
          onChange={(event) => {
            FileUploader.instance.addUploadFiles(
              this.state.uploadFileLang,
              this._fileInput.current?.files,
            );
          }}
        />
        {/* for upload youtube */}
        <UploadYoutubeWindow
          show={this.state.showUploadYoutube}
          onClose={() => {
            this.setState({
              showUploadYoutube: false,
            });
          }}
          uploadFileLang={this.state.uploadFileLang}
        />
        {/* for feedback */}
        <FeedbackWindow
          show={this.state.showFeedback}
          onCancel={() => {
            this.setState({
              showFeedback: false,
            });
          }}
          onSend={() => {
            this.setState({
              showFeedback: false,
            });
          }}
        />
        {/* for confirm actions */}
        {this.getFolderExportUI()}
        {this.getFolderNamingUI()}
        {this.getFolderDeletingUI()}
        {/* for mobile */}
        {this.getMobileVersionUI()}
        <ErrorModal
          show={this.state.isOpenErrorModal}
          errorType={this.state.errorType}
          onClose={() =>
            this.setState({
              isOpenErrorModal: false,
            })
          }
        />
      </>
    );
  }
}

const HamburgerIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_6617_8526)">
      <path
        d="M2.99423 18H20.9909V16H2.99423V18ZM2.99423 13H20.9909V11H2.99423V13ZM2.99423 6V8H20.9909V6H2.99423Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_6617_8526">
        <rect
          width={23.9955}
          height={24}
          fill="white"
          transform="translate(-0.00521851)"
        />
      </clipPath>
    </defs>
  </svg>
);
