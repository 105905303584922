import React from "react";

import "../../assets/scss/YatingInput.scss";

interface Props {
  value: string;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  onChange?: (event: React.ChangeEvent<{ value: string }>) => void;
}
export default class YatingInput extends React.Component<Props, any> {
  render() {
    return (
      <div className="yating-input-container">
        {this.props.label ? <p className="label">{this.props.label}</p> : ""}
        <input
          type="text"
          value={this.props.value}
          placeholder={this.props.placeholder || ""}
          onChange={(event) => this.props.onChange(event)}
          disabled={this.props.disabled || false}
        />
      </div>
    );
  }
}
