import i18n from "../../i18n";
import React from "react";
import { Translation } from "react-i18next";
import Dropdown from "semantic-ui-react/dist/commonjs/modules/Dropdown";
import Modal from "semantic-ui-react/dist/commonjs/modules/Modal";
import { ButtonColor } from "../GlobalDefine";
import { ModalHeader, ModalActions } from "../ui/Component";
import { DropdownItemProps } from "semantic-ui-react/dist/commonjs/modules/Dropdown/DropdownItem";
import Config from "../Config";

import "../../assets/scss/SpeakerRematchWindow.scss";
import speakerNumberIcon from "../../assets/img/icon-customer.svg";

export default class SpeakerRematchWindow extends React.Component<
  {
    show: boolean;
    onClose: () => void;
    onConfirm: (selectedSpeakerCount?: number) => void;
  },
  {
    selectedSpeakerCount?: number;
  }
> {
  constructor(props: any) {
    super(props);

    this.state = {
      selectedSpeakerCount: undefined,
    };
  }

  render() {
    let speakerOptions:
      | DropdownItemProps[]
      | { text: string; value: number }[] = [];
    speakerOptions.push({
      text: i18n.t("detecting", { ns: "file" }),
      value: -1,
    });

    for (let i = 1; i <= 15; i++) {
      speakerOptions.push({
        text:
          i >= 15
            ? i18n.t("over_number", { ns: "file", number: 15 })
            : i.toString(),
        value: i,
      });
    }

    return (
      <React.Fragment>
        <Translation ns="file">
          {(t) => (
            <Modal open={this.props.show} size="tiny" dimmer="blurring">
              <ModalHeader
                title={t("speaker_match")}
                onClose={this.props.onClose}
              />
              <div className="speaker-rematch-container">
                <div className="speaker-rematch-icon">
                  <img src={speakerNumberIcon} />
                </div>
                <div className="speaker-rematch-message">
                  <div className="label">{t("number_of_speakers")}:</div>
                  <div className="message">
                    {t("edit_speaker_description", {
                      appName: i18n.t(Config.APP_NAME),
                    })}
                  </div>
                </div>
                <div className="speaker-rematch-selector fix-child-style">
                  <Dropdown
                    scrolling
                    selection
                    fluid
                    direction="right"
                    options={speakerOptions}
                    value={this.state.selectedSpeakerCount || -1}
                    onChange={(event, data) => {
                      let value = Number(data.value);
                      if (value > 0) {
                        this.setState({
                          selectedSpeakerCount: value,
                        });
                      } else {
                        this.setState({
                          selectedSpeakerCount: undefined,
                        });
                      }
                    }}
                  />
                </div>
              </div>
              <ModalActions
                actions={[
                  {
                    color: ButtonColor.Orange,
                    content: i18n.t("confirm"),
                    onClick: () => {
                      this.props.onConfirm(this.state.selectedSpeakerCount);
                    },
                  },
                ]}
              />
            </Modal>
          )}
        </Translation>
      </React.Fragment>
    );
  }
}
