import React from "react";
import { Link } from "react-router-dom";
import * as qs from "qs";
import { observer } from "mobx-react";

import i18n from "../../../i18n";
import { Translation } from "react-i18next";

import DataManager, { AccountTab } from "../../DataManager";
import Utils from "../../Utils";

import Footer from "../../ui/Footer";
import SettingTab from "./SettingTab";
import PurchaseHistoryTab from "./PurchaseHistoryTab";
import PlanTab from "./PlanTab";
import YatingAPITab from "./YatingAPITab";

import "../../../assets/scss/AccountPage.scss";

// Tab Bar

interface tabBarProps {
  tab: AccountTab;
  onClickTab: (tab: AccountTab) => void;
}

class TabBar extends React.Component<tabBarProps, any> {
  render(): React.ReactNode {
    return (
      <Translation ns="account">
        {(t) => (
          <div className="tab-bar">
            <Link
              className={`tab-item ${this.props.tab === AccountTab.setting ? "active" : ""}`}
              to={`/account?tab=${AccountTab.setting}`}
            >
              {i18n.t("settings")}
            </Link>
            <Link
              className={`tab-item ${this.props.tab === AccountTab.plans ? "active" : ""}`}
              to={`/account?tab=${AccountTab.plans}`}
            >
              {t("plan")}
            </Link>
            <Link
              className={`tab-item ${this.props.tab === AccountTab.purchaseHistory ? "active" : ""}`}
              to={`/account?tab=${AccountTab.purchaseHistory}`}
            >
              {t("purchase_history")}
            </Link>
            {/* hide yating api tab */}
            {/* <Link
                            className={`tab-item ${this.props.tab === AccountTab.yatingAPI ? "active" : ""}`}
                            to={`/account?tab=${AccountTab.yatingAPI}`}
                        >
                            {t("yating_api")}
                        </Link> */}
          </div>
        )}
      </Translation>
    );
  }
}

// Account Page V2
@observer
export default class AccountPageV2 extends React.Component<
  any,
  {
    tab: AccountTab;
  }
> {
  static urlParam = "/account";
  static tabName = i18n.t("account_setting");

  constructor(props: any) {
    super(props);

    this.state = {
      tab: AccountTab.setting,
    };
  }

  componentDidMount() {
    Utils.analyticsPageView("/account");

    // support /payment and /payment-history for apps webview
    if (this.props.location.pathname === "/payment") {
      this.setState({
        tab: AccountTab.plans,
      });
      return;
    }

    if (this.props.location.pathname === "/payment-history") {
      this.setState({
        tab: AccountTab.purchaseHistory,
      });
      return;
    }

    DataManager.instance.updateCurrentLocation(
      this.props.location.pathname,
      this.props.location.search,
    );
    let params = qs.parse(location.search, { ignoreQueryPrefix: true });

    if (params.tab) {
      this.validateTab(parseInt(params.tab));
    }
  }

  componentDidUpdate(): void {
    let params = qs.parse(location.search, { ignoreQueryPrefix: true });

    // support /payment and /payment-history for apps webview
    // /payment and /payment-history don't need to do validateTab
    if (
      parseInt(params.tab) != this.state.tab &&
      this.props.location.pathname === AccountPageV2.urlParam
    ) {
      this.validateTab(parseInt(params.tab));
    }
  }

  validateTab(tab: number) {
    if (tab in AccountTab) {
      this.setState({
        tab,
      });
    } else {
      window.location.href = `/account?tab=${AccountTab.setting}`;
    }
  }

  render() {
    return (
      <div className="account-page">
        <h2 className="title">{AccountPageV2.tabName}</h2>
        <TabBar
          tab={this.state.tab}
          onClickTab={(tab) => {
            this.setState({ tab });
          }}
        />
        {this.state.tab === AccountTab.setting ? (
          <SettingTab />
        ) : this.state.tab === AccountTab.purchaseHistory ? (
          <PurchaseHistoryTab />
        ) : this.state.tab === AccountTab.plans ? (
          <PlanTab />
        ) : this.state.tab === AccountTab.yatingAPI ? (
          <YatingAPITab />
        ) : (
          <></>
        )}
        <Footer />
      </div>
    );
  }
}
