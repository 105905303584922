import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Progress from "semantic-ui-react/dist/commonjs/modules/Progress";
import Popup from "semantic-ui-react/dist/commonjs/modules/Popup";
import styled from "styled-components";
import {
  Styles,
  Color,
  Color2,
  hex2RGBA,
  ButtonColor,
  getButtonColors,
  MessageBoxColor,
  getMessageBoxColors,
  getUserIconColorFromName,
  getUserIconColor,
} from "../GlobalDefine";
import React from "react";
import * as CSS from "csstype";
import { faTimes } from "@fortawesome/free-solid-svg-icons/faTimes";

import Utils from "../Utils";
import i18n from "../../i18n";
import Badge, { BadgeProps } from "./Badge";

import "../../assets/scss/Animation.scss";
import crossIcon from "../../assets/img/icon-cross.svg";
import crossIconWhite from "../../assets/img/icon-cross-white.svg";
import arrowIcon from "../../assets/img/icon-arrow-down.svg";
import noRecordImg from "../../assets/img/no-record-taxtile.png";

interface CustomButtonProps {
  width?: any;
  minWidth?: any;
  maxWidth?: any;
  height?: any;
  invisible?: boolean;
  padding?: any;
  textColor: {
    normal: any;
    hover?: any;
    active?: any;
    disabled?: any;
  };
  bg: {
    normal: any;
    hover?: any;
    active?: any;
    disabled?: any;
  };
  radius?: any;
  border?: {
    normal: {
      color: any;
      width: any;
    };
    hover?: {
      color: any;
      width: any;
    };
    active?: {
      color: any;
      width: any;
    };
    disabled?: {
      color: any;
      width: any;
    };
  };
  animation?: string;
}

export const CustomButton = styled.button<CustomButtonProps>`
    width: ${(props) => props.width || "fit-content"};
    height: ${(props) => props.height || props.width};
    line-height: ${(props) => props.height || props.width};
    color: ${(props) => props.textColor.normal};
    background: ${(props) => props.bg.normal};
    padding: ${(props) => props.padding || "0px"};
    border: ${(props) =>
      props.border && props.border.normal
        ? `${props.border.normal.width} solid ${props.border.normal.color}`
        : "0px"};
    font-size: 17px;
    outline: none;
    cursor: pointer;
    visibility: ${(props) => (props.invisible ? "hidden" : "inherit")}
    ${(props) => (props.radius ? `border-radius: ${props.radius};` : "")}
    ${(props) => (props.minWidth ? `min-width: ${props.minWidth};` : "")}
    ${(props) => (props.maxWidth ? `max-width: ${props.maxWidth};` : "")}

    ${(props) =>
      props.animation && "" !== props.animation
        ? `animation: ${props.animation} 1.0s linear infinite;`
        : ""}

    :hover {
        color: ${(props) => props.textColor.hover || props.textColor.normal};
        background: ${(props) => props.bg.hover || props.bg.normal};
        border: ${(props) =>
          props.border && props.border.hover
            ? `${props.border.hover.width} solid ${props.border.hover.color}`
            : ""};
    }

    :active {
        color: ${(props) => props.textColor.active || props.textColor.normal};
        background: ${(props) => props.bg.active || props.bg.normal};
        border: ${(props) =>
          props.border && props.border.active
            ? `${props.border.active.width} solid ${props.border.active.color}`
            : ""};
    }

    :disabled {
        color: ${(props) => props.textColor.disabled || props.textColor.normal};
        background: ${(props) => props.bg.disabled || props.bg.normal};
        border: ${(props) =>
          props.border && props.border.disabled
            ? `${props.border.disabled.width} solid ${props.border.disabled.color}`
            : ""};
        cursor: default;
    }

    /* Pink Animate */
    @-webkit-keyframes at-ripple-orange {
        0% {
            ${(props) =>
              `box-shadow: 0 4px 10px rgba(247, 147, 29, 0.2), 0 0 0 0 rgba(247, 147, 29, 0.2), 0 0 0 5px rgba(247, 147, 29, 0.2), 0 0 0 10px rgba(247, 147, 29, 0.2);`}
        }
        100% {
            box-shadow: 0 4px 10px rgba(247, 147, 29, 0.2), 0 0 0 5px rgba(247, 147, 29, 0.2), 0 0 0 10px rgba(247, 147, 29, 0.2), 0 0 0 20px rgba(247, 147, 29, 0);
        }
    }
    @keyframes at-ripple-orange {
        0% {
            box-shadow: 0 4px 10px rgba(247, 147, 29, 0.2), 0 0 0 0 rgba(247, 147, 29, 0.2), 0 0 0 5px rgba(247, 147, 29, 0.2), 0 0 0 10px rgba(247, 147, 29, 0.2);
        }
        100% {
            box-shadow: 0 4px 10px rgba(247, 147, 29, 0.2), 0 0 0 5px rgba(247, 147, 29, 0.2), 0 0 0 10px rgba(247, 147, 29, 0.2), 0 0 0 20px rgba(247, 147, 29, 0);
        }
    }
`;

export const WrapButton = styled.button`
  width: auto;
  height: auto;
  background: transparent;
  padding: 0px;
  border: 0px;
  outline: none;
  cursor: pointer;

  :disabled {
    cursor: default;
  }
`;

interface ProgressBarProps {
  showAnimation?: boolean;
}

export const ProgressBar = styled(Progress)<ProgressBarProps>`
  background: ${Color2.black25} !important;
  height: 10px !important;
  border-radius: 5px !important;

  .bar {
    height: 100% !important;
    background: ${hex2RGBA(Color2.black75, 0.3)} !important;
    min-width: ${(props) => (0 === props.percent ? 0 : 10)}px !important;
    border-radius: 5px !important;
  }

  &.animation .bar {
    background-image: linear-gradient(
      -45deg,
      rgba(255, 255, 255, 0.125) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.125) 50%,
      rgba(255, 255, 255, 0.125) 75%,
      transparent 75%,
      transparent
    ) !important;
    background-size: 35px 35px !important;
    animation: cssProgressActive 2s linear infinite;
  }

  @-webkit-keyframes cssProgressActive {
    0% {
      background-position: 0 0;
    }
    100% {
      background-position: 35px 35px;
    }
  }
  @-ms-keyframes cssProgressActive {
    0% {
      background-position: 0 0;
    }
    100% {
      background-position: 35px 35px;
    }
  }
  @keyframes cssProgressActive {
    0% {
      background-position: 0 0;
    }
    100% {
      background-position: 35px 35px;
    }
  }
`;

export const FontIcon = styled(FontAwesomeIcon)`
  vertical-align: 0px !important;
`;

export const PopupMenu = styled.div`
  min-width: 150px;
  width: max-content;
  background: ${Color2.white};
  color: ${Color2.black80};
  font-size: 20px;
  display: grid;
  justify-items: left;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

export const ModalRow = styled.div`
  text-align: center;
  width: 100%;
  color: ${Color.black};
`;

export const PopupMenuItem = styled.button<{
  textColor?: any;
}>`
  width: 100%;
  min-height: 40px;
  padding: 8px 15px;
  border: 0px;
  outline: none;
  background: ${Color2.white};
  text-align: left;
  color: ${(props) => props.textColor || ""};

  :enabled {
    cursor: pointer;
  }

  :enabled:hover {
    background: ${Color2.black25};
  }

  :enabled:active {
    background: ${Color2.black25};
  }
`;

export const PopupMenuDivider = styled.div`
  width: 100%;
  border-top: 1px solid ${Color2.black50};
  margin: 8px 0px;
`;

export class Hint extends React.Component<{
  type: "error" | "warning" | "info" | "loading";
  text?: string;
  hide?: () => void;
  children?: any;
  notClosable?: boolean;
  style?: any;
  inline?: boolean;
}> {
  autoHide: ReturnType<typeof setTimeout>;

  //給第一次出現時呼叫
  componentDidMount() {
    this.startAutoHide();
  }

  //給更新 props 後呼叫
  componentDidUpdate() {
    this.startAutoHide();
  }

  componentWillUnmount() {
    clearTimeout(this.autoHide);
  }

  get background(): string {
    let bg = Color2.white;
    let stripe = Color2.black100;

    if ("error" === this.props.type) {
      bg = Color2.red75;
      stripe = Color2.red;
    } else if ("warning" === this.props.type) {
      bg = Color2.yellow75;
      stripe = Color2.yellow;
    } else if ("info" === this.props.type || "loading" === this.props.type) {
      bg = Color2.Orange75;
      stripe = Color2.mainOrange;
    }

    return `repeating-linear-gradient(60deg, ${bg}, ${bg} 10px, ${stripe} 10px, ${stripe} 20px)`;
  }

  startAutoHide() {
    if (!this.props.notClosable) {
      this.autoHide = setTimeout(() => {
        this.onHide();
      }, 3000);
    }
  }

  onHide(evt?: MouseEvent) {
    if (evt) {
      evt.preventDefault();
      evt.stopPropagation();
    }

    if (this.props.hide) {
      this.props.hide();
    }

    clearTimeout(this.autoHide);
  }

  render() {
    return (
      <div
        style={Object.assign(
          {
            background: this.background,
            backgroundSize: "1000px",
            animationName: "loading" === this.props.type ? "hintloading" : "",
            animationDuration: "2s",
            animationIterationCount: "infinite",
            animationTimingFunction: "linear",
          },
          {
            width: this.props.inline
              ? "unset"
              : Utils.isDesktop
                ? "calc(100% - 340px)"
                : "calc(100% - 100px)",
            maxWidth: "900px",
            minHeight: "36px",
            borderRadius: "4px",
            boxShadow: "0 4px 16px 0 rgba(0, 0, 0, 0.06)",
            color: Color2.white,
            fontSize: "17px",
            fontWeight: "bold",
            textAlign: "left",
            cursor: "default",
            display: "grid",
            gridTemplateColumns: "auto max-content",
            placeItems: "center left",
            padding: "10px",
            gridGap: "10px",
            position: this.props.inline ? "unset" : "absolute",
            marginLeft: this.props.inline ? "" : "60px",
            ...this.props.style,
          },
        )}
        onClick={(evt) => evt.stopPropagation()}
      >
        <div>{this.props.children ? this.props.children : this.props.text}</div>
        {this.props.notClosable ? null : (
          <CustomButton
            width="16px"
            textColor={{
              normal: "#ffffff",
              hover: "#e5e5e5",
              active: "#cbcbcb",
            }}
            bg={{
              normal: "transparent",
            }}
            onClick={this.onHide.bind(this)}
          >
            <FontIcon icon={faTimes} />
          </CustomButton>
        )}
      </div>
    );
  }
}

export class ModalHeader extends React.Component<
  {
    title: string;
    color?: "default" | "white";
    textAlign?: CSS.TextAlignProperty;
    style?: React.CSSProperties;
    onClose?: () => void;
  },
  {}
> {
  render() {
    return (
      <div
        style={{
          height: "55px",
          fontSize: "17px",
          fontWeight: "bolder",
          borderBottom: "solid 1px",
          borderBottomColor: Color2.black50,
          userSelect: "none",
          WebkitUserSelect: "none",
          MozUserSelect: "none",
          msUserSelect: "none",
          ...this.props.style,
        }}
      >
        <div
          style={{
            color:
              this.props.color === "white" ? Color2.white : Color2.black100,
            textAlign: this.props.textAlign || "center",
            lineHeight: "55px",
            margin: "0px 21px",
          }}
        >
          {this.props.title}
        </div>
        {this.props.onClose ? (
          <div
            style={{
              color: Color2.black75,
              position: "absolute",
              top: this.props.color === "white" ? "10px" : "21px",
              right: "25.5px",
              cursor: "pointer",
            }}
            onClick={(evt) => {
              if (this.props.onClose) this.props.onClose();
            }}
          >
            <img
              src={this.props.color === "white" ? crossIconWhite : crossIcon}
            />
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export interface PopupMenuItemProps {
  text: string;
  icon?: string;
  divider?: boolean;
  style?: React.CSSProperties;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  subMenu?: PopupMenuItemProps[];
  badge?: BadgeProps;
}

export class PopupLayeredMenu extends React.Component<{
  className?: string;
  items: PopupMenuItemProps[];
}> {
  render() {
    return (
      <PopupMenu className={this.props.className}>
        {this.props.items.map((item, idx) => {
          const { text, icon, divider, style, onClick, subMenu, badge } = item;
          let menuItem = (
            <PopupMenuItem
              style={{
                display: "flex",
                alignItems: "top",
              }}
              onClick={(event) => {
                onClick && onClick(event);
              }}
            >
              {icon ? (
                <img
                  src={icon}
                  style={{
                    width: "24px",
                    height: "24px",
                    position: "absolute",
                  }}
                />
              ) : (
                ""
              )}
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: icon ? "30px" : "",
                }}
              >
                <label
                  style={{
                    color: Color2.black80,
                    cursor: "pointer",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    marginRight: "4px",
                  }}
                >
                  {item.text}
                </label>
                {badge ? (
                  <div className="badge-container">
                    <Badge color={badge.color} content={badge.content} />
                  </div>
                ) : (
                  ""
                )}
              </div>
              {subMenu && subMenu.length > 0 ? (
                <i className="menu-item dropdown icon align-self-center" />
              ) : (
                ""
              )}
            </PopupMenuItem>
          );

          return (
            <React.Fragment key={idx}>
              {subMenu && subMenu.length > 0 ? (
                <Popup
                  basic
                  on="click"
                  position="right center"
                  style={{
                    ...Styles.popupContainer,
                    ...style,
                  }}
                  trigger={menuItem}
                  content={<PopupLayeredMenu items={subMenu} />}
                />
              ) : (
                menuItem
              )}
              {divider === true ? <PopupMenuDivider /> : ""}
            </React.Fragment>
          );
        })}
      </PopupMenu>
    );
  }
}

export interface ActionButtonProps {
  color: ButtonColor;
  content?: any;
  icon?: string;
  endIcon?: string;
  disabled?: boolean;
  hide?: boolean;
  id?: string;
  style?: React.CSSProperties;
  linkTo?: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export class ActionButton extends React.Component<ActionButtonProps> {
  render() {
    const { bg, textColor, border } = getButtonColors(this.props.color);
    let style = { ...this.props.style };
    if (this.props.icon && !this.props.content) {
      style.width = style.width || "40px";
      style.height = style.height || "40px";
      style.borderRadius = style.borderRadius || "20px";
      style.margin = style.margin || "0px";
    } else {
      style.minWidth = style.width ? "" : style.minWidth || "125px";
      style.width = style.width || "auto";
      style.height = style.height || "40px";
      style.borderRadius = style.borderRadius || "8px";
      style.margin = style.margin || "0px 5px";
    }

    style.padding =
      style.padding || (style.textAlign === "left" ? "0px 9px" : "0px");
    style.verticalAlign = "middle";
    style.display = this.props.hide ? "none" : "";
    style.userSelect = "none";
    style.WebkitUserSelect = "none";
    style.MozUserSelect = "none";
    style.msUserSelect = "none";

    let btn = (
      <CustomButton
        id={this.props.id}
        width={style.width}
        minWidth={style.minWidth}
        height={style.height}
        radius={style.borderRadius}
        padding={style.padding}
        textColor={textColor}
        bg={bg}
        border={border}
        style={style}
        disabled={this.props.disabled}
        onClick={(evt) => {
          if (this.props.onClick) this.props.onClick(evt);
        }}
      >
        {this.props.icon ? (
          <>
            <img
              src={this.props.icon}
              style={{
                width: this.props.content ? "35px" : "100%",
                height: this.props.content ? "35px" : "100%",
                verticalAlign: "middle",
                marginLeft:
                  this.props.content && style.textAlign !== "left"
                    ? "-7px"
                    : "",
              }}
            />
            {this.props.content ? (
              <label
                style={{
                  verticalAlign: "middle",
                  cursor: "inherit",
                  lineHeight: style.height,
                }}
              >
                {this.props.content}
              </label>
            ) : (
              ""
            )}
          </>
        ) : (
          this.props.content
        )}
        {this.props.endIcon && (
          <img
            src={this.props.endIcon}
            style={{
              width: this.props.content ? "30px" : "100%",
              height: this.props.content ? "30px" : "100%",
              verticalAlign: "middle",
              marginLeft:
                this.props.content && style.textAlign !== "left" ? "-7px" : "",
            }}
          />
        )}
      </CustomButton>
    );

    return this.props.linkTo ? (
      <NavLink to={this.props.linkTo}>{btn}</NavLink>
    ) : (
      btn
    );
  }
}

export class ModalActions extends React.Component<{
  actions: ActionButtonProps[];
  error?: string;
}> {
  render() {
    return (
      <div
        style={{
          background: hex2RGBA(Color2.Orange25, 0.3),
          borderTop: "solid 1px",
          borderTopColor: Color2.black50,
          textAlign: "right",
          padding: "12.5px 21.5px 14px 21.5px",
        }}
      >
        {this.props.error && this.props.error.length > 0 ? (
          <div
            style={{
              display: "inline-block",
              color: Color2.red,
              fontSize: "12px",
            }}
          >
            {this.props.error}
          </div>
        ) : (
          ""
        )}
        {this.props.actions.map((action, idx) => {
          return (
            <ActionButton
              key={idx}
              color={action.color}
              content={action.content}
              disabled={action.disabled}
              style={action.style}
              linkTo={action.linkTo}
              onClick={action.onClick}
            />
          );
        })}
      </div>
    );
  }
}

interface FloatOptionButtonProps {
  icon: string;
  label: string;
  className?: string;
  id?: string;
  disabled?: boolean;
  onClick?: () => void;
  onMouseEnter?: () => void;
}

export class FloatOptionButton extends React.Component<
  FloatOptionButtonProps,
  {}
> {
  render() {
    return (
      <div
        id={this.props.id}
        className={`option${this.props.className ? ` ${this.props.className}` : ""}`}
        style={
          !this.props.disabled
            ? {
                cursor: "pointer",
              }
            : {}
        }
        onClick={() => {
          if (this.props.onClick) {
            this.props.onClick();
          }
        }}
        onTouchEnd={() => {
          if (this.props.onClick) {
            this.props.onClick();
          }
        }}
        onMouseEnter={() => {
          if (this.props.onMouseEnter) {
            this.props.onMouseEnter();
          }
        }}
      >
        <ActionButton
          color={ButtonColor.Gray}
          icon={this.props.icon}
          disabled={this.props.disabled ? this.props.disabled : false}
          onClick={() => {
            if (this.props.onClick) {
              this.props.onClick();
            }
          }}
        />
        <div className={`label ${this.props.disabled ? "hide" : ""}`}>
          {this.props.label}
        </div>
      </div>
    );
  }
}

export class UserIcon extends React.Component<
  {
    imgUrl: string;
    name: string;
    email: string;
    size?: string;
    fontSize?: string;
  },
  {
    showImg: boolean;
  }
> {
  constructor(props: any) {
    super(props);
    this.state = {
      showImg: true,
    };
  }

  render() {
    let letters =
      this.props.name && this.props.name.length > 0
        ? this.props.name
        : this.props.email && this.props.email.length > 0
          ? this.props.email
          : i18n.t("unknown");

    let headLetter = letters[0].toUpperCase();
    let background = getUserIconColorFromName(letters);
    if (/語者[1234567890]*$/.test(letters)) {
      headLetter = letters.substring(2);
      background = getUserIconColor(parseInt(headLetter) - 1);
    }

    return (
      <div
        className="user-icon"
        style={{
          minWidth: this.props.size || "45px",
          maxWidth: this.props.size || "45px",
          minHeight: this.props.size || "45px",
          maxHeight: this.props.size || "45px",
        }}
      >
        {this.state.showImg &&
        this.props.imgUrl &&
        this.props.imgUrl.length > 0 ? (
          <img
            style={{
              width: this.props.size || "45px",
              height: this.props.size || "45px",
            }}
            src={this.props.imgUrl}
            draggable={false}
            onErrorCapture={(event) => {
              this.setState({
                showImg: false,
              });
            }}
          />
        ) : (
          <div
            className={`user-name-letter${headLetter.charCodeAt(0) < 128 ? " number" : ""}`}
            style={{
              background: background,
              width: this.props.size || "45px",
              height: this.props.size || "45px",
              lineHeight: this.props.size || "45px",
              fontSize: this.props.fontSize || "24px",
              userSelect: "none",
            }}
          >
            {headLetter}
          </div>
        )}
      </div>
    );
  }
}

export class AnimatedArrow extends React.Component<{
  open: "open" | "close" | "init-open" | "init-close";
  openDegree?: "upside-down";
  onClick?: (event: React.MouseEvent<HTMLImageElement>) => void;
}> {
  render() {
    return (
      <img
        className={`arrow ${this.props.open} ${this.props.openDegree ? this.props.openDegree : ""}`}
        src={arrowIcon}
        onClick={(event) => {
          if (this.props.onClick) {
            this.props.onClick(event);
          }
        }}
      />
    );
  }
}

export class SiteMessageBox extends React.Component<
  {
    color: MessageBoxColor;
    message: string;
    actions?: {
      text: string;
      style?: React.CSSProperties;
      onClick: () => void;
    }[];
    onClick?: () => void;
  },
  {
    showing: boolean;
    hiding: boolean;
  }
> {
  private ANIMATION_DURATION: number = 500;
  private isComponentMounted: boolean;

  constructor(props: any) {
    super(props);
    this.state = {
      showing: false,
      hiding: false,
    };
  }

  componentDidMount() {
    this.isComponentMounted = true;
  }

  componentWillUnmount() {
    this.isComponentMounted = false;
  }

  public show(completed?: () => void) {
    if (this.state.showing) {
      if (completed) {
        completed();
      }
      return;
    }
    this.setState(
      {
        showing: true,
        hiding: false,
      },
      () => {
        setTimeout(() => {
          if (completed) {
            completed();
          }
          if (this.isComponentMounted) {
            this.setState({
              showing: false,
            });
          }
        }, this.ANIMATION_DURATION + 200);
      },
    );
  }

  public hide(completed?: () => void) {
    if (this.state.hiding) {
      if (completed) {
        completed();
      }
      return;
    }
    this.setState(
      {
        showing: false,
        hiding: true,
      },
      () => {
        setTimeout(() => {
          if (completed) {
            completed();
          }
          setTimeout(() => {
            if (this.isComponentMounted) {
              this.setState({
                hiding: false,
              });
            }
          }, 100);
        }, this.ANIMATION_DURATION + 200);
      },
    );
  }

  render() {
    const { color, background, buttonColors } = getMessageBoxColors(
      this.props.color,
    );
    const animation = this.state.showing || this.state.hiding;

    return (
      <div
        style={{
          display: "flex",
          position: "fixed",
          top: "0px",
          left: "0px",
          width: "100%",
          zIndex: 9999,
          pointerEvents: "none",
          animationName: this.state.showing
            ? "show-from-top"
            : this.state.hiding
              ? "hide-to-top"
              : "",
          animationDuration: animation
            ? `${this.ANIMATION_DURATION / 1000}s`
            : "",
          animationIterationCount: animation ? 1 : 0,
          animationFillMode: "both",
          animationDirection: "normal",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            padding: "22px 52px",
            margin: "auto",
            width: "fit-content",
            color: color,
            background: background,
            fontSize: "17px",
            fontWeight: "bold",
            borderRadius: "0px 0px 5px 5px",
            cursor: this.props.onClick ? "pointer" : "",
            pointerEvents: "painted",
          }}
          onClick={() => {
            if (this.props.onClick) {
              this.props.onClick();
            }
          }}
        >
          <div
            style={{
              marginRight: "13px",
              userSelect: "none",
            }}
          >
            {this.props.message}
          </div>
          {this.props.actions
            ? this.props.actions.map((action, idx) => {
                return (
                  <CustomButton
                    key={idx}
                    width="auto"
                    padding="0px 10px"
                    minWidth="65px"
                    height="35px"
                    radius="4px"
                    textColor={buttonColors.textColor}
                    bg={buttonColors.bg}
                    border={{
                      normal: {
                        color: Color2.white,
                        width: "1px",
                      },
                    }}
                    style={{
                      margin: "0px 10px 0px 0px",
                      fontWeight: "bold",
                      ...action.style,
                    }}
                    onClick={(evt) => {
                      evt.stopPropagation();
                      action.onClick();
                    }}
                  >
                    {action.text}
                  </CustomButton>
                );
              })
            : ""}
        </div>
      </div>
    );
  }
}

export class NoRecorderIndicator extends React.Component<
  {
    message?: string;
  },
  {}
> {
  render() {
    return (
      <div className="no-record-indicator">
        <div className="container">
          <img src={noRecordImg} />
          {!this.props.message ? null : (
            <div className="message">{this.props.message}</div>
          )}
        </div>
      </div>
    );
  }
}
