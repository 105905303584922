import React from "react";
import { Translation } from "react-i18next";
import { Link } from "react-router-dom";
import i18n from "../../../i18n";
import YatingInput from "../../ui/MainInput";
import YatingButton from "../../ui/YatingButton";
import User from "../../User";
import Utils from "../../Utils";

// Setting Tab
interface SettingState {
  userName: string;
  userPicture: string;
  userEmail: string;
}

export default class SettingTab extends React.Component<any, SettingState> {
  constructor(props: any) {
    super(props);
    let profile = User.instance.profile;
    this.state = {
      userName: profile.name,
      userPicture: profile.picture,
      userEmail: profile.email,
    };
  }

  private onChangeUserName(event: React.ChangeEvent<{ value: string }>) {
    this.setState({
      userName: event.target.value,
    });
  }

  private onClickSaveButton() {
    User.instance.updateUserName(this.state.userName);
    Utils.analyticsEvent({
      category: "Account",
      action: "Update User Name",
      label: this.state.userName,
    });
  }

  render(): React.ReactNode {
    return (
      <Translation ns="account">
        {(t) => (
          <div className="setting-tab">
            <div className="profile-container">
              <div className="profile-picture-container">
                <img
                  className="profile-picture"
                  src={this.state.userPicture}
                  alt=""
                />
              </div>
              <div className="input-container">
                <div className="display-name-container">
                  <YatingInput
                    label={t("display_name")}
                    value={this.state.userName}
                    onChange={this.onChangeUserName.bind(this)}
                  />

                  <YatingButton
                    variant="text"
                    color="main"
                    size="small"
                    onClick={() => this.onClickSaveButton()}
                    className="display-name-save-button"
                  >
                    {i18n.t("save")}
                  </YatingButton>
                </div>

                <YatingInput
                  label={i18n.t("email")}
                  value={this.state.userEmail}
                  disabled
                />
              </div>
            </div>
            <div className="calender-setting-container"></div>
            <div className="default-language-setting-container"></div>

            <div className="delete-account-container">
              <Link
                to={`delete-account`}
                onClick={() => {
                  Utils.analyticsEvent({
                    category: "Delete Account",
                    action: "Clicked Init",
                  });
                }}
              >
                <YatingButton variant="text" size="large" color="red">
                  {t("delete_account")}
                </YatingButton>
              </Link>
            </div>
          </div>
        )}
      </Translation>
    );
  }
}
