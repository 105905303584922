import { observable, computed } from "mobx";
import type { SuperAgentRequest } from "superagent";

import User from "./User";
import Utils, { RedirectTo } from "./Utils";
import Logger from "./Logger";

import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import utc from "dayjs/plugin/utc";
import i18n from "../i18n";
import { VoiceLang } from "./DataManager";

dayjs.extend(duration);
dayjs.extend(utc);

// supported export languages
export enum TranslationLang {
  Traditional_Chinese = "zh-Hant",
  // Chinese = "zh",
  English = "en",
  Japanese = "ja",
  Vietnamese = "vi",
  Korean = "ko",
  Malay = "ms",
  Thai = "th",
  Indonesian = "id",
}

//Sync with backend voice.ts
export enum VoiceState {
  Pending,
  Ongoing,
  Completed,
  Cancelled,
  Failed,
  Deleted,
  Recording,
  Relabeling,
}

//Sync with backend voice.ts
export enum ProgressState {
  Connecting,
  Processing,
  Finished,
}

//Sync with backend share.ts
export enum Permission {
  None = -1,
  Read = 0,
  //Comment = 1,
  Edit = 2,
  //Admin = 3,
  Owner = 50,
}

export const getPermissionName = (permission: Permission): string => {
  switch (permission) {
    case Permission.Read:
      return i18n.t("viewer");
    case Permission.Edit:
      return i18n.t("editor");
  }
};

// Sync with backend voice.ts (AutoSummaryType)
export enum AutoSummaryType {
  UserRemoved = -1,
  Hightlight = 1,
  ActionItem = 2,
}

// Sync with backend Error.ts (ErrorCode)
export interface ErrorCode {
  reason: ErrorReason;
  message: string;
  info?: any;
}

// Sync with backend Error.ts (ErrorReaon)
export enum ErrorReason {
  QuotaNotEnough = "quota_not_enough",
  ServiceError = "internal_service_error",
  DataNotFound = "data_not_found",
}

// Sync with backend voice.ts (VoiceInfo)
export interface VoiceInfo {
  eid: string;
  name: string;
  displayName: string;
  langCode: string;
  state: VoiceState;
  is_owner: boolean;
  owner: UserProfile;
  permission?: Permission;
  duration?: number; // seconds
  quota_used?: number; // seconds - 實際扣款時間
  progress?: {
    progress: number; // 0~100
    state: ProgressState;
    isRetry: boolean;
  };
  speaker_progress?: number;
  summary_progress?: number;
  error?: string;
  errorCode?: ErrorCode;
  ctime?: string;
  dtime?: number;
  tags?: string[];
  preparing?: boolean;
}

// Sync with backend voice.ts (VoiceDisplay)
export interface VoiceDetail {
  eid: string;
  state: VoiceState;
  is_owner: boolean;
  owner: UserProfile;
  permission?: Permission;
  duration: number; // seconds
  sentences: VoiceSentenceLine[];
  is_sentence_realign_need: boolean;
  summary: SummarySection[];
  tags: string[];
  ctime: number;
  url: string;
  name: string;
  lang: string;
  speakers?: Speakers;
  shared: ShareInfo[];
  enable_share_url: boolean;
  done_auto_summary: boolean;
  summary_progress?: number;
  quota_arrears?: number;
  /** If the voice created is paid */
  is_premium?: 0 | 1;
}

// Sync with backend voice.ts (Speakers)
export interface Speakers {
  [key: number]: string;
}

//Sync with backend voice.ts (Sentence)
export interface VoiceSentenceLine {
  startTime: number; // ms
  endTime: number; // ms
  content: string;
  translations?: Translations;
  asr_confidence?: number;
  asr_word_time_stamp?: VoiceWord[];
  speaker_id?: number;
  speaker_user_label?: string;
  user_highlights?: UtteranceFragment[];
  auto_summary?: AutoSummaryType[];
}

//Sync with backend voice.ts (Word)
export interface VoiceWord {
  word: string;
  begin_time: number; //ms
  end_time: number; // ms
}

//Sync with backend voice.ts (UtteranceFragment)
export interface UtteranceFragment {
  start: number;
  end: number;
}

// Sync with backend voice.ts (SummarySection)
export interface SummarySection {
  title: string; // title of the section
  utterances: number[]; // selected utterance start time, the order is display order
  isGrouped?: boolean; // if is a section, will have value after computed
  speakers?: string[]; // will have value after computed
  contents?: string[]; // will have value after computed
  userHighlight?: boolean[]; // will have value after computed
}

//Sync with backend voice.ts (Translations)
export type Translations = {
  [key in string]?: string;
};

//Sync with backend share.ts (ShareInfo)
export interface ShareInfo {
  userId?: string;
  userEmail: string;
  userName?: string;
  userPicture?: string;
  permission: Permission;
}

export interface UserProfile {
  email: string;
  name?: string;
  picture?: string;
}

interface SpeakerEditLog {
  startTime: number;
  speaker_id?: number;
  speaker_user_label: string;
}

export interface EditLog {
  utteranceIndex: number;

  oldValue: string;
  newValue: string;

  startIndex: number;
  endIndex: number;
  replacement: string;

  oldHighlights: UtteranceFragment[];
  newHighlights: UtteranceFragment[];

  oldAutoSummary: AutoSummaryType[];
  newAutoSummary: AutoSummaryType[];
}

interface ContentSavingItem {
  log: EditLog;
  isUndo: boolean;
}

export default class Voice {
  static _cached = new Map<string, Voice>();

  private _eid: string;
  info: VoiceInfo;
  @observable private detail: VoiceDetail;

  @observable private reqList: {
    queryingSummaryProgress?: SuperAgentRequest;
    AddingShares?: SuperAgentRequest;
    updatingSpeakerMap?: SuperAgentRequest;
    savingSpeakerChanges?: SuperAgentRequest;
    savingContentChanges?: SuperAgentRequest;
    updatingSummary?: SuperAgentRequest;
  } = {};

  public updatingShared: string[] = []; // the list of users that is updating their permission
  @observable private speakerDizrizeProgress: number = 0;
  @observable private autoSummaryProgress: number = 0;
  private editingToken: string = undefined; // for editing

  private speakerEditLogs: Array<SpeakerEditLog> = []; // speaker edit history wait for saving to server
  @observable private contentEditHistory: Array<EditLog> = []; // user editing change will push to here
  @observable private contentUndoHistory: Array<EditLog> = []; // user editing undo will push to here
  @observable private contentChangeList: Array<ContentSavingItem> = []; // content changes wait for update to sever

  public get eid(): string {
    return this._eid || this.info?.eid || this.detail?.eid;
  }

  public get name(): string {
    return this.info?.displayName || this.detail?.name;
  }

  @computed
  public get is_premium(): boolean {
    return !!this.detail.is_premium;
  }

  @computed
  public get lang(): string {
    return this.info?.langCode || this.detail?.lang;
  }

  @computed
  public get state(): VoiceState {
    if (this.info) {
      return this.info.state;
    } else {
      return this.detail?.state;
    }
  }

  @computed
  public get speakerProcessProgress(): number {
    return this.speakerDizrizeProgress;
  }

  @computed
  public get summaryProgress(): number {
    return this.autoSummaryProgress;
  }

  @computed
  public get duration(): number {
    if (this.info) {
      return this.info.duration;
    } else {
      return this.detail?.duration;
    }
  }

  @computed
  public get quotaArrears(): number {
    return this.detail?.quota_arrears;
  }

  public get owner(): UserProfile {
    return this.info?.owner || this.detail?.owner;
  }

  public get is_owner(): boolean {
    if (this.info) {
      return this.info.is_owner;
    } else {
      return this.detail?.is_owner;
    }
  }

  @computed
  public get tags(): string[] {
    const duration = this.duration;
    let tags: string[] = JSON.parse(
      JSON.stringify(this.info?.tags || this.detail?.tags || []),
    );
    let tagCount = 3;
    if (duration > 180 && duration < 600) {
      tagCount = 5;
    } else if (duration >= 600 && duration < 1800) {
      tagCount = 10;
    } else if (duration >= 1800 && duration < 3600) {
      tagCount = 15;
    } else if (duration >= 3600) {
      tagCount = 20;
    }
    tagCount = Math.min(tagCount, tags.length);
    tags = tags.slice(0, tagCount);

    return tags;
  }

  @computed
  public get utterances(): VoiceSentenceLine[] {
    return this.detail?.sentences;
  }

  public get isUtteranceRealignNeed(): boolean {
    return this.detail?.is_sentence_realign_need;
  }

  @computed
  public get letterCounts(): number {
    let result = 0;
    if (this.utterances) {
      this.utterances.forEach((item) => {
        result += item.content.length;
      });
    }
    return result;
  }

  @computed
  public get userHighlightUtterances(): VoiceSentenceLine[] {
    return this.utterances?.filter((item) => {
      return item.user_highlights && item.user_highlights.length > 0;
    });
  }

  public get speakers(): Speakers {
    return this.detail?.speakers;
  }

  public get speakersHasSpoken(): Speakers {
    let result: Speakers = {};
    Object.keys(this.speakers).map((key, idx) => {
      const id = Number.parseInt(key);
      if (
        Number.isNaN(id) ||
        id < 0 ||
        !this.speakers[id] ||
        this.speakers[id].length === 0
      ) {
        return;
      }
      for (let i = 0; i < this.utterances.length; i++) {
        if (this.utterances[i].speaker_id === id) {
          result[id] = this.speakers[id];
          break;
        }
      }
    });

    return result;
  }

  private getNewSpeakerID(): number {
    let maxID = -1;
    Object.keys(this.speakers).forEach((key) => {
      const id = Number.parseInt(key);
      maxID = Math.max(maxID, id);
    });
    if (maxID >= 0) {
      return maxID + 1;
    } else {
      return undefined;
    }
  }

  public getSpeakerNameOfUtterance(idx: number): string {
    const utterance = this.utterances[idx];
    let speaker = i18n.t("unknown");
    if (utterance.speaker_id >= 0 && this.speakers[utterance.speaker_id]) {
      speaker = this.speakers[utterance.speaker_id];
    }
    return speaker;
  }

  @computed
  public get summary(): SummarySection[] {
    let result: SummarySection[] = [];
    if (this.detail?.summary) {
      this.detail?.summary.forEach((section) => {
        const title = section.title;
        let utterances: number[] = [];
        let contents: string[] = [];
        let speakers: string[] = [];
        let userHighlight: boolean[] = [];
        section.utterances.forEach((startTime) => {
          const utteranceIdx = this.findUtteranceIndexAtTime(startTime);
          const utterance = this.utterances[utteranceIdx];
          const speaker = this.getSpeakerNameOfUtterance(utteranceIdx);
          let content = "";
          let isUserhighlight = true;
          if (utterance.user_highlights) {
            utterance.user_highlights.forEach((highlight) => {
              content += utterance.content.substring(
                highlight.start,
                highlight.end,
              );
            });
          }
          content = content.trim();
          // check auto highlights
          if (
            content.length === 0 &&
            utterance.auto_summary &&
            utterance.auto_summary.includes(AutoSummaryType.Hightlight)
          ) {
            isUserhighlight = false;
            content = utterance.content;
          }
          if (content.length > 0) {
            utterances.push(startTime);
            contents.push(content);
            speakers.push(speaker);
            userHighlight.push(isUserhighlight);
          }
        });
        if (utterances.length > 0) {
          result.push({
            title: title,
            isGrouped: true,
            utterances: utterances,
            contents: contents,
            speakers: speakers,
            userHighlight: userHighlight,
          });
        }
      });
    }

    // check other highlighted utterance that not in here
    let unknown: SummarySection = {
      title: "",
      isGrouped: false,
      utterances: [],
      contents: [],
      speakers: [],
      userHighlight: [],
    };
    this.utterances.forEach((utterance, idx) => {
      if (
        (!utterance.user_highlights ||
          utterance.user_highlights.length === 0) &&
        (!utterance.auto_summary ||
          utterance.auto_summary.includes(AutoSummaryType.UserRemoved) ||
          !utterance.auto_summary.includes(AutoSummaryType.Hightlight))
      ) {
        return;
      }
      let found = false;
      result.forEach((section) => {
        section.utterances.forEach((u) => {
          if (u === utterance.startTime) {
            found = true;
          }
        });
      });
      if (!found) {
        // get speaker
        const speaker = this.getSpeakerNameOfUtterance(idx);
        // check user highlights
        let content = "";
        let isUserhighlight = true;
        utterance.user_highlights.forEach((highlight) => {
          content += utterance.content.substring(
            highlight.start,
            highlight.end,
          );
        });
        content = content.trim();
        // check auto highlights
        if (
          content.length === 0 &&
          utterance.auto_summary &&
          utterance.auto_summary.includes(AutoSummaryType.Hightlight)
        ) {
          isUserhighlight = false;
          content = utterance.content;
        }
        if (content.length > 0) {
          unknown.utterances.push(utterance.startTime);
          unknown.contents.push(content);
          unknown.speakers.push(speaker);
          unknown.userHighlight.push(isUserhighlight);
        }
      }
    });
    if (unknown.utterances.length > 0) {
      result.push(unknown);
    }

    return result;
  }

  public get audioUrl(): string {
    return this.detail?.url;
  }

  public get permission(): Permission {
    if (this.is_owner) {
      return Permission.Owner;
    }

    return this.detail?.permission || this.info?.permission || Permission.Read;
  }

  public get accessByUrlPermission(): Permission {
    if (this.detail?.enable_share_url === true) {
      return Permission.Read;
    }
    return Permission.None;
  }

  public get shared(): ShareInfo[] {
    return this.detail?.shared;
  }

  public get ctime(): dayjs.Dayjs {
    if (this.info && this.info.ctime) {
      return dayjs(this.info.ctime);
    } else if (this.detail) {
      return dayjs.unix(this.detail.ctime);
    } else {
      return undefined;
    }
  }

  public get isDetailedLoaded(): boolean {
    return this.detail !== undefined;
  }

  public get hasAutoSummary(): boolean {
    return this.detail?.done_auto_summary;
  }

  public get canOpen(): boolean {
    return (
      this.state === VoiceState.Completed ||
      this.state === VoiceState.Relabeling
    );
  }

  public get canOpenShare(): boolean {
    return this.canOpen;
  }

  public get canExport(): boolean {
    return this.canOpen;
  }

  public get canMove(): boolean {
    return (
      this.is_owner &&
      this.state !== VoiceState.Cancelled &&
      this.state !== VoiceState.Deleted
    );
  }

  public get canDelete(): boolean {
    return (
      this.is_owner &&
      this.state !== VoiceState.Cancelled &&
      this.state !== VoiceState.Deleted
    );
  }

  public get canCancel(): boolean {
    return (
      this.is_owner &&
      (this.state === VoiceState.Ongoing || this.state === VoiceState.Pending)
    );
  }

  public get canEditShare(): boolean {
    return this.is_owner;
  }

  public get canEditName(): boolean {
    return (
      this.state !== VoiceState.Cancelled &&
      this.state !== VoiceState.Deleted &&
      this.state !== VoiceState.Failed &&
      (this.is_owner || this.permission >= Permission.Edit)
    );
  }

  public get canEdit(): boolean {
    return (
      this.state === VoiceState.Completed &&
      this.speakerDizrizeProgress >= 100 &&
      this.summaryProgress >= 100 &&
      (this.is_owner || this.permission >= Permission.Edit)
    );
  }

  @computed
  public get isAutoSummarySupported(): boolean {
    return this.lang === "zh" || this.lang === "tw";
  }

  @computed
  public get isAutoBreaklineSupported(): boolean {
    return this.lang.slice(0, 2) === "zh" || this.lang === VoiceLang.English;
  }

  @computed
  public get isRemoveRedundancySupported(): boolean {
    return this.lang.slice(0, 2) === "zh";
  }

  @computed
  public get isCheckingSummaryProgress(): boolean {
    return this.reqList.queryingSummaryProgress !== undefined;
  }

  @computed
  public get isAddingShare(): boolean {
    return this.reqList.AddingShares !== undefined;
  }

  @computed
  public get isUpdatingSpeakerMap(): boolean {
    return this.reqList.updatingSpeakerMap !== undefined;
  }

  @computed
  public get isSavingSpeakerChanges(): boolean {
    return this.reqList.savingSpeakerChanges !== undefined;
  }

  @computed
  public get isSavingContentChanges(): boolean {
    return this.reqList.savingSpeakerChanges !== undefined;
  }

  @computed
  public get isAllContentChangesSaved(): boolean {
    return !this.isSavingContentChanges && this.contentChangeList.length === 0;
  }

  @computed
  public get contentUndoCount(): number {
    return this.contentUndoHistory.length;
  }

  @computed
  public get contentEditCount(): number {
    return this.contentEditHistory.length;
  }

  public static cacheVoiceFromInfo(info: VoiceInfo): Voice {
    let voice = this._cached.get(info.eid);
    if (voice) {
      voice.setFileInfo(info);
    } else {
      voice = new Voice(info.eid);
      voice.setFileInfo(info);
      this._cached.set(info.eid, voice);
    }
    return voice;
  }

  public static getVoice(eid: string): Voice {
    let voice = this._cached.get(eid);
    if (!voice) {
      voice = new Voice(eid);
      this._cached.set(eid, voice);
    }
    return voice;
  }

  constructor(eid: string) {
    this._eid = eid;
  }

  public findUtteranceIndexAtTime(time: number) {
    // time is in ms
    let result = -1;
    if (this.utterances && this.utterances.length > 0) {
      for (let i = 0; i < this.utterances.length - 1; i++) {
        if (this.utterances[i + 1].startTime > time) {
          result = i;
          break;
        }
      }
      if (
        result < 0 &&
        this.utterances[this.utterances.length - 1].endTime >= time
      ) {
        result = this.utterances.length - 1;
      }
    }
    return result;
  }

  public setFileInfo(info: VoiceInfo) {
    this.info = info;
    this.speakerDizrizeProgress = info.speaker_progress
      ? info.speaker_progress
      : 100;
    this.autoSummaryProgress = info.summary_progress
      ? info.summary_progress
      : 100;
  }

  public setFileDetail(detail: VoiceDetail) {
    this.detail = detail;
  }

  public loadInfo(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let req = Utils.getApi("post", "/db/voice/list/filter").send({
        filter: { eid: [this.eid] },
      });

      let success = false;
      req
        .then((res) => {
          if (res.body.success) {
            this.setFileInfo(res.body.voices[0]);
            success = true;
          }
        })
        .catch((err) => {
          Logger.error(`get voice info error ${err}`, {
            errorMessage: err.message,
            errorName: err.name,
          });
        })
        .finally(() => {
          resolve(success);
        });
    });
  }

  public loadDetail(viewRecord: boolean = true): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let req = Utils.getApi(
        "post",
        User.isLogined ? "/db/voice/info" : "/unauthed/db/voice/info",
      ).send({
        eid: this.eid,
        no_record: !viewRecord,
      });

      req
        .then((res) => {
          if (res.body.success) {
            this.setFileDetail(res.body.data);
            resolve(true);
          } else {
            if (res.body.errorCode.reason == ErrorReason.DataNotFound) {
              // not found
              Utils.analyticsEvent({
                category: "Edit Page",
                action: "File Not Found",
                label: User.isLogined ? "Logined" : "Not Logined",
              });
              reject(ErrorReason.DataNotFound);
            } else {
              // error
              Logger.error(`get voice display info error ${res.body.error}`, {
                eid: this.eid,
                isLogined: User.isLogined,
              });
              resolve(false);
            }
          }
        })
        .catch((err) => {
          Logger.error(`get voice display info error ${err}`, {
            errorMessage: err.message,
            errorName: err.name,
          });
          resolve(false);
        });
    });
  }

  public downloadAudio(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let req = Utils.getApi("post", "/db/voice/download/audio").send({
        eid: this.eid,
      });

      let success = false;
      req
        .then((res) => {
          if (res.body.success) {
            // save audios
            Utils.downloadResource(res.body.data.url, res.body.data.name);
            success = true;
          }
        })
        .catch((err) => {
          Logger.error(`download audio error ${err}`, {
            is_owner: this.is_owner,
            permission: Permission[this.permission],
            errorMessage: err.message,
            errorName: err.name,
          });
        })
        .finally(() => {
          resolve(success);
        });
    });
  }

  public updateFileName(newName: string, page: string): Promise<boolean> {
    Utils.analyticsEvent({
      category: "Edit Page",
      action: "Edit title",
      page: page,
      is_owner: this.is_owner,
      permission: Permission[this.permission],
      label: newName,
      old_label: this.name,
      duration: this.duration ? this.duration : -1, //-1 表示沒有voice 沒有時間長度
    });

    return new Promise((resolve, reject) => {
      if (!newName || newName.trim().length === 0) {
        resolve(false);
        return;
      }

      let req = Utils.getApi(
        "put",
        User.isLogined
          ? "/db/voice/update/name"
          : "/unauthed/db/voice/update/name",
        User.isLogined,
      ).send({
        eid: this.eid,
        name: newName.trim(),
      });

      let success = false;
      req
        .then((res) => {
          if (res.body.success) {
            success = true;
            if (this.info) {
              this.info.displayName = newName;
            }
            if (this.detail) {
              this.detail.name = newName;
            }
          } else {
            throw res.error;
          }
        })
        .catch((err) => {
          Logger.error(`${page} update file name error ${err}`, {
            is_owner: this.is_owner,
            permission: Permission[this.permission],
            errorMessage: err.message,
            errorName: err.name,
          });
        })
        .finally(() => {
          resolve(success);
        });
    });
  }

  public changeSpeakers(utteranceIndexs: number[], newName: string) {
    Utils.analyticsEvent({
      category: "Edit Page",
      action: "Edit speaker",
      value: utteranceIndexs.length,
      is_owner: this.is_owner,
      permission: Permission[this.permission],
      label: newName,
      duration: this.duration ? this.duration : -1, //-1 表示沒有voice 沒有時間長度
      file_name: this.name,
    });

    // if need to add an new speaker id
    let speaker_id: number = undefined;
    for (let [k, v] of Object.entries(this.speakers)) {
      if (newName.toLocaleLowerCase() === v.toString().toLocaleLowerCase()) {
        speaker_id = Number(k);
        break;
      }
    }
    if (speaker_id === undefined) {
      speaker_id = this.getNewSpeakerID();
      this.speakers[speaker_id] = newName;
    }

    // update speaker of utterances
    utteranceIndexs.forEach((utteranceIndex) => {
      const start_time = this.utterances[utteranceIndex].startTime;
      this.utterances[utteranceIndex].speaker_id = speaker_id;
      this.speakerEditLogs.push({
        startTime: start_time,
        speaker_id: speaker_id,
        speaker_user_label: newName,
      });
    });

    this.updateSpeakerChangesTillDone();
  }

  public changeSpeaker(utteranceIndex: number, newName: string) {
    const start_time = this.utterances[utteranceIndex].startTime;
    const speaker_id = this.utterances[utteranceIndex].speaker_id;
    const speakers = this.speakers;
    const speaker = speakers[speaker_id];
    Utils.analyticsEvent({
      category: "Edit Page",
      action: "Edit speaker",
      value: 1,
      is_owner: this.is_owner,
      permission: Permission[this.permission],
      label: newName,
      old_label: speaker,
      duration: this.duration ? this.duration : -1, //-1 表示沒有voice 沒有時間長度
      file_name: this.name,
    });

    // cases:
    // 1. correction
    // 2. update name
    // 3. new name

    //change to other existed speakers (correcting)
    for (let [k, v] of Object.entries(speakers)) {
      if (newName.toLocaleLowerCase() === v.toString().toLocaleLowerCase()) {
        let oldValue = this.utterances[utteranceIndex].speaker_id;
        let newValue = Number(k);
        if (oldValue !== newValue) {
          this.utterances[utteranceIndex].speaker_id = newValue;
          this.speakerEditLogs.push({
            startTime: start_time,
            speaker_id: newValue,
            speaker_user_label: newName,
          });
          this.updateSpeakerChangesTillDone();
          return;
        }
      }
    }

    let isOnlyName = true;
    if (speaker_id > 0) {
      // unknown case
      for (let i = 0; i < this.utterances.length; i++) {
        if (
          i !== utteranceIndex &&
          this.utterances[i].speaker_id === speaker_id
        ) {
          isOnlyName = false;
          break;
        }
      }
    }
    const oldValue = this.speakers[speaker_id];
    if (
      (isOnlyName && speaker_id > 0) ||
      /語者[1234567890]*$/.test(oldValue) ||
      newName.toLocaleLowerCase() ===
        (speaker ? speaker.toLocaleLowerCase() : speaker)
    ) {
      // update name
      this.speakers[speaker_id] = newName;
      this.speakerEditLogs.push({
        startTime: start_time,
        speaker_user_label: newName,
      });
    } else {
      // add new speaker
      let new_speaker_id = this.getNewSpeakerID();
      this.speakers[new_speaker_id] = newName;
      this.utterances[utteranceIndex].speaker_id = new_speaker_id;
      this.speakerEditLogs.push({
        startTime: start_time,
        speaker_id: new_speaker_id,
        speaker_user_label: newName,
      });
    }

    this.updateSpeakerChangesTillDone();
    return;
  }

  private updateSpeakerChangesTillDone() {
    if (this.isSavingSpeakerChanges || this.speakerEditLogs.length === 0) {
      return;
    }

    this.updateSpeakerChanges().then((success) => {
      if (!success) {
        setTimeout(() => {
          this.updateSpeakerChangesTillDone();
        }, 3000);
      } else {
        this.updateSpeakerChangesTillDone();
      }
    });
  }

  private updateSpeakerChanges(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (this.isSavingSpeakerChanges || this.speakerEditLogs.length === 0) {
        resolve(true);
        return;
      }
      const saving = this.speakerEditLogs.slice();
      this.speakerEditLogs = [];
      let req = Utils.getApi(
        "put",
        User.isLogined ? "/db/voice/update" : "/unauthed/db/voice/update",
        User.isLogined,
      ).send({
        eid: this.eid,
        token: this.editingToken,
        speakers: this.speakers,
        sentences: saving,
      });
      this.reqList.savingSpeakerChanges = req;

      let success = false;
      req
        .then((res) => {
          if (res.body.success) {
            success = true;
          } else {
            this.speakerEditLogs = saving.concat(this.speakerEditLogs);
            Logger.error(`Edit Page save speaker error`, {
              is_owner: this.is_owner,
              permission: Permission[this.permission],
              reason: res.body.errorCode.message,
              error: res.body.error,
            });
          }
        })
        .catch((err) => {
          this.speakerEditLogs = saving.concat(this.speakerEditLogs);
          Logger.error(`Edit Page save speaker error ${err}`, {
            is_owner: this.is_owner,
            permission: Permission[this.permission],
            errorMessage: err.message,
            errorName: err.name,
          });
        })
        .finally(() => {
          this.reqList.savingSpeakerChanges = undefined;
          resolve(success);
        });
    });
  }

  public repay(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const req = Utils.getApi("put", "/db/voice/repay").send({
        eid: this.eid,
      });

      let success = false;
      req
        .then((res) => {
          if (res.body.success) {
            success = true;
          }
        })
        .catch((err) => {
          console.error("repay fail", err);
        })
        .finally(() => {
          resolve(success);
        });
    });
  }

  public addContentEditLog(log: EditLog) {
    this.contentEditHistory.push(log);
    this.contentUndoHistory = [];
    this.utterances[log.utteranceIndex].content = log.newValue;
    this.utterances[log.utteranceIndex].user_highlights = log.newHighlights;
    this.utterances[log.utteranceIndex].auto_summary = log.newAutoSummary;
    this.contentChangeList.push({
      log: log,
      isUndo: false,
    });
    this.updateContentChangesTillDone();
  }

  public undoContentEditing(): EditLog {
    if (this.contentEditHistory.length === 0) {
      return;
    }
    const log = this.contentEditHistory.pop();
    this.contentUndoHistory.push(log);
    this.utterances[log.utteranceIndex].content = log.oldValue;
    this.utterances[log.utteranceIndex].user_highlights = log.oldHighlights;
    this.utterances[log.utteranceIndex].auto_summary = log.oldAutoSummary;
    this.contentChangeList.push({
      log: log,
      isUndo: true,
    });
    this.updateContentChangesTillDone();
    return log;
  }

  public redoContentEditing(): EditLog {
    if (this.contentUndoHistory.length === 0) {
      return;
    }
    const log = this.contentUndoHistory.pop();
    this.contentEditHistory.push(log);
    this.utterances[log.utteranceIndex].content = log.newValue;
    this.utterances[log.utteranceIndex].user_highlights = log.newHighlights;
    this.utterances[log.utteranceIndex].auto_summary = log.newAutoSummary;
    this.contentChangeList.push({
      log: log,
      isUndo: false,
    });
    this.updateContentChangesTillDone();
    return log;
  }

  private updateContentChangesTillDone() {
    if (this.isSavingContentChanges || this.contentChangeList.length === 0) {
      return;
    }

    this.updateContentChanges().then((success) => {
      if (!success) {
        setTimeout(() => {
          this.updateContentChangesTillDone();
        }, 3000);
      } else {
        this.updateContentChangesTillDone();
      }
    });
  }

  private updateContentChanges(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (this.isSavingContentChanges || this.contentChangeList.length === 0) {
        resolve(true);
        return;
      }
      let uploading = this.getContentUploadingList();
      this.contentChangeList = [];
      if (uploading.length === 0) {
        resolve(true);
        return;
      }
      let sendData = this.convertSavingListToAPIFormat(uploading);
      let req = Utils.getApi(
        "put",
        User.isLogined ? "/db/voice/update" : "/unauthed/db/voice/update",
        User.isLogined,
      ).send({
        eid: this.eid,
        token: this.editingToken,
        sentences: sendData,
      });
      this.reqList.savingContentChanges = req;

      let success = false;
      req
        .then((res) => {
          if (res.body.success) {
            success = true;
          } else if (
            "expired" == res.body.error ||
            "someone is editing" == res.body.error
          ) {
            Utils.analyticsEvent({
              category: "Edit Page",
              action: "Editing expired",
              is_owner: this.is_owner,
              permission: Permission[this.permission],
            });
            alert(i18n.t("alert_idle"));
            location.reload();
          } else {
            this.contentChangeList = uploading.concat(this.contentChangeList);
            Logger.error(`Edit Page save content editing error`, {
              is_owner: this.is_owner,
              permission: Permission[this.permission],
              reason: res.body.errorCode.message,
              error: res.body.error,
            });
          }
        })
        .catch((err) => {
          this.contentChangeList = uploading.concat(this.contentChangeList);
          Logger.error(`Edit Page save content editing error ${err}`, {
            is_owner: this.is_owner,
            permission: Permission[this.permission],
            errorMessage: err.message,
            errorName: err.name,
          });
        })
        .finally(() => {
          this.reqList.savingContentChanges = undefined;
          resolve(success);
        });
    });
  }

  private getContentUploadingList() {
    let result: Array<ContentSavingItem> = [];
    this.contentChangeList.forEach((item) => {
      let foundMatch = false;
      for (let i = result.length - 1; i >= 0; i--) {
        let item2 = result[i];
        if (
          item.isUndo != item2.isUndo &&
          item.log.utteranceIndex == item2.log.utteranceIndex &&
          item.log.oldValue == item2.log.oldValue &&
          item.log.newValue == item2.log.newValue
        ) {
          result.splice(i, 1);
          foundMatch = true;
          break;
        }
      }
      if (!foundMatch) {
        result.push(item);
      }
    });
    return result;
  }

  private convertSavingListToAPIFormat(list: Array<ContentSavingItem>) {
    //TODO sentence 有很多個，會怎麼更新？(目前這樣做沒問題)
    //TODO speaker 可以只 update 部分 speaker name (目前這樣做沒問題)
    //TODO sentence 可以只 update speaker id (目前這樣做沒問題)
    let result: Array<any> = [];
    list.forEach((item) => {
      let origin = this.utterances[item.log.utteranceIndex];
      result.push({
        startTime: origin.startTime,
        endTime: origin.endTime,
        content: item.isUndo ? item.log.oldValue : item.log.newValue,
        user_highlights: item.isUndo
          ? item.log.oldHighlights
          : item.log.newHighlights,
        auto_summary: item.isUndo
          ? item.log.oldAutoSummary
          : item.log.newAutoSummary,
      });
    });
    return result;
  }

  public updateSummarySections(summary: SummarySection[]) {
    let newSummary: SummarySection[] = [];
    summary.forEach((item) => {
      newSummary.push({
        title: item.title,
        utterances: item.utterances,
      });
    });
    if (this.detail) {
      this.detail.summary = newSummary;
    }
    this.updateSummarySectionsTillDone(newSummary);
  }

  private updateSummarySectionsTillDone(summary: SummarySection[]) {
    this.updateSummarySectionsToServer(summary).then((success) => {
      if (!success) {
        setTimeout(() => {
          this.updateSummarySectionsTillDone(summary);
        }, 3000);
      }
    });
  }

  private updateSummarySectionsToServer(
    summary: SummarySection[],
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (this.reqList.updatingSummary) {
        this.reqList.updatingSummary.abort();
      }
      let req = Utils.getApi(
        "put",
        User.isLogined
          ? "/db/voice/update/summary"
          : "/unauthed/db/voice/update/summary",
        User.isLogined,
      ).send({
        eid: this.eid,
        summary: summary,
      });
      this.reqList.updatingSummary = req;

      req
        .then((res) => {
          if (res.body.success) {
            resolve(true);
          } else {
            Logger.error(`Update user summary failed`, {
              eid: this.eid,
              duration: this.duration,
              file_name: this.name,
            });
            resolve(false);
          }
        })
        .catch((err) => {
          if (Utils.isAborted(err)) {
            Logger.info(`Update user summary aborted`, {
              eid: this.eid,
              duration: this.duration,
              file_name: this.name,
            });
            resolve(true);
          } else {
            Logger.error(`Update user summary failed ${err}`, {
              eid: this.eid,
              duration: this.duration,
              file_name: this.name,
              errorMessage: err.message,
              errorName: err.name,
            });
            resolve(false);
          }
        })
        .finally(() => {
          this.reqList.updatingSummary = undefined;
        });
    });
  }

  public removeUtteranceUserHightlight(startTime: number) {
    let utteranceIdx = this.findUtteranceIndexAtTime(startTime);
    let utterance = this.utterances[utteranceIdx];
    this.addContentEditLog({
      utteranceIndex: utteranceIdx,
      oldValue: utterance.content,
      newValue: utterance.content,
      startIndex: 0,
      endIndex: 0,
      replacement: "",
      oldHighlights: utterance.user_highlights,
      newHighlights: [],
      oldAutoSummary: utterance.auto_summary,
      newAutoSummary: utterance.auto_summary,
    });
  }

  public removeUtteranceAutoSummary(startTime: number) {
    let utteranceIdx = this.findUtteranceIndexAtTime(startTime);
    let utterance = this.utterances[utteranceIdx];
    if (!utterance.auto_summary || utterance.auto_summary.length === 0) {
      return;
    }
    let auto_summary = [AutoSummaryType.UserRemoved].concat(
      utterance.auto_summary,
    );
    let save: AutoSummaryType[] = [];
    auto_summary.forEach((item) => {
      if (!save.includes(item)) {
        save.push(item);
      }
    });
    this.addContentEditLog({
      utteranceIndex: utteranceIdx,
      oldValue: utterance.content,
      newValue: utterance.content,
      startIndex: 0,
      endIndex: 0,
      replacement: "",
      oldHighlights: utterance.user_highlights,
      newHighlights: utterance.user_highlights,
      oldAutoSummary: utterance.auto_summary || [],
      newAutoSummary: save,
    });
  }

  public addShares(
    emails: string[],
    permission: Permission,
    ownerPage: string,
    message?: string,
  ): Promise<boolean> {
    Utils.analyticsEvent({
      category: "Share",
      action: "Add shares",
      label: Permission[permission],
      value: emails.length,
      page: ownerPage,
      duration: this.duration,
      file_name: this.name,
    });

    return new Promise((resolve, reject) => {
      let req = Utils.getApi(
        "put",
        User.isLogined ? "/db/voice/share/add" : "/unauthed/db/voice/share/add",
        User.isLogined,
      ).send({
        eid: this.eid,
        emails: emails,
        permission: permission,
        message: message,
      });
      this.reqList.AddingShares = req;

      req
        .then((res) => {
          if (res.body.success) {
            resolve(true);
            this.onAddedShare(res.body.added);
          } else {
            Logger.error(`Add shares failed`, {
              label: Permission[permission],
              emails: emails,
              page: ownerPage,
              duration: this.duration,
              file_name: this.name,
            });
            resolve(false);
          }
        })
        .catch((err) => {
          Logger.error(`Add shares failed ${err}`, {
            label: Permission[permission],
            emails: emails,
            page: ownerPage,
            duration: this.duration,
            file_name: this.name,
            errorMessage: err.message,
            errorName: err.name,
          });
          resolve(false);
        })
        .finally(() => {
          this.reqList.AddingShares = undefined;
        });
    });
  }

  public revokeShare(share: ShareInfo, ownerPage: string): Promise<boolean> {
    Utils.analyticsEvent({
      category: "Share",
      action: "Revoke a share",
      label: share.userEmail,
      page: ownerPage,
      duration: this.duration,
      file_name: this.name,
    });

    return new Promise((resolve, reject) => {
      this.updatingShared.push(share.userId || share.userEmail);
      let req = Utils.getApi(
        "put",
        User.isLogined
          ? "/db/voice/share/revoke"
          : "/unauthed/db/voice/share/revoke",
        User.isLogined,
      ).send(
        Object.assign({
          eid: this.eid,
          userId: share.userId,
          userEmail: share.userEmail,
        }),
      );

      let success = false;
      req
        .then((res) => {
          if (res.body.success) {
            success = true;
            this.onRevokedShare(share);
          } else {
            Logger.error(`Revoke a share failed`, {
              label: share.userEmail,
              page: ownerPage,
              duration: this.duration,
              file_name: this.name,
            });
          }
        })
        .catch((err) => {
          Logger.error(`Revoke a share failed ${err}`, {
            label: share.userEmail,
            page: ownerPage,
            duration: this.duration,
            file_name: this.name,
            errorMessage: err.message,
            errorName: err.name,
          });
        })
        .finally(() => {
          this.updatingShared = Utils.removeFromArray(
            this.updatingShared,
            share.userId || share.userEmail,
          );
          resolve(success);
        });
    });
  }

  public updateShare(
    share: ShareInfo,
    newPermission: Permission,
    ownerPage: string,
  ): Promise<boolean> {
    Utils.analyticsEvent({
      category: "Share",
      action: "update a share permission",
      label: share.userEmail,
      newPwemission: newPermission,
      page: ownerPage,
      duration: this.duration,
      file_name: this.name,
    });

    return new Promise((resolve, reject) => {
      this.updatingShared.push(share.userId || share.userEmail);

      let req = Utils.getApi(
        "put",
        User.isLogined
          ? "/db/voice/share/update/permission"
          : "/unauthed/db/voice/share/update/permission",
        User.isLogined,
      ).send({
        eid: this.eid,
        userId: share.userId,
        userEmail: share.userEmail,
        permission: newPermission,
      });

      let success = false;
      req
        .then((res) => {
          if (res.body.success) {
            success = true;
            share.permission = newPermission;
            this.onUpdatedShare(share);
          } else {
            Logger.error(`update a share permission failed`, {
              label: share.userEmail,
              newPwemission: newPermission,
              page: ownerPage,
              duration: this.duration,
              file_name: this.name,
            });
          }
        })
        .catch((err) => {
          Logger.error(`update a share permission failed ${err}`, {
            label: share.userEmail,
            newPwemission: newPermission,
            page: ownerPage,
            duration: this.duration,
            file_name: this.name,
            errorMessage: err.message,
            errorName: err.name,
          });
        })
        .finally(() => {
          this.updatingShared = Utils.removeFromArray(
            this.updatingShared,
            share.userId || share.userEmail,
          );
          resolve(success);
        });
    });
  }

  public changeAccessByUrlPermission(
    permission: Permission,
    ownerPage: string,
  ): Promise<boolean> {
    Utils.analyticsEvent({
      category: "Share",
      action:
        permission > Permission.None ? "Enable Share Url" : "Disable Share Url",
      page: ownerPage,
      duration: this.duration,
      file_name: this.name,
    });

    return new Promise((resolve, reject) => {
      let req = Utils.getApi(
        "put",
        User.isLogined
          ? "/db/voice/share/update/url"
          : "/unauthed/db/voice/share/update/url",
        User.isLogined,
      ).send(
        Object.assign({
          eid: this.eid,
          enable: permission > Permission.None,
        }),
      );

      let success = false;
      let oldPermission = this.accessByUrlPermission;
      this.onUpdatedAccessByUrlPermission(permission);
      req
        .then((res) => {
          if (res.body.success) {
            success = true;
          } else {
            Logger.error(`Enable Share Url error`, {
              page: ownerPage,
              duration: this.duration,
              file_name: this.name,
            });
          }
        })
        .catch((err) => {
          Logger.error(`Enable Share Url error ${err}`, {
            errorMessage: err.message,
            errorName: err.name,
            page: ownerPage,
            duration: this.duration,
            file_name: this.name,
          });
        })
        .finally(() => {
          if (!success) {
            this.onUpdatedAccessByUrlPermission(oldPermission);
          }
          resolve(success);
        });
    });
  }

  public speakerRematch(selectedSpeakerCount?: number): Promise<boolean> {
    Utils.analyticsEvent({
      category: "Speaker",
      action: "Rematch",
      value: selectedSpeakerCount,
      is_owner: this.is_owner,
      permission: Permission[this.permission],
      duration: this.duration ? this.duration : -1, //-1 表示沒有voice 沒有時間長度
      file_name: this.name,
    });

    return new Promise((resolve, reject) => {
      let req = Utils.getApi(
        "put",
        User.isLogined ? "/db/voice/speaker/rematch" : "/unauthed/db",
        User.isLogined,
      ).send({
        eid: this.eid,
        count: selectedSpeakerCount,
      });

      let success = false;
      req
        .then((res) => {
          if (res.body.success) {
            success = true;
            this.speakerDizrizeProgress = 0;
          }
        })
        .catch((err) => {
          Logger.error(`Send speaker rematch request error ${err}`, {
            errorMessage: err.message,
            errorName: err.name,
            duration: this.duration,
            file_name: this.name,
          });
        })
        .finally(() => {
          resolve(success);
        });
    });
  }

  public updateSpeakerDiarizeProgress(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let req = Utils.getApi(
        "post",
        User.isLogined
          ? "/db/voice/progress/speaker"
          : "/unauthed/db/voice/progress/speaker",
        User.isLogined,
      ).send({
        eid: this.eid,
      });

      let success = false;
      let progress = this.speakerDizrizeProgress;
      req
        .then((res) => {
          if (res.body.success) {
            success = true;
            if (res.body.progress) {
              progress = Number.parseInt(res.body.progress);
            } else {
              progress = 100;
            }
          }
        })
        .catch((err) => {
          Logger.error(`get speaker diarize progress error ${err}`, {
            is_owner: this.is_owner,
            permission: Permission[this.permission],
            errorMessage: err.message,
            errorName: err.name,
          });
        })
        .finally(() => {
          if (success) {
            if (progress >= 100) {
              // reload detail
              this.loadDetail().then((s) => {
                this.speakerDizrizeProgress = progress;
                resolve(s);
              });
            } else {
              this.speakerDizrizeProgress = progress;
              resolve(success);
            }
          } else {
            resolve(success);
          }
        });
    });
  }

  public updateSummaryProgress(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let req = Utils.getApi(
        "post",
        User.isLogined
          ? "/db/voice/progress/summary"
          : "/unauthed/db/voice/progress/summary",
        User.isLogined,
      ).send({
        eid: this.eid,
      });
      this.reqList.queryingSummaryProgress = req;

      let success = false;
      let progress = this.autoSummaryProgress;
      req
        .then((res) => {
          if (res.body.success) {
            success = true;
            if (res.body.progress) {
              progress = Number.parseInt(res.body.progress);
            } else {
              progress = 100;
            }
          }
        })
        .catch((err) => {
          Logger.error(`get summary progress error ${err}`, {
            is_owner: this.is_owner,
            permission: Permission[this.permission],
            errorMessage: err.message,
            errorName: err.name,
          });
        })
        .finally(() => {
          this.reqList.queryingSummaryProgress = undefined;
          if (success) {
            if (progress >= 100) {
              // reload detail
              this.loadDetail().then((s) => {
                this.autoSummaryProgress = progress;
                resolve(s);
              });
            } else {
              this.autoSummaryProgress = progress;
              resolve(success);
            }
          } else {
            resolve(success);
          }
        });
    });
  }

  public updateSpeakerMap(updates: {
    [key: number]: string;
  }): Promise<boolean> {
    let speakers = JSON.parse(JSON.stringify(this.speakers));
    let utterances: any[] = [];
    let changed = 0;
    let merged = 0;

    // compare current value and merge updates
    if (Object.keys(updates).length > 0) {
      Object.keys(updates).forEach((id, idx) => {
        const speakerID = Number.parseInt(id);
        const oldName = speakers[speakerID];
        const newName = updates[speakerID];
        if (oldName !== newName) {
          changed++;

          // if has the same name, merge
          let sameNameId = -1;
          Object.keys(speakers).map((key, idx) => {
            const id = Number.parseInt(key);
            if (id !== speakerID && speakers[id] === newName) {
              sameNameId = id;
            }
          });
          if (sameNameId >= 0) {
            merged++;
            const mergeId = Math.max(speakerID, sameNameId);
            const mergeIntoId = Math.min(speakerID, sameNameId);
            delete speakers[mergeId];
            speakers[mergeIntoId] = newName;
            this.utterances.forEach((utterance) => {
              if (
                utterance.speaker_user_label &&
                utterance.speaker_user_label === oldName
              ) {
                utterances.push({
                  startTime: utterance.startTime,
                  speaker_user_label: newName,
                  speaker_id: mergeIntoId,
                });
              } else if (utterance.speaker_id === mergeId) {
                utterances.push({
                  startTime: utterance.startTime,
                  speaker_id: mergeIntoId,
                });
              }
            });
          } else {
            speakers[speakerID] = newName;
            this.utterances.forEach((utterance) => {
              if (
                utterance.speaker_user_label &&
                utterance.speaker_user_label === oldName
              ) {
                utterances.push({
                  startTime: utterance.startTime,
                  speaker_user_label: newName,
                });
              }
            });
          }
        }
      });
    }

    Utils.analyticsEvent({
      category: "Speaker",
      action: "Rename Speakers",
      value: changed,
      merged: merged,
      is_owner: this.is_owner,
      permission: Permission[this.permission],
    });

    return new Promise((resolve, reject) => {
      if (changed === 0) {
        resolve(true);
        return;
      }
      let req = Utils.getApi(
        "put",
        User.isLogined
          ? "/db/voice/update/speakers"
          : "/unauthed/db/voice/update/speakers",
        User.isLogined,
      ).send({
        eid: this.eid,
        speakers: speakers,
        sentences: utterances,
      });
      this.reqList.updatingSpeakerMap = req;

      req
        .then((res) => {
          if (res.body.success) {
            this.loadDetail().then((success) => {
              resolve(success);
            });
          } else {
            if (res.body.editor) {
              alert(
                `${res.body.editor.name}(${res.body.editor.email}) ${i18n.t("alert_editing")}`,
              );
            } else if (res.body.errorCode.reason === ErrorReason.DataNotFound) {
              alert(i18n.t("alert_file_is_deleted_or_disabled"));
            }
            resolve(false);
          }
        })
        .catch((err) => {
          Logger.error(`Rename speakers ${err}`, {
            value: changed,
            is_owner: this.is_owner,
            permission: Permission[this.permission],
            errorMessage: err.message,
            errorName: err.name,
          });
          resolve(false);
        })
        .finally(() => {
          this.reqList.updatingSpeakerMap = undefined;
        });
    });
  }

  public extendEditingToken(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let req = Utils.getApi(
        "put",
        User.isLogined ? "/db/voice/update" : "/unauthed/db/voice/update",
        User.isLogined,
      ).send({
        eid: this.eid,
        token: this.editingToken,
      });

      req.then((res) => {
        if (res.body.success) {
        } else if (
          "expired" === res.body.error ||
          "someone is editing" === res.body.error
        ) {
          Utils.analyticsEvent({
            category: "Edit Page",
            action: "Editing expired",
            is_owner: this.is_owner,
            permission: Permission[this.permission],
          });
          alert(i18n.t("alert_idle"));
          location.reload();
        }
      });
    });
  }

  public startEditing(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let req = Utils.getApi(
        "put",
        User.isLogined ? "/db/voice/editing" : "/unauthed/db/voice/editing",
        User.isLogined,
      ).send({
        eid: this.eid,
      });
      req
        .then((res) => {
          if (res.body.success) {
            this.editingToken = res.body.token;
            return this.loadDetail();
          } else {
            Utils.analyticsEvent({
              category: "Edit Page",
              action: "Start editing failed",
              label: res.body.editor ? res.body.editor.email : undefined,
              is_owner: this.is_owner,
              permission: Permission[this.permission],
              reason: res.body.errorCode.message,
            });
            this.editingToken = undefined;
            if (res.body.editor) {
              alert(
                `${res.body.editor.name}(${res.body.editor.email}) ${i18n.t("alert_editing")}`,
              );
            } else if (res.body.errorCode.reason === ErrorReason.DataNotFound) {
              alert(i18n.t("alert_file_is_deleted_or_disabled"));
              RedirectTo.instance.redirectTo(RedirectTo.FrontPage);
            }
            resolve(false);
          }
        })
        .then((success) => {
          resolve(success);
        })
        .catch((err) => {
          Logger.error(`startEditing error ${err}`, {
            errorMessage: err.message,
            errorName: err.name,
          });
          resolve(false);
        });
    });
  }

  public completeEdit(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (this.isAllContentChangesSaved) {
        this.sendCompleteEdit().then((success) => {
          if (success) {
            this.contentEditHistory = [];
            this.contentUndoHistory = [];
          }
          resolve(success);
        });
      } else {
        let tid = setInterval(() => {
          if (this.isAllContentChangesSaved) {
            clearInterval(tid);
            this.sendCompleteEdit().then((success) => {
              if (success) {
                this.contentEditHistory = [];
                this.contentUndoHistory = [];
              }
              resolve(success);
            });
          }
        }, 100);
      }
    });
  }

  private sendCompleteEdit(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let req = Utils.getApi(
        "put",
        User.isLogined ? "/db/voice/update" : "/unauthed/db/voice/update",
        User.isLogined,
      ).send({
        eid: this.eid,
        token: this.editingToken,
        completed_edit: true,
      });

      req
        .then(() => {
          this.editingToken = undefined;
          return this.loadDetail();
        })
        .then(() => {
          resolve(true);
        })
        .catch((err) => {
          Logger.error(`completeEdit error ${err}`, {
            errorMessage: err.message,
            errorName: err.name,
          });
          resolve(false);
        });
    });
  }

  public runAutoSummary(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let req = Utils.getApi(
        "put",
        User.isLogined
          ? "/db/voice/summary/rerun"
          : "/unauthed/db/voice/summary/rerun",
        User.isLogined,
      ).send({
        eid: this.eid,
      });

      let success = false;
      req
        .then((res) => {
          if (res.body.success) {
            success = true;
            this.autoSummaryProgress = 0;
          }
        })
        .catch((err) => {
          Logger.error(`runAutoSummary error ${err}`, {
            errorMessage: err.message,
            errorName: err.name,
          });
        })
        .finally(() => {
          resolve(success);
        });
    });
  }

  // sort shared:
  // users with name in the front
  // order by added date
  private sortShared() {
    if (this.shared && this.shared.length > 0) {
      this.shared.sort((a, b) => {
        if (undefined !== a.userName && undefined !== b.userName) {
          return 0;
          //return a.userName.localeCompare(b.userName);
        } else if (undefined === a.userName) {
          return 1;
        } else if (undefined === b.userName) {
          return -1;
        } else {
          return 0;
          //return a.userEmail.localeCompare(b.userEmail);
        }
      });
    }
  }

  private onAddedShare(added: ShareInfo[]) {
    if (!this.shared) {
      return;
    }

    added.forEach((share) => {
      let found = false;
      for (let index = 0; index < this.shared.length; index++) {
        const element = this.shared[index];
        if (
          (undefined !== element.userId && element.userId === share.userId) ||
          (undefined === element.userId &&
            element.userEmail === share.userEmail)
        ) {
          this.shared[index].permission = share.permission;
          found = true;
          break;
        }
      }
      if (!found) {
        this.shared.push(share);
      }
    });
    this.sortShared();
  }

  private onUpdatedShare(updated: ShareInfo) {
    if (!this.shared) {
      return;
    }

    for (let index = 0; index < this.shared.length; index++) {
      const element = this.shared[index];
      if (
        (undefined !== element.userId && element.userId === updated.userId) ||
        (undefined === element.userId &&
          element.userEmail === updated.userEmail)
      ) {
        this.shared[index].permission = updated.permission;
        break;
      }
    }
  }

  private onRevokedShare(revoked: ShareInfo) {
    if (!this.shared) {
      return;
    }

    for (let index = 0; index < this.shared.length; index++) {
      const element = this.shared[index];
      if (
        (undefined !== element.userId && element.userId === revoked.userId) ||
        (undefined === element.userId &&
          element.userEmail === revoked.userEmail)
      ) {
        this.shared.splice(index, 1);
        break;
      }
    }
  }

  private onUpdatedAccessByUrlPermission(newPermission: Permission) {
    if (this.detail) {
      this.detail.enable_share_url = newPermission > Permission.None;
    }
  }
}
