import React from "react";

import "../../assets/scss/ExportWindow.scss";
import checkedIcon from "../../assets/img/icon-checkbox-checked.svg";
import questionIcon from "../../assets/img/icon-question-small.svg";

interface Props {
  title: string;
  checked: boolean;
  tooltiptext?: string;
  isAutoFit?: boolean;
  onClick?: () => void;
  disabled?: boolean;
}

export default class SelectionButton extends React.Component<Props, any> {
  render() {
    const { disabled = false } = this.props;
    if (disabled) {
      // 為了不讓下面個 component condition 變得太複雜
      // disabled condition 往前拉
      return (
        <div
          className={`export-content-select-btn disabled ${this.props.isAutoFit ? "auto-fit" : ""}`}
        >
          <div>
            {this.props.title}
            <div
              className={`${this.props.tooltiptext ? "export-tooltip" : ""}`}
            >
              {this.props.tooltiptext ? <img src={questionIcon} /> : null}
              <span className="tooltiptext">{this.props.tooltiptext}</span>
            </div>
          </div>

          <div className="disabled-box"></div>
        </div>
      );
    }

    return (
      <>
        <div
          className={`export-content-select-btn${this.props.checked ? " active" : ""} ${
            this.props.isAutoFit ? "auto-fit" : ""
          }`}
          onClick={this.props.onClick}
        >
          <div>
            {this.props.title}
            <div
              className={`${this.props.tooltiptext ? "export-tooltip" : ""}`}
            >
              {this.props.tooltiptext ? <img src={questionIcon} /> : null}
              <span className="tooltiptext">{this.props.tooltiptext}</span>
            </div>
          </div>
          {this.props.checked ? (
            <img src={checkedIcon} width="18" height="18" alt="Checked" />
          ) : (
            <div className="non-selected"></div>
          )}
        </div>
      </>
    );
  }
}
