import { observable, action, computed } from "mobx";
import Config from "./Config";
import Utils from "./Utils";
import Logger from "./Logger";

//Sync with backend user.ts
export enum UserLevel {
  Guest = -5,
  Blocked = -1,
  Registered = 0,
  AILabs = 99,
}

export interface UserAccount {
  id: string;
  email: string;
  name?: string;
  picture?: string;
}

type SubscribedProduct = {
  productName: string;
  productDescription: string;
  productPrice: number;
  productCurrency: string;
  billingCycle: string;
  isCancel: boolean;
  /** ISO Date string */
  expiredAt: string;
};

export default class User {
  private static _instance: User = null;

  @observable _initialized: boolean = false;
  @observable _profile: UserAccount = undefined;
  @observable _level: UserLevel = UserLevel.Guest;
  @observable _subscription: SubscribedProduct | undefined = undefined;
  @observable _createdTime: number | undefined = undefined;
  @observable _isPaid = false;

  static get instance() {
    if (!User._instance) {
      User._instance = new User();

      User._instance._initialized = true;

      if (User.isLogined) {
        User._instance.queryUserInfo();
      }
    }

    return User._instance;
  }

  static removeInstance() {
    if (User._instance) {
      let user = User._instance;
      user._initialized = false;
    }

    User._instance = null;
  }

  static logout() {
    localStorage.clear();
    document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;"; // remove current domain cookie
    if (Config.CookieDomain) {
      document.cookie = `token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${Config.CookieDomain};`; // remove super domain cookie
    }
    window.location.href = Config.loginPageUrl;
  }

  static get isLogined(): boolean {
    return null != User.token;
  }

  static get token(): string {
    let token = Utils.getCookie("token");
    // check login/signup
    let newbie = Utils.getCookie("newbie");
    if (newbie) {
      document.cookie =
        "newbie=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;"; // remove current domain cookie
      if (Config.CookieDomain) {
        document.cookie = `newbie=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${Config.CookieDomain};`; // remove super domain cookie
      }
      if (newbie == "true") {
        Utils.analyticsEvent({
          category: "User",
          action: "Signup success",
        });
      } else {
        Utils.analyticsEvent({
          category: "User",
          action: "Login Success",
        });
      }
    }
    return token;
  }

  @action
  setProfile(profile: UserAccount) {
    this._profile = profile;
    localStorage.setItem("profile", JSON.stringify(profile));
  }

  @computed
  get level(): UserLevel {
    return this._level;
  }

  @computed
  get subscription(): SubscribedProduct | undefined {
    return this._subscription;
  }

  @computed
  get isSubscribed(): boolean {
    return this._isPaid;
  }

  @computed
  get isSubscriptionCancel(): boolean {
    return !!this._subscription?.isCancel;
  }

  @computed
  get profile(): UserAccount {
    if (!this._profile || !this._profile.email) {
      try {
        this._profile = JSON.parse(localStorage.getItem("profile")) || {};
      } catch (err) {
        Logger.error(`parse user profile in localStorage error ${err}`, {
          errorMessage: err.message,
          errorName: err.name,
        });
        this._profile = {} as any;
      }
    }
    return this._profile;
  }

  @computed
  get ready(): boolean {
    return this._initialized && this.profile?.email?.length > 0;
  }

  @computed
  get createdTime(): number {
    if (!this._createdTime) {
      this.queryUserInfo();
    }

    return this._createdTime;
  }

  @action
  queryUserInfo() {
    Utils.getApi("post", "/db/user/info")
      .then((res) => {
        if (res.body.success) {
          this._level = res.body.info.level;
          this._createdTime = res.body.info.created_time;
          if (!res.body.info.profile) {
            this.uploadUserProfile();
          } else {
            // save to local storage
            let profile = this.profile;
            profile.name = res.body.info.profile.name;
            profile.picture = res.body.info.profile.picture;
            this.setProfile(profile);
          }
          let profile = this.profile;
          if (res.body.info.email && res.body.info.email.length > 0) {
            profile.email = res.body.info.email;
          }
          if (res.body.info.id && res.body.info.id.length > 0) {
            profile.id = res.body.info.id;
          }
          this.setProfile(profile);
          Utils.setAnalyticsUser(profile.id, profile.email, profile.name);
        } else {
          Logger.error(`get user info error`, {
            error: res.body.error,
          });
        }
      })
      .catch((err) => {
        if (Utils.isAborted(err)) {
          Logger.info("get user info aborted");
        } else {
          Logger.error(`get user info error ${err}`, {
            errorMessage: err.message,
            errorName: err.name,
          });
        }
      });

    const getSubscription = async () => {
      try {
        const res = await Utils.getApi("get", "/db/user/subscriptions/status");
        if (!res.body.success) {
          throw Error("res not success");
        }
        this._subscription = res.body.subscription;
        this._isPaid = res.body.isPaid;
      } catch (err) {
        console.warn(err);
      }
    };
    getSubscription();
  }

  @action
  uploadUserProfile() {
    let profile = this.profile;
    if (!profile || !profile.name) {
      return;
    }
    Utils.getApi("put", "/db/user/profile")
      .send({
        profile: {
          name: profile.name,
          picture: profile.picture,
        },
      })
      .then((res) => {
        if (!res.body.success) {
          Logger.error(`upload user profile error`, {
            error: res.body.error,
          });
        }
      })
      .catch((err) => {
        if (Utils.isAborted(err)) {
          Logger.info("upload user profile aborted");
        } else {
          Logger.error(`upload user profile error ${err}`, {
            errorMessage: err.message,
            errorName: err.name,
          });
        }
      });
  }

  @action
  updateUserName(name: string) {
    // save to local storage
    let profile = this.profile;
    profile.name = name;
    this.setProfile(profile);
    // save to server
    this.uploadUserProfile();
  }

  public postDeleteUser(reason: string) {
    let url = "/db/user/delete";
    return Utils.getApi("post", url)
      .send({
        report: reason,
      })
      .then((res) => {
        if (res.body.success) {
          Utils.analyticsEvent({
            category: "Delete Account",
            action: "Submit Request",
            result: "Succeed",
          });
          User.logout();
        } else {
          Logger.error(`delete account error`, {
            error: res.body.error,
          });

          Utils.analyticsEvent({
            category: "Delete Account",
            action: "Submit Request",
            result: "Failed",
            error: res.body.error,
          });

          throw new Error(res.body.error);
        }
      })
      .catch((err) => {
        Utils.analyticsEvent({
          category: "Delete Account",
          action: "Submit Request",
          result: "Failed",
          error: err,
        });
        throw new Error(err);
      });
  }
}
