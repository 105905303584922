import React from "react";
import DataManager from "../../DataManager";
import { observer } from "mobx-react";
import { StreamState } from "../../StreamingTask";
import classnames from "classnames";
import Utils from "../../Utils";
import i18n from "../../../i18n";
import { Translation } from "react-i18next";

type StatusOption = {
  ccStatus: StreamState;
  ytConnectionStatus: boolean;
};

const CCStreamStatus = ({ ccStatus, ytConnectionStatus }: StatusOption) => {
  if (!ytConnectionStatus && ccStatus === StreamState.STOP) {
    return <>{i18n.t("streaming_pending", { ns: "streaming" })}</>;
  } else if (ccStatus === StreamState.RUNNING) {
    return <>{i18n.t("streaming_working", { ns: "streaming" })}</>;
  } else if (ccStatus === StreamState.STOP) {
    return <>{i18n.t("streaming_stopped", { ns: "streaming" })}</>;
  } else {
    return <>{i18n.t("streaming_connecting", { ns: "streaming" })}</>;
  }
};

const StreamStatusView = () => {
  let streamingtask = DataManager.instance.streamingTask;
  if (!streamingtask) {
    return null;
  }

  return (
    <Translation ns="streaming">
      {(t) => (
        <div className="connection-status-container">
          <div className="connection-status">
            <div className="connection">{t("caption_status")}</div>
            <div
              className={classnames("status", {
                red: streamingtask.ccStatus !== StreamState.RUNNING,
              })}
            >
              {streamingtask.ccStatus ? (
                <CCStreamStatus
                  ccStatus={streamingtask.ccStatus as StreamState}
                  ytConnectionStatus={streamingtask.ytConnectionStatus}
                />
              ) : (
                t("streaming_connecting")
              )}
            </div>
            {!streamingtask.ytConnectionStatus ||
            streamingtask.ccStatus !== StreamState.STOP ? null : (
              <div className="action">
                <span
                  className="clickable"
                  onClick={async () => {
                    Utils.analyticsEvent({
                      category: "Stream Task",
                      action: "Restart CC Connection",
                      yt_connection_status: streamingtask.ytConnectionStatus,
                      cc_status: streamingtask.ccStatus,
                    });
                    await streamingtask.restartCcConnection();
                  }}
                >
                  {t("streaming_restart")}
                </span>
              </div>
            )}
          </div>
          <div className="connection-status">
            <div className="connection">{t("youtube_connection_status")}</div>
            <div
              className={classnames("status", {
                red: !streamingtask.ytConnectionStatus,
              })}
            >
              {streamingtask.ytConnectionStatus
                ? t("streaming_working")
                : t("streaming_pending")}
            </div>
            {!streamingtask.ytConnectionStatus ? null : (
              <div className="action">
                {t("black_screen")}
                <span
                  className="clickable"
                  onClick={() => {
                    streamingtask.restartYoutubeConnection().finally();
                    Utils.analyticsEvent({
                      category: "Stream Task",
                      action: "Restart Youtube Connection",
                      yt_connection_status: streamingtask.ytConnectionStatus,
                      cc_status: streamingtask.ccStatus,
                    });
                  }}
                >
                  {t("reconnecting")}
                </span>
              </div>
            )}
          </div>
        </div>
      )}
    </Translation>
  );
};

export default observer(StreamStatusView);
