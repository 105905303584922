import React from "react";
import { Link } from "react-router-dom";
import { observer } from "mobx-react";
import Modal from "semantic-ui-react/dist/commonjs/modules/Modal";
import Popup from "semantic-ui-react/dist/commonjs/modules/Popup";
import { Styles, ButtonColor } from "../GlobalDefine";
import {
  ModalHeader,
  ModalActions,
  PopupMenu,
  PopupMenuItem,
  NoRecorderIndicator,
} from "../ui/Component";
import FileListTable from "../ui/FileListTable";
import FolderSelector from "../ui/FolderSelector";
import ShareSettingWindow from "../ui/ShareSettingWindow";
import { Hint } from "../ui/Component";
import ExportWindow from "../ui/ExportWindow";
import LoadingPage from "../page/LoadingPage";
import Voice from "../Voice";
import DataManager, { FolderType, VoiceList } from "../DataManager";
import Utils from "../Utils";
import dayjs from "dayjs";
import { Translation } from "react-i18next";
import i18n from "../../i18n";
import Footer from "../ui/Footer";
import Announcement from "../ui/Announcement";

import "../../assets/scss/FileListPage.scss";
import arrowIcon from "../../assets/img/icon-arrow-down-active.svg";

class Maintenance {
  static readonly schedule: {
    startTime: number;
    endTime: number;
    showBeforeDay: number;
    msg: string;
  }[] = [
    // TODO: refactor hardcoded maintenance object
    {
      startTime: 1620748800 /*2021/05/12 00:00:00*/,
      endTime: 1620756000 /*2021/05/12 02:00:00*/,
      showBeforeDay: 1,
      msg: "因應系統維護，將於台灣時間 2021年5月12日(二) 凌晨 00:00 ~ 02:00 暫停網站功能",
    },
  ];
  static info = Maintenance.check();

  private static check() {
    let ret: {
      hint: React.ReactElement;
      isInMaintenance: boolean;
    } = {
      hint: null,
      isInMaintenance: false,
    };

    Maintenance.schedule.some((s) => {
      let now = dayjs();

      ret.isInMaintenance =
        now.isAfter(dayjs.unix(s.startTime)) &&
        now.isBefore(dayjs.unix(s.endTime));

      if (
        now.isBefore(dayjs.unix(s.endTime)) &&
        now.isAfter(dayjs.unix(s.startTime).subtract(s.showBeforeDay, "day"))
      ) {
        ret.hint = (
          <Hint
            inline
            style={{
              margin: "0px 0px 20px 0px",
            }}
            type={ret.isInMaintenance ? "error" : "info"}
            notClosable
          >
            {s.msg}
          </Hint>
        );
      }

      return null !== ret.hint;
    });

    return ret;
  }
}

@observer
export default class FileListPage extends React.Component<
  any,
  {
    showCleanTrash: boolean;
    onShare?: Voice;
    onExport?: Voice;
    onExportFiles?: Voice[];
    onRename?: Voice;
    onMove: string[];
    onDelete: string[];
    onCancelFile?: Voice;
    onDeleteForever: string[];
    onRecover: string[];
    isLoadingFile: boolean;
    isRenamingFile: boolean;
  }
> {
  private isComponentMounted: boolean;
  private _table: React.RefObject<FileListTable>;
  private _fileNameInput: React.RefObject<HTMLInputElement>;
  private _actions: React.RefObject<HTMLDivElement>;

  constructor(props: any) {
    super(props);
    this.state = {
      showCleanTrash: false,
      onShare: undefined,
      onExport: undefined,
      onExportFiles: undefined,
      onRename: undefined,
      onMove: [],
      onDelete: [],
      onCancelFile: undefined,
      onDeleteForever: [],
      onRecover: [],
      isLoadingFile: false,
      isRenamingFile: false,
    };
    this._table = React.createRef<FileListTable>();
    this._fileNameInput = React.createRef<HTMLInputElement>();
    this._actions = React.createRef<HTMLDivElement>();
  }

  componentDidMount() {
    this.isComponentMounted = true;

    if (!this.state.isLoadingFile) {
      this.handleFileListScrollPosition();
    }

    DataManager.instance.updateCurrentLocation(
      this.props.location.pathname,
      this.props.location.search,
    );
  }

  componentWillUnmount() {
    this.isComponentMounted = false;
  }

  shouldComponentUpdate(nextProps: any, nextState: any) {
    DataManager.instance.updateCurrentLocation(
      nextProps.location.pathname,
      nextProps.location.search,
    );
    return true;
  }

  public handleKeyDownListener(e: KeyboardEvent): boolean {
    let pass = true;
    if (this._table.current) {
      pass = pass && this._table.current?.handleKeyDownListener(e);
    }
    return pass;
  }

  public handleKeyUpListener(e: KeyboardEvent): boolean {
    this._table.current?.handleKeyUpListener(e);
    if (e.keyCode === 13) {
      // enter
      this.confirmDeleteOnYes();
    } else if (e.keyCode === 27) {
      // esc
      this.setState({
        onDelete: [],
        onCancelFile: undefined,
        showCleanTrash: false,
        onShare: undefined,
        onExport: undefined,
        onRename: undefined,
        onDeleteForever: [],
        onRecover: [],
        onMove: [],
      });
    }

    return true;
  }

  public onOtterContainerScroll(event: React.UIEvent<HTMLDivElement>) {
    this.checkScrolling(event.target as HTMLElement);
  }

  private checkScrolling(container: HTMLElement) {
    if (!this._actions.current || !container) {
      return;
    }
    // consider nav heaight
    const navHeaight = Utils.isDesktop ? 0 : Utils.isPhone ? 48 : 64;
    if (container.scrollTop > this._actions.current.offsetTop - navHeaight) {
      this._actions.current.classList.add("sticky");
    } else {
      this._actions.current.classList.remove("sticky");
    }
  }

  private getFileName(eid: string): string {
    let file = this.voiceList.list.find((file, idx) => {
      return eid === file.eid;
    });
    return file ? file.name : "";
  }

  private getPager(): React.ReactElement {
    const start = Math.min(
      this.voiceList.listOffset * this.voiceList.listSize,
      this.voiceList.totalCnt,
    );
    const end = Math.min(
      start + this.voiceList.listSize,
      this.voiceList.totalCnt,
    );

    const startPage = 1;
    const endPage = this.voiceList.maxOffset + 1;
    const prevPage = Math.max(1, this.voiceList.listOffset);
    const nextPage = Math.min(
      this.voiceList.maxOffset + 1,
      this.voiceList.listOffset + 2,
    );
    const currentPage = this.voiceList.listOffset + 1;

    const startUrlQuery = Utils.setValueToUrlQuery(
      window.location.search,
      "page",
      startPage.toString(),
    );
    const endUrlQuery = Utils.setValueToUrlQuery(
      window.location.search,
      "page",
      endPage.toString(),
    );
    const prevUrlQuery = Utils.setValueToUrlQuery(
      window.location.search,
      "page",
      prevPage.toString(),
    );
    const nextUrlQuery = Utils.setValueToUrlQuery(
      window.location.search,
      "page",
      nextPage.toString(),
    );

    if (start === end) {
      return null;
    }

    return (
      <div className="list-pager">
        <Translation ns="fileList">
          {(t) => (
            <Popup
              basic
              on="click"
              size="mini"
              style={Styles.popupContainer}
              trigger={
                <div className="current-page number">
                  {start === end
                    ? ""
                    : `${start + 1}-${end}（${i18n.t(
                        "total_number_of_transcripts",
                        {
                          totalCnt: this.voiceList.totalCnt,
                        },
                      )}）`}
                </div>
              }
            >
              <PopupMenu className="list-order-options">
                <PopupMenuItem
                  className={`order-option${startPage === currentPage ? " disabled" : ""}`}
                >
                  {startPage === currentPage ? (
                    i18n.t("newest")
                  ) : (
                    <Link to={startUrlQuery} draggable={false}>
                      {i18n.t("newest")}
                    </Link>
                  )}
                </PopupMenuItem>
                <PopupMenuItem
                  className={`order-option${endPage === currentPage ? " disabled" : ""}`}
                >
                  {endPage === currentPage ? (
                    i18n.t("oldest")
                  ) : (
                    <Link to={endUrlQuery} draggable={false}>
                      {i18n.t("oldest")}
                    </Link>
                  )}
                </PopupMenuItem>
              </PopupMenu>
            </Popup>
          )}
        </Translation>
        {prevPage === currentPage ? (
          <img src={arrowIcon} className="prev-page" />
        ) : (
          <Link to={prevUrlQuery}>
            <img src={arrowIcon} className="prev-page" />
          </Link>
        )}
        {nextPage === currentPage ? (
          <img src={arrowIcon} className="next-page" />
        ) : (
          <Link to={nextUrlQuery}>
            <img src={arrowIcon} className="next-page" />
          </Link>
        )}
      </div>
    );
  }

  private get voiceList(): VoiceList {
    return DataManager.instance.voices;
  }

  private get isModalForDeleteOpen(): boolean {
    const showDelete = this.state.onDelete && this.state.onDelete.length > 0;
    const showDeleteForever =
      this.state.onDeleteForever && this.state.onDeleteForever.length > 0;
    const showRecover = this.state.onRecover && this.state.onRecover.length > 0;

    return (
      showDelete ||
      this.state.onCancelFile !== undefined ||
      this.state.showCleanTrash ||
      showDeleteForever ||
      showRecover
    );
  }

  private get isModalForMoveOpen(): boolean {
    return this.state.onMove && this.state.onMove.length > 0;
  }

  private confirmRenameOnYes() {
    if (!this.state.onRename) {
      return;
    }

    this.setState({
      isRenamingFile: true,
    });
    this.state.onRename
      .updateFileName(this._fileNameInput.current?.value, "File List Page")
      .then(() => {
        this.setState({
          isRenamingFile: false,
          onRename: undefined,
        });
      });
  }

  private confirmDeleteOnYes() {
    if (!this.isModalForDeleteOpen) {
      return;
    }

    const showDelete = this.state.onDelete && this.state.onDelete.length > 0;
    const showDeleteForever =
      this.state.onDeleteForever && this.state.onDeleteForever.length > 0;

    let promise;
    if (showDelete) {
      promise = DataManager.instance.deleteVoices(
        this.state.onDelete,
        "File List Page",
        true,
      );
    } else if (this.state.onCancelFile) {
      promise = DataManager.instance.cancelVoice(this.state.onCancelFile);
    } else if (this.state.showCleanTrash) {
      promise = DataManager.instance.deleteForeverVoices();
    } else if (showDeleteForever) {
      promise = DataManager.instance.deleteForeverVoices(
        this.state.onDeleteForever,
      );
    } else {
      promise = DataManager.instance.restoreVoices(this.state.onRecover);
    }
    promise.then((success) => {
      if (success && this._table.current) {
        this._table.current.resetSelection();
      }
    });
    this.setState({
      onDelete: [],
      onCancelFile: undefined,
      showCleanTrash: false,
      onDeleteForever: [],
      onRecover: [],
    });
  }

  private onBlurFolderTitle(event: React.ChangeEvent<HTMLInputElement>) {
    let oldName = this.voiceList.folderName;
    let newName = event.target.innerText ? event.target.innerText.trim() : "";

    DataManager.instance
      .renameFolder(this.voiceList.folderId, newName)
      .then((success) => {
        if (!success) {
          event.target.innerText = oldName;
        }
      });
  }

  private onKeyDownFolderTitle(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.keyCode == 13) {
      // enter
      (event.target as HTMLElement).blur();
    } else if (event.keyCode == 27) {
      // esc
      (event.target as HTMLElement).innerText = this.voiceList.folderName;
      (event.target as HTMLElement).blur();
    }
  }

  private getShareSettingUI(): React.ReactElement {
    return (
      <ShareSettingWindow
        show={this.state.onShare !== undefined}
        file={this.state.onShare}
        startTime={0}
        ownerPage={"File List Page"}
        onClose={() => {
          this.setState({
            onShare: undefined,
          });
        }}
      />
    );
  }

  private getExportUI(): React.ReactElement {
    return (
      <ExportWindow
        show={
          this.state.onExport !== undefined ||
          this.state.onExportFiles !== undefined
        }
        file={
          this.state.onExportFiles
            ? this.state.onExportFiles[0]
            : this.state.onExport
        }
        files={this.state.onExportFiles}
        ownerPage={"File List Page"}
        onClose={() => {
          this.setState({
            onExport: undefined,
            onExportFiles: undefined,
          });
        }}
        onExport={() => {
          this.setState({
            onExport: undefined,
            onExportFiles: undefined,
          });
        }}
      />
    );
  }

  private getRenameUI(): React.ReactElement {
    if (!this.state.onRename) {
      return null;
    }

    return (
      <Translation ns="fileList">
        {(t) => (
          <Modal
            open={this.state.onRename !== undefined}
            size="tiny"
            dimmer="blurring"
          >
            <ModalHeader title={t("edit_title")} textAlign={"left"} />
            <div className="folder-name-input-container ">
              <input
                ref={this._fileNameInput}
                type="text"
                placeholder={t("enter_title")}
                className="name-input"
                defaultValue={
                  !this.state.onRename ? undefined : this.state.onRename?.name
                }
                onBlur={(event) => {
                  if (
                    !this.state.onRename &&
                    event.target.value.trim().length === 0
                  ) {
                    event.target.value = this.state.onRename?.name;
                  }
                }}
              />
            </div>
            <ModalActions
              actions={[
                {
                  color: ButtonColor.Default,
                  content: i18n.t("cancel"),
                  disabled: this.state.isRenamingFile,
                  onClick: () => {
                    this.setState({
                      onRename: undefined,
                    });
                  },
                },
                {
                  color: ButtonColor.Orange,
                  content: this.state.isRenamingFile
                    ? i18n.t("saving")
                    : i18n.t("confirm"),
                  disabled: this.state.isRenamingFile,
                  onClick: () => {
                    this.confirmRenameOnYes();
                  },
                },
              ]}
            />
          </Modal>
        )}
      </Translation>
    );
  }

  private getConfirmDeleteUI(): React.ReactElement {
    const showDelete = this.state.onDelete && this.state.onDelete.length > 0;
    const showDeleteForever =
      this.state.onDeleteForever && this.state.onDeleteForever.length > 0;

    return (
      <Translation ns="fileList">
        {(t) => (
          <Modal open={this.isModalForDeleteOpen} size="tiny" dimmer="blurring">
            <ModalHeader
              title={
                showDelete
                  ? i18n.t("delete_transcript")
                  : this.state.onCancelFile
                    ? t("stop_transcribing")
                    : this.state.showCleanTrash
                      ? t("empty_trash_bin")
                      : showDeleteForever
                        ? i18n.t("delete_permanently")
                        : i18n.t("restore")
              }
              textAlign="left"
            />
            <div className="deleting-message-container">
              {showDelete ? (
                <>
                  <div>{i18n.t("alert_delete_transcript")}</div>
                  <div>{i18n.t("alert_moved_to_the_trash_bin")}</div>
                  {this.state.onDelete.map((selected, idx) => {
                    return (
                      <div key={idx} className="name number">
                        {this.getFileName(selected)}
                      </div>
                    );
                  })}
                </>
              ) : this.state.onCancelFile ? (
                <>
                  <div>{t("alert_stop_transcript")}</div>
                  <div className="name">{this.state.onCancelFile?.name}</div>
                </>
              ) : this.state.showCleanTrash ? (
                <>
                  <div>{t("alert_delete_all_in_the_trash_bin")}</div>
                  <div>{t("alert_can_not_be_restored")}</div>
                </>
              ) : showDeleteForever ? (
                <>
                  <div>{t("alert_delete_transcript_permanently")}</div>
                  <div>{t("alert_can_not_be_restored")}</div>
                  {this.state.onDeleteForever.map((selected, idx) => {
                    return (
                      <div key={idx} className="name">
                        {this.getFileName(selected)}
                      </div>
                    );
                  })}
                </>
              ) : (
                <>
                  <div>{t("alert_restore_transcript")}</div>
                  {this.state.onRecover.map((selected, idx) => {
                    return (
                      <div key={idx} className="name number">
                        {this.getFileName(selected)}
                      </div>
                    );
                  })}
                </>
              )}
            </div>
            <ModalActions
              actions={[
                {
                  color: ButtonColor.Default,
                  content: i18n.t("cancel"),
                  onClick: () => {
                    this.setState({
                      onDelete: [],
                      onCancelFile: undefined,
                      showCleanTrash: false,
                      onDeleteForever: [],
                      onRecover: [],
                    });
                  },
                },
                {
                  color: ButtonColor.Orange,
                  content: this.state.onCancelFile
                    ? t("stop_and_remove")
                    : i18n.t("confirm"),
                  onClick: () => {
                    this.confirmDeleteOnYes();
                  },
                },
              ]}
            />
          </Modal>
        )}
      </Translation>
    );
  }

  private getConfirmMoveUI(): React.ReactElement {
    return (
      <Translation ns="fileList">
        {(t) => (
          <Modal open={this.isModalForMoveOpen} size="tiny" dimmer="blurring">
            <ModalHeader title={t("move_to_folder")} textAlign="left" />
            <FolderSelector
              onSelect={(folderId) => {
                DataManager.instance.moveVoices(
                  this.state.onMove,
                  folderId,
                  "File List Page",
                  true,
                );
                this.setState({
                  onMove: [],
                });
              }}
            />
            <ModalActions
              actions={[
                {
                  color: ButtonColor.Default,
                  content: i18n.t("cancel"),
                  onClick: () => {
                    this.setState({
                      onMove: [],
                    });
                  },
                },
              ]}
            />
          </Modal>
        )}
      </Translation>
    );
  }

  private handleFileListScrollPosition() {
    const scrollElement = Utils.isDesktop
      ? document.getElementById("main")
      : document.getElementsByClassName("file-list-page")[0];

    Utils.handleScrollPosition(scrollElement, "file-list-scroll-position");
  }

  render() {
    let isLoading =
      DataManager.instance.reqList.voiceList != undefined &&
      !DataManager.instance.isPolling;
    let isDeleted = this.voiceList.folderType === FolderType.Deleted;
    let noRecord = this.voiceList.totalCnt == 0;

    return (
      <Translation ns="fileList">
        {(t) => (
          <>
            <Announcement />
            <div
              className="file-list-page"
              onScroll={(event) => {
                this.checkScrolling(event.target as HTMLDivElement);
              }}
            >
              {Maintenance.info.hint}
              <div
                className="file-list-title"
                suppressContentEditableWarning={true}
                contentEditable={
                  this.voiceList.folderType === FolderType.Folder
                }
                onBlur={this.onBlurFolderTitle.bind(this)} // defocus => update
                onKeyDown={this.onKeyDownFolderTitle.bind(this)}
              >
                {this.voiceList.folderName}
              </div>
              <div ref={this._actions} className="file-list-actions">
                {isDeleted ? (
                  <div className="delete-in-days-hint">
                    <span>{t("after_30_days_deleted_permanently")}</span>
                    <span
                      className="clicable"
                      onClick={() => {
                        this.setState({
                          showCleanTrash: true,
                        });
                      }}
                    >
                      {t("empty_trash_bin")}
                    </span>
                    <span>。</span>
                  </div>
                ) : (
                  <div
                    className={`file-count number${isLoading ? " loading" : ""}`}
                  >
                    {i18n.t("total_number_of_transcripts", {
                      totalCnt: this.voiceList.totalCnt,
                    })}
                  </div>
                )}
                {this.getPager()}
              </div>
              <FileListTable
                ref={this._table}
                files={this.voiceList}
                isLoading={isLoading}
                movable={
                  this.voiceList.folderType === FolderType.Uncategorized ||
                  this.voiceList.folderType === FolderType.All ||
                  this.voiceList.folderType === FolderType.Recent ||
                  this.voiceList.folderType === FolderType.Folder
                }
                deletable={
                  this.voiceList.folderType === FolderType.Uncategorized ||
                  this.voiceList.folderType === FolderType.All ||
                  this.voiceList.folderType === FolderType.Recent ||
                  this.voiceList.folderType === FolderType.Folder
                }
                isTrashTable={isDeleted}
                onDelete={(selected: string[]) => {
                  this.setState({
                    onDelete: selected || [],
                  });
                }}
                onCancel={(file: Voice) => {
                  this.setState({
                    onCancelFile: file,
                  });
                }}
                onMove={(selected: string[]) => {
                  this.setState({
                    onMove: selected || [],
                  });
                }}
                onShare={(file) => {
                  if (file.isDetailedLoaded) {
                    this.setState({
                      onShare: file,
                    });
                  } else {
                    this.setState({
                      isLoadingFile: true,
                    });
                    file.loadDetail(false).then((success) => {
                      this.setState({
                        isLoadingFile: false,
                        onShare: success ? file : undefined,
                      });
                    });
                  }
                }}
                onExport={(file) => {
                  if (file.isDetailedLoaded) {
                    this.setState({
                      onExport: file,
                    });
                  } else {
                    this.setState({
                      isLoadingFile: true,
                    });
                    file.loadDetail().then((success) => {
                      this.setState({
                        isLoadingFile: false,
                        onExport: success ? file : undefined,
                      });
                    });
                  }
                }}
                onExportAudio={(file) => {
                  this.setState({
                    isLoadingFile: true,
                  });
                  file.downloadAudio().then((success) => {
                    this.setState({
                      isLoadingFile: false,
                    });
                  });
                }}
                onExportFiles={(files) => {
                  if (files.length < 1) {
                    return;
                  }
                  if (files[0].isDetailedLoaded) {
                    this.setState({
                      onExportFiles: files,
                    });
                  } else {
                    this.setState({
                      isLoadingFile: true,
                    });
                    files[0].loadDetail().then((success) => {
                      this.setState({
                        isLoadingFile: false,
                        onExportFiles: success ? files : undefined,
                      });
                    });
                  }
                }}
                onExportAudios={(files) => {
                  if (files.length < 1) {
                    return;
                  }
                  this.setState({
                    isLoadingFile: true,
                  });
                  let eids: string[] = [];
                  files.forEach((file) => {
                    eids.push(file.eid);
                  });
                  Utils.getApi("post", "/db/voice/download/audios")
                    .send({
                      eids: eids,
                    })
                    .then((res) => {
                      if (res.body.success) {
                        res.body.data.forEach((data: any) => {
                          Utils.downloadResource(data.url, data.name);
                        });
                      }
                    })
                    .finally(() => {
                      this.setState({
                        isLoadingFile: false,
                      });
                    });
                }}
                onRename={(file) => {
                  this.setState({
                    onRename: file,
                  });
                }}
                onDeleteForever={(selected: string[]) => {
                  this.setState({
                    onDeleteForever: selected || [],
                  });
                }}
                onRecover={(selected: string[]) => {
                  this.setState({
                    onRecover: selected || [],
                  });
                }}
              />
              {this.state.isLoadingFile ? (
                <div className="loading-file-indicator">
                  <LoadingPage />
                </div>
              ) : !isLoading && !isDeleted && noRecord ? (
                <NoRecorderIndicator message={t("no_recorder_message")} />
              ) : null}
              {!isLoading && <Footer />}
            </div>

            {this.getConfirmDeleteUI()}
            {this.getConfirmMoveUI()}
            {this.getShareSettingUI()}
            {this.getExportUI()}
            {this.getRenameUI()}
          </>
        )}
      </Translation>
    );
  }
}
