import React from "react";
import Config from "../Config";
import i18n from "../../i18n";
import "../../assets/scss/Login.scss";
import studioIcon from "../../assets/img/studio-icon.svg";
import googleIcon from "../../assets/img/google.svg";
import appleIcon from "../../assets/img/apple.svg";

export default class Login extends React.Component {
  render() {
    return (
      <div className="login-page-container">
        <div className="login-inner">
          <img
            src={studioIcon}
            width={133}
            height={30}
            className="studio-icon"
          />
          <a
            href={Login.wrapWithRedirectUrl(
              `${Config.baseName}api/v2/user/auth/google`,
            )}
            className="login-button"
          >
            <img src={googleIcon} width={20} height={20} />
            {i18n.t("sign_in_with_google")}
          </a>
          <a
            href={Login.wrapWithRedirectUrl(
              `${Config.baseName}api/v2/user/auth/apple`,
            )}
            className="login-button"
          >
            <img src={appleIcon} width={20} height={20} />
            {i18n.t("sign_in_with_apple")}
          </a>
          <p className="yating-studio-text">YaTing @ Studio</p>
        </div>
      </div>
    );
  }

  /** Get redirect search params from url and pass to mitglied login button */
  static wrapWithRedirectUrl(destination: string): string {
    const params = new URLSearchParams(window.location.search);
    const redirect = params.get("redirect");
    if (!redirect) {
      return destination;
    }
    let redirectUrl: URL;
    try {
      redirectUrl = new URL(redirect);
    } catch (e) {
      console.warn(e);
      return destination;
    }
    if (
      !(redirectUrl.protocol === "https:" || redirectUrl.protocol === "http:")
    ) {
      return destination;
    }
    const search = new URLSearchParams();
    search.set("redirect", redirect);
    const connector = destination.includes("?") ? "&" : "?";
    return `${destination}${connector}${search.toString()}`;
  }
}
