import React from "react";
import Utils from "../Utils";
import User from "../User";
import Accordion from "semantic-ui-react/dist/commonjs/modules/Accordion";
import { Color, Dimen } from "../GlobalDefine";
import dayjs from "dayjs";
import DataManager from "../DataManager";
import i18n from "../../i18n";
import { Translation } from "react-i18next";

let styles: { [key: string]: React.CSSProperties } = {
  footerDivider: {
    margin: "0 23px",
  },
  footerContainerDesktop: {
    fontSize: "14px",
    color: Color.black,
    textAlign: "center",
  },
  footerContainerMobile: {
    fontSize: "14px",
    color: Color.black,
    textAlign: "center",
  },
  content: {
    height: "30px",
    color: Color.black,
  },
  link: {
    margin: "auto",
    color: "inherit",
  },
};

export default class FaqPage extends React.Component<any, any> {
  static urlParam = "/faq/";
  static tabName = "FAQ";

  panels = [
    {
      key: "customer identifier",
      title: {
        content: i18n.t("faq_reimbursement", { ns: "faq" }),
      },
      content: {
        content: <span>{i18n.t("reimbursement_answer", { ns: "faq" })}</span>,
      },
    },
    {
      key: "wrong file format",
      title: {
        content: i18n.t("faq_format_issue", { ns: "faq" }),
      },
      content: {
        content: (
          <span>
            {i18n.t("format_issue_answer", { ns: "faq" })}
            <a
              href="https://online-audio-converter.com/tw/"
              target="_blank"
              onClick={Utils.sendAnalyticsOutboundClickEvent}
            >
              https://online-audio-converter.com/tw/
            </a>{" "}
          </span>
        ),
      },
    },
    {
      key: "upload failed",
      title: {
        content: i18n.t("faq_upload_fail", { ns: "faq" }),
      },
      content: {
        content: (
          <span>
            {i18n.t("upload_fail_answer_first", { ns: "faq" })}
            <a
              href="https://online-audio-converter.com/tw/"
              target="_blank"
              onClick={Utils.sendAnalyticsOutboundClickEvent}
            >
              https://online-audio-converter.com/tw/
            </a>{" "}
            {i18n.t("upload_fail_answer_last", { ns: "faq" })}{" "}
            <a href="mailto:contact@yating.tw">contact@yating.tw</a>。
          </span>
        ),
      },
    },
    {
      key: "other question",
      title: {
        content: i18n.t("other_question", { ns: "faq" }),
      },
      content: {
        content: (
          <span>
            <a
              href={`https://docs.google.com/forms/d/e/1FAIpQLSe5aWLFJKbxgmH-K0NWu2jHKZkm7YfVKtxoT2i-OC94LsdcHg/viewform?usp=pp_url&entry.1932016503=${User.instance.profile.name}&entry.1142685754=${User.instance.profile.email}`}
              target="_blank"
              onClick={Utils.sendAnalyticsOutboundClickEvent}
            >
              {i18n.t("fill_form", { ns: "faq" })}
            </a>
          </span>
        ),
      },
    },
  ];

  componentDidMount() {
    Utils.analyticsPageView("/faq");
    DataManager.instance.updateCurrentLocation(
      this.props.location.pathname,
      this.props.location.search,
    );
  }

  sendAnalyticsTitleClick(event: any, data: any) {
    if (!data.active) {
      Utils.analyticsEvent({
        category: "Navigation",
        action: "FAQ open question",
        label: data.content,
      });
    }
  }

  render() {
    return (
      <React.Fragment>
        <Translation ns="faq">
          {(t) => (
            <div
              style={{
                background: "none",
              }}
            >
              <Accordion
                onTitleClick={this.sendAnalyticsTitleClick.bind(this)}
                style={{
                  width: "auto",
                  maxWidth: Dimen.uploadPageMaxWidth,
                  margin: "0px 10px 20px 10px",
                }}
                styled
                fluid
                panels={this.panels}
              />
              {Utils.isDesktop ? (
                <div style={styles.footerContainerDesktop}>
                  <span style={styles.content}>
                    © {dayjs().year()} {i18n.t("yating")}
                  </span>
                  <span style={styles.footerDivider}>|</span>
                  <a
                    href="https://yating.tw"
                    target="_blank"
                    style={styles.content}
                  >
                    {t("yating_tw")}
                  </a>
                  <span style={styles.footerDivider}>|</span>
                  <a href="mailto:contact@yating.tw" style={styles.content}>
                    contact@yating.tw
                  </a>
                  <span style={styles.footerDivider}>|</span>
                  <a
                    href="https://yating.tw/privacy-statement/"
                    target="_blank"
                    style={styles.content}
                  >
                    {t("privacy_policy")}
                  </a>
                  <span style={styles.footerDivider}>|</span>
                  <a
                    href="https://yating.tw/yating-terms-of-service/"
                    target="_blank"
                    style={styles.content}
                  >
                    {t("terns_of_service")}
                  </a>
                </div>
              ) : (
                <div style={styles.footerContainerMobile}>
                  <div style={styles.content}>
                    <label style={styles.link}>
                      © {dayjs().year()} {i18n.t("yating")}
                    </label>
                  </div>
                  <div style={styles.content}>
                    <a
                      href="https://yating.tw"
                      target="_blank"
                      style={styles.link}
                    >
                      {t("yating_tw")}
                    </a>
                  </div>
                  <div style={styles.content}>
                    <a href="mailto:contact@yating.tw" style={styles.link}>
                      contact@yating.tw
                    </a>
                  </div>
                  <div style={styles.content}>
                    <a
                      href="https://yating.tw/privacy-statement/"
                      target="_blank"
                      style={styles.link}
                    >
                      {t("privacy_policy")}
                    </a>
                  </div>
                  <div style={styles.content}>
                    <a
                      href="https://yating.tw/yating-terms-of-service/"
                      target="_blank"
                      style={styles.link}
                    >
                      {t("terns_of_service")}
                    </a>
                  </div>
                </div>
              )}
            </div>
          )}
        </Translation>
      </React.Fragment>
    );
  }
}
