import React from "react";
import Utils from "../Utils";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";

import "../../assets/scss/GPTSummary.scss";

import loadingImage from "../../assets/img/animation-summary.gif";
import styled from "styled-components";
import Config from "../Config";
import classNames from "classnames";
import User from "../User";

type ChunkKey = "summary" | "actionItems" | (string & {});
type GPTSummaryData = {
  key: ChunkKey;
  title: string;
  defaultTitle?: Record<string, string>;
  values: string[];
}[];

function getChunkTitle(data: GPTSummaryData[number]) {
  return data.title || data.defaultTitle?.[i18n.language] || "";
}

export async function getAISummary(eid: string): Promise<GPTSummaryData> {
  try {
    const res = await Utils.getApi("post", "/db/voice/ai-summary").send({
      eid,
    });
    return res.body.data;
  } catch (e) {
    console.warn(e);
    return [];
  }
}

export function convertSummaryToText(data: GPTSummaryData) {
  if (!data?.length) {
    return "";
  }
  let res = "";
  for (const chunk of data) {
    if (!chunk.values?.length) {
      continue;
    }
    res += `\n${getChunkTitle(chunk)}`;
    res += "\n\n";
    for (const item of chunk.values) {
      res += `• ${item}\n`;
    }
  }
  return res;
}

export function GPTSummaryStillPending() {
  const [t] = useTranslation("summaryFile");
  return (
    <section className="gpt-summary gpt-summary-loading">
      <div>
        <div className="gpt-summary-loading-title">
          {t("summary_progress_title")}
        </div>
        <div>{t("summary_progress_desc")}</div>
      </div>
      <img src={loadingImage} width={200} alt="" />
    </section>
  );
}

export function GPTSummary(props: { eid: string; mask: boolean }) {
  const [t, i18n] = useTranslation("summaryFile");
  const [loading, setLoading] = React.useState(true);
  const [data, setData] = React.useState<GPTSummaryData | undefined>();

  async function fetchSummary() {
    if (!props.eid) {
      return;
    }
    setLoading(true);
    try {
      const res = await getAISummary(props.eid);
      if (res) {
        setData(res);
      }
    } finally {
      setLoading(false);
    }
  }

  async function rerunSummary() {
    if (!props.eid) {
      return;
    }
    setLoading(true);
    try {
      await Utils.getApi("put", "/db/voice/summary/rerun").send({
        eid: props.eid,
      });
      const res = await pollingFor(() => getAISummary(props.eid), {
        finish: (res) => res?.length > 0,
        maxRetry: 12,
      });
      if (res) {
        setData(res);
      }
    } finally {
      setLoading(false);
    }
  }

  React.useEffect(() => {
    fetchSummary();
  }, [props.eid]);

  if (loading) {
    return (
      <section className="gpt-summary">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "0.5rem",
            color: "#666",
          }}
        >
          <LoadingIcon
            className="animate-spin"
            fill="#3F54D1"
            style={{ fontSize: "1.5rem" }}
          />
        </div>
      </section>
    );
  }

  if (!data?.length) {
    return (
      <section
        className={classNames("gpt-summary", props.mask && "gpt-summary-mask")}
      >
        {props.mask && <Mask />}
        <div className="gpt-summary-chunk-title">{t("data_error")}</div>
        <button
          className="gpt-retry-button"
          onClick={() => rerunSummary()}
          disabled={loading || props.mask}
        >
          {t("rerun")} {loading && <LoadingIcon className="animate-spin" />}
        </button>
      </section>
    );
  }

  return (
    <section
      className={classNames("gpt-summary", props.mask && "gpt-summary-mask")}
    >
      {props.mask && <Mask />}
      {data.map((chunk) => {
        return (
          <div
            className="gpt-summary-chunk"
            key={chunk.key}
            style={
              props.mask
                ? { pointerEvents: "none", userSelect: "none" }
                : undefined
            }
          >
            <h3 className="gpt-summary-chunk-title">{getChunkTitle(chunk)}</h3>
            <ul>
              {chunk.values.map((text) => (
                <li key={text}>{text}</li>
              ))}
            </ul>
            {chunk.values.length === 0 && (
              <div style={{ color: "#666" }}>{t("no_data")}</div>
            )}
          </div>
        );
      })}
    </section>
  );
}

const LoadingIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="1em"
    viewBox="0 -960 960 960"
    width="1em"
    {...props}
  >
    <path d="M480-80q-82 0-155-31.5t-127.5-86Q143-252 111.5-325T80-480q0-83 31.5-155.5t86-127Q252-817 325-848.5T480-880q17 0 28.5 11.5T520-840q0 17-11.5 28.5T480-800q-133 0-226.5 93.5T160-480q0 133 93.5 226.5T480-160q133 0 226.5-93.5T800-480q0-17 11.5-28.5T840-520q17 0 28.5 11.5T880-480q0 82-31.5 155t-86 127.5q-54.5 54.5-127 86T480-80Z" />
  </svg>
);

async function pollingFor<T = unknown>(
  fn: () => Promise<T>,
  params: {
    finish: (res: T) => boolean;
    maxRetry?: number;
    abortSignal?: AbortSignal;
  },
): Promise<T> {
  let count = 0;
  const finish = new Promise<T>((resolve, reject) => {
    const onAbort = () => {
      reject("The request is aborted by the user.");
    };
    params?.abortSignal?.addEventListener("abort", onAbort);
    async function tryFetch() {
      try {
        if (params?.abortSignal?.aborted) {
          onAbort();
          return;
        }
        const result = await fn();
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if (params.finish(result)) {
          resolve(result);
        } else {
          count += 1;
          if (params.maxRetry && count > params.maxRetry) {
            reject("Max retry reached.");
            return;
          }
          setTimeout(tryFetch, 5000);
        }
      } catch (e) {
        reject(e);
      }
    }
    tryFetch();
  });

  return finish;
}

const MaskContainer = styled("div")`
  background: linear-gradient(
    180deg,
    rgba(255, 240, 229, 0) 7%,
    rgba(255, 240, 229, 0.72) 40.97%,
    #fff0e5 100%
  );
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(5px);
  > h3 {
    font-weight: 600;
    font-size: 18px;
  }

  > a {
    margin-top: 1rem;
    padding: 0.5rem 1rem;
    background-color: #ff6d00;
    color: white;
    text-underline: none;
    border-radius: 0.5rem;

    &:hover {
      background-color: #ff6d00cc;
    }
  }
`;

function Mask() {
  const [t] = useTranslation("summaryFile");
  const isSubscribed = User.instance.isSubscribed;
  return (
    <MaskContainer>
      {isSubscribed ? (
        <p>{t("outdate_subscription")}</p>
      ) : (
        <>
          <h3>{t("upgrade_title")}</h3>
          <p>{t("upgrade_desc")}</p>
          <a href={Config.AccountCenterPlanUrl}>{t("upgrade_button")}</a>
        </>
      )}
    </MaskContainer>
  );
}
