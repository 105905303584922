import React from "react";
import dayjs from "dayjs";

import { Translation } from "react-i18next";
import i18n from "../../i18n";

import Logger from "../Logger";
import Utils from "../Utils";

import YatingInput from "./MainInput";
import YatingButton from "./YatingButton";

import { CustomButton } from "./Component";
import { getButtonColors, ButtonColor, Color } from "../GlobalDefine";
import { Modal } from "semantic-ui-react";

let styles: { [key: string]: React.CSSProperties } = {
  modalTitle: {
    margin: "15px 10px 20px 10px",
    fontSize: "18px",
    fontWeight: "bold",
    color: "#2e333a",
    textAlign: "center",
  },
  modalContent: {
    maxHeight: "50vh",
    overflowWrap: "anywhere",
    padding: "0px 17px",
    margin: "0px 3px",
  },
  modalAction: {
    textAlign: "right",
    margin: "45px 30px 15px 15px",
  },
};

export default class AccessTokenPanel extends React.Component<
  any,
  {
    appName: string;
    expire: string;
    token?: string;
    tokens: {
      name: string;
      expire?: number;
      ctime: number;
    }[];
  }
> {
  private isComponentMounted: boolean;

  constructor(props: any) {
    super(props);
    this.state = {
      appName: "",
      expire: "",
      token: undefined,
      tokens: [],
    };
  }

  componentDidMount() {
    this.isComponentMounted = true;
    this.queryTokenList();
  }

  componentWillUnmount(): void {
    this.isComponentMounted = false;
  }

  private queryTokenList() {
    Utils.getApi("post", "/db/user/token/list")
      .then((res) => {
        if (res.body.success) {
          let tokens: any[] = [];
          res.body.tokens.forEach((t: any) => {
            tokens.push(t);
          });
          tokens.sort((a: any, b: any) => {
            if (a.ctime > b.ctime) {
              return 1;
            } else if (a.ctime == b.ctime) {
              return 0;
            } else {
              return -1;
            }
          });
          if (this.isComponentMounted) {
            this.setState({
              tokens: tokens,
            });
          }
        }
      })
      .catch((err) => {
        Logger.error(`Account, get access tokens error ${err}`, {
          errorMessage: err.message,
          errorName: err.name,
        });
      });
  }

  private getTokenList(type: "table" | "card") {
    let nodes: React.ReactElement[] = [];

    this.state.tokens.forEach((token) => {
      if (type === "table") {
        nodes.push(
          <div className="token-row" key={token.ctime}>
            <div className="token-item">{token.name}</div>
            <div className="token-item">
              {dayjs.unix(token.ctime).format("YYYY-MM-DD")}
            </div>
            <div className="token-item">
              {token.expire != null
                ? dayjs.unix(token.expire).format("YYYY-MM-DD")
                : i18n.t("never_expire", { ns: "account" })}
            </div>

            <a
              onClick={(e) => {
                this.clickedRevoke(token.name, token.ctime);
              }}
            >
              {i18n.t("delete")}
            </a>
          </div>,
        );
      } else {
        nodes.push(
          <div className="token-card" key={token.ctime}>
            <div className="card-row">
              <span className="card-key">
                {i18n.t("application_name", { ns: "account" })}:
              </span>
              {token.name}
            </div>
            <div className="card-row">
              <span className="card-key">
                {i18n.t("create_date", { ns: "account" })}:
              </span>
              {dayjs.unix(token.ctime).format("YYYY-MM-DD")}
            </div>
            <div className="card-row">
              <span className="card-key">
                {i18n.t("expiration_date", { ns: "account" })}：
              </span>
              {token.expire != null
                ? dayjs.unix(token.expire).format("YYYY-MM-DD")
                : i18n.t("never_expire", { ns: "account" })}
            </div>

            <div className="card-button-container">
              <YatingButton
                className="card-delete-button"
                color="red"
                variant="primary"
                size="small"
                onClick={() => {
                  this.clickedRevoke(token.name, token.ctime);
                }}
              >
                {i18n.t("delete")}
              </YatingButton>
            </div>
          </div>,
        );
      }
    });

    return nodes;
  }

  private onChangeAppName(event: React.ChangeEvent<{ value: string }>) {
    this.setState({
      appName: event.target.value,
    });
  }

  private onChangeExpire(event: React.ChangeEvent<{ value: string }>) {
    this.setState({
      expire: event.target.value,
    });
  }

  private clickedAddToken() {
    let expire: Date = undefined;
    if (this.state.expire && this.state.expire.length > 0) {
      let d = dayjs(this.state.expire, "YYYY/MM/DD");
      expire = d.toDate();
    }
    Utils.getApi("put", "/db/user/token/create")
      .send({
        name: this.state.appName,
        expire: expire ? expire.toUTCString() : expire,
      })
      .then((res) => {
        if (res.body.success) {
          this.setState({
            token: res.body.token,
          });
          this.queryTokenList();
        }
        Utils.analyticsEvent({
          category: "Account",
          action: "Create API Access Token",
          label: res.body.success ? "Success" : "Failed",
          name: this.state.appName,
          expire: expire,
          error: res.body.error,
        });
      })
      .catch((err) => {
        Logger.error(`Account, Create API Access Token failed ${err}`, {
          name: this.state.appName,
          expire: expire,
          error: err,
        });
      });
  }

  private clickedRevoke(name: string, ctime: number) {
    if (
      !confirm(i18n.t("alert_delete_access_token", { name, ns: "account" }))
    ) {
      return;
    }
    Utils.getApi("put", "/db/user/token/revoke")
      .send({
        ctime: ctime,
      })
      .then((res) => {
        if (res.body.success) {
          this.queryTokenList();
        }
        Utils.analyticsEvent({
          category: "Account",
          action: "Revoke API Access Token",
          label: res.body.success ? "Success" : "Failed",
          name: this.state.appName,
          ctime: ctime,
          error: res.body.error,
        });
      })
      .catch((err) => {
        Logger.error(`Account, Revoke API Access Token failed ${err}`, {
          name: this.state.appName,
          ctime: ctime,
          error: err,
        });
      });
  }

  render() {
    let colorOrange = getButtonColors(ButtonColor.Orange);
    return (
      <Translation ns="account">
        {(t) => (
          <div className="access-control-panel">
            <div className="create-access-token-form">
              <h5 className="form-title">{t("create_access_token")}</h5>
              <div className="create-token-container">
                <YatingInput
                  label={t("application_name")}
                  value={this.state.appName}
                  placeholder={t("api_name")}
                  onChange={this.onChangeAppName.bind(this)}
                />

                <YatingInput
                  label={t("expiration_date")}
                  value={this.state.expire}
                  placeholder={t("api_expired_date")}
                  onChange={this.onChangeExpire.bind(this)}
                />

                <YatingButton
                  variant="primary"
                  size="large"
                  color="main"
                  onClick={this.clickedAddToken.bind(this)}
                  disabled={this.state.appName.length === 0}
                >
                  {i18n.t("add")}
                </YatingButton>
              </div>
            </div>
            <div className="active-access-token-table">
              <h5 className="table-title">Active Access Tokens</h5>
              <div className="table-container">
                <div className="table-header">
                  <div className="header-item">{t("application_name")}</div>
                  <div className="header-item">{t("create_date")}</div>
                  <div className="header-item">{t("expiration_date")}</div>
                  <div className="header-item">{t("delete")}</div>
                </div>
                {this.getTokenList("table")}
              </div>
            </div>
            <div className="active-access-token-cards-container">
              <h5 className="active-access-token-cards-title">
                Active Access Tokens
              </h5>
              {this.getTokenList("card")}
            </div>
            <Modal open={this.state.token != undefined} size="tiny">
              <div style={styles.modalTitle}>
                <div>Access Token</div>
              </div>
              <div style={styles.modalContent}>
                <div>{this.state.token}</div>
                <div
                  style={{
                    color: Color.darkerRed,
                    marginTop: "20px",
                  }}
                >
                  {t("please_store_the_access_token_safety")}
                </div>
              </div>
              <div style={styles.modalAction}>
                <CustomButton
                  width="100px"
                  height="35px"
                  radius="20px"
                  padding="0 20px"
                  textColor={colorOrange.textColor}
                  bg={colorOrange.bg}
                  onClick={(e) => {
                    this.setState({
                      token: undefined,
                    });
                  }}
                >
                  {t("access_token_had_been_stored")}
                </CustomButton>
              </div>
            </Modal>
          </div>
        )}
      </Translation>
    );
  }
}
