import * as superagent from "superagent";
import dayjs from "dayjs";
import Config from "./Config";
import Utils from "./Utils";

enum LogType {
  trace = "trace",
  log = "log",
  debug = "debug",
  info = "info",
  warn = "warn",
  error = "error",
  fatal = "fatal",
}

interface Log {
  type: LogType;
  message: string;
  properties?: any;
  eventTime: number;
}

export default class Logger {
  private static req?: superagent.SuperAgentRequest = undefined;
  private static _logs: Log[] = undefined;

  private static get logs(): Log[] {
    try {
      if (!Logger._logs) {
        Logger._logs = JSON.parse(localStorage.getItem("logs")) || [];
      }
    } catch (e) {
      Logger._logs = [];
    } finally {
      return Logger._logs;
    }
  }

  private static set logs(logs: Log[]) {
    Logger._logs = logs;
    localStorage.setItem("logs", JSON.stringify(logs));
  }

  private static addLog(log: Log) {
    if (!Config.log) {
      return;
    }

    let logs = Logger.logs;
    logs.push(log);
    Logger.logs = logs;
    Logger.sendLogs();
  }

  private static clearLogs() {
    Logger.logs = [];
  }

  private static sendLogs() {
    if (Logger.req) {
      return;
    }

    Logger.send().then((success) => {
      setTimeout(() => {
        Logger.sendLogs();
      }, 10000);
    });
  }

  private static send(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let logs = Logger.logs;
      Logger.clearLogs();
      if (logs.length > 0) {
        Logger.req = Utils.getApi("post", "/log");
        Logger.req
          .send({
            logs: logs,
            uploadTime: dayjs().unix(),
          })
          .then((res) => {
            if (!res.body.success) {
              let newLogs = Logger.logs;
              logs = logs.concat(newLogs);
              Logger.logs = logs;
              resolve(false);
            } else {
              resolve(true);
            }
          })
          .catch((err) => {
            let newLogs = Logger.logs;
            logs = logs.concat(newLogs);
            Logger.logs = logs;
            resolve(false);
          })
          .finally(() => {
            Logger.req = undefined;
          });
      } else {
        resolve(true);
      }
    });
  }

  static log(msg: string, properties?: any) {
    let log: Log = {
      type: LogType.log,
      message: msg,
      properties: properties,
      eventTime: dayjs().unix(),
    };
    Logger.addLog(log);
  }

  static info(msg: string, properties?: any) {
    let log: Log = {
      type: LogType.info,
      message: msg,
      properties: properties,
      eventTime: dayjs().unix(),
    };
    Logger.addLog(log);
  }

  static warn(msg: string, properties?: any) {
    let log: Log = {
      type: LogType.warn,
      message: msg,
      properties: properties,
      eventTime: dayjs().unix(),
    };
    Logger.addLog(log);
  }

  static error(msg: string, properties?: any) {
    let log: Log = {
      type: LogType.error,
      message: msg,
      properties: properties,
      eventTime: dayjs().unix(),
    };
    Logger.addLog(log);
  }

  static fatal(msg: string, properties?: any) {
    let log: Log = {
      type: LogType.fatal,
      message: msg,
      properties: properties,
      eventTime: dayjs().unix(),
    };
    Logger.addLog(log);
  }
}
