import React from "react";
import { Translation } from "react-i18next";
import Config from "../../Config";
import { ButtonColor } from "../../GlobalDefine";
import { ActionButton } from "../../ui/Component";

import finishedImg from "../../../assets/img/animation-loading-complex.gif";

const StreamingEndView = (props: any) => (
  <Translation ns="streaming">
    {(t) => (
      <div id="streaming-task-page" className="white-background">
        <div id="finished-page">
          <img src={finishedImg} />
          <div className="description">
            <span>{t("thank_for_your_using")}</span>
            <span>{t("yating_streaming_subtitle")}</span>
          </div>
          <div className="actions">
            <ActionButton
              color={ButtonColor.Orange}
              content={t("return_to_all_transcripts")}
              style={{
                width: "160px",
                margin: "24px 0px",
              }}
              onClick={() => {
                window.location.replace(
                  `${window.location.origin}${Config.baseName}`,
                );
              }}
            />
            <ActionButton
              color={ButtonColor.Orange}
              content={t("streaming_again")}
              style={{
                width: "160px",
                margin: "0px",
              }}
              onClick={() => {
                window.location.reload();
              }}
            />
          </div>
        </div>
      </div>
    )}
  </Translation>
);

export default StreamingEndView;
