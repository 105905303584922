import React from "react";
import { Translation } from "react-i18next";
import AccessTokenPanel from "../../ui/AccessTokenPanel";
import Utils from "../../Utils";

// YatingAPI Tab
export default class YatingAPITab extends React.Component<any, any> {
  render(): React.ReactNode {
    return (
      <Translation ns="account">
        {(t) => (
          <div className="yating-api-tab">
            <h5 className="access-token-title">API Access Token</h5>

            <p className="body01 access-token-introduction">
              {t("access_token_introduction")}
              <a
                href="https://docs.google.com/document/d/1_hQI6odfxvZHwzLXc63dPNERhx8zDS9dbUJ2HRjLN5o/edit?usp=sharing"
                target="_blank"
                onClick={(e) => {
                  Utils.sendAnalyticsOutboundClickEvent(e);
                }}
              >
                {t("developer_document")}
              </a>
              。
            </p>

            <AccessTokenPanel />
          </div>
        )}
      </Translation>
    );
  }
}
