import React from "react";
import { Translation } from "react-i18next";

import "../../assets/scss/Footer.scss";

export default class Footer extends React.Component<any, any> {
  render() {
    return (
      <Translation>
        {(t) => (
          <div className="footer">
            <a className="copy-right">© 2024 {t("yating")}</a>
            <div className="link-divider">|</div>
            <a href="https://yating.tw/" target="_blank">
              {t("yating_intelligence")}
            </a>
            <div className="link-divider">|</div>
            <a href="mailto:contact@yating.tw">contact@yating.tw</a>
          </div>
        )}
      </Translation>
    );
  }
}
