import React from "react";
import "../../assets/scss/YatingBadge.scss";
export interface BadgeProps {
  className?: string;
  color: "main" | "green";
  content: string;
}
export default class Badge extends React.Component<BadgeProps, any> {
  render() {
    const { className, content, color } = this.props;
    return (
      <div className={`${className || ""} badge badge-color-${color}`}>
        {content}
      </div>
    );
  }
}
