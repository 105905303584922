import React from "react";
import i18n from "../../i18n";
import "../../assets/scss/Announcement.scss";

async function initFirebase() {
  return await import("../RemoteConfig");
}

export default class Announcement extends React.Component<
  any,
  {
    title: string;
    buttonTitle: string;
    link: string;
  }
> {
  constructor(props: any) {
    super(props);

    this.state = {
      title: "",
      buttonTitle: "",
      link: "",
    };
  }
  async componentDidMount(): Promise<void> {
    const announcement = await (
      await initFirebase()
    ).getAnnouncement(i18n.language);
    if (announcement) {
      this.setState(announcement);
    }
  }
  render() {
    if (this.state.title) {
      return (
        <div className="announcement">
          <p>
            {this.state.title}
            <a href={this.state.link} target="_blank">
              {this.state.buttonTitle}
            </a>
          </p>
        </div>
      );
    }
    return <></>;
  }
}
