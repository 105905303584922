import React from "react";

import { ActionButton, UserIcon } from "./Component";

import DataManager from "../DataManager";
import Voice, { Permission, SummarySection } from "../Voice";
import { observer } from "mobx-react";
import Utils from "../Utils";
import { ButtonColor } from "../GlobalDefine";
import { Translation } from "react-i18next";
import i18n from "../../i18n";
import Config from "../Config";

import "../../assets/scss/FileSummaryWidget.scss";
import "../external/DragDropTouch.js";

import noSummaryImg from "../../assets/img/no-record-taxtile.png";
import processingSummaryImg from "../../assets/img/animation-summary.gif";
import deleteIcon from "../../assets/img/icon-delete.svg";
import moveIcon from "../../assets/img/icon-move.svg";

interface SectionProps {
  section: SummarySection;
  isEditable: boolean;
  isTitleDropable: boolean;
  autoFocusTitle: boolean;

  onDragStart: () => void;
  onDragMove: (ele: HTMLElement) => void;
  onDragEnd: () => void;
  onUpdateTitle: (newTitle: string) => void;
  onAddSection: (title: string, pos: number) => void;
  onRemoveSection: () => void;
  onMoveSummary: (startTime: number, newPos: number) => void; // newPos = -1 means append at the end of previous section
  onDeleteSummary: (pos: number, startTime: number) => void;
}

interface SectionState {
  clickedIdx: number;
}

class Section extends React.Component<SectionProps, SectionState> {
  private _titleInput: React.RefObject<HTMLDivElement>;
  private _titleContainer: React.RefObject<HTMLDivElement>;
  private _newTitleInput: React.RefObject<HTMLDivElement>;

  constructor(props: any) {
    super(props);
    this.state = {
      clickedIdx: 0,
    };
    this._titleInput = React.createRef<HTMLDivElement>();
    this._titleContainer = React.createRef<HTMLDivElement>();
    this._newTitleInput = React.createRef<HTMLDivElement>();
  }

  componentDidMount() {
    if (this.props.autoFocusTitle) {
      this._titleInput.current?.focus();
    }
  }

  componentDidUpdate(prevProps: SectionProps, prevStats: SectionState) {
    if (
      !prevProps.isEditable &&
      this.props.isEditable &&
      this.props.autoFocusTitle
    ) {
      this._titleInput.current?.focus();
    }
  }

  render() {
    let hideTitle =
      this.props.isEditable &&
      this.props.section.title.length === 0 &&
      this.state.clickedIdx !== 0;
    return (
      <Translation ns="file">
        {(t) => (
          <>
            {hideTitle ? null : (
              <div className={`section-title-container`}>
                <div></div>
                <div
                  ref={this._titleContainer}
                  className={`section-title`}
                  onDragEnter={(event) => {
                    this.props.onDragMove(event.target as HTMLElement);
                    (event.target as HTMLDivElement).classList.add(
                      "title-drag-over",
                    );
                  }}
                  onDragLeave={(event) => {
                    (event.target as HTMLDivElement).classList.remove(
                      "title-drag-over",
                    );
                  }}
                  onDragOver={(e) => {
                    e.preventDefault();
                  }}
                  onDrop={(event) => {
                    (event.target as HTMLDivElement).classList.remove(
                      "title-drag-over",
                    );
                    let startTime = event.dataTransfer.getData("startTime");
                    if (startTime) {
                      this.props.onMoveSummary(Number.parseInt(startTime), -1);
                    }
                    this.setState({
                      clickedIdx: -1,
                    });
                    this.props.onDragEnd();
                  }}
                >
                  {this.props.isEditable ? (
                    <div
                      ref={this._titleInput}
                      contentEditable={this.props.isEditable}
                      suppressContentEditableWarning={true}
                      placeholder={t("section_title")}
                      onBlur={(event) => {
                        const name = event.target.textContent.trim();
                        this.setState(
                          {
                            clickedIdx: -1,
                          },
                          () => {
                            if (
                              name.length > 0 ||
                              !this.props.isTitleDropable
                            ) {
                              this.props.onUpdateTitle(name);
                            } else if (this.props.section.isGrouped) {
                              this.props.onRemoveSection();
                            }
                          },
                        );
                      }}
                      onKeyDown={(event) => {
                        if (event.keyCode === 13) {
                          // enter
                          (event.target as HTMLDivElement).blur();
                        } else if (event.keyCode === 8) {
                          //back
                          if (
                            (event.target as HTMLDivElement).innerText
                              .length === 0
                          ) {
                            (event.target as HTMLDivElement).blur();
                          }
                        } else if (event.keyCode === 27) {
                          //esc
                          (event.target as HTMLDivElement).innerText =
                            this.props.section.title;
                          (event.target as HTMLDivElement).blur();
                        }
                      }}
                    >
                      {this.props.section.title}
                    </div>
                  ) : (
                    this.props.section.title
                  )}
                </div>
              </div>
            )}
            {this.props.section.utterances.map((startTime, idx) => {
              let contentContainer = React.createRef<HTMLDivElement>();
              let divider = React.createRef<HTMLDivElement>();
              let content = React.createRef<HTMLDivElement>();
              return (
                <React.Fragment key={idx}>
                  {this.state.clickedIdx === idx && idx > 0 ? (
                    <div className={`section-title-container`}>
                      <div></div>
                      <div className="section-title">
                        <div
                          ref={this._newTitleInput}
                          contentEditable={this.props.isEditable}
                          suppressContentEditableWarning={true}
                          placeholder={t("section_title")}
                          onBlur={(event) => {
                            const name = event.target.textContent.trim();
                            if (name.length > 0) {
                              this.props.onAddSection(name, idx);
                            } else {
                              setTimeout(() => {
                                if (this.state.clickedIdx === idx) {
                                  this.setState({
                                    clickedIdx: -1,
                                  });
                                }
                              }, 150);
                            }
                          }}
                          onKeyDown={(event) => {
                            if (event.keyCode === 13) {
                              // enter
                              (event.target as HTMLDivElement).blur();
                            } else if (event.keyCode === 8) {
                              //back
                              if (
                                (event.target as HTMLDivElement).innerText
                                  .length === 0
                              ) {
                                (event.target as HTMLDivElement).blur();
                              }
                            }
                          }}
                        />
                      </div>
                    </div>
                  ) : null}
                  {idx === 0 && !hideTitle ? null : (
                    <div
                      ref={divider}
                      className={`summary-content-divider`}
                      style={{
                        display: this.state.clickedIdx === idx ? "none" : "",
                      }}
                      onClick={() => {
                        if (this.props.isEditable) {
                          this.setState(
                            {
                              clickedIdx: idx,
                            },
                            () => {
                              if (idx > 0) {
                                this._newTitleInput.current?.focus();
                              } else {
                                this._titleInput.current?.focus();
                              }
                            },
                          );
                        }
                      }}
                    >
                      <div></div>
                      <div className="content">{t("insert_title")}</div>
                    </div>
                  )}
                  <div ref={content} className={`summary-content-container`}>
                    <div className={`left-container`}>
                      <div className={`speaker-container`}>
                        <UserIcon
                          imgUrl={""}
                          name={this.props.section.speakers[idx]}
                          email={""}
                          size="25px"
                          fontSize="14px"
                        />
                        <div className="speaker-name">
                          {this.props.section.speakers[idx]}
                        </div>
                      </div>
                      <div
                        className="action-delete"
                        onClick={() => {
                          if (this.props.isEditable) {
                            this.props.onDeleteSummary(idx, startTime);
                          }
                        }}
                      >
                        <img src={deleteIcon} />
                      </div>
                    </div>
                    <div
                      ref={contentContainer}
                      className="content"
                      draggable={this.props.isEditable && !Utils.isUsingTouch}
                      onMouseEnter={() => {
                        if (this.props.isEditable) {
                          divider.current?.classList.add("hover");
                        }
                      }}
                      onMouseLeave={() => {
                        divider.current?.classList.remove("hover");
                      }}
                      onDragStart={(event) => {
                        divider.current?.classList.remove("hover");
                        event.dataTransfer.setData(
                          "startTime",
                          this.props.section.utterances[idx].toString(),
                        );
                        content.current?.classList.add("draging");
                        this.props.onDragStart();
                      }}
                      onDragEnd={() => {
                        content.current?.classList.remove("draging");
                        this.props.onDragEnd();
                      }}
                      onDragEnter={(event) => {
                        this.props.onDragMove(event.target as HTMLElement);
                        divider.current?.classList.add("drag-over");
                        if (idx === 0) {
                          this._titleContainer.current?.classList.add(
                            "drag-over",
                          );
                        }
                      }}
                      onDragLeave={() => {
                        divider.current?.classList.remove("drag-over");
                        if (idx === 0) {
                          this._titleContainer.current?.classList.remove(
                            "drag-over",
                          );
                        }
                      }}
                      onDragOver={(e) => {
                        e.preventDefault();
                      }}
                      onDrop={(event) => {
                        divider.current?.classList.remove("drag-over");
                        content.current?.classList.remove("draging");
                        if (idx === 0) {
                          this._titleContainer.current?.classList.remove(
                            "drag-over",
                          );
                        }
                        let startTime = event.dataTransfer.getData("startTime");
                        if (startTime) {
                          this.props.onMoveSummary(
                            Number.parseInt(startTime),
                            idx,
                          );
                        }
                        this.setState({
                          clickedIdx: -1,
                        });
                        this.props.onDragEnd();
                      }}
                    >
                      <div className="content-inner-container">
                        <div className="content-text">
                          {this.props.section.contents[idx]}
                        </div>

                        <div
                          className="action-move"
                          draggable={this.props.isEditable}
                          onTouchStart={() => {
                            if (contentContainer.current) {
                              contentContainer.current.draggable =
                                this.props.isEditable;
                            }
                          }}
                          onTouchEnd={() => {
                            if (contentContainer.current) {
                              contentContainer.current.draggable =
                                this.props.isEditable && !Utils.isUsingTouch;
                            }
                          }}
                          onDragStart={(event) => {
                            divider.current?.classList.remove("hover");
                            event.dataTransfer.setData(
                              "startTime",
                              this.props.section.utterances[idx].toString(),
                            );
                            event.dataTransfer.setDragImage(
                              contentContainer.current,
                              contentContainer.current.clientWidth - 25,
                              0,
                            );
                            content.current?.classList.add("draging");
                            this.props.onDragStart();
                          }}
                          onDragEnd={() => {
                            content.current?.classList.remove("draging");
                            this.props.onDragEnd();
                          }}
                        >
                          <img src={moveIcon} draggable={false} />
                        </div>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              );
            })}
          </>
        )}
      </Translation>
    );
  }
}

interface Props {
  file?: Voice;
  show: boolean;
  isEditing: boolean;
  container: HTMLElement;

  onRunSummary: () => void;
}

interface State {}

@observer
export default class FileSummaryWidget extends React.Component<Props, State> {
  private _container: React.RefObject<HTMLDivElement>;

  private eventCount = {
    addTitle: 0, // add new section
    updateTitle: 0, // update title of a section
    removeTitle: 0, // remove a section
    rearrange: 0, // re-arrang an utternace
    remove: 0, // remove an utternace
  };

  constructor(props: any) {
    super(props);
    this._container = React.createRef<HTMLDivElement>();
  }

  public resetEventCount() {
    this.eventCount.addTitle = 0;
    this.eventCount.updateTitle = 0;
    this.eventCount.rearrange = 0;
    this.eventCount.remove = 0;
  }

  public getEventCount(): any {
    let result = {};
    Object.assign(result, this.eventCount);
    return result;
  }

  private findUtteranceLocation(
    summaries: SummarySection[],
    utteranceStartTime: number,
  ) {
    for (let i = 0; i < summaries.length; i++) {
      const section = summaries[i];
      for (let j = 0; j < section.utterances.length; j++) {
        if (section.utterances[j] === utteranceStartTime) {
          return {
            section: i,
            idx: j,
          };
        }
      }
    }
    return undefined;
  }

  private scrollToElement(ele: HTMLElement) {
    if (!ele || !this.props.container) {
      return;
    }

    // auto scroll
    const container = this.props.container;
    const scrollUp = (ele as HTMLElement).offsetTop - 200 - container.scrollTop;
    const scrollDown =
      (ele as HTMLElement).offsetTop +
      (ele as HTMLElement).clientHeight -
      200 -
      container.scrollTop -
      container.clientHeight +
      200;
    if (ele) {
      if (ele.getBoundingClientRect().top < 150 && scrollUp < 0) {
        // going up
        container.scrollBy({
          top: scrollUp,
          left: 0,
          behavior: "smooth",
        });
      } else if (
        ele.getBoundingClientRect().bottom > container.clientHeight - 100 &&
        scrollDown > 0
      ) {
        // going down
        container.scrollBy({
          top: scrollDown,
          left: 0,
          behavior: "smooth",
        });
      }
    }
  }

  render() {
    if (!this.props.file) {
      return null;
    }

    if (!this.props.show) {
      return null;
    }

    // 可能的狀況:
    // * 逐字稿太短， -> 顯示訊息 ?
    // * 還沒執行過 auto-summary -> 點按鈕執行 (可編輯) (不可編輯)
    // * 正在執行 auto-summary -> 顯示進度
    // * 執行後，沒有內容 -> 顯示訊息
    // * 執行後，未編輯前 -> 顯示訊息引導編輯，預設顯示 auto-summary
    // * 編輯後

    let summary = this.props.file.summary;
    let letterCounts = this.props.file.letterCounts;

    // * 逐字稿太短， -> 顯示訊息 ?
    if (letterCounts < 500 && summary.length === 0) {
      return (
        <Translation ns="file">
          {(t) => (
            <div className="no-summary-message-container">
              <div className="message-action-container">
                <div className="title number">
                  <span>OO</span>
                  <span style={{ fontWeight: "bold" }}>PS!</span>
                </div>
                <div className="main-message">
                  {t("no_highlight_because_of_little_content", {
                    appName: i18n.t(Config.APP_NAME),
                  })}
                </div>
              </div>
              <img src={noSummaryImg} />
            </div>
          )}
        </Translation>
      );
    }

    let permission = this.props.file.permission;
    let langShortName = DataManager.instance.getLanguageShortName(
      this.props.file.lang,
    );
    // * 還沒執行過 auto-summary -> 點按鈕執行
    if (
      !this.props.file.hasAutoSummary &&
      this.props.file.summaryProgress >= 100 &&
      summary.length === 0
    ) {
      return (
        <Translation ns="file">
          {(t) => (
            <div className="no-summary-message-container">
              <div className="message-action-container">
                <div className="title number">
                  <span>OO</span>
                  <span style={{ fontWeight: "bold" }}>PS!</span>
                </div>
                {!this.props.file.isAutoSummarySupported ? (
                  t("teach_yating", {
                    langShortName,
                    appName: i18n.t(Config.APP_NAME),
                  })
                ) : permission >= Permission.Edit ? (
                  // (可編輯)
                  <>
                    <div className="main-message">
                      {t("no_highlight_yet")}
                      {t("yating_can_help_organize", {
                        appName: i18n.t(Config.APP_NAME),
                      })}
                    </div>
                    <ActionButton
                      color={ButtonColor.Orange}
                      content={t("ask_yating_to_organize", {
                        appName: i18n.t(Config.APP_NAME),
                      })}
                      style={{
                        margin: "0px",
                        height: "40px",
                        padding: "10px",
                        lineHeight: "0px",
                      }}
                      onClick={() => {
                        this.props.file.runAutoSummary().then(() => {
                          this.props.onRunSummary();
                        });
                      }}
                    />
                  </> // (不可編輯)
                ) : (
                  <div className="main-message">
                    {t("the_transcript_contains_no_highlight")}
                  </div>
                )}
              </div>
              <img src={noSummaryImg} />
            </div>
          )}
        </Translation>
      );
    }

    // * 正在執行 auto-summary -> 顯示進度
    if (this.props.file.summaryProgress < 100 && summary.length === 0) {
      return (
        <Translation ns="file">
          {(t) => (
            <div className="no-summary-message-container">
              <div className="message-action-container">
                <div className="title">{t("organizing")}</div>
                <div className="main-message">
                  {t("summarizing_transcript", {
                    appName: i18n.t(Config.APP_NAME),
                  })}
                </div>
                <div className="progress-widget">
                  <div className="progress-container">
                    <div
                      className="progress"
                      style={{
                        width: `${this.props.file.summaryProgress}%`,
                      }}
                    />
                  </div>
                  <div className="number">{`${this.props.file.summaryProgress}%`}</div>
                </div>
              </div>
              <img src={processingSummaryImg} />
            </div>
          )}
        </Translation>
      );
    }

    // * 執行後，沒有內容 -> 顯示訊息
    if (summary.length === 0) {
      return (
        <Translation ns="file">
          {(t) => (
            <div className="no-summary-message-container">
              <div className="message-action-container">
                <div className="title number">
                  <span>OO</span>
                  <span style={{ fontWeight: "bold" }}>PS!</span>
                </div>
                <div className="main-message">
                  {this.props.file.isAutoSummarySupported
                    ? t("yating_can_not_find_highlight", {
                        appName: i18n.t(Config.APP_NAME),
                      })
                    : t("teach_yating", { appName: i18n.t(Config.APP_NAME) })}
                </div>
              </div>
              <img src={noSummaryImg} />
            </div>
          )}
        </Translation>
      );
    }

    let userEdited = false;
    summary.forEach((item) => {
      if (item.userHighlight.includes(true)) {
        userEdited = true;
      }
    });

    return (
      <>
        {/** 執行後，未編輯前 -> 顯示訊息引導編輯，預設顯示 auto-summary */}
        {userEdited ? null : (
          <div className="summary-before-message">
            {i18n.t("yating_find_these_highlight", {
              ns: "file",
              appName: i18n.t(Config.APP_NAME),
            })}
          </div>
        )}

        <div
          ref={this._container}
          className={`summary-container${this.props.isEditing ? " editing" : ""}${
            Utils.isUsingTouch ? " use-touch" : ""
          }`}
        >
          <div className="background">
            {summary.map((section, idx) => {
              return (
                <Section
                  key={idx}
                  section={section}
                  isEditable={this.props.isEditing}
                  isTitleDropable={idx !== 0}
                  autoFocusTitle={section.title.length === 0 && idx === 0}
                  onDragStart={() => {
                    this._container.current?.classList.add("is-dragging");
                  }}
                  onDragMove={(ele) => {
                    setTimeout(
                      () => {
                        this.scrollToElement(ele);
                      },
                      Utils.isUsingTouch ? 300 : 50,
                    );
                  }}
                  onDragEnd={() => {
                    this._container.current?.classList.remove("is-dragging");
                  }}
                  onUpdateTitle={(newTitle) => {
                    if (newTitle === section.title) {
                      return;
                    }

                    this.eventCount.updateTitle++;
                    summary[idx].title = newTitle;
                    this.props.file.updateSummarySections(summary);
                  }}
                  onAddSection={(title, pos) => {
                    this.eventCount.addTitle++;
                    let add: SummarySection = {
                      title: title,
                      utterances: [],
                    };
                    for (let i = pos; i < section.utterances.length; i++) {
                      add.utterances.push(section.utterances[i]);
                    }
                    section.utterances.splice(
                      pos,
                      section.utterances.length - pos,
                    );
                    summary.splice(idx + 1, 0, add);
                    this.props.file.updateSummarySections(summary);
                  }}
                  onRemoveSection={() => {
                    this.eventCount.removeTitle++;
                    if (idx === 0) {
                      return;
                    }
                    summary[idx - 1].utterances = summary[
                      idx - 1
                    ].utterances.concat(section.utterances);
                    summary.splice(idx, 1);
                    this.props.file.updateSummarySections(summary);
                  }}
                  onMoveSummary={(startTime, newPos) => {
                    console.log(newPos);
                    this.eventCount.rearrange++;
                    if (!section.isGrouped) {
                      let oldSectionLoc = this.findUtteranceLocation(
                        summary,
                        startTime,
                      );
                      // add the whole section to users
                      summary[idx].isGrouped = true;
                      // re-arange array
                      Utils.moveArrayElement(
                        summary[idx].utterances,
                        oldSectionLoc.idx,
                        newPos > oldSectionLoc.idx ? newPos - 1 : newPos,
                      );
                    } else {
                      let oldSectionLoc = this.findUtteranceLocation(
                        summary,
                        startTime,
                      );
                      if (
                        newPos >= 0 &&
                        oldSectionLoc &&
                        oldSectionLoc.section === idx &&
                        oldSectionLoc.idx !== newPos
                      ) {
                        // re-arange array
                        Utils.moveArrayElement(
                          summary[idx].utterances,
                          oldSectionLoc.idx,
                          newPos > oldSectionLoc.idx ? newPos - 1 : newPos,
                        );
                      } else {
                        // remove from old
                        summary[oldSectionLoc.section].utterances.splice(
                          oldSectionLoc.idx,
                          1,
                        );

                        // add to new
                        if (newPos >= 0) {
                          summary[idx].utterances.splice(newPos, 0, startTime);
                        } else if (idx > 0) {
                          summary[idx - 1].utterances.splice(
                            summary[idx - 1].utterances.length,
                            0,
                            startTime,
                          );
                        } else if (newPos < 0) {
                          let newSection: SummarySection = {
                            title: "",
                            utterances: [startTime],
                          };
                          summary.splice(0, 0, newSection);
                        }
                      }
                    }
                    this.props.file.updateSummarySections(summary);
                  }}
                  onDeleteSummary={(pos, startTime) => {
                    this.eventCount.remove++;
                    summary[idx].utterances.splice(pos, 1);
                    this.props.file.removeUtteranceUserHightlight(startTime);
                    this.props.file.removeUtteranceAutoSummary(startTime);
                    this.props.file.updateSummarySections(summary);
                  }}
                />
              );
            })}
          </div>
        </div>
      </>
    );
  }
}
