import Modal from "semantic-ui-react/dist/commonjs/modules/Modal";
import React from "react";
import { Translation } from "react-i18next";
import i18n from "../../i18n";
import User from "../User";
import Utils from "../Utils";

import "../../assets/scss/DeleteAccountWindow.scss";

interface Props {
  show: boolean;
  onClose: () => void;
  onDeleteAccount: () => void;
}

interface State {
  isShowHelper: boolean;
  isEmailMatched: boolean;
}

export default class DeleteAccountWindow extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      isShowHelper: false,
      isEmailMatched: false,
    };
  }

  validateEmail(inputValue: string) {
    if (inputValue === User.instance.profile.email) {
      this.setState({
        isShowHelper: false,
        isEmailMatched: true,
      });
    } else {
      this.setState({
        isShowHelper: true,
        isEmailMatched: false,
      });
    }
  }
  render() {
    return (
      <Translation ns="account">
        {(t) => (
          <Modal open={this.props.show} size="small" dimmer="blurring">
            <div className="modal-container">
              <p className="modal-title">{t("delete_account_modal_title")}</p>

              <p className="modal-delete-account-note">
                {t("delete_account_modal_note")}
              </p>
              <p className="modal-account-text">
                {t("account")} : {User.instance.profile.email}
              </p>
              <div className="modal-input-container">
                <input
                  type="text"
                  placeholder={t("delete_account_modal_placeholder")}
                  onChange={(event) => this.validateEmail(event.target.value)}
                  className={this.state.isShowHelper && `warning`}
                />
                <p className={`input-helper-text`}>
                  {this.state.isShowHelper && t("email_not_match")}
                </p>
              </div>

              <div className="modal-button-container">
                <button
                  className="delete-btn"
                  disabled={!this.state.isEmailMatched}
                  onClick={() => {
                    this.props.onDeleteAccount();
                  }}
                >
                  {t("delete_account_permanently")}
                </button>
                <button
                  className="cancel-btn"
                  onClick={() => {
                    Utils.analyticsEvent({
                      category: "Delete Account",
                      action: "Cancel Request",
                    });
                    this.props.onClose();
                  }}
                >
                  {i18n.t("cancel")}
                </button>
              </div>
            </div>
          </Modal>
        )}
      </Translation>
    );
  }
}
