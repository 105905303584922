import React from "react";
import { Link } from "react-router-dom";
import Popup from "semantic-ui-react/dist/commonjs/modules/Popup";
import Modal from "semantic-ui-react/dist/commonjs/modules/Modal";
import { ActionButton, PopupMenu, PopupMenuItem } from "../ui/Component";
import { ModalHeader, ModalActions } from "../ui/Component";
import ExportWindow from "../ui/ExportWindow";
import ShareSettingWindow from "../ui/ShareSettingWindow";
import FolderSelector from "../ui/FolderSelector";
import { ButtonColor, Styles } from "../GlobalDefine";
import DataManager, { folderMap, FolderType } from "../DataManager";
import Voice, { Permission } from "../Voice";
import Utils, { RedirectTo } from "../Utils";
import User, { UserLevel } from "../User";
import { Translation } from "react-i18next";
import i18n from "../../i18n";
import Config from "../Config";

import "../../assets/scss/FilePageHeader.scss";
import backIcon from "../../assets/img/icon-arrow-left.svg";
import searchIcon from "../../assets/img/icon-search.svg";
import moreIcon from "../../assets/img/icon-more.svg";

interface Props {
  isLogined: boolean;
  folderId: string;
  folderName: string;
  folderType: FolderType;
  file?: Voice;
  history?: any;
  isEditing: boolean;
  searchedWord: string;

  onEdit: () => void;
  onEditCompleted: () => void;
  onSearch: (keyword: string) => void;
  onLoadingStatusChanged: (isLoading: boolean) => void;
}

interface State {
  openPopup: boolean;
  searchInputFocused: boolean;
  searchedWord: string;
  focusedUtteranceTime: number;

  isEditingButtonLoading: boolean; // true when start / complete editing
  onShare: boolean; // open share setting UI
  onExport: boolean; // open export UI
  onMove: boolean; // open folder selector
  onDelete: boolean; // open confirm delete
}

export default class FilePageHeader extends React.Component<Props, State> {
  private isComponentMounted: boolean;
  private _searchInput: React.RefObject<HTMLInputElement>;

  constructor(props: any) {
    super(props);
    this.state = {
      openPopup: false,
      searchInputFocused: false,
      searchedWord: props.searchedWord,
      focusedUtteranceTime: 0,
      isEditingButtonLoading: false,
      onShare: false,
      onExport: false,
      onMove: false,
      onDelete: false,
    };
    this._searchInput = React.createRef<HTMLInputElement>();
  }

  componentDidMount() {
    this.isComponentMounted = true;
    window.addEventListener("resize", this.onResize.bind(this));
  }

  componentWillUnmount() {
    this.isComponentMounted = false;
    window.removeEventListener("resize", this.onResize.bind(this));
  }

  componentDidUpdate(prevProps: Props, prevStats: State) {
    if (this.props.searchedWord !== prevProps.searchedWord) {
      this.setState(
        {
          searchedWord: this.props.searchedWord,
        },
        () => {
          this._searchInput.current?.focus();
        },
      );
    }
  }

  public handleKeyDownListener(e: KeyboardEvent): boolean {
    return true;
  }

  public handleKeyUpListener(e: KeyboardEvent): boolean {
    if (e.keyCode === 27) {
      // esc
      this.setState({
        onShare: false,
        onExport: false,
        onMove: false,
        onDelete: false,
      });
    }
    return true;
  }

  private onResize() {
    this.forceUpdate();
  }

  private onEdit() {
    this.setState({
      isEditingButtonLoading: true,
    });
    this.props.file.startEditing().then((success) => {
      if (this.isComponentMounted) {
        this.setState({
          isEditingButtonLoading: false,
        });
        if (success) {
          this.props.onEdit();
        }
      }
    });
  }

  private onCompleteEdit() {
    this.setState({
      isEditingButtonLoading: true,
    });
    this.props.file.completeEdit().then((success) => {
      if (this.isComponentMounted) {
        this.setState({
          isEditingButtonLoading: false,
        });
        if (success) {
          this.props.onEditCompleted();
        }
      }
    });
  }

  private getFolderInfo(): any {
    if (this.props.folderType !== undefined) {
      const meta = folderMap.get(this.props.folderType);
      if (this.props.folderType === FolderType.Folder) {
        return {
          url: `${meta.pathname}?eid=${this.props.folderId}`,
          title: this.props.folderName,
          historyBack: true,
        };
      } else {
        return {
          url: meta.pathname,
          title: meta.title,
          historyBack: true,
        };
      }
    } else if (!this.props.isLogined) {
      return undefined;
    } else {
      //detault go back to All folder
      const meta = folderMap.get(FolderType.All);
      return {
        url: meta.pathname,
        title: meta.title,
      };
    }
  }

  private getMoreActions(): React.ReactElement {
    return (
      <Translation>
        {(t) => (
          <Popup
            basic
            hideOnScroll
            position="bottom left"
            on="focus"
            style={Styles.popupContainer}
            onClose={() => {
              this.setState({
                openPopup: false,
              });
            }}
            open={this.state.openPopup}
            trigger={
              Utils.isPhone ? (
                <img
                  src={moreIcon}
                  className="more-actions-btn"
                  onClick={() => {
                    this.setState((prevProps) => ({
                      openPopup: !prevProps.openPopup,
                    }));
                  }}
                />
              ) : (
                <ActionButton
                  color={ButtonColor.GrayOutline}
                  content={i18n.t("menu")}
                  style={{
                    height: "35px",
                    width: "85px",
                    padding: "0 0 0 10px",
                  }}
                  disabled={this.state.isEditingButtonLoading}
                  onClick={() => {
                    this.setState((prevProps) => ({
                      openPopup: !prevProps.openPopup,
                    }));
                  }}
                  endIcon={moreIcon}
                />
              )
            }
          >
            <PopupMenu>
              <PopupMenuItem
                onClick={(event) => {
                  this.setState({
                    openPopup: false,
                    onShare: true,
                  });
                  Utils.analyticsEvent({
                    category: "Share",
                    action: "Open share setting",
                    page: "File Page",
                    is_owner: this.props.file.is_owner,
                    permission: Permission[this.props.file.permission],
                    duration: this.props.file.duration
                      ? this.props.file.duration
                      : -1, //-1 表示沒有voice 沒有時間長度
                    file_name: this.props.file.name,
                  });
                }}
              >
                {t("share")}
              </PopupMenuItem>
              <PopupMenuItem
                onClick={(event) => {
                  this.setState({
                    openPopup: false,
                    onExport: true,
                  });
                  Utils.analyticsEvent({
                    category: "Export",
                    action: "Open export",
                    page: "File Page",
                    is_owner: this.props.file.is_owner,
                    permission: Permission[this.props.file.permission],
                    duration: this.props.file.duration
                      ? this.props.file.duration
                      : -1, //-1 表示沒有voice 沒有時間長度
                    file_name: this.props.file.name,
                  });
                }}
              >
                {t("export")}
              </PopupMenuItem>

              <PopupMenuItem
                onClick={(event) => {
                  this.setState({
                    openPopup: false,
                  });
                  this.props.onLoadingStatusChanged(true);
                  this.props.file.downloadAudio().then((success) => {
                    this.props.onLoadingStatusChanged(false);
                  });
                }}
              >
                {t("export_audio")}
              </PopupMenuItem>

              {this.props.file.canMove ? (
                <PopupMenuItem
                  onClick={(event) => {
                    this.setState({
                      openPopup: false,
                      onMove: true,
                    });
                  }}
                >
                  {t("move")}
                </PopupMenuItem>
              ) : null}
              {this.props.file.canDelete &&
              Config.HideDeleteTranscribeButton ? (
                <PopupMenuItem
                  onClick={(event) => {
                    this.setState({
                      openPopup: false,
                      onDelete: true,
                    });
                  }}
                >
                  {t("delete")}
                </PopupMenuItem>
              ) : null}
            </PopupMenu>
          </Popup>
        )}
      </Translation>
    );
  }

  private getShareSettingUI(): React.ReactElement {
    return (
      <ShareSettingWindow
        show={this.state.onShare}
        file={this.props.file}
        startTime={Math.floor(this.state.focusedUtteranceTime)}
        ownerPage={"File Page"}
        onClose={() => {
          this.setState({
            onShare: false,
          });
        }}
      />
    );
  }

  private getExportUI(): React.ReactElement {
    return (
      <ExportWindow
        show={this.state.onExport}
        file={this.props.file}
        ownerPage={"File Page"}
        onClose={() => {
          this.setState({
            onExport: false,
          });
        }}
        onExport={() => {
          this.setState({
            onExport: false,
          });
        }}
      />
    );
  }

  private getMoveUI(): React.ReactElement {
    return (
      <Translation>
        {(t) => (
          <Modal open={this.state.onMove} size="tiny" dimmer="blurring">
            <ModalHeader title={t("moved_to_folder")} textAlign="left" />
            <FolderSelector
              onSelect={(folderId) => {
                DataManager.instance.moveVoices(
                  [this.props.file.eid],
                  folderId,
                  "File Page",
                  true,
                );
                this.setState({
                  onMove: false,
                });
              }}
            />
            <ModalActions
              actions={[
                {
                  color: ButtonColor.Default,
                  content: t("cancel"),
                  onClick: () => {
                    this.setState({
                      onMove: false,
                    });
                  },
                },
              ]}
            />
          </Modal>
        )}
      </Translation>
    );
  }

  private getDeleteUI(): React.ReactElement {
    return (
      <Translation>
        {(t) => (
          <Modal open={this.state.onDelete} size="tiny" dimmer="blurring">
            <ModalHeader title={t("delete_transcript")} textAlign="left" />
            <div className="deleting-message-container">
              <div>{t("alert_delete_transcript")}</div>
              <div>{t("alert_moved_to_the_trash_bin")}</div>
            </div>
            <ModalActions
              actions={[
                {
                  color: ButtonColor.Default,
                  content: t("cancel"),
                  onClick: () => {
                    this.setState({
                      onDelete: false,
                    });
                  },
                },
                {
                  color: ButtonColor.Orange,
                  content: t("confirm"),
                  onClick: () => {
                    DataManager.instance
                      .deleteVoices([this.props.file.eid], "File Page", true)
                      .then((success) => {
                        console.log(success);
                        if (success) {
                          if (
                            this.props.history &&
                            this.props.history.length > 2
                          ) {
                            this.props.history.goBack();
                          } else {
                            RedirectTo.instance.redirectTo(
                              RedirectTo.FrontPage,
                            );
                          }
                        }
                      });
                  },
                },
              ]}
            />
          </Modal>
        )}
      </Translation>
    );
  }

  render() {
    let folderinfo = this.getFolderInfo();

    return (
      <div
        className="file-page-header"
        style={Config.DisableAccountWidget ? { top: 0 } : {}}
      >
        {folderinfo ? (
          folderinfo.historyBack &&
          this.props.history &&
          this.props.history.length > 2 ? (
            <div
              className="previous-page"
              onClick={() => {
                this.props.history.goBack();
              }}
            >
              <img src={backIcon} className="back-btn" />
              <div>{folderinfo.title}</div>
            </div>
          ) : (
            <Link to={folderinfo.url} className="previous-page">
              <img src={backIcon} className="back-btn" />
              <div>{folderinfo.title}</div>
            </Link>
          )
        ) : (
          <div></div>
        )}
        <Translation ns="file">
          {(t) => (
            <div className="actions">
              <div className="search-container">
                <input
                  ref={this._searchInput}
                  className="search-field"
                  type="search"
                  placeholder=""
                  value={this.state.searchedWord}
                  onFocus={() => {
                    this.setState({
                      searchInputFocused: true,
                    });
                  }}
                  onBlur={(event) => {
                    this.setState({
                      searchInputFocused: false,
                    });
                  }}
                  onChange={(event) => {
                    let searchedWord = event.target.value;
                    this.setState({
                      searchedWord: searchedWord,
                    });
                    this.props.onSearch(searchedWord);
                  }}
                  onKeyUp={(event) => {
                    if (event.keyCode === 13) {
                      // enter
                      this.props.onSearch(this.state.searchedWord);
                    }
                  }}
                />
                <img
                  src={searchIcon}
                  className="search-icon"
                  style={{ position: "absolute", top: 0, left: 0 }}
                  onClick={() => {
                    this._searchInput.current?.focus();
                  }}
                />
              </div>
              {this.props.file.canEdit && (
                <ActionButton
                  color={ButtonColor.White}
                  content={
                    this.props.isEditing
                      ? this.state.isEditingButtonLoading
                        ? i18n.t("saving")
                        : t("done_editing")
                      : this.state.isEditingButtonLoading
                        ? t("loading")
                        : t("edit")
                  }
                  style={{
                    height: "35px",
                    width: this.props.isEditing ? "120px" : "85px",
                  }}
                  disabled={this.state.isEditingButtonLoading}
                  onClick={() => {
                    if (this.props.isEditing) {
                      this.onCompleteEdit();
                    } else {
                      this.onEdit();
                    }
                  }}
                />
              )}
              {this.getMoreActions()}
            </div>
          )}
        </Translation>
        {this.getShareSettingUI()}
        {this.getExportUI()}
        {this.getMoveUI()}
        {this.getDeleteUI()}
      </div>
    );
  }
}
