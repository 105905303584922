import Modal from "semantic-ui-react/dist/commonjs/modules/Modal";
import React from "react";
import { Translation } from "react-i18next";
import i18n from "../../i18n";

import "../../assets/scss/DeleteAccountErrorAlert.scss";
import errorIcon from "../../assets/img/icon_error.png";

interface Props {
  show: boolean;
  onClose: () => void;
}

export default class DeleteAccountErrorAlert extends React.Component<
  Props,
  any
> {
  render() {
    return (
      <Translation ns="account">
        {(t) => (
          <Modal open={this.props.show} size="small" dimmer="blurring">
            <div className="error-modal-container">
              <img src={errorIcon} alt="error icon" className="error-img" />
              <p className="error-desc">{t("delete_account_error")}</p>
              <button
                className="confirm-btn"
                onClick={() => this.props.onClose()}
              >
                {i18n.t("confirm")}
              </button>
            </div>
          </Modal>
        )}
      </Translation>
    );
  }
}
