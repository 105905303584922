import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import Utils from "../Utils";
import { Translation } from "react-i18next";

import "../../assets/scss/GoToAppBanner.scss";

interface Props {
  goToApp: (platform: string) => void;
}

interface State {
  isClose: boolean;
  haveClickedCloseButton: boolean;
}

class GoToAppBanner extends React.Component<
  Props & RouteComponentProps,
  State
> {
  constructor(props: any) {
    super(props);
    this.state = {
      isClose:
        !(Utils.isIOS || Utils.isAndroid) ||
        this.inBlackList() ||
        Utils.isWebInApp,
      haveClickedCloseButton: false,
    };
  }

  // TODO:
  // 當user使用app點擊付款或付款歷史紀錄時，app會開啟webview，此狀態應不顯示banner，分兩階段修改
  // 當用戶還沒有全面或大部分更新版本時，使用黑名單的機制讓這兩頁不顯示banner
  // 當用戶皆更新版本後，使用useragent的Yating webview來判斷是否是 web in app，若是則不顯示banner

  inBlackList() {
    return (
      this.props.location.pathname === "/payment" ||
      this.props.location.pathname === "/payment-history"
    );
  }

  isClose() {
    return !Utils.isIOS || this.inBlackList();
  }

  componentDidUpdate() {
    if (this.isClose() !== this.state.isClose) {
      this.setState({
        isClose: this.isClose(),
      });
    }
  }

  render() {
    const platform = Utils.isIOS ? "ios" : "android";
    return (
      <div
        className={`go-to-app-banner ${this.state.isClose || this.state.haveClickedCloseButton ? "close" : ""}`}
      >
        <div
          className="close-btn"
          onClick={() => {
            Utils.analyticsEvent({
              hitType: "event",
              category: "Navigation",
              action: "Close go-to-app banner",
              platform,
            });
            this.setState({
              isClose: true,
              haveClickedCloseButton: true,
            });
          }}
        />
        <Translation>
          {(t) => (
            <div className="go-to-app-banner-container">
              <div className="logo" />
              <span>{t("app_name")}</span>
              <p>{t("install_description")}</p>
              <button onClick={() => this.props.goToApp(platform)}>
                {t("open_app")}
              </button>
            </div>
          )}
        </Translation>
      </div>
    );
  }
}

export default withRouter(GoToAppBanner);
