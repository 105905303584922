import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

export const defaultNS = "common";
import * as common from "./locale/zh/common.json";
import * as account from "./locale/zh/account.json";
import * as file from "./locale/zh/file.json";
import * as fileList from "./locale/zh/fileList.json";
import * as liveTranskribe from "./locale/zh/liveTranskribe.json";
import * as paymentHistory from "./locale/zh/paymentHistory.json";
import * as payment from "./locale/zh/payment.json";
import * as paymentResult from "./locale/zh/paymentResult.json";
import * as streaming from "./locale/zh/streaming.json";
import * as faq from "./locale/zh/faq.json";
import * as share from "./locale/zh/share.json";
import * as upload from "./locale/zh/upload.json";
import * as exportFile from "./locale/zh/export.json";
import * as summaryFile from "./locale/zh/summary.json";

import * as common_en from "./locale/en/common.json";
import * as account_en from "./locale/en/account.json";
import * as file_en from "./locale/en/file.json";
import * as fileList_en from "./locale/en/fileList.json";
import * as liveTranskribe_en from "./locale/en/liveTranskribe.json";
import * as paymentHistory_en from "./locale/en/paymentHistory.json";
import * as payment_en from "./locale/en/payment.json";
import * as paymentResult_en from "./locale/en/paymentResult.json";
import * as streaming_en from "./locale/en/streaming.json";
import * as faq_en from "./locale/en/faq.json";
import * as share_en from "./locale/en/share.json";
import * as upload_en from "./locale/en/upload.json";
import * as exportFile_en from "./locale/en/export.json";
import * as summaryFile_en from "./locale/en/summary.json";

export const resources = {
  zh: {
    common,
    account,
    file,
    fileList,
    liveTranskribe,
    paymentHistory,
    payment,
    paymentResult,
    streaming,
    faq,
    share,
    upload,
    exportFile,
    summaryFile,
  },
  en: {
    common: common_en,
    account: account_en,
    file: file_en,
    fileList: fileList_en,
    liveTranskribe: liveTranskribe_en,
    paymentHistory: paymentHistory_en,
    payment: payment_en,
    paymentResult: paymentResult_en,
    streaming: streaming_en,
    faq: faq_en,
    share: share_en,
    upload: upload_en,
    exportFile: exportFile_en,
    summaryFile: summaryFile_en,
  },
} as const;

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    ns: ["common"],
    defaultNS,
    resources,
    fallbackLng: "en",
    detection: {
      order: ["cookie", "navigator"],
    },
  });

export default i18n;
