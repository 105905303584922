import { observer } from "mobx-react";
import React from "react";
import { BrowserRouter, Route, Switch, Redirect, Link } from "react-router-dom";
import styled from "styled-components";

import FaqPage from "./page/FaqPage";
import AccountPage from "./page/Account/AccountPage";
import FileListPage from "./page/FileListPage";
import FilePage from "./page/FilePage";
import LiveTranskribePage from "./page/LiveTranskribePage";
import StreamingTaskPage from "./page/StreamingTask/StreamingTaskPage";
import SideBar from "./ui/SideBar";
import UploadFileWindow from "./ui/UploadFileWindow";
import UploadFile from "./UploadFile";
import { MessageBoxColor } from "./GlobalDefine";
import { SiteMessageBox } from "./ui/Component";
import GoToAppBanner from "./ui/GoToAppBanner";
import Config from "./Config";
import { FolderType, folderMap } from "./DataManager";
import User from "./User";
import Utils, { SiteMessage, RedirectTo } from "./Utils";
import Logger from "./Logger";
import DataManager from "./DataManager";
import i18n from "../i18n";
import DeleteAccountPage from "./page/DeleteAccountPage";
import PaymentResultPage from "./page/PaymentResultPage";
import AccountWidget from "./ui/AccountWidget";
import Login from "./ui/Login";
import { StudioAppsButton } from "./ui/StudioNav";

import "../assets/scss/App.scss";
import "../assets/scss/Judicial.scss";
import { StudioLogoLink } from "./ui/StudioLogoLink";

const SITE_MESSAGE_DURATION = 3000;
const Body = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  min-height: 100vh;
  width: 100vw;
  font-family: "Noto Sans TC", sans-serif !important;
  background: none;
`;

@observer
class Main extends React.Component<
  React.PropsWithChildren<{
    isLogined: boolean;
    onScroll?: (event: React.UIEvent<HTMLDivElement>) => void;
  }>,
  {
    isDesktop: boolean;
  }
> {
  private isComponentMounted: boolean;

  constructor(props: any) {
    super(props);
    this.state = {
      isDesktop: Utils.isDesktop,
    };
  }

  componentDidMount() {
    this.isComponentMounted = true;
    window.onerror = function (msg, url, line, col, error) {
      Logger.fatal(`unhandled error ${msg}`, {
        url: url,
        line: line,
        col: col,
        error: error,
      });
    };
    window.addEventListener("resize", this.onResize.bind(this));
  }

  componentWillUnmount() {
    this.isComponentMounted = false;
    window.removeEventListener("resize", this.onResize.bind(this));
  }

  private onResize() {
    if (this.isComponentMounted) {
      this.setState({
        isDesktop: Utils.isDesktop,
      });
    }
  }

  private hideAccountWidget(path: string) {
    return (
      Config.DisableAccountWidget ||
      path ===
        `${Config.baseName}${LiveTranskribePage.urlParam.substring(1)}` ||
      path === `${Config.baseName}${StreamingTaskPage.urlParam.substring(1)}`
    );
  }

  render() {
    return (
      <div
        id="main"
        className={`main${this.props.isLogined ? " logined" : ""}${this.state.isDesktop ? " desktop" : ""}${
          DataManager.instance.isScreenshotMode ? " screenshot" : ""
        }`}
        onScroll={(event) => {
          if (this.props.onScroll) {
            this.props.onScroll(event);
          }
        }}
        onTouchStart={() => {
          Utils.isUsingTouch = true;
        }}
      >
        <div
          className="main-content-container"
          style={
            this.hideAccountWidget(window.location.pathname)
              ? { marginTop: 0 }
              : {}
          }
        >
          {this.props.children}
        </div>
      </div>
    );
  }
}

@observer
class SiteMessageController extends React.Component<
  {},
  {
    siteMessage?: {
      message: string;
      color: MessageBoxColor;
    };
  }
> {
  private _messageBox: React.RefObject<SiteMessageBox>;
  private hideMessageTimer: ReturnType<typeof setTimeout>;

  constructor(props: any) {
    super(props);
    this.state = {
      siteMessage: undefined,
    };
    this._messageBox = React.createRef<SiteMessageBox>();
  }

  componentDidUpdate(prevProps: any, prevStats: any) {}

  private showMessage() {
    if (this.hideMessageTimer) {
      clearTimeout(this.hideMessageTimer);
      this.hideMessageTimer = undefined;
    }
    this.setState(
      {
        siteMessage: SiteMessage.instance.message,
      },
      () => {
        this._messageBox.current?.show(() => {
          // dismiss the message
          this.hideMessageTimer = setTimeout(() => {
            this._messageBox.current?.hide(() => {
              SiteMessage.instance.clearMessage();
              this.setState({
                siteMessage: undefined,
              });
            });
            this.hideMessageTimer = undefined;
          }, SITE_MESSAGE_DURATION);
        });
      },
    );
  }

  private hideMessage() {
    if (this.hideMessageTimer) {
      clearTimeout(this.hideMessageTimer);
      this.hideMessageTimer = undefined;
    }
    this._messageBox.current?.hide(() => {
      SiteMessage.instance.clearMessage();
    });
  }

  render() {
    setTimeout(() => {
      if (!this.state.siteMessage && SiteMessage.instance.message) {
        // new message
        this.showMessage();
      } else if (
        SiteMessage.instance.message &&
        this.state.siteMessage &&
        SiteMessage.instance.message.message !== this.state.siteMessage.message
      ) {
        // updated message
        this._messageBox.current?.hide(() => {
          this.showMessage();
        });
      } else if (this.state.siteMessage && !SiteMessage.instance.message) {
        // clear message
        this.setState({
          siteMessage: undefined,
        });
      }
    }, 10);

    return (
      <div>
        {/** just for register update event */}
        <div style={{ display: "none" }}>
          {SiteMessage.instance.message
            ? SiteMessage.instance.message.message
            : ""}
        </div>
        {this.state.siteMessage ? (
          <SiteMessageBox
            ref={this._messageBox}
            color={this.state.siteMessage.color}
            message={this.state.siteMessage.message}
            onClick={() => {
              this.hideMessage();
            }}
          />
        ) : (
          ""
        )}
      </div>
    );
  }
}

@observer
class UploaderController extends React.Component<
  {
    onUploadCompleted: () => void;
  },
  {}
> {
  render() {
    return (
      <UploadFileWindow
        files={UploadFile.instance.uploadingFiles}
        status={UploadFile.instance.uploading?.status}
        progress={UploadFile.instance.uploading?.progress}
        isCompleted={UploadFile.instance.isCompleted}
        totalProgress={UploadFile.instance.progressInPercent}
        failedCount={UploadFile.instance.failedCount}
        onCancel={(idx: number) => {
          UploadFile.instance.cancel(idx);
        }}
        onCancelAll={() => {
          UploadFile.instance.cancelAll();
        }}
        onCloseInCompleted={() => {
          UploadFile.instance.reset();
          this.props.onUploadCompleted();
        }}
      />
    );
  }
}

@observer
class SideBarController extends React.Component<{}, {}> {
  private _sidebar: React.RefObject<SideBar>;

  constructor(props: any) {
    super(props);
    this._sidebar = React.createRef<SideBar>();
  }

  public handleKeyUpListener(e: KeyboardEvent): boolean {
    let pass = true;
    if (this._sidebar.current) {
      pass = pass && this._sidebar.current?.handleKeyUpListener(e);
    }
    return pass;
  }

  public resetFileInput() {
    this._sidebar.current?.resetFileInput();
  }

  render() {
    return (
      <SideBar
        ref={this._sidebar}
        folderList={DataManager.instance.folderList}
        currentFolder={DataManager.instance.voices.folderType || FolderType.All} //default is all
        currentFolderId={DataManager.instance.voices.folderId}
        isLogined={User.isLogined}
        isDataReady={User.instance.ready && DataManager.instance.ready}
      />
    );
  }
}

@observer
class RedirectController extends React.Component<{}, {}> {
  render() {
    const isLogined = User.isLogined;
    const redirectTo = isLogined ? RedirectTo.instance.redirect : undefined;

    if (redirectTo) {
      return <Redirect to={redirectTo} />;
    } else {
      return null;
    }
  }
}

export default class App extends React.Component {
  private _sidebar: React.RefObject<SideBarController>;
  private _filelistPage: React.RefObject<FileListPage>;
  private _filePage: React.RefObject<FilePage>;
  private _livePage: React.RefObject<LiveTranskribePage>;

  constructor(props: any) {
    super(props);
    this._sidebar = React.createRef<SideBarController>();
    this._filelistPage = React.createRef<FileListPage>();
    this._filePage = React.createRef<FilePage>();
    this._livePage = React.createRef<LiveTranskribePage>();
    this.handleKeyDownListener = this.handleKeyDownListener.bind(this);
    this.handleKeyUpListener = this.handleKeyUpListener.bind(this);
  }

  componentDidMount() {
    document.onkeydown = this.handleKeyDownListener;
    document.onkeyup = this.handleKeyUpListener;
    window.onbeforeunload = (): any => {
      // check uploader
      if (!UploadFile.instance.isCompleted) {
        return i18n.t("alert_is_uploading");
      }
      // check File page
      if (this._filePage.current?.onbeforeunload()) {
        return i18n.t("alert_not_save");
      }

      return null;
    };
  }

  componentWillUnmount() {
    document.onkeydown = undefined;
    document.onkeyup = undefined;
    DataManager.removeInstance();
    User.removeInstance();
  }

  private handleKeyDownListener(e: KeyboardEvent): boolean {
    let pass = true;
    if (this._filelistPage.current) {
      pass = pass && this._filelistPage.current?.handleKeyDownListener(e);
    }
    if (this._filePage.current) {
      pass = pass && this._filePage.current?.handleKeyDownListener(e);
    }
    if (this._livePage.current) {
      pass = pass && this._livePage.current?.handleKeyDownListener(e);
    }
    return pass;
  }

  private handleKeyUpListener(e: KeyboardEvent): boolean {
    let pass = true;
    if (this._sidebar.current) {
      pass = pass && this._sidebar.current?.handleKeyUpListener(e);
    }
    if (this._filelistPage.current) {
      pass = pass && this._filelistPage.current?.handleKeyUpListener(e);
    }
    if (this._filePage.current) {
      pass = pass && this._filePage.current?.handleKeyUpListener(e);
    }
    return pass;
  }

  private goToApp(platform: string): void {
    Utils.analyticsEvent({
      hitType: "event",
      category: "Navigation",
      action: "Click go to app button",
      platform,
    });

    let pathname = window.location.pathname;
    if (pathname == Config.baseName) {
      pathname = `${Config.baseName}app-launch`;
    }

    const search = window.location.search;

    // lab3, lab4 和 production目前都使用 https://applink-asr.yating.tw
    let domain = Config.universalLinkURL;

    if (search.length > 0) {
      window.location.href = `${domain}${pathname}${search}&redirect=${platform}`;
    } else {
      window.location.href = `${domain}${pathname}?redirect=${platform}`;
    }
  }

  render() {
    const location = window.location;
    const isLogined = User.isLogined;

    if (!isLogined || new URLSearchParams(location.search).has("force")) {
      return (
        <BrowserRouter basename={Config.baseName}>
          <RedirectController />
          <Body>
            <TopBar hideAccount />
            <Switch>
              <Route exact path={"/login"} component={() => <Login />} />
              <Redirect from="*" to="/login" />
            </Switch>
            <GoToAppBanner
              goToApp={(platform: string) => this.goToApp(platform)}
            />
          </Body>
        </BrowserRouter>
      );
    }

    const hideSideBar =
      window.location.pathname ===
        `${Config.baseName}${LiveTranskribePage.urlParam.substring(1)}` ||
      window.location.pathname ===
        `${Config.baseName}${StreamingTaskPage.urlParam.substring(1)}`;

    return (
      <BrowserRouter basename={Config.baseName}>
        <RedirectController />
        <Body className={Config.BodyCSSClass}>
          {hideSideBar ? null : <SideBarController ref={this._sidebar} />}
          <TopBar />
          <Main
            isLogined={isLogined}
            onScroll={(event) => {
              this._filelistPage.current?.onOtterContainerScroll(event);
              this._filePage.current?.onOtterContainerScroll(event);
            }}
          >
            <Switch>
              <Route
                exact
                path={FilePage.urlParam}
                component={(data: any) => (
                  <FilePage
                    ref={this._filePage}
                    isLogined={isLogined}
                    location={location}
                    history={data.history}
                    folderId={DataManager.instance.voices.folderId}
                    folderName={DataManager.instance.voices.folderName}
                    folderType={DataManager.instance.voices.folderType}
                  />
                )}
              />
              <Route
                exact
                path={LiveTranskribePage.urlParam}
                component={(data: any) => (
                  <LiveTranskribePage
                    ref={this._livePage}
                    location={location}
                  />
                )}
              />
              <Route
                exact
                path={StreamingTaskPage.urlParam}
                component={StreamingTaskPage}
              />
              <Route
                exact
                path={AccountPage.urlParam}
                component={AccountPage}
              />

              {/* support /payment and /payment-history for apps webview */}
              <Route exact path={"/payment"} component={AccountPage} />
              <Route exact path={"/payment-history"} component={AccountPage} />

              <Route exact path={FaqPage.urlParam} component={FaqPage} />
              <Route
                exact
                path={DeleteAccountPage.urlParam}
                component={DeleteAccountPage}
              />
              <Route
                exact
                path={PaymentResultPage.urlParam}
                component={PaymentResultPage}
              />

              {Array.from(folderMap).map((entity, idx) => {
                return (
                  <Route
                    exact
                    path={entity[1].pathname}
                    key={idx}
                    component={(data: any) => (
                      <FileListPage
                        ref={this._filelistPage}
                        location={location}
                      />
                    )}
                  />
                );
              })}
              <Redirect from="*" to="/" />
            </Switch>
            <UploaderController
              onUploadCompleted={() => {
                this._sidebar.current?.resetFileInput();
              }}
            />

            <GoToAppBanner
              goToApp={(platform: string) => this.goToApp(platform)}
            />
          </Main>
          <SiteMessageController />
        </Body>
      </BrowserRouter>
    );
  }
}

function shouldHideTopBar(path: string) {
  return (
    path === `${Config.baseName}${LiveTranskribePage.urlParam.substring(1)}` ||
    path === `${Config.baseName}${StreamingTaskPage.urlParam.substring(1)}`
  );
}

function TopBar({ hideAccount = false }: { hideAccount?: boolean }) {
  if (shouldHideTopBar(window.location.pathname)) {
    return null;
  }
  return (
    <div className="toolbar-container">
      <StudioLogoLink />
      <StudioAppsButton />
      {!hideAccount && <AccountWidget />}
    </div>
  );
}
