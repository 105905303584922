import React from "react";
import { createRoot } from "react-dom/client";
import App from "./components/App";
import "semantic-ui-css/semantic.min.css";
import "./assets/scss/GlobalStyle.scss";
import "./assets/scss/GlobalDefines.scss";

const rootEl = document.getElementById("root");
const root = createRoot(rootEl);

root.render(<App />);
