import React from "react";
import { observer } from "mobx-react";
import ContentEditable from "react-contenteditable";
import Popup from "semantic-ui-react/dist/commonjs/modules/Popup";

import {
  UserIcon,
  PopupMenu,
  PopupMenuItem,
  PopupMenuDivider,
  FloatOptionButton,
  ActionButton,
} from "./Component";
import { Styles, ButtonColor } from "../GlobalDefine";

import Voice, {
  VoiceSentenceLine,
  UtteranceFragment,
  AutoSummaryType,
} from "../Voice";
import Utils from "../Utils";
import i18n from "../../i18n";
import { Translation } from "react-i18next";
import Config from "../Config";
import YatingButton from "./YatingButton";
import DataManager from "../DataManager";

import "../../assets/scss/FileTranscriptionWidget.scss";
import * as patienceDiff from "./../external/patienceDiff.js";
import arrowIcon from "../../assets/img/icon-arrow-down-active.svg";
import textIcon from "../../assets/img/icon-edit-edit.svg";
import highlighterIcon from "../../assets/img/icon-edit-highlighter.svg";
import eraserIcon from "../../assets/img/icon-edit-eraser.svg";

const CLASSNAME_HIGHLIGHT = "content-highlight";
const CLASSNAME_MATCH_SEARCH = "searched";

const setSelectionPosition = function (
  element: HTMLElement,
  selectionPosX: number,
  reverse: boolean,
) {
  let range = document.createRange();
  let selectobj = window.getSelection();
  range.collapse(true);

  let lastDistance = 999;
  let lastNode: Node = undefined;
  let lastPos = 0;
  if (reverse) {
    for (let i = element.childNodes.length - 1; i >= 0; i--) {
      const node = element.childNodes[i];
      if (node.nodeType === document.TEXT_NODE) {
        continue;
      }
      let found = false;
      for (let j = node.textContent.length; j >= 0; j--) {
        range.setStart(node.firstChild, j);
        range.setEnd(node.firstChild, j);
        let rect = range.getBoundingClientRect();
        if (rect.right <= selectionPosX) {
          if (lastNode && lastDistance < Math.abs(rect.right - selectionPosX)) {
            range.setStart(lastNode.firstChild, lastPos);
            range.setEnd(lastNode.firstChild, lastPos);
          }
          found = true;
          break;
        }
        lastDistance = Math.abs(rect.right - selectionPosX);
        lastNode = node;
        lastPos = j;
      }
      if (found) {
        break;
      }
    }
  } else {
    for (let i = 0; i < element.childNodes.length; i++) {
      const node = element.childNodes[i];
      if (node.nodeType === document.TEXT_NODE) {
        continue;
      }
      let found = false;
      for (let j = 0; j <= node.textContent.length; j++) {
        range.setStart(node.firstChild, j);
        range.setEnd(node.firstChild, j);
        let rect = range.getBoundingClientRect();
        if (rect.left >= selectionPosX) {
          if (lastDistance < Math.abs(rect.left - selectionPosX)) {
            range.setStart(lastNode.firstChild, lastPos);
            range.setEnd(lastNode.firstChild, lastPos);
          }
          found = true;
          break;
        }
        lastDistance = Math.abs(rect.left - selectionPosX);
        lastNode = node;
        lastPos = j;
      }
      if (found) {
        break;
      }
    }
  }

  selectobj.removeAllRanges();
  selectobj.addRange(range);
};

const checkEditingContent = function (
  oldContent: string,
  newContent: string,
):
  | {
      start: number;
      end: number;
      replacement: string;
    }
  | undefined {
  if (newContent !== oldContent) {
    let pdiff = patienceDiff.patienceDiff(oldContent, newContent);
    let start = -1;
    let end = -1;
    let replacement = "";
    for (let i = 0; i < pdiff.lines.length; i++) {
      const line = pdiff.lines[i];
      if (start < 0 && line.aIndex >= 0 && line.bIndex >= 0) {
        continue;
      }
      if (start < 0) {
        start = i;
        if (line.bIndex >= 0) {
          replacement += line.line;
        }
        continue;
      }
      if (line.bIndex >= 0) {
        if (line.aIndex >= 0) {
          end = line.aIndex;
          break;
        }
        replacement += line.line;
      }
    }
    end = end > 0 ? end : oldContent.length;
    return {
      start: start,
      end: end,
      replacement: replacement,
    };
  }
};

const getActualSelectionPosition = function (
  selection: Selection,
  container: HTMLElement,
): {
  start: number;
  end: number;
} {
  let start = selection.anchorOffset;
  let end = selection.focusOffset;

  let offsetStart = 0;
  let offsetEnd = 0;
  for (let i = 0; i < container.childNodes.length; i++) {
    let node = container.childNodes[i];
    if (
      offsetStart >= 0 &&
      (node as Element) === (selection.anchorNode.parentNode as Element)
    ) {
      start += offsetStart;
      offsetStart = -1;
    }
    if (
      offsetEnd >= 0 &&
      (node as Element) === (selection.focusNode.parentNode as Element)
    ) {
      end += offsetEnd;
      offsetEnd = -1;
    }
    offsetStart += node.textContent.length;
    offsetEnd += node.textContent.length;
  }

  return {
    start: start,
    end: end,
  };
};

const mergeHighlights = function (
  oldHightlights: UtteranceFragment[],
): UtteranceFragment[] {
  let newHighlights: UtteranceFragment[] = [];
  while (oldHightlights.length > 0) {
    let a = oldHightlights.pop();
    let merged = false;
    let checked: UtteranceFragment[] = [];
    while (oldHightlights.length > 0) {
      let b = oldHightlights.pop();
      if (
        (a.end >= b.start && a.start <= b.end) ||
        (a.start <= b.end && a.end >= b.start)
      ) {
        a.start = Math.min(a.start, b.start);
        a.end = Math.max(a.end, b.end);
        merged = true;
      } else {
        checked.push(b);
      }
    }
    if (merged) {
      checked.push(a);
    } else {
      newHighlights.push(a);
    }
    oldHightlights = checked;
  }

  newHighlights.sort((a, b) => {
    return a.start - b.start;
  });

  return newHighlights;
};

// export enum EditType {
//     Text = "edit-type-text",
//     Hightlight = "edit-type-pen",
//     Eraser = "edit-type-eraser",
// }

interface ContentFragment {
  start: number; // start idx in the utterance string
  content: string;
  isHighlight: boolean;
  isMatchSearch: boolean;
  idx?: number;
}

interface UtteranceProps {
  id: string; // html id for this element
  contentid: string; // html id for content element
  tabIdx: number;
  file: Voice;
  utteranceIdx: number;

  hideSpeaker: boolean; // no display speaker
  isSpeakerSelected: boolean; // if highligh speaker
  showSpeakerNameOptions: boolean; // if is editing the speaker
  isSpeakerEditable: boolean; // if can click and update speaker
  isContentEditable: boolean; // if can click and update content
  isContentHighlaghtable: boolean; // if can highlight
  isHighlightRemovable: boolean; // if can erase highlight
  searchedWord: string; // display focused style to the word that matched

  onSelect: () => void; // trigger when user clcik this utterance
  onSelectSpeaker: (isMultiSelection: boolean) => void;
  onShiftSelectSpeaker: () => void;
  onChangeSpeaker: (newName: string) => void; // trigger when user change speaker name

  onMoveCursorToPrevious?: (posX?: number) => void; // trigger when go previous utterance
  onMoveCursorToNext?: (posY?: number) => void; // trigger when go next utterance

  onBeginEditingContent?: () => void;
  onEndEditingContent?: () => void;
  onChangedContent: (
    oldContent: string,
    newContent: string,
    start: number,
    end: number,
    replacement: string,
    newHighlights: UtteranceFragment[],
  ) => void;
  onChangedHighlight: (newHighlights: UtteranceFragment[]) => void;
  onRemoveAutoSummary: () => void;
}

interface UtteranceState {
  nameIputValue: string; // the value of user input name
}

@observer
class TranscriptionUtterance extends React.Component<
  UtteranceProps,
  UtteranceState
> {
  private isComponentMounted: boolean;
  private userSelectionUsingTouch: any;
  private touchStartPosition: {
    x: number;
    y: number;
  };
  private _speakerContainerElement: React.RefObject<HTMLDivElement>;
  private _contentElement: React.RefObject<HTMLDivElement>;

  constructor(props: any) {
    super(props);
    this.state = {
      nameIputValue: "",
    };
    this._speakerContainerElement = React.createRef<HTMLDivElement>();
    this._contentElement = React.createRef<HTMLDivElement>();
  }

  componentDidMount() {
    this.isComponentMounted = true;
  }

  componentWillUnmount() {
    this.isComponentMounted = false;
  }

  private get speakerOptions(): string[] {
    let speakers: string[] = [];
    Object.keys(this.props.file.speakers).forEach((sid) => {
      if (
        Number(sid) >= 0 &&
        !/語者[1234567890]*$/.test(this.props.file.speakers[Number(sid)])
      ) {
        speakers.push(this.props.file.speakers[Number(sid)]);
      }
    });
    speakers = speakers.sort((a, b) => {
      const al = a ? a.toLocaleLowerCase() : a;
      const bl = b ? b.toLocaleLowerCase() : b;
      return al === bl ? b.localeCompare(a) : al.localeCompare(bl);
    });
    // remove same string in lowercase
    let lowercaseSpeakers: string[] = [];
    speakers.forEach((speaker, i) => {
      lowercaseSpeakers.push(speaker ? speaker.toLocaleLowerCase() : speaker);
    });
    speakers = speakers.filter(
      (v, i) => lowercaseSpeakers.indexOf(lowercaseSpeakers[i]) === i,
    );

    return speakers;
  }

  private get utterance(): VoiceSentenceLine {
    return this.props.file.utterances[this.props.utteranceIdx];
  }

  private get speaker(): string {
    const speaker_id = this.utterance.speaker_id;
    return this.props.file.speakers[speaker_id];
  }

  private get contentFragments(): ContentFragment[] {
    const content = this.utterance.content;
    let result: ContentFragment[] = [];
    let justWholeUtterance = true;

    if (this.props.searchedWord && this.props.searchedWord.trim().length > 0) {
      const keyWord = this.props.searchedWord.trim().toUpperCase();
      const noMatched = content.toUpperCase().split(keyWord);
      if (noMatched.length > 1) {
        let curPos = 0;
        noMatched.forEach((item, idx) => {
          const searchContent = content.substr(
            curPos + item.length,
            keyWord.length,
          );
          const nomatchContent = content.substr(curPos, item.length);
          result.push({
            start: curPos,
            content: nomatchContent,
            isHighlight: false,
            isMatchSearch: false,
          });
          if (searchContent.length > 0) {
            result.push({
              start: curPos + item.length,
              content: searchContent,
              isHighlight: false,
              isMatchSearch: true,
            });
          }
          curPos += keyWord.length + item.length;
          justWholeUtterance = false;
        });
      }
    } else if (
      this.utterance.user_highlights &&
      this.utterance.user_highlights.length > 0
    ) {
      justWholeUtterance = false;
      let currentPos = 0;
      this.utterance.user_highlights.forEach((item, idx) => {
        let pos = currentPos;
        currentPos = item.end;
        if (pos < item.start) {
          result.push({
            start: pos,
            content: this.utterance.content.substring(pos, item.start),
            isHighlight: false,
            isMatchSearch: false,
          });
        }
        result.push({
          start: item.start,
          content: this.utterance.content.substring(item.start, item.end),
          isHighlight: true,
          isMatchSearch: false,
          idx: idx,
        });
        if (
          idx === this.utterance.user_highlights.length - 1 &&
          currentPos < this.utterance.content.length
        ) {
          result.push({
            start: currentPos,
            content: this.utterance.content.substring(currentPos),
            isHighlight: false,
            isMatchSearch: false,
          });
        }
      });
    }

    if (justWholeUtterance) {
      result.push({
        start: 0,
        content: content,
        isHighlight: false,
        isMatchSearch: false,
      });
    }
    return result;
  }

  private updateSpeakerName(name: string) {
    let newName = name ? name.trim() : "";
    const speaker = this.speaker;

    if (newName.length > 0 && newName !== speaker) {
      this.props.onChangeSpeaker(newName);
    }
  }

  public checkEditingContent(element: HTMLDivElement) {
    if (!element) {
      return;
    }
    const newContent = element.innerText;
    const oldContent = this.utterance.content;
    const log = checkEditingContent(oldContent, newContent);
    if (log) {
      // get highlights
      let highlights: UtteranceFragment[] = [];
      let pos = 0;
      let ele = document.createElement("div");
      ele.innerHTML = element.innerHTML;
      ele.childNodes.forEach((node, idx) => {
        if (
          (node as Element).classList &&
          (node as Element).classList.contains(CLASSNAME_HIGHLIGHT)
        ) {
          highlights.push({
            start: pos,
            end: pos + node.textContent.length,
          });
        }
        pos += node.textContent.length;
      });
      highlights = mergeHighlights(highlights);

      this.props.onChangedContent(
        oldContent,
        newContent,
        log.start,
        log.end,
        log.replacement,
        highlights,
      );
    }
  }

  private highlightContent(start: number, end: number) {
    if (start === end) {
      return;
    }
    let oldHightlights: UtteranceFragment[] = this.utterance.user_highlights
      ? JSON.parse(JSON.stringify(this.utterance.user_highlights))
      : [];
    oldHightlights.push({
      start: start,
      end: end,
    });

    let newHighlights = mergeHighlights(oldHightlights);
    this.props.onChangedHighlight(newHighlights);
  }

  private eraseHighlight(idx: number) {
    if (!this.utterance.user_highlights) {
      return;
    }
    if (idx < 0 || idx >= this.utterance.user_highlights.length) {
      return;
    }
    let newHighlights: UtteranceFragment[] = JSON.parse(
      JSON.stringify(this.utterance.user_highlights),
    );
    newHighlights.splice(idx, 1);
    this.props.onChangedHighlight(newHighlights);
  }

  private getSpeakerUI(): React.ReactElement {
    let speaker = undefined;
    if (this.utterance.speaker_id >= 0) {
      speaker = this.props.file.speakers[this.utterance.speaker_id];
    }

    return (
      <div
        ref={this._speakerContainerElement}
        className={`
                    speaker-container
                    ${this.props.hideSpeaker ? " no-display" : ""}
                    ${this.props.isSpeakerEditable ? " editable" : ""}
                    ${this.props.isSpeakerSelected ? " selected" : ""}
                    ${this.props.showSpeakerNameOptions ? " editing" : ""}
                    ${speaker ? "" : " unknown-speaker"}
                `}
        onClick={(event) => {
          if (!this.props.isSpeakerEditable) {
            return;
          }
          if (event.shiftKey) {
            this.props.onShiftSelectSpeaker();
          } else {
            this.props.onSelectSpeaker(
              Utils.isMacLike ? event.metaKey : event.ctrlKey,
            );
          }
          this.setState({
            nameIputValue: "",
          });
        }}
      >
        <UserIcon
          imgUrl={""}
          name={speaker ? speaker : i18n.t("unknown")}
          email={""}
          size="25px"
          fontSize="14px"
        />
        <div className={`speaker-name`}>
          {speaker ? Utils.showSpeaker(speaker) : i18n.t("unknown")}
        </div>
      </div>
    );
  }

  private getTimestampUI(): React.ReactElement {
    return (
      <div
        className="timestamp number"
        onMouseEnter={(event) => {
          if (this._speakerContainerElement.current) {
            this._speakerContainerElement.current.classList.add("hover");
          }
        }}
        onMouseLeave={(event) => {
          if (this._speakerContainerElement.current) {
            this._speakerContainerElement.current.classList.remove("hover");
          }
        }}
        onClick={(event) => {
          if (!this.props.isSpeakerEditable) {
            return;
          }
          if (event.shiftKey) {
            this.props.onShiftSelectSpeaker();
          } else {
            this.props.onSelectSpeaker(
              Utils.isMacLike ? event.metaKey : event.ctrlKey,
            );
          }
          this.setState({
            nameIputValue: "",
          });
        }}
      >
        {Utils.transferMilliSecondToTime(this.utterance.startTime)}
      </div>
    );
  }

  private getSpeakerOptionMenu(): React.ReactElement {
    let speakerOptions = this.speakerOptions;
    return (
      <Translation ns="file">
        {(t) => (
          <PopupMenu className="edit-speaker-option-menu">
            <div className="edit-speaker-input">
              <input
                type="text"
                placeholder={t("enter_speaker_name")}
                value={this.state.nameIputValue}
                onChange={(event) => {
                  this.setState({
                    nameIputValue: event.target.value,
                  });
                }}
                onKeyDown={(event) => {
                  if (event.keyCode === 13) {
                    // enter
                    this.updateSpeakerName(this.state.nameIputValue);
                  }
                }}
              />
              <ActionButton
                color={ButtonColor.Orange}
                content={i18n.t("confirm")}
                style={{
                  width: "60px",
                }}
                onClick={() => {
                  this.updateSpeakerName(this.state.nameIputValue);
                }}
              />
            </div>
            {speakerOptions.length > 0 ? (
              <>
                <PopupMenuDivider />
                <div className="edit-speaker-options">
                  {speakerOptions.map((option, idx) => {
                    return (
                      <PopupMenuItem
                        key={idx}
                        onClick={(event) => {
                          this.updateSpeakerName(option);
                        }}
                      >
                        {option}
                      </PopupMenuItem>
                    );
                  })}
                </div>
              </>
            ) : null}
          </PopupMenu>
        )}
      </Translation>
    );
  }

  private getContent(): string {
    const fragments = this.contentFragments;
    return fragments
      .map((item, idx) => {
        let key = item.idx;
        let className = `${item.isHighlight ? CLASSNAME_HIGHLIGHT : ""} ${
          item.isMatchSearch ? CLASSNAME_MATCH_SEARCH : ""
        }`.trim();
        return `<span${key ? ` key="${key}"` : ""} ${
          className.length > 0 ? `class="${className}"` : ""
        }>${Utils.escapeHtml(item.content)}</span>`;
      })
      .join("");
  }

  render() {
    let autoSummaryHighlight =
      this.props.searchedWord.length === 0 &&
      this.utterance.auto_summary &&
      this.utterance.auto_summary.includes(AutoSummaryType.Hightlight) &&
      !this.utterance.auto_summary.includes(AutoSummaryType.UserRemoved);
    return (
      <div
        id={this.props.id}
        className={`utterance-container${autoSummaryHighlight ? " auto-highlight" : ""}`}
        onClick={() => {
          if (this.props.isHighlightRemovable && autoSummaryHighlight) {
            this.props.file.removeUtteranceAutoSummary(
              this.utterance.startTime,
            );
            this.props.onRemoveAutoSummary();
          }
          this.props.onSelect();
        }}
        onTouchStart={(event) => {
          this.touchStartPosition = {
            x: event.touches[0].clientX,
            y: event.touches[0].clientY,
          };
        }}
        onTouchMove={(event) => {
          if (
            this.touchStartPosition &&
            event.touches &&
            event.touches.length > 0
          ) {
            let moved =
              Math.abs(this.touchStartPosition.x - event.touches[0].clientX) +
              Math.abs(this.touchStartPosition.y - event.touches[0].clientY);
            if (moved > 20) {
              this.touchStartPosition = undefined;
            }
          }
        }}
        onTouchCancel={() => {
          this.touchStartPosition = undefined;
          this._contentElement.current?.blur();
        }}
        onTouchEnd={(event) => {
          if (Utils.isUsingTouch && this.props.isContentHighlaghtable) {
            if (this.touchStartPosition && this.userSelectionUsingTouch) {
              // user clicked
              this._contentElement.current?.blur();
              return;
            }

            const selected = window.getSelection();
            if (!selected || selected.isCollapsed) {
              this.userSelectionUsingTouch = undefined;
              return;
            }
            let updateaSelect = true;
            if (this.userSelectionUsingTouch) {
              const newPos = getActualSelectionPosition(
                selected,
                this._contentElement.current,
              );
              const newLength = Math.abs(newPos.start - newPos.end);
              const oldPos = getActualSelectionPosition(
                this.userSelectionUsingTouch,
                this._contentElement.current,
              );
              const oldLength = Math.abs(oldPos.start - oldPos.end);
              if (newLength === 1 && oldLength > 1) {
                selected.removeAllRanges();
                this.userSelectionUsingTouch = undefined;
                updateaSelect = false;
              }
            }
            if (updateaSelect) {
              this.userSelectionUsingTouch = {
                anchorOffset: selected.anchorOffset,
                focusOffset: selected.focusOffset,
                anchorNode: selected.anchorNode,
                focusNode: selected.focusNode,
              };
              this._contentElement.current?.focus();
            }
          }
        }}
      >
        {this.props.isSpeakerEditable ? (
          <Popup
            basic
            position="bottom left"
            on="focus"
            open={
              this.props.showSpeakerNameOptions && this.props.isSpeakerSelected
            }
            style={Styles.popupContainer}
            trigger={
              <>
                {this.getSpeakerUI()}
                {this.getTimestampUI()}
              </>
            }
          >
            {this.getSpeakerOptionMenu()}
          </Popup>
        ) : (
          <>
            {this.getSpeakerUI()}
            {this.getTimestampUI()}
          </>
        )}
        <ContentEditable
          id={this.props.contentid}
          innerRef={this._contentElement}
          className="content"
          disabled={
            !this.props.isContentEditable ||
            (Utils.isUsingTouch &&
              (this.props.isContentHighlaghtable ||
                this.props.isHighlightRemovable))
          }
          tabIndex={this.props.tabIdx}
          html={this.getContent()}
          onChange={() => {}}
          onFocus={() => {
            if (
              this.props.isContentEditable &&
              this.props.onBeginEditingContent
            ) {
              this.props.onBeginEditingContent();
            }
          }}
          onBlur={() => {
            if (this.userSelectionUsingTouch) {
              let pos = getActualSelectionPosition(
                this.userSelectionUsingTouch,
                this._contentElement.current,
              );
              if (Math.abs(pos.start - pos.end) > 0) {
                this.highlightContent(
                  Math.min(pos.start, pos.end),
                  Math.max(pos.start, pos.end),
                );
              }
              this.userSelectionUsingTouch = undefined;
            }
            if (this.props.isContentEditable) {
              this.checkEditingContent(this._contentElement.current);
              if (this.props.onEndEditingContent) {
                this.props.onEndEditingContent();
              }
            }
          }}
          onKeyDown={(event) => {
            if (
              event.keyCode === 37 || // left
              event.keyCode === 38 || // up
              event.keyCode === 39 || // right
              event.keyCode === 40 // down
            ) {
              this.checkEditingContent(this._contentElement.current);
            }

            // move cursor
            const range = window.getSelection().getRangeAt(0);
            if (
              event.keyCode === 38 || // up
              event.keyCode === 40 // down
            ) {
              const rect = range.getBoundingClientRect();
              const parentRect =
                range.startContainer.parentElement.parentElement.getBoundingClientRect();
              if (
                event.keyCode === 38 &&
                rect.top - rect.height < parentRect.top
              ) {
                // go up
                event.preventDefault();
                this.props.onMoveCursorToPrevious(rect.right);
              } else if (
                event.keyCode === 40 &&
                rect.bottom + rect.height > parentRect.bottom
              ) {
                // down
                event.preventDefault();
                this.props.onMoveCursorToNext(rect.right);
              }
            } else if (
              event.keyCode === 37 && // left
              range.startContainer.parentElement ===
                range.startContainer.parentElement.parentElement
                  .firstElementChild &&
              range.startOffset === 0
            ) {
              event.preventDefault();
              this.props.onMoveCursorToPrevious();
            } else if (
              event.keyCode === 39 && // right
              range.endContainer.parentElement ===
                range.startContainer.parentElement.parentElement
                  .lastElementChild &&
              range.endOffset ===
                range.startContainer.parentNode.parentNode.lastElementChild
                  .textContent.length
            ) {
              event.preventDefault();
              this.props.onMoveCursorToNext();
            }
          }}
          onMouseDown={() => {
            if (this.props.isContentEditable) {
              this.checkEditingContent(this._contentElement.current);
            }
          }}
          onTouchStart={() => {
            if (this.props.isContentEditable) {
              this.checkEditingContent(this._contentElement.current);
            }
          }}
          onMouseUp={() => {
            if (this.props.isContentHighlaghtable && !Utils.isUsingTouch) {
              const selected = window.getSelection();
              if (!selected.isCollapsed) {
                let pos = getActualSelectionPosition(
                  selected,
                  this._contentElement.current,
                );
                this.highlightContent(
                  Math.min(pos.start, pos.end),
                  Math.max(pos.start, pos.end),
                );
              }
            }
          }}
          onClick={(event) => {
            if (this.props.isHighlightRemovable) {
              let range = window.getSelection().getRangeAt(0);
              if (
                range.startContainer.parentElement.classList &&
                range.startContainer.parentElement.classList.contains(
                  CLASSNAME_HIGHLIGHT,
                )
              ) {
                let idx =
                  range.startContainer.parentElement.getAttribute("key");
                this.eraseHighlight(Number.parseInt(idx));
                event.preventDefault();
                event.stopPropagation();
              }
            }
          }}
        />
      </div>
    );
  }
}

@observer
export class ContentEditingStatusWidget extends React.Component<
  {
    file?: Voice;
    onUndo: () => void;
    onRedo: () => void;
  },
  {}
> {
  render() {
    if (!this.props.file) {
      return null;
    }

    return (
      <div className="edit-saving-status-container">
        <div className="edit-saving-status-widget">
          <div className="edit-saving-status-message">
            <Translation>
              {(t) =>
                this.props.file.isAllContentChangesSaved
                  ? t("changes_are_saved")
                  : this.props.file.isSavingContentChanges
                    ? t("saving")
                    : t("not_yet_saved")
              }
            </Translation>
          </div>
          <div className="edit-saving-status-actions">
            <img
              src={arrowIcon}
              className={`editing-undo${this.props.file.contentEditCount === 0 ? " disabled" : ""}`}
              onClick={() => {
                this.props.onUndo();
              }}
            />
            <img
              src={arrowIcon}
              className={`editing-redo${this.props.file.contentUndoCount === 0 ? " disabled" : ""}`}
              onClick={() => {
                this.props.onRedo();
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

/** Hide
export class ContentEditingControlWidget extends React.Component<
    {
        file?: Voice;
        type: EditType;
        onSelect: (type: EditType) => void;
    },
    {
        type: EditType;
        isSelectingPen: boolean; // true when open the selecting options
    }
> {
    constructor(props: any) {
        super(props);
        this.state = {
            type: this.props.type,
            isSelectingPen: false,
        };
    }

    componentDidUpdate(prevProps: any, prevStats: any) {
        if (prevProps.type !== this.props.type) {
            this.setState({
                type: this.props.type,
            });
        }
    }

    private getIcon(type: EditType): string {
        switch (type) {
            case EditType.Text:
                return textIcon;
            case EditType.Hightlight:
                return highlighterIcon;
            case EditType.Eraser:
                return eraserIcon;
        }
    }

    private getLabel(type: EditType): string {
        switch (type) {
            case EditType.Text:
                return i18n.t("edit_text", { ns: "file" });
            case EditType.Hightlight:
                return i18n.t("highlight", { ns: "file" });
            case EditType.Eraser:
                return i18n.t("eraser", { ns: "file" });
        }
    }

    private getOptions(): JSX.Element {
        return (
            <>
                {this.state.type !== EditType.Text ? (
                    <FloatOptionButton
                        icon={this.getIcon(EditType.Text)}
                        label={this.getLabel(EditType.Text)}
                        onClick={() => {
                            this.setState({
                                type: EditType.Text,
                                isSelectingPen: false,
                            });
                            this.props.onSelect(EditType.Text);
                        }}
                    />
                ) : null}
                {this.state.type !== EditType.Hightlight ? (
                    <FloatOptionButton
                        icon={this.getIcon(EditType.Hightlight)}
                        label={this.getLabel(EditType.Hightlight)}
                        onClick={() => {
                            Utils.analyticsEvent({
                                category: "Edit Page",
                                action: "Clicked highlight",
                            });
                            this.setState({
                                type: EditType.Hightlight,
                                isSelectingPen: false,
                            });
                            this.props.onSelect(EditType.Hightlight);
                        }}
                    />
                ) : null}
                {this.state.type !== EditType.Eraser ? (
                    <FloatOptionButton
                        icon={this.getIcon(EditType.Eraser)}
                        label={this.getLabel(EditType.Eraser)}
                        onClick={() => {
                            this.setState({
                                type: EditType.Eraser,
                                isSelectingPen: false,
                            });
                            this.props.onSelect(EditType.Eraser);
                        }}
                    />
                ) : null}
            </>
        );
    }

    render() {
        if (!this.props.file) {
            return null;
        }

        return (
            <div className="edit-control-panel">
                <div
                    className="pen-selector"
                    onMouseEnter={() => {
                        this.setState({
                            isSelectingPen: true,
                        });
                    }}
                    onMouseLeave={() => {
                        this.setState({
                            isSelectingPen: false,
                        });
                    }}
                    onTouchEnd={() => {
                        this.setState({
                            isSelectingPen: !this.state.isSelectingPen,
                        });
                    }}
                >
                    {this.state.isSelectingPen ? this.getOptions() : ""}
                    {this.props.type !== undefined ? (
                        <FloatOptionButton
                            className="on-panel"
                            icon={this.getIcon(this.state.type)}
                            label={this.getLabel(this.state.type)}
                        />
                    ) : null}
                </div>
            </div>
        );
    }
}
*/

const QuotaArrearsContainer = ({ file }: { file: Voice }) => {
  const repay = async () => {
    Utils.analyticsEvent({
      category: "Conversion",
      action: "Clicked exchange",
    });
    await file.repay();
    setTimeout(() => window.location.reload(), 100);
  };

  const redirectToPlan = () => {
    window.location.assign(
      `${window.location.origin}${Config.baseName}account?tab=2`,
    );
  };

  const canRepay =
    DataManager.instance.quotaDetail?.remaining_quota >= file.quotaArrears;
  const handleClick = canRepay ? repay : redirectToPlan;

  const wording = canRepay
    ? {
        title: i18n.t("finish_asr", { ns: "file" }),
        content: i18n.t("exchange_content", { ns: "file" }),
        button: i18n.t("exchange"),
      }
    : {
        title: i18n.t("quota_not_enough", { ns: "file" }),
        content: i18n.t("purchase_content", { ns: "file" }),
        button: i18n.t("buy"),
      };

  return (
    <div className="quota-arrears-container">
      <div className="quota-arrears-content">
        <p className="quota-arrears-title">{wording.title}</p>
        <p>{i18n.t("quota_arrears_reason", { ns: "file" })}</p>
        <p>{wording.content}</p>
      </div>

      <YatingButton
        color="main"
        variant="primary"
        size="large"
        onClick={handleClick}
      >
        {" "}
        {wording.button}
      </YatingButton>
    </div>
  );
};

interface Props {
  file?: Voice;
  show: boolean;
  isEditing: boolean;
  searchedWord: string;

  onSelectingUtterance: (idx: number) => void;
  onSpeakerChanged: () => void;
  onContentChanged: () => void;
  onHighlightChanged: () => void;
  // onSelectingEditType: (type: EditType) => void;
}

interface State {
  // editType: EditType;
  selectedSpeakerIdxs: number[];
  isMultiSelectingSpeaker: boolean;
}

export default class FileTranscriptionWidget extends React.Component<
  Props,
  State
> {
  private _containerElement: React.RefObject<HTMLDivElement>;
  private focusedUtteranceIdx: number;
  private isUndoRedoing: boolean;
  private isShiftSelectedSpeakers: boolean;

  // for tracking
  private sentenceContentChangeList: Array<number> = [];
  private sentenceHighlightChangeList: Array<number> = [];
  private sentenceSpeakerChangeList: Array<number> = [];
  private eventCount = {
    undo: 0,
    redo: 0,
    removeAutoSummary: 0,
  };

  constructor(props: any) {
    super(props);
    this._containerElement = React.createRef<HTMLDivElement>();
    this.focusedUtteranceIdx = -1;
    this.isUndoRedoing = false;
    this.isShiftSelectedSpeakers = false;
    this.state = {
      // editType: EditType.Text,
      selectedSpeakerIdxs: [],
      isMultiSelectingSpeaker: false,
    };
  }

  getSnapshotBeforeUpdate(nextProps: Props, nextState: State) {
    if (nextProps.isEditing && !this.props.isEditing) {
      this.isShiftSelectedSpeakers = false;
      this.setState({
        selectedSpeakerIdxs: [],
      });
    }

    return true;
  }

  public handleKeyDownListener(e: KeyboardEvent): boolean {
    if (!this.props.show || e.repeat) {
      return true;
    }
    if (
      !this.props.isEditing &&
      this.props.file &&
      this.focusedUtteranceIdx >= 0
    ) {
      const currentIdx = this.focusedUtteranceIdx;
      if (currentIdx >= 0) {
        if (e.keyCode === 38) {
          // up
          if (currentIdx > 0) {
            e.preventDefault();
            e.stopPropagation();
            this.setFocusedUtterance(currentIdx - 1);
            this.props.onSelectingUtterance(currentIdx - 1);
          }
        } else if (e.keyCode === 40) {
          // down
          if (currentIdx < this.props.file.utterances.length - 1) {
            e.preventDefault();
            e.stopPropagation();
            this.setFocusedUtterance(currentIdx + 1);
            this.props.onSelectingUtterance(currentIdx + 1);
          }
        }
      }
    } else if (this.props.isEditing && this.props.file) {
      if (
        // ctrl + z
        e.keyCode === 90 &&
        ((Utils.isMacLike && e.metaKey) || (!Utils.isMacLike && e.ctrlKey))
      ) {
        e.preventDefault();
        e.stopPropagation();
        if (e.shiftKey) {
          this.redoContentChange();
        } else {
          this.undoContentChange();
        }
      } else if (
        // ctrl + y
        e.keyCode === 89 &&
        ((Utils.isMacLike && e.metaKey) || (!Utils.isMacLike && e.ctrlKey))
      ) {
        e.preventDefault();
        e.stopPropagation();
        this.redoContentChange();
      } else if (
        e.key === "Shift" ||
        (Utils.isMacLike && e.key === "Meta") ||
        (!Utils.isMacLike && e.key === "Control")
      ) {
        // shift or ctrl
        if (
          !document.activeElement.classList.contains("content") &&
          document.activeElement.tagName != "input"
        ) {
          let elements = document.getElementsByClassName(
            "edit-speaker-option-menu",
          );
          for (let i = 0; i < elements.length; i++) {
            elements.item(i).classList.add("hide");
          }
          this.setState({
            isMultiSelectingSpeaker: true,
          });
        }
      }
    }
    return true;
  }

  public handleKeyUpListener(e: KeyboardEvent): boolean {
    if (!this.props.show) {
      return true;
    }

    if (this.props.isEditing && this.props.file) {
      if (
        e.key === "Shift" ||
        (Utils.isMacLike && e.key === "Meta") ||
        (!Utils.isMacLike && e.key === "Control")
      ) {
        // shift or ctrl
        if (
          !document.activeElement.classList.contains("content") &&
          document.activeElement.tagName != "input"
        ) {
          this.setState({
            isMultiSelectingSpeaker: false,
          });
        }
      }
    }

    return true;
  }

  public setFocusedUtterance(idx: number) {
    if (idx === this.focusedUtteranceIdx) {
      return;
    }
    this.findElementOfUtteranceAtIndex(
      this.focusedUtteranceIdx,
    )?.classList.remove("focused");
    this.findElementOfUtteranceAtIndex(idx)?.classList.add("focused");
    this.focusedUtteranceIdx = idx;
  }

  public resetEventCount() {
    this.sentenceContentChangeList.length = 0;
    this.sentenceHighlightChangeList.length = 0;
    this.sentenceSpeakerChangeList.length = 0;
    this.eventCount.undo = 0;
    this.eventCount.redo = 0;
    this.eventCount.removeAutoSummary = 0;
  }

  public getEventCount(): any {
    let result = {
      change: this.sentenceContentChangeList.length,
      highlightChange: this.sentenceHighlightChangeList.length,
      speakerChange: this.sentenceSpeakerChangeList.length,
    };
    Object.assign(result, this.eventCount);
    return result;
  }

  private saveCurrentFocusedUtteranceContent() {
    if (this.focusedUtteranceIdx < 0) {
      return;
    }

    const ele = this.findElementOfUtteranceConentAtIndex(
      this.focusedUtteranceIdx,
    );
    const utterance = this.props.file.utterances[this.focusedUtteranceIdx];
    const oldContent = utterance.content;
    const newContent = ele.innerText;
    let log = checkEditingContent(oldContent, newContent);
    if (log) {
      // get highlights
      let highlights: UtteranceFragment[] = [];
      let pos = 0;
      ele.childNodes.forEach((node, idx) => {
        if (
          (node as Element).classList &&
          (node as Element).classList.contains(CLASSNAME_HIGHLIGHT)
        ) {
          highlights.push({
            start: pos,
            end: node.textContent.length,
          });
        }
        pos += node.textContent.length;
      });
      highlights = mergeHighlights(highlights);

      this.props.file.addContentEditLog({
        utteranceIndex: this.focusedUtteranceIdx,
        oldValue: oldContent,
        newValue: newContent,
        startIndex: log.start,
        endIndex: log.end,
        replacement: log.replacement,
        oldHighlights: utterance.user_highlights,
        newHighlights: highlights,
        oldAutoSummary: utterance.auto_summary,
        newAutoSummary: utterance.auto_summary,
      });
      this.props.onContentChanged();
    }
  }

  private undoContentChange() {
    if (this.isUndoRedoing) {
      return;
    }
    this.isUndoRedoing = true;

    this.saveCurrentFocusedUtteranceContent();
    let log = this.props.file?.undoContentEditing();
    if (log) {
      this.eventCount.undo++;
      this.setFocusedUtterance(log.utteranceIndex);
      this.props.onSelectingUtterance(log.utteranceIndex);
      const ele = this.findElementOfUtteranceConentAtIndex(log.utteranceIndex);
      if (ele) {
        ele.focus();
        const pos = log.endIndex;
        this.setSelectionRange(ele, pos, pos);
      }
      setTimeout(() => {
        this.isUndoRedoing = false;
      }, 100);
      this.props.onContentChanged();
    } else {
      this.isUndoRedoing = false;
    }
  }

  private redoContentChange() {
    if (this.isUndoRedoing) {
      return;
    }
    this.isUndoRedoing = true;

    this.saveCurrentFocusedUtteranceContent();
    let log = this.props.file?.redoContentEditing();
    if (log) {
      this.eventCount.redo++;
      this.setFocusedUtterance(log.utteranceIndex);
      this.props.onSelectingUtterance(log.utteranceIndex);
      const ele = this.findElementOfUtteranceConentAtIndex(log.utteranceIndex);
      if (ele) {
        ele.focus();
        const pos = log.startIndex + log.replacement.length;
        this.setSelectionRange(ele, pos, pos);
      }
      setTimeout(() => {
        this.isUndoRedoing = false;
      }, 100);
      this.props.onContentChanged();
    } else {
      this.isUndoRedoing = false;
    }
  }

  private setSelectionRange(
    element: HTMLElement,
    selectionStart: number,
    selectionEnd: number,
  ) {
    let range = document.createRange();
    let selectobj = window.getSelection();
    range.collapse(selectionStart === selectionEnd);

    let pos = 0;
    let foundStart = false;
    let foundEnd = false;
    for (let i = 0; i < element.childNodes.length; i++) {
      const node = element.childNodes[i];
      if (!foundStart && pos + node.textContent.length >= selectionStart) {
        range.setStart(node.firstChild, selectionStart - pos);
        foundStart = true;
      }
      if (!foundEnd && pos + node.textContent.length >= selectionEnd) {
        range.setEnd(node.firstChild, selectionEnd - pos);
        foundEnd = true;
      }
      pos += node.textContent.length;
    }

    selectobj.removeAllRanges();
    selectobj.addRange(range);
  }

  public findElementOfUtteranceAtIndex(idx: number): HTMLElement {
    return document.getElementById(`utterance-${idx}`);
  }

  public findElementOfUtteranceConentAtIndex(idx: number): HTMLElement {
    return document.getElementById(`content-${idx}`);
  }

  render() {
    if (!this.props.file) {
      return null;
    }
    if (!this.props.show) {
      return null;
    }

    return (
      <>
        <div
          ref={this._containerElement}
          className={`
                    transcription-container
                    ${this.props.isEditing ? " editing" : ""}
                `}
        >
          {this.props.file.utterances.map((utterance, idx) => {
            let hideSpeaker =
              !this.props.isEditing &&
              idx !== 0 &&
              this.props.file.utterances[idx - 1].speaker_id ===
                utterance.speaker_id;

            return (
              <TranscriptionUtterance
                key={idx}
                id={`utterance-${idx}`}
                contentid={`content-${idx}`}
                tabIdx={idx}
                file={this.props.file}
                utteranceIdx={idx}
                hideSpeaker={hideSpeaker}
                isSpeakerSelected={this.state.selectedSpeakerIdxs.includes(idx)}
                showSpeakerNameOptions={
                  this.state.isMultiSelectingSpeaker
                    ? false
                    : this.state.selectedSpeakerIdxs.length >= 1
                      ? this.state.selectedSpeakerIdxs[
                          this.state.selectedSpeakerIdxs.length - 1
                        ] == idx
                      : false
                }
                isSpeakerEditable={this.props.isEditing}
                isContentEditable={this.props.isEditing}
                isContentHighlaghtable={false}
                isHighlightRemovable={false}
                searchedWord={this.props.searchedWord}
                onSelect={() => {
                  this.setFocusedUtterance(idx);
                  this.props.onSelectingUtterance(idx);
                }}
                onSelectSpeaker={(isMultiSelection) => {
                  this.isShiftSelectedSpeakers = false;
                  if (isMultiSelection) {
                    if (!this.state.selectedSpeakerIdxs.includes(idx)) {
                      this.state.selectedSpeakerIdxs.push(idx);
                      this.setState({
                        isMultiSelectingSpeaker: isMultiSelection,
                        selectedSpeakerIdxs: this.state.selectedSpeakerIdxs,
                      });
                    } else {
                      this.setState({
                        isMultiSelectingSpeaker: isMultiSelection,
                        selectedSpeakerIdxs: Utils.removeFromArray(
                          this.state.selectedSpeakerIdxs,
                          idx,
                        ),
                      });
                    }
                  } else {
                    if (
                      this.state.selectedSpeakerIdxs.length == 1 &&
                      this.state.selectedSpeakerIdxs[
                        this.state.selectedSpeakerIdxs.length - 1
                      ] == idx
                    ) {
                      this.setState({
                        isMultiSelectingSpeaker: isMultiSelection,
                        selectedSpeakerIdxs: [],
                      });
                    } else {
                      this.setState({
                        isMultiSelectingSpeaker: isMultiSelection,
                        selectedSpeakerIdxs: [idx],
                      });
                    }
                  }
                }}
                onShiftSelectSpeaker={() => {
                  let selectedSpeakerIdxs = this.state.selectedSpeakerIdxs;
                  if (selectedSpeakerIdxs.length === 0) {
                    this.setState({
                      isMultiSelectingSpeaker: true,
                      selectedSpeakerIdxs: [idx],
                    });
                  } else {
                    let lastIndex =
                      selectedSpeakerIdxs[selectedSpeakerIdxs.length - 1];
                    if (lastIndex === idx) {
                      this.setState({
                        isMultiSelectingSpeaker: true,
                        selectedSpeakerIdxs: [idx],
                      });
                    } else {
                      let lastlastIndex =
                        selectedSpeakerIdxs.length > 1
                          ? selectedSpeakerIdxs[selectedSpeakerIdxs.length - 2]
                          : undefined;
                      if (lastIndex > idx) {
                        for (let i = lastIndex; i >= idx; i--) {
                          if (!selectedSpeakerIdxs.includes(i)) {
                            selectedSpeakerIdxs.push(i);
                          } else if (
                            this.isShiftSelectedSpeakers &&
                            (lastlastIndex < lastIndex || i != lastIndex)
                          ) {
                            selectedSpeakerIdxs = Utils.removeFromArray(
                              selectedSpeakerIdxs,
                              i,
                            );
                          }
                        }
                      } else {
                        for (let i = lastIndex; i <= idx; i++) {
                          if (!selectedSpeakerIdxs.includes(i)) {
                            selectedSpeakerIdxs.push(i);
                          } else if (
                            this.isShiftSelectedSpeakers &&
                            (lastlastIndex > lastIndex || i != lastIndex)
                          ) {
                            selectedSpeakerIdxs = Utils.removeFromArray(
                              selectedSpeakerIdxs,
                              i,
                            );
                          }
                        }
                      }
                      this.setState({
                        isMultiSelectingSpeaker: true,
                        selectedSpeakerIdxs: selectedSpeakerIdxs,
                      });
                    }
                  }
                  this.isShiftSelectedSpeakers = true;
                }}
                onChangeSpeaker={(newName: string) => {
                  this.isShiftSelectedSpeakers = false;
                  this.state.selectedSpeakerIdxs.forEach((selectedIdx) => {
                    if (!this.sentenceSpeakerChangeList.includes(selectedIdx)) {
                      this.sentenceSpeakerChangeList.push(selectedIdx);
                    }
                  });
                  if (this.state.selectedSpeakerIdxs.length > 1) {
                    this.props.file.changeSpeakers(
                      this.state.selectedSpeakerIdxs,
                      newName,
                    );
                  } else if (this.state.selectedSpeakerIdxs.length === 1) {
                    this.props.file.changeSpeaker(
                      this.state.selectedSpeakerIdxs[0],
                      newName,
                    );
                  } else {
                    return;
                  }

                  this.setState({
                    selectedSpeakerIdxs: [],
                  });
                  this.props.onSpeakerChanged();
                }}
                onMoveCursorToPrevious={(posX) => {
                  const ele = this.findElementOfUtteranceConentAtIndex(idx - 1);
                  if (ele) {
                    ele.focus();
                    setSelectionPosition(ele, posX ? posX : 999999, true);
                    this.setFocusedUtterance(idx - 1);
                  }
                }}
                onMoveCursorToNext={(posX) => {
                  const ele = this.findElementOfUtteranceConentAtIndex(idx + 1);
                  if (ele) {
                    ele.focus();
                    setSelectionPosition(ele, posX ? posX : 0, false);
                    this.setFocusedUtterance(idx + 1);
                  }
                }}
                onBeginEditingContent={() => {
                  this.isShiftSelectedSpeakers = false;
                  if (this.state.selectedSpeakerIdxs.length > 0) {
                    this.setState({
                      selectedSpeakerIdxs: [],
                    });
                  }
                }}
                onChangedContent={(
                  oldContent,
                  newContent,
                  start,
                  end,
                  replacement,
                  newHighlights,
                ) => {
                  setTimeout(() => {
                    // for restore cursor position after re-render
                    const ele = this.findElementOfUtteranceConentAtIndex(idx);
                    let currentSelection =
                      ele === document.activeElement
                        ? getActualSelectionPosition(window.getSelection(), ele)
                        : undefined;

                    if (!this.sentenceContentChangeList.includes(idx)) {
                      this.sentenceContentChangeList.push(idx);
                    }
                    this.props.file.addContentEditLog({
                      utteranceIndex: idx,
                      oldValue: oldContent,
                      newValue: newContent,
                      startIndex: start,
                      endIndex: end,
                      replacement: replacement,
                      oldHighlights:
                        this.props.file.utterances[idx].user_highlights,
                      newHighlights: newHighlights,
                      oldAutoSummary: utterance.auto_summary,
                      newAutoSummary: utterance.auto_summary,
                    });
                    this.props.onContentChanged();

                    // restore cursor position after re-render
                    if (currentSelection) {
                      this.setSelectionRange(
                        ele,
                        currentSelection.start,
                        currentSelection.end,
                      );
                    }
                  }, 30);
                }}
                onChangedHighlight={(newHighlights) => {
                  if (!this.sentenceHighlightChangeList.includes(idx)) {
                    this.sentenceHighlightChangeList.push(idx);
                  }
                  let content = this.props.file.utterances[idx].content;
                  this.props.file.addContentEditLog({
                    utteranceIndex: idx,
                    oldValue: content,
                    newValue: content,
                    startIndex: 0,
                    endIndex: 0,
                    replacement: "",
                    oldHighlights:
                      this.props.file.utterances[idx].user_highlights,
                    newHighlights: newHighlights,
                    oldAutoSummary: utterance.auto_summary,
                    newAutoSummary: utterance.auto_summary,
                  });
                  this.props.onHighlightChanged();
                }}
                onRemoveAutoSummary={() => {
                  this.eventCount.removeAutoSummary++;
                }}
              />
            );
          })}
          {this.props.file?.quotaArrears > 0 && !this.props.isEditing && (
            <QuotaArrearsContainer file={this.props.file} />
          )}
        </div>

        {/* for saving status and undo/redo */}
        {!this.props.show || !this.props.isEditing ? null : (
          <ContentEditingStatusWidget
            file={this.props.file}
            onUndo={() => {
              setTimeout(() => {
                this.undoContentChange();
              }, 50);
            }}
            onRedo={() => {
              setTimeout(() => {
                this.redoContentChange();
              }, 50);
            }}
          />
        )}

        {/* for editing controller */}
        {/* {!this.props.show || !this.props.isEditing ? null : (
                    <ContentEditingControlWidget
                        file={this.props.file}
                        type={this.state.editType}
                        onSelect={(type) => {
                            this.setState({
                                editType: type,
                            });
                            this.props.onSelectingEditType(type);
                        }}
                    />
                )} */}
      </>
    );
  }
}
