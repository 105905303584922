import React from "react";
import Modal from "semantic-ui-react/dist/commonjs/modules/Modal";
import Switch from "@material-ui/core/Switch";
import DataManager from "../DataManager";

import LoadingPage from "../page/LoadingPage";
import { ModalHeader, ModalActions } from "./Component";
import { ButtonColor } from "../GlobalDefine";
import Utils from "../Utils";
import { Translation } from "react-i18next";

import "../../assets/scss/FeedbackWindow.scss";

interface FeedbackWindowProps {
  show: boolean; // show this window or not

  onCancel: () => void;
  onSend: () => void;
}

interface FeedbackWindowStates {
  feedback: string;
  includeScreenshot: boolean;
  loadingScreenshot: boolean;
  sending: boolean;
}

async function screenshot() {
  const html2canvas = (await import("html2canvas")).default;
  return html2canvas(document.getElementById("root"));
}

export default class FeedbackWindow extends React.Component<
  FeedbackWindowProps,
  FeedbackWindowStates
> {
  private _screenshotContainer: React.RefObject<HTMLDivElement>;

  constructor(props: FeedbackWindowProps) {
    super(props);
    this.state = {
      feedback: "",
      includeScreenshot: false,
      loadingScreenshot: false,
      sending: false,
    };
    this._screenshotContainer = React.createRef<HTMLDivElement>();
  }

  componentDidUpdate(
    prevProps: FeedbackWindowProps,
    prevStats: FeedbackWindowStates,
  ) {
    if (!prevProps.show && this.props.show && this.state.includeScreenshot) {
      this.setState({
        loadingScreenshot: true,
      });
      setTimeout(() => {
        this.takeScreenshot();
      }, 500);
    }
  }

  private reset() {
    this.setState({
      feedback: "",
      includeScreenshot: false,
    });
  }

  private takeScreenshot() {
    DataManager.instance.setScreenshotMode(true);
    this.setState(
      {
        loadingScreenshot: true,
      },
      () => {
        screenshot().then((canvas) => {
          this.setState(
            {
              loadingScreenshot: false,
            },
            () => {
              DataManager.instance.setScreenshotMode(false);
              if (this._screenshotContainer.current) {
                this._screenshotContainer.current.innerHTML = "";
                canvas.id = "captured-screenshot";
                canvas.style.width = "100%";
                canvas.style.height = "auto";
                this._screenshotContainer.current.append(canvas);
              }
            },
          );
        });
      },
    );
  }

  render() {
    return (
      <Translation>
        {(t) => (
          <Modal open={this.props.show} size="mini">
            <ModalHeader
              title={t("feedback")}
              textAlign="left"
              onClose={() => {
                this.props.onCancel();
              }}
            />
            <div className="feedback-container">
              <div className="feedback-content">
                <textarea
                  placeholder={t("please_leave_your_feedback")}
                  value={this.state.feedback}
                  onChange={(event) => {
                    this.setState({
                      feedback: event.target.value,
                    });
                  }}
                />
              </div>
              <div className="feedback-options">
                <div className="option-container">
                  <div className="option-title">{t("include_screenshots")}</div>
                  <Switch
                    className="slide-bar"
                    checked={this.state.includeScreenshot}
                    onClick={() => {
                      this.setState(
                        {
                          includeScreenshot: !this.state.includeScreenshot,
                        },
                        () => {
                          if (this.state.includeScreenshot) {
                            this.takeScreenshot();
                          }
                        },
                      );
                    }}
                  />
                </div>
                {!this.state.includeScreenshot ? null : (
                  <div className="screenshot-container">
                    {this.state.loadingScreenshot ? (
                      <LoadingPage />
                    ) : (
                      <div
                        ref={this._screenshotContainer}
                        className="screenshot-canvas-container"
                      />
                    )}
                  </div>
                )}
              </div>
            </div>
            <ModalActions
              actions={[
                {
                  color: ButtonColor.Orange,
                  disabled:
                    this.state.feedback.trim().length === 0 ||
                    this.state.sending ||
                    (this.state.includeScreenshot &&
                      this.state.loadingScreenshot),
                  content: this.state.sending ? t("sending") : t("sent"),
                  onClick: () => {
                    this.setState({
                      sending: true,
                    });
                    let screenshot = undefined;
                    if (this.state.includeScreenshot) {
                      let canvas = document.getElementById(
                        "captured-screenshot",
                      );
                      if (canvas) {
                        screenshot = (canvas as HTMLCanvasElement).toDataURL(
                          "image/jpeg",
                        );
                      }
                    }
                    Utils.getApi("put", "/db/user/feedback")
                      .send({
                        from: "web",
                        feedback: this.state.feedback.trim(),
                        screenshot: screenshot ?? undefined,
                      })
                      .then((res) => {
                        this.setState({
                          sending: false,
                        });
                        if (!res.body.success) {
                          alert(t("alert_try_again_later"));
                        } else {
                          alert(t("your_feedback_has_been_sent"));
                          this.reset();
                          this.props.onSend();
                        }
                      })
                      .catch((err) => {
                        this.setState({
                          sending: false,
                        });
                        alert(
                          t(
                            "submit_fail_please_check_network_status_and_try_again",
                          ),
                        );
                      });
                  },
                },
              ]}
            />
          </Modal>
        )}
      </Translation>
    );
  }
}
