import React from "react";
import dayjs from "dayjs";
import { Color2, ButtonColor, MessageBoxColor } from "../GlobalDefine";
import {
  ActionButton,
  ModalHeader,
  ModalActions,
  FloatOptionButton,
  SiteMessageBox,
} from "../ui/Component";
import Modal from "semantic-ui-react/dist/commonjs/modules/Modal";
import Dropdown from "semantic-ui-react/dist/commonjs/modules/Dropdown";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { AilabsAsr, LiveStreamingErrorType } from "../pipeline/AilabsAsr";
import { AudioController, Device } from "../pipeline/AudioController";
import Logger from "../Logger";
import Utils, { RedirectTo } from "../Utils";
import Config from "../Config";
import DataManager, { VoiceLang } from "../DataManager";
import Voice, { Translations, VoiceSentenceLine, VoiceState } from "../Voice";
import { Translation } from "react-i18next";
import i18n from "../../i18n";
import { ErrorModal } from "../ui/ErrorModal";

import "../../assets/scss/LiveTranskribePage.scss";
import checkedIcon from "../../assets/img/icon-multiple-choice-active.svg";
import clockIcon from "../../assets/img/icon-clock.svg";
import deviceIcon from "../../assets/img/icon-recording.svg";
import pauseIcon from "../../assets/img/icon-pause.svg";
import resumeIcon from "../../assets/img/icon-recording-orange.svg";
import stopIcon from "../../assets/img/icon-stop.svg";
import stopIconDisabled from "../../assets/img/icon-stop-white.svg";
import autoScrollIcon from "../../assets/img/icon-auto-scroll.svg";
import captionIcon from "../../assets/img/icon-caption-gray.svg";
import connectingImg from "../../assets/img/animation-loading-all.gif";

import logo from "../../assets/img/logo.png";
import translationIcon from "../../assets/img/icon-caption-translation-shift-svg.svg";

enum PipelineState {
  Idle,
  Closing,
  Connecting,
  Recording,
  Pause,
  AskSave,
  SaveError,
  SaveDone,
  WaitForClose,
}

// sync with asr-stream-proxy.ts
enum StatusCode {
  SERVER_RECONNECT,
  SERVER_CONNECTED,
  DURATION_HINT,
  OVER_DURATION_LIMIT,
  READY_TO_CLOSE,
}

enum Mode {
  Normal,
  Caption,
}

enum CaptionSize {
  Big,
  Medium,
  Small,
}

enum CaptionLayout {
  NewestAtTop,
  NewestAtBottom,
}

class AudioSourceSelection extends React.Component<
  {
    show: boolean;
    devices: Device[];
    onAudioDeviceSelected: (deviceId: string, label: string) => {};
  },
  {
    selectedDeviceId: string;
  }
> {
  constructor(props: any) {
    super(props);
    const defaultDeviceId =
      this.props.devices.length > 0 ? this.props.devices[0].deviceId : "";
    this.state = {
      selectedDeviceId: defaultDeviceId,
    };
  }

  private handleChange(event: HTMLInputElement, value: string) {
    this.setState({
      selectedDeviceId: value,
    });
  }

  render() {
    return (
      <Translation ns="liveTranskribe">
        {(t) => (
          <Modal open={this.props.show} size="tiny" dimmer="blurring">
            <ModalHeader
              title={t("please_select_the_audio_input_source")}
              textAlign="left"
            />
            <div
              className="audio-selector-modal-body"
              style={{ color: Color2.dark }}
            >
              <RadioGroup
                value={this.state.selectedDeviceId}
                onChange={this.handleChange.bind(this)}
              >
                {this.props.devices.map((device, index) => {
                  return (
                    <FormControlLabel
                      key={index}
                      value={device.deviceId}
                      control={
                        <Radio
                          style={{
                            marginRight: "5px",
                          }}
                          icon={
                            <div className="radio-check-icon">
                              <div />
                            </div>
                          }
                          checkedIcon={
                            <img
                              src={checkedIcon}
                              className="radio-checked-icon"
                            />
                          }
                        />
                      }
                      label={device.label}
                    />
                  );
                })}
              </RadioGroup>
            </div>
            <ModalActions
              actions={[
                {
                  color: ButtonColor.Orange,
                  disabled: !this.state.selectedDeviceId,
                  content: i18n.t("select"),
                  style: {
                    margin: "0px 0px 0px 0px",
                    width: "130px",
                  },
                  onClick: (evt) => {
                    let label = "";
                    this.props.devices.forEach((device) => {
                      if (device.deviceId === this.state.selectedDeviceId) {
                        label = device.label;
                      }
                    });
                    this.props.onAudioDeviceSelected(
                      this.state.selectedDeviceId,
                      label,
                    );
                    Utils.analyticsEvent({
                      category: "Live Recording",
                      action: "Slected Device",
                      label: label,
                    });
                  },
                },
              ]}
            />
          </Modal>
        )}
      </Translation>
    );
  }
}

class CaptionWindow extends React.Component<
  {
    show: boolean;
    language: string;
    translation?: string;
    currentSentence: string;
    currentSentenceTranslation?: string;
    sentences: VoiceSentenceLine[];
    onChangeTranslation: (lang?: string) => void;
    onChangeLayout: (captionLayout: CaptionLayout) => void;
    onChangeFontSize: (captionSize: CaptionSize) => void;
    onExit: () => void;
  },
  {
    showCaptionControl: boolean;
    isFocusCaptionControl: boolean;
    captionSize: CaptionSize;
    captionLayout: CaptionLayout;
    translation?: string;
  }
> {
  private _asrTextWindow: React.RefObject<HTMLDivElement>;
  private _translationTextWindow: React.RefObject<HTMLDivElement>;
  private isComponentMounted: boolean;
  private hideControlPanel: number;
  private onMouseMovePoint?: {
    x: number;
    y: number;
  };

  constructor(props: any) {
    super(props);
    this._asrTextWindow = React.createRef<HTMLDivElement>();
    this._translationTextWindow = React.createRef<HTMLDivElement>();
    this.state = {
      showCaptionControl: false,
      isFocusCaptionControl: false,
      captionSize: CaptionSize.Big,
      captionLayout: CaptionLayout.NewestAtTop,
      translation: this.props.translation,
    };
  }

  componentDidMount() {
    this.isComponentMounted = true;
    document.addEventListener("mousemove", this.onMouseMove.bind(this));
  }

  componentWillUnmount() {
    this.isComponentMounted = false;
    document.removeEventListener("mousemove", this.onMouseMove.bind(this));
  }

  componentDidUpdate(prevProps: any, prevStats: any) {
    if (
      this.props.sentences.length !== prevProps.sentences.length ||
      this.props.currentSentence !== prevProps.currentSentence
    ) {
      this.scrollCaptionToBottom();
    }

    if (this.props.translation !== prevProps.translation) {
      if (this.isComponentMounted) {
        this.setState({
          translation: this.props.translation,
        });
      }
    }
  }

  private onMouseMove(event?: MouseEvent) {
    // check moved distance
    if (
      event &&
      this.onMouseMovePoint &&
      Math.abs(this.onMouseMovePoint.x - event.clientX) +
        Math.abs(this.onMouseMovePoint.y - event.clientY) <
        100
    ) {
      return;
    }
    if (event && !this.onMouseMovePoint) {
      this.onMouseMovePoint = {
        x: event.clientX,
        y: event.clientY,
      };
      return;
    }

    if (this.hideControlPanel) {
      clearTimeout(this.hideControlPanel);
    }
    if (this.isComponentMounted) {
      this.setState({
        showCaptionControl: true,
      });
      setTimeout(() => {
        if (this.isComponentMounted) {
          this.setState({
            showCaptionControl: false,
          });
          this.onMouseMovePoint = undefined;
        }
      }, 3000);
    }
  }

  private scrollCaptionToBottom() {
    if (this.state.captionLayout === CaptionLayout.NewestAtBottom) {
      const asrContent = this._asrTextWindow.current;
      if (asrContent) {
        asrContent.scrollTop =
          asrContent.scrollHeight - asrContent.clientHeight;
      }
      const translationContent = this._translationTextWindow.current;
      if (translationContent) {
        translationContent.scrollTop =
          translationContent.scrollHeight - translationContent.clientHeight;
      }
    } else {
      const asrContent = this._asrTextWindow.current;
      if (asrContent) {
        asrContent.scrollTop = 0;
      }
      const translationContent = this._translationTextWindow.current;
      if (translationContent) {
        translationContent.scrollTop = 0;
      }
    }
  }

  private getLanguageOptions() {
    let options = [{ text: i18n.t("close"), value: `${this.props.language}` }];
    DataManager.instance.asrModels.live.forEach((asrModel) => {
      if (asrModel.id === this.props.language) {
        asrModel.translations.forEach((translation) => {
          options.push({
            text: i18n.t("translate_to", {
              ns: "liveTranskribe",
              name: i18n.t(translation.targetLangCode, {
                ns: "liveTranskribe",
              }),
            }),
            value: translation.id,
          });
        });
      }
    });

    return options;
  }

  render() {
    let sentences: any;
    if (this.props.show) {
      sentences = this.props.sentences.slice(0);
      if (this.state.captionLayout === CaptionLayout.NewestAtTop) {
        sentences = sentences.reverse();
      }
    }

    return this.props.show ? (
      <Translation ns="liveTranskribe">
        {(t) => (
          <div
            id="fullscreen"
            style={{
              cursor:
                this.state.showCaptionControl ||
                this.state.isFocusCaptionControl
                  ? ""
                  : "none",
            }}
            onClick={() => {
              this.onMouseMove();
            }}
          >
            <div
              className={`caption-text${this.props.translation ? " translation" : ""} ${
                CaptionLayout[this.state.captionLayout]
              } ${CaptionSize[this.state.captionSize]}`}
            >
              <div
                ref={this._asrTextWindow}
                className={`asr-sentence`}
                onScroll={() => {
                  this.scrollCaptionToBottom();
                }}
              >
                {this.state.captionLayout === CaptionLayout.NewestAtTop &&
                this.props.currentSentence.length > 0 ? (
                  <div key={0}>{this.props.currentSentence}</div>
                ) : (
                  ""
                )}
                {sentences.map((sentence: any, index: number) => {
                  if (sentence.startTime > 0 || sentence.endTime > 0) {
                    return <div key={index + 1}>{sentence.content}</div>;
                  } else {
                    return "";
                  }
                })}
                {this.state.captionLayout === CaptionLayout.NewestAtBottom &&
                this.props.currentSentence.length > 0 ? (
                  <div key={0}>{this.props.currentSentence}</div>
                ) : (
                  ""
                )}
              </div>

              <div
                ref={this._translationTextWindow}
                className={`translation`}
                style={{
                  display: this.props.translation ? "" : "none",
                }}
                onScroll={() => {
                  this.scrollCaptionToBottom();
                }}
              >
                {this.state.captionLayout === CaptionLayout.NewestAtTop &&
                this.props.currentSentence.length > 0 ? (
                  <div key={0}>{this.props.currentSentenceTranslation}</div>
                ) : (
                  ""
                )}
                {sentences.map((sentence: any, index: number) => {
                  let translation = sentence.translations
                    ? sentence.translations[this.state.translation]
                    : undefined;
                  if (
                    !translation &&
                    sentence.translations &&
                    Object.keys(sentence.translations).length > 0
                  ) {
                    translation =
                      sentence.translations[
                        Object.keys(sentence.translations)[0]
                      ];
                  }
                  return <div key={index + 1}>{translation}</div>;
                })}
                {this.state.captionLayout === CaptionLayout.NewestAtBottom &&
                this.props.currentSentence.length > 0 ? (
                  <div key={0}>{this.props.currentSentenceTranslation}</div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div
              className={
                "caption-control-panel " +
                CaptionLayout[this.state.captionLayout]
              }
              style={{
                display:
                  this.state.showCaptionControl ||
                  this.state.isFocusCaptionControl
                    ? ""
                    : "none",
              }}
              onMouseEnter={() => {
                this.setState({ isFocusCaptionControl: true });
              }}
              onMouseLeave={() => {
                this.onMouseMove();
                this.setState({ isFocusCaptionControl: false });
              }}
            >
              <div className="background">
                <div
                  className={`language-control ${
                    DataManager.instance.getAvailableTranslations(
                      this.props.language,
                    ).length === 0
                      ? "hide"
                      : ""
                  }`}
                >
                  <div>{`${t("translation")}：`}</div>
                  <div className="fix-child-style">
                    <Dropdown
                      fluid
                      direction="right"
                      options={this.getLanguageOptions()}
                      value={
                        this.state.translation
                          ? `${this.props.language}/${this.state.translation}`
                          : this.props.language
                      }
                      onChange={(event, data) => {
                        const values = data.value.toString().split("/");
                        let translation: string = undefined;
                        if (values.length > 1) {
                          if (values[0] === this.props.language) {
                            translation = values[1];
                          } else {
                            translation = values[0];
                          }
                        }
                        this.setState(
                          {
                            translation: translation,
                          },
                          () => {
                            this.scrollCaptionToBottom();
                          },
                        );
                        this.props.onChangeTranslation(translation);
                      }}
                    />
                  </div>
                </div>
                <div className="position-control">
                  <div>{`${t("position")}：`}</div>
                  <div className="fix-child-style">
                    <Dropdown
                      fluid
                      direction="right"
                      options={[
                        {
                          text: t("bottom"),
                          value: CaptionLayout[CaptionLayout.NewestAtBottom],
                        },
                        {
                          text: t("top"),
                          value: CaptionLayout[CaptionLayout.NewestAtTop],
                        },
                      ]}
                      value={CaptionLayout[this.state.captionLayout]}
                      onChange={(event, data) => {
                        const value = data.value.toString();
                        const layout =
                          CaptionLayout[value as keyof typeof CaptionLayout];
                        this.setState(
                          {
                            captionLayout: layout,
                          },
                          () => {
                            this.scrollCaptionToBottom();
                          },
                        );
                        this.props.onChangeLayout(layout);
                      }}
                    />
                  </div>
                </div>
                <div className="font-size-control">
                  <div
                    className={`big${this.state.captionSize === CaptionSize.Big ? " active" : ""}`}
                    onClick={(evt) => {
                      this.setState({ captionSize: CaptionSize.Big });
                      this.props.onChangeFontSize(CaptionSize.Big);
                    }}
                  >
                    {t("large")}
                  </div>
                  <div
                    className={`medium${
                      this.state.captionSize === CaptionSize.Medium
                        ? " active"
                        : ""
                    }`}
                    onClick={(evt) => {
                      this.setState({ captionSize: CaptionSize.Medium });
                      this.props.onChangeFontSize(CaptionSize.Medium);
                    }}
                  >
                    {t("medium")}
                  </div>
                  <div
                    className={`small${
                      this.state.captionSize === CaptionSize.Small
                        ? " active"
                        : ""
                    }`}
                    onClick={(evt) => {
                      this.setState({ captionSize: CaptionSize.Small });
                      this.props.onChangeFontSize(CaptionSize.Small);
                    }}
                  >
                    {t("small")}
                  </div>
                </div>
                <div className="other-control">
                  <div
                    onClick={(evt) => {
                      this.props.onExit();
                    }}
                  >
                    {t("exit")}
                  </div>
                </div>
              </div>
            </div>
            <img className="caption-logo" src={logo} alt="AILabs" />
          </div>
        )}
      </Translation>
    ) : (
      ""
    );
  }
}

enum ServiceStatus {
  Unavailable = -1,
  BadConnection = 0,
  Normal = 1,
}

function getServiceStatusLabel(status: ServiceStatus): string {
  switch (status) {
    case ServiceStatus.Unavailable:
      return i18n.t("alert_service_disconnected", { ns: "liveTranskribe" });
    case ServiceStatus.BadConnection:
      return i18n.t("alert_network_unstable", { ns: "liveTranskribe" });
    case ServiceStatus.Normal:
      return i18n.t("alert_good_network_connection", { ns: "liveTranskribe" });
  }
}

interface State {
  language: VoiceLang;
  translation?: string;
  pipelineState: PipelineState;
  serviceStatus: ServiceStatus;
  asrProcConnected: boolean;
  currentSentence: string;
  currentSentenceTranslation?: string;
  sentences: VoiceSentenceLine[];
  isEnableASREnhanced: boolean;
  superFinalIdx: number;
  startTime: Date;
  recordTime: number;
  volume: number;
  filename: string;
  file?: Voice;
  audioSourceSelected: boolean;
  autoScroll: boolean;
  onSelectingTranslation: boolean;
  mode: Mode;
  isOpenErrorModal: boolean;
  errorType: LiveStreamingErrorType | undefined;
}

export default class LiveTranskribePage extends React.Component<any, State> {
  static urlParam = "/livetranskribe";
  private _contentWindow: React.RefObject<HTMLDivElement>;
  private _displayControlPanel: React.RefObject<HTMLDivElement>;
  private _messageBox: React.RefObject<SiteMessageBox>;
  private isComponentMounted: boolean;
  private asr: AilabsAsr;
  private audio: AudioController;
  private checkBufferId: number;
  private audioDevices: Device[];
  private audioDeviceName: string;
  private translationUsedSecs: {
    [key in string]?: number;
  };

  // setting when open
  private mode: Mode = Mode.Normal;
  private micName: string = undefined;

  // tracking
  private isClickEndTrackingSent: boolean;

  constructor(props: any) {
    super(props);
    this._contentWindow = React.createRef<HTMLDivElement>();
    this._displayControlPanel = React.createRef<HTMLDivElement>();
    this._messageBox = React.createRef<SiteMessageBox>();
    this.audio = new AudioController(
      this.onAudioProcess.bind(this),
      this.onUserMedia.bind(this),
      this.onUserMediaError.bind(this),
      this.onDeviceNotSupport.bind(this),
      this.onDeviceDetected.bind(this),
    );
    const current = new Date();
    this.state = {
      language: VoiceLang.Chinese,
      translation: undefined,
      pipelineState: PipelineState.Idle,
      serviceStatus: ServiceStatus.Unavailable,
      asrProcConnected: false,
      currentSentence: "",
      currentSentenceTranslation: undefined,
      sentences: [],
      isEnableASREnhanced: false,
      superFinalIdx: 0,
      startTime: current,
      recordTime: 0,
      volume: 0,
      filename: this.generateFilename(current),
      file: undefined,
      audioSourceSelected: false,
      autoScroll: true,
      onSelectingTranslation: false,
      mode: Mode.Normal,
      isOpenErrorModal: false,
      errorType: undefined,
    };
    this.checkBufferId = 0;
    this.audioDevices = [];
    this.audioDeviceName = "default";
    this.translationUsedSecs = {};
    this.isClickEndTrackingSent = false;
  }

  componentDidMount() {
    this.isComponentMounted = true;

    let useDefaultLang = true;
    if (this.props.location && this.props.location.search) {
      if (
        Utils.getValueFromUrlQuery(this.props.location.search, "mode") ===
        "caption"
      ) {
        this.mode = Mode.Caption;
      }
      this.micName = Utils.getValueFromUrlQuery(
        this.props.location.search,
        "mic",
      );
      if (this.micName && this.micName.length > 0) {
        this.micName = decodeURI(this.micName);
      }
      let language = DataManager.instance.getASRModel(
        Utils.getValueFromUrlQuery(this.props.location.search, "lang"),
      );
      if (language) {
        useDefaultLang = false;
        this.setState(
          {
            language: language.id,
          },
          () => {
            this.asr = new AilabsAsr(
              this.onSettingReady.bind(this),
              this.onPipelineReady.bind(this),
              this.onSentence.bind(this),
              this.onReconnect.bind(this),
              this.onAsrStatus.bind(this),
              this.onEnableASREnhanced.bind(this),
              this.onDefinedError.bind(this),
            );
            DataManager.instance
              .getAvailableTranslations(this.state.language)
              .forEach((translation) => {
                this.translationUsedSecs[translation.targetLangCode] = 0;
              });
          },
        );
      }
      let translation = DataManager.instance.getTranslationModel(
        language ? language.id : this.state.language,
        Utils.getValueFromUrlQuery(this.props.location.search, "translation"),
      );
      if (translation) {
        this.setState({
          translation: translation.targetLangCode,
        });
      }
    }

    if (useDefaultLang) {
      this.asr = new AilabsAsr(
        this.onSettingReady.bind(this),
        this.onPipelineReady.bind(this),
        this.onSentence.bind(this),
        this.onReconnect.bind(this),
        this.onAsrStatus.bind(this),
        this.onEnableASREnhanced.bind(this),
        this.onDefinedError.bind(this),
      );
      DataManager.instance
        .getAvailableTranslations(this.state.language)
        .forEach((translation) => {
          this.translationUsedSecs[translation.targetLangCode] = 0;
        });
    }

    window.onbeforeunload = () => {
      if (
        this.state.pipelineState < PipelineState.SaveDone &&
        this.state.pipelineState !== PipelineState.Idle
      ) {
        Utils.analyticsEvent({
          category: "Live Recording",
          action: "Closing Window Before End",
          value: Math.floor(this.state.recordTime / 1000),
          lang: this.state.language,
        });
        return i18n.t("alert_close_unsaved_file", { ns: "liveTranskribe" });
      } else {
        return null;
      }
    };

    // API for control UI from outside:

    // change mode
    (window as any).goToMode = (mode: string) => {
      if (mode === "caption") {
        this.onClickCaptionMode();
      } else if (mode === "normal") {
        this.onEnterNormalMode();
      }
    };

    // change translation
    (window as any).useTranslation = (trans: string) => {
      if (trans) {
        let translation = DataManager.instance.getTranslationModel(
          this.state.language,
          trans,
        );
        if (translation) {
          this.setState({
            translation: translation.targetLangCode,
          });
          this.asr.useTranslantion(translation.targetLangCode);
        }
      } else {
        this.setState({
          translation: undefined,
        });
        this.asr.stopTranslantion();
      }
    };

    Utils.analyticsPageView("/livetranskribe");
  }

  componentDidUpdate() {}

  componentWillUnmount(): void {
    this.isComponentMounted = false;
    if (this.checkBufferId) {
      clearInterval(this.checkBufferId);
    }
    this.audio.closeAudioIn();
    this.asr.disconnect();
    window.onbeforeunload = undefined;
  }

  private closeWindow() {
    setTimeout(function () {
      window.close();
    }, 500);
  }

  private onScroll(event: React.UIEvent<HTMLDivElement>) {
    const content = this._contentWindow.current;
    console.log(
      "onScroll",
      content.scrollHeight,
      content.scrollTop,
      content.clientHeight,
    );
    // 2 is a threshold
    // Sometimes user didn't scroll, but the scrollHeight - scollTop - clientHeight = 2
    // So I added 2 to be a threshold
    if (content.scrollHeight - content.scrollTop > content.clientHeight + 2) {
      this.setState({
        autoScroll: false,
      });
    } else {
      this.setState({
        autoScroll: true,
      });
    }
  }

  public handleKeyDownListener(e: KeyboardEvent): boolean {
    if (e.keyCode === 27 && this.state.mode === Mode.Caption) {
      this.onEnterNormalMode();
    }
    return true;
  }

  private onClickRecordEnd() {
    if (this.isComponentMounted) {
      if (!this.isClickEndTrackingSent) {
        this.isClickEndTrackingSent = true;

        let event: any = {
          category: "Live Recording",
          action: "Clicked End",
          value: Math.floor(this.state.recordTime / 1000),
          lang: this.state.language,
        };
        Object.keys(this.translationUsedSecs).forEach((key) => {
          event[`translation_${key}`] = this.translationUsedSecs[key];
        });
        Utils.analyticsEvent(event);

        Utils.analyticsUserPropertiesAddValue([
          {
            property: "Live Recording",
            value: 1,
          },
        ]);
        Utils.analyticsUserPropertiesAddValue([
          {
            property: "Live Recording Hours",
            value: this.state.recordTime / 1000.0 / 3600.0,
          },
        ]);
      }

      this.audio.closeAudioIn();
      this.asr.requestClose();
      if (this.checkBufferId) {
        clearInterval(this.checkBufferId);
      }
      this.setState({
        pipelineState: PipelineState.Closing,
      });
    }
  }

  private onReadyToClose() {
    this.asr.disconnect();

    this.setState(
      {
        pipelineState: PipelineState.AskSave,
        file: Voice.getVoice(this.asr.FileEid),
      },
      () => {
        if (this._messageBox.current) {
          this._messageBox.current.show();
        }
        this.updateFileStatusTillDone();
      },
    );
  }

  // TODO: refactor transfer meetId
  // private async updateMeetNoAndFileEid(meetNo: string, fileEid: string) {
  //     let res = await Utils.getApi("post", "/external/UpdateMeetEid").send({
  //         eid: fileEid,
  //         meetNo,
  //     });
  //     if (!res.body.success) {
  //         console.log("send meetNo and fileEid error");
  //     }
  // }

  private onClickRecordPause() {
    if (this.isComponentMounted) {
      this.audio.pauseAudio();
      this.setState({
        pipelineState: PipelineState.Pause,
        volume: 0,
      });
      Utils.analyticsEvent({
        category: "Live Recording",
        action: "Clicked Pause",
        value: Math.floor(this.state.recordTime / 1000),
        lang: this.state.language,
      });
    }
  }

  private onClickRecordResume() {
    if (this.isComponentMounted) {
      this.audio.resumeAudio();
      this.setState({
        pipelineState: PipelineState.Recording,
      });
      Utils.analyticsEvent({
        category: "Live Recording",
        action: "Clicked Resume",
        value: Math.floor(this.state.recordTime / 1000),
        lang: this.state.language,
      });
    }
  }

  private onClickAutoScroll() {
    this.setState(
      {
        autoScroll: true,
      },
      () => {
        this.scrollToBottom();
        Utils.analyticsEvent({
          category: "Live Recording",
          action: "Clicked AutoScroll",
          value: Math.floor(this.state.recordTime / 1000),
          lang: this.state.language,
        });
      },
    );
  }

  private onClickCaptionMode() {
    if (this.isComponentMounted) {
      this.setState({
        mode: Mode.Caption,
      });
      Utils.analyticsEvent({
        category: "Live Recording",
        action: "Go Caption Mode",
        value: Math.floor(this.state.recordTime / 1000),
        lang: this.state.language,
      });
    }
  }

  private onEnterNormalMode() {
    if (this.state.mode === Mode.Normal) {
      return;
    }
    if (this.isComponentMounted) {
      this.setState({
        mode: Mode.Normal,
      });
      Utils.analyticsEvent({
        category: "Live Recording",
        action: "Go Normal Mode",
        value: Math.floor(this.state.recordTime / 1000),
        lang: this.state.language,
      });
    }
  }

  // header file name change => update db
  private onBlurHeader(event: React.ChangeEvent<HTMLInputElement>) {
    if (this.isComponentMounted && event.target.innerText) {
      let newName = event.target.innerText.replace("\n", " ").trim();
      if (newName.length > 0 && this.state.filename !== newName) {
        Utils.analyticsEvent({
          category: "Live Recording",
          action: "Change Title",
          label: event.target.innerText,
          value: Math.floor(this.state.recordTime / 1000),
          old_label: this.state.filename,
          lang: this.state.language,
        });
        this.setState({
          filename: newName,
        });
        this.asr
          .updateFileName(newName)
          .then((success) => {
            if (!success) {
              Logger.error(`Live Recording change title error`, {
                lang: this.state.language,
              });
            }
          })
          .catch((err) => {
            Logger.error(`Live Recording change title error ${err}`, {
              lang: this.state.language,
              errorMessage: err.message,
              errorName: err.name,
            });
          });
      } else {
        event.target.innerText = this.state.filename;
      }
    } else {
      event.target.innerText = this.state.filename;
    }
  }

  private onKeyDownHeader(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.keyCode == 13) {
      // enter
      (event.target as HTMLElement).blur();
    } else if (event.keyCode == 27) {
      // esc
      (event.target as HTMLElement).innerText = this.state.filename;
      (event.target as HTMLElement).blur();
    }
  }

  private async onSettingReady() {
    await this.audio.openAudioIn();
    if (this.isComponentMounted && this.audio.checkAudioIn()) {
      this.asr.connect(
        this.state.language,
        this.state.filename,
        this.audioDeviceName,
        this.state.translation,
      );
      this.setState({
        pipelineState: PipelineState.Connecting,
      });
    } else {
      setTimeout(this.onSettingReady.bind(this), 500);
    }
  }

  private onPipelineReady() {
    if (this.checkBufferId) {
      clearInterval(this.checkBufferId);
    }
    this.checkBufferId = setInterval(this.checkBuffer.bind(this), 300);

    if (this.isComponentMounted) {
      this.setState({
        pipelineState: PipelineState.Recording,
      });
      if (this.mode === Mode.Caption) {
        this.onClickCaptionMode();
      }
    }

    // TODO refactor to pass eid in frontend
    if (Config.EnableTransferEid) {
      // this.updateMeetNoAndFileEid(
      //     Utils.getValueFromUrlQuery(this.props.location.search, "meetNo"),
      //     this.asr.FileEid
      // );
    }
  }

  private onSentence(
    s: string,
    begin_time: number,
    end_time: number,
    asr_final: boolean,
    translations?: Translations,
    asrSuperFinal?: boolean,
  ) {
    const asr_sentence = this.state.sentences;
    if (asrSuperFinal) {
      asr_sentence[this.state.superFinalIdx] = {
        content: s,
        translations: translations,
        startTime: Math.round(begin_time * 1000),
        endTime: Math.round(end_time * 1000),
      };
      this.setState({
        sentences: asr_sentence,
        superFinalIdx: this.state.superFinalIdx + 1,
      });
    } else if (asr_final) {
      asr_sentence.push({
        content: s,
        translations: translations,
        startTime: Math.round(begin_time * 1000),
        endTime: Math.round(end_time * 1000),
      });
      // caculate used translation
      if (translations && Object.keys(translations).length > 0) {
        Object.keys(translations).forEach((key) => {
          this.translationUsedSecs[key] =
            (this.translationUsedSecs[key]
              ? this.translationUsedSecs[key]
              : 0) +
            (end_time - begin_time);
        });
      }

      if (this.isComponentMounted) {
        this.setState({
          sentences: asr_sentence,
          currentSentence: "",
          currentSentenceTranslation: "",
        });
      }
    } else {
      if (this.isComponentMounted) {
        this.setState({
          currentSentence: s,
          currentSentenceTranslation:
            translations && this.state.translation
              ? translations[this.state.translation]
              : undefined,
        });
      }
    }
    if (this.state.autoScroll) {
      this.scrollToBottom();
    }
  }

  private manuallyAppendSentence(sentence: string) {
    if (this.isComponentMounted) {
      const asr_sentence = this.state.sentences;

      asr_sentence.push({
        content: sentence,
        startTime: 0,
        endTime: 0,
      });

      this.setState({
        sentences: asr_sentence,
      });

      if (this.state.autoScroll) {
        this.scrollToBottom();
      }
    }
  }

  private onReconnect() {
    this.manuallyAppendSentence(
      i18n.t("alert_service_disconnected_stored_new_transcript", {
        ns: "liveTranskribe",
      }),
    );
    Logger.error(`Live Recording onReconnect`, {
      value: Math.floor(this.state.recordTime / 1000),
      lang: this.state.language,
    });
    Utils.analyticsEvent({
      category: "Live Recording",
      action: "onReconnect",
      value: Math.floor(this.state.recordTime / 1000),
      lang: this.state.language,
    });
  }

  private onAsrStatus(data: any) {
    switch (data.asr_status_code) {
      case StatusCode.SERVER_CONNECTED:
        this.setState({ asrProcConnected: true });
        Utils.analyticsEvent({
          category: "Live Recording",
          action: "Server Connected",
          value: Math.floor(this.state.recordTime / 1000),
          lang: this.state.language,
        });
        break;
      case StatusCode.SERVER_RECONNECT:
        this.setState({ asrProcConnected: false });
        Utils.analyticsEvent({
          category: "Live Recording",
          action: "Server Reconnect",
          value: Math.floor(this.state.recordTime / 1000),
          lang: this.state.language,
        });
        break;
      case StatusCode.DURATION_HINT:
        let maxDurationInMs = parseInt(data.max_duarion);
        let durationInMs = parseInt(data.duration);

        // show hint within 30 mins
        if (30 * 60 * 1000 > maxDurationInMs - durationInMs) {
          let maxHours = (maxDurationInMs / 1000 / 3600).toFixed(2);
          let currentHours = (durationInMs / 1000 / 3600).toFixed(2);
          this.manuallyAppendSentence(
            i18n.t("alert_record_time", {
              ns: "liveTranskribe",
              maxHours,
              currentHours,
            }),
          );
          Utils.analyticsEvent({
            category: "Live Recording",
            action: "Server Duration Hint",
            value: Math.floor(this.state.recordTime / 1000),
            lang: this.state.language,
          });
        }
        break;
      case StatusCode.OVER_DURATION_LIMIT:
        let maxHours = parseInt(data.max_duarion) / 1000 / 3600;

        this.onClickRecordEnd();
        alert(
          i18n.t("alert_exceed_record_time", {
            ns: "liveTranskribe",
            maxHours: maxHours.toFixed(1),
          }),
        );
        Utils.analyticsEvent({
          category: "Live Recording",
          action: "Server Duration Limit",
          value: Math.floor(this.state.recordTime / 1000),
          lang: this.state.language,
        });
        break;
      case StatusCode.READY_TO_CLOSE:
        this.onReadyToClose();
        break;
    }
  }

  private onEnableASREnhanced() {
    this.setState({
      isEnableASREnhanced: true,
    });
  }

  private onDefinedError(errorType: LiveStreamingErrorType) {
    if (errorType === LiveStreamingErrorType.CONNECTION_LIMIT_EXCEEDED) {
      this.setState({
        isOpenErrorModal: true,
        errorType: errorType,
      });
    }
    if (errorType === LiveStreamingErrorType.QUOTA_NOT_ENOUGH) {
      if (this.state.pipelineState === PipelineState.Connecting) {
        this.setState({
          isOpenErrorModal: true,
          errorType: errorType,
        });
      } else {
        this.manuallyAppendSentence(
          i18n.t("alert_quota_not_enough_when_streaming", {
            ns: "liveTranskribe",
          }),
        );
      }
    }
  }

  private onAudioProcess(data: Int16Array, avgVolume: number) {
    this.asr.sendPcm(data);

    if (this.isComponentMounted) {
      this.setState({
        recordTime: this.asr.sentDataInMs,
        volume: avgVolume,
      });
    }
  }
  private onUserMedia(stream: MediaStream) {}

  private onUserMediaError(err: unknown) {
    if (err instanceof DOMException) {
      Utils.analyticsEvent({
        category: "Live Recording",
        action: "onUserMediaError",
        lang: this.state.language,
        label: (err as DOMException).name,
        isIOS: Utils.isIOS,
        message: (err as DOMException).message,
      });

      switch ((err as DOMException).name) {
        case "NotAllowedError":
          this.onShowAlertAndCloseWindow(
            i18n.t("alert_enable_microphone_setting", { ns: "liveTranskribe" }),
          );
          break;

        default:
          if (Utils.isIOS) {
            this.onShowAlertAndCloseWindow(
              i18n.t("alert_unable_to_enable_microphone_safari", {
                ns: "liveTranskribe",
              }),
            );
          } else {
            this.onShowAlertAndCloseWindow(
              i18n.t("alert_unable_to_enable_microphone_chrome", {
                ns: "liveTranskribe",
              }),
            );
          }
          break;
      }
    }
  }
  private onDeviceNotSupport(msg: string, errName: string) {
    Utils.analyticsEvent({
      category: "Live Recording",
      action: "onDeviceNotSupport",
      label: errName,
      lang: this.state.language,
    });
    this.onShowAlertAndCloseWindow(msg);
  }
  private onShowAlertAndCloseWindow(msg: string) {
    alert(msg);
    this.closeWindow();
  }
  private onDeviceDetected(devices: Device[]) {
    if (this.audioDevices.length !== devices.length) {
      this.audioDevices = devices;
      Utils.analyticsEvent({
        category: "Live Recording",
        action: "onDeviceDetected",
        value: this.audioDevices.length,
        lang: this.state.language,
      });
      if (this.audioDevices.length > 1) {
        let found = false;
        if (this.micName && this.micName.length > 0) {
          devices.forEach((device) => {
            if (device.label === this.micName) {
              found = true;
              this.onAudioDeviceSelected(device.deviceId, device.label);
            }
          });
        }
        if (!found) {
          this.setState({
            audioSourceSelected: true,
          });
        }
      } else if (this.audioDevices.length == 1) {
        this.audioDeviceName = devices[0].label;
      }
    }
  }
  private onAudioDeviceSelected(deviceId: string, label: string) {
    //console.log("deviceId = " + deviceId + " label = " + label);
    this.audio.setAudioDeviceId(deviceId);
    this.audioDeviceName = label;
    this.setState({
      audioSourceSelected: false,
    });
  }

  private checkBuffer() {
    if (this.isComponentMounted) {
      let serviceStatus = ServiceStatus.Unavailable;
      if (!this.asr.isWsConnected) {
        if (this.state.serviceStatus != ServiceStatus.Unavailable) {
          Utils.analyticsEvent({
            category: "Live Recording",
            action: "Disconnected",
            value: Math.floor(this.state.recordTime / 1000),
            lang: this.state.language,
          });
        }
      } else {
        if (this.asr.bufferedAmount >= 20000) {
          serviceStatus = ServiceStatus.BadConnection;
        } else if (this.asr.bufferedAmount >= 0) {
          serviceStatus = ServiceStatus.Normal;
        }

        if (
          this.state.serviceStatus === ServiceStatus.Unavailable &&
          serviceStatus >= ServiceStatus.BadConnection
        ) {
          Utils.analyticsEvent({
            category: "Live Recording",
            action: "Connected",
            value: Math.floor(this.state.recordTime / 1000),
            lang: this.state.language,
          });
        } else if (
          this.state.serviceStatus === ServiceStatus.Normal &&
          serviceStatus === ServiceStatus.BadConnection
        ) {
          Logger.info(`Live Recording Bad Network`, {
            value: Math.floor(this.state.recordTime / 1000),
            lang: this.state.language,
          });
        } else if (
          this.state.serviceStatus === ServiceStatus.BadConnection &&
          serviceStatus === ServiceStatus.Normal
        ) {
          Logger.info(`Live Recording OK Network`, {
            value: Math.floor(this.state.recordTime / 1000),
            lang: this.state.language,
          });
        }
      }

      const lastStatus = this.state.serviceStatus;
      if (
        lastStatus !== ServiceStatus.Normal &&
        serviceStatus === ServiceStatus.Normal &&
        this._messageBox.current
      ) {
        this._messageBox.current.hide(() => {
          this.setState({
            serviceStatus: serviceStatus,
          });
        });
      } else {
        this.setState(
          {
            serviceStatus: serviceStatus,
          },
          () => {
            if (
              lastStatus === ServiceStatus.Normal &&
              this.state.serviceStatus !== ServiceStatus.Normal &&
              this._messageBox.current
            ) {
              this._messageBox.current.show();
            }
          },
        );
      }
    }
  }

  private onServerConnected() {}

  private onServerReconnect() {}

  private onDurationHint() {}

  private onOverDurationLimit() {}

  private scrollToBottom() {
    const content = this._contentWindow.current;
    content?.scrollTo(0, content.scrollHeight);
  }

  private generateFilename(date: Date): string {
    function pad(n: number): string {
      return n < 10 ? "0" + n.toString() : n.toString();
    }
    let filename = "";
    filename += i18n.t("transcript_topic");
    filename += "-";
    filename += date.getFullYear().toString();
    filename += "-";
    filename += pad(date.getMonth() + 1);
    filename += pad(date.getDate());

    return filename;
  }

  private downloadBlob(blob: Blob, filename: string) {
    saveAs(blob, filename);
  }

  private saving(save: boolean) {
    if (save) {
      const blob = this.audio.getWavBlob();
      const filename = this.state.filename + ".wav";
      this.downloadBlob(blob, filename);
    }

    if (this._messageBox.current) {
      this._messageBox.current.hide(() => {
        this.setState(
          {
            pipelineState: PipelineState.SaveDone,
          },
          () => {
            if (this._messageBox.current) {
              this._messageBox.current.show();
            }
            setTimeout(() => {
              if (this._messageBox.current) {
                this._messageBox.current.hide(() => {
                  this.setState({
                    pipelineState: PipelineState.WaitForClose,
                  });
                });
              }
            }, 7500);
          },
        );
      });
    } else {
      this.setState(
        {
          pipelineState: PipelineState.SaveDone,
        },
        () => {
          if (this._messageBox.current) {
            this._messageBox.current.show();
          }
          setTimeout(() => {
            if (this._messageBox.current) {
              this._messageBox.current.hide(() => {
                this.setState({
                  pipelineState: PipelineState.WaitForClose,
                });
              });
            }
          }, 7500);
        },
      );
    }
  }

  private updateFileStatusTillDone() {
    if (
      !this.state.file ||
      (this.state.file.state !== undefined &&
        this.state.file.state !== VoiceState.Recording)
    ) {
      this.forceUpdate();
      return;
    }

    this.state.file.loadInfo().then(() => {
      setTimeout(() => {
        this.updateFileStatusTillDone();
      }, 3000);
    });
  }

  private showVolume(): JSX.Element {
    let maxVolume = 80;
    let volumeUnit = 4;
    let htmlContent: JSX.Element = null;
    let volumeState: boolean[] = [];
    for (let i = 0; i < maxVolume / volumeUnit; i++) {
      if ((i + 1) * volumeUnit <= this.state.volume) {
        volumeState.push(true);
      } else {
        volumeState.push(false);
      }
    }
    htmlContent = (
      <div className="volume-bar">
        {volumeState.map((volume, index) => {
          return (
            <div
              key={index}
              className={`volume-block${volume ? " active" : ""}`}
            />
          );
        })}
      </div>
    );
    return htmlContent;
  }

  private getLanguageOptions(): JSX.Element {
    return (
      <Translation ns="liveTranskribe">
        {(t) => (
          <>
            {this.state.translation ? (
              <FloatOptionButton
                icon={translationIcon}
                label={t("exit_translation")}
                onClick={() => {
                  Utils.analyticsEvent({
                    category: "Live Recording",
                    action: "Close Translation",
                    value: Math.floor(this.state.recordTime / 1000),
                    lang: this.state.language,
                    mode: Mode[this.state.mode],
                  });
                  this.setState({
                    onSelectingTranslation: false,
                    translation: undefined,
                  });
                  this.asr.stopTranslantion();
                }}
              />
            ) : (
              ""
            )}
            {DataManager.instance
              .getAvailableTranslations(this.state.language)
              .map((supportedTranslation, idx) => {
                if (
                  supportedTranslation.targetLangCode === this.state.translation
                ) {
                  return "";
                } else {
                  return (
                    <FloatOptionButton
                      key={idx + 1}
                      icon={supportedTranslation.iconUrl}
                      label={t("translate_to", {
                        name: t(supportedTranslation.targetLangCode),
                      })}
                      onClick={() => {
                        Utils.analyticsEvent({
                          category: "Live Recording",
                          action: "Choose Translation",
                          label: supportedTranslation.targetLangCode,
                          value: Math.floor(this.state.recordTime / 1000),
                          mode: Mode[this.state.mode],
                        });
                        this.setState({
                          onSelectingTranslation: false,
                          translation: supportedTranslation.targetLangCode,
                        });
                        this.asr.useTranslantion(
                          supportedTranslation.targetLangCode,
                        );
                      }}
                    />
                  );
                }
              })}
          </>
        )}
      </Translation>
    );
  }

  decideContentTextClassName(sentence: VoiceSentenceLine, index: number) {
    return `content-text ${
      sentence.startTime === 0 && sentence.endTime === 0
        ? "message"
        : this.state.superFinalIdx > index
          ? "enhanced"
          : this.state.isEnableASREnhanced
            ? "have-not-enhanced"
            : ""
    }`;
  }

  decideCurrentSentenceClassName() {
    return `content-text ${this.state.isEnableASREnhanced && "have-not-enhanced"}`;
  }

  render() {
    let asrModel = DataManager.instance.getASRModel(this.state.language);
    let translationModel = DataManager.instance.getTranslationModel(
      this.state.language,
      this.state.translation,
    );

    return (
      <Translation ns="liveTranskribe">
        {(t) => (
          <>
            <div
              id="live-transkribe-page"
              ref={this._contentWindow}
              data-eid={this.asr ? this.asr.FileEid : ""}
              onScroll={this.onScroll.bind(this)}
            >
              <div className="page-container">
                <div className="page">
                  <div className="header">
                    <div
                      className="title-text"
                      suppressContentEditableWarning={true}
                      contentEditable={true}
                      onBlur={this.onBlurHeader.bind(this)}
                      onKeyDown={this.onKeyDownHeader.bind(this)}
                    >
                      {this.state.filename}
                    </div>
                    <div className="attributes">
                      <div className="recording number">
                        <div className="start-time">
                          {dayjs(this.state.startTime).format(
                            "YYYY/MM/DD A hh:mm",
                          )}
                        </div>
                        <div className="length">
                          <img className="icon" src={clockIcon} />
                          {Utils.transferMilliSecondToTime(
                            this.state.recordTime,
                          )}
                        </div>
                        {!asrModel ? null : (
                          <div className="language">{asrModel.shortName}</div>
                        )}
                      </div>
                      <div className="status">
                        <div className="device">
                          <img className="icon" src={deviceIcon} />
                          {this.audioDeviceName}
                        </div>
                        <div className={`network`}>
                          <div
                            className={`light ${ServiceStatus[this.state.serviceStatus]}`}
                          ></div>
                          <div className="status">
                            {getServiceStatusLabel(this.state.serviceStatus)}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="content">
                    {this.state.sentences.map((sentence, index) => {
                      let translation = sentence.translations
                        ? sentence.translations[this.state.translation]
                        : undefined;
                      if (
                        !translation &&
                        sentence.translations &&
                        Object.keys(sentence.translations).length > 0
                      ) {
                        translation =
                          sentence.translations[
                            Object.keys(sentence.translations)[0]
                          ];
                      }
                      return (
                        <div key={index}>
                          {sentence.content.length > 0 &&
                          (sentence.startTime > 0 || sentence.endTime > 0) ? (
                            <div className="content-time number">
                              {Utils.transferMilliSecondToTime(
                                sentence.startTime,
                              )}
                            </div>
                          ) : (
                            ""
                          )}
                          {sentence.content.length > 0 && (
                            <div
                              className={this.decideContentTextClassName(
                                sentence,
                                index,
                              )}
                            >
                              <div>{sentence.content}</div>
                            </div>
                          )}
                          {translation ? (
                            <div
                              className={this.decideContentTextClassName(
                                sentence,
                                index,
                              )}
                            >
                              <div>{translation}</div>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      );
                    })}
                    {this.state.pipelineState == PipelineState.Recording ||
                    this.state.pipelineState == PipelineState.Pause ? (
                      <div>
                        <div className="content-time">
                          {this.state.pipelineState == PipelineState.Recording
                            ? t("transcribing")
                            : this.state.pipelineState == PipelineState.Pause
                              ? t("pause_live_transkribe")
                              : ""}
                        </div>
                        <div className={this.decideCurrentSentenceClassName()}>
                          <div>{this.state.currentSentence}</div>
                        </div>
                        {this.state.currentSentenceTranslation ? (
                          <div
                            className={this.decideCurrentSentenceClassName()}
                          >
                            <div>{this.state.currentSentenceTranslation}</div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
              {this.state.pipelineState < PipelineState.AskSave ? null : this
                  .state.file &&
                this.state.file.state !== undefined &&
                this.state.file.state !== VoiceState.Recording ? (
                <div className="recording-stopped-message">
                  {t("transcript_is_ready")}
                  <span
                    id="go-to-file-page"
                    className="action"
                    onClick={() => {
                      const url = `${RedirectTo.FilePage}?eid=${this.asr.FileEid}`;
                      location.replace(url);
                    }}
                  >
                    {t("view_transcript")}
                  </span>
                </div>
              ) : (
                <div className="recording-stopped-message">
                  {t("exit_recording_yating_is_now_working_on_the_transcript")}
                </div>
              )}
            </div>

            {/* for recording controller */}
            <div
              className="recording-control-container"
              style={{
                display:
                  this.state.pipelineState >= PipelineState.AskSave
                    ? "none"
                    : "'",
              }}
            >
              <div className="recording-control">
                <div className="auto-scroll-btn">
                  <ActionButton
                    color={ButtonColor.lightOrange}
                    icon={autoScrollIcon}
                    hide={this.state.autoScroll}
                    style={{
                      boxShadow: "0 2px 6px 0 rgba(120, 120, 120, 0.5)",
                    }}
                    onClick={this.onClickAutoScroll.bind(this)}
                  />
                </div>
                <div className="audio-volume">
                  <div className="time-label number">
                    {Utils.transferMilliSecondToTime(this.state.recordTime)}
                  </div>
                  {this.showVolume()}
                </div>
                <div className="control-btns">
                  <ActionButton
                    color={
                      this.state.pipelineState === PipelineState.Pause
                        ? ButtonColor.lightOrange
                        : ButtonColor.Red
                    }
                    content={
                      this.state.pipelineState === PipelineState.Pause
                        ? Utils.isPhone
                          ? i18n.t("continue")
                          : t("continue_to_record")
                        : i18n.t("pause")
                    }
                    icon={
                      this.state.pipelineState === PipelineState.Pause
                        ? resumeIcon
                        : pauseIcon
                    }
                    style={{
                      margin: "0px 13px 0px 0px",
                      width: Utils.isPhone ? "90px" : "125px",
                    }}
                    disabled={
                      this.state.pipelineState !== PipelineState.Pause &&
                      this.state.pipelineState !== PipelineState.Recording
                    }
                    onClick={() => {
                      if (this.state.pipelineState === PipelineState.Pause) {
                        this.onClickRecordResume();
                      } else {
                        this.onClickRecordPause();
                      }
                    }}
                  />
                  <ActionButton
                    id="stop-recording"
                    color={ButtonColor.lightOrange}
                    content={Utils.isPhone ? i18n.t("stop") : t("stop_record")}
                    icon={
                      this.state.pipelineState !== PipelineState.Pause &&
                      this.state.pipelineState !== PipelineState.Recording
                        ? stopIconDisabled
                        : stopIcon
                    }
                    style={{
                      margin: "0px 0px 0px 0px",
                      width: Utils.isPhone ? "90px" : "125px",
                    }}
                    disabled={
                      this.state.pipelineState !== PipelineState.Pause &&
                      this.state.pipelineState !== PipelineState.Recording
                    }
                    onClick={this.onClickRecordEnd.bind(this)}
                  />
                </div>
              </div>
            </div>

            {/* for display controller */}
            <div
              ref={this._displayControlPanel}
              className="display-control-panel"
              style={{
                display:
                  this.state.pipelineState >= PipelineState.AskSave
                    ? "none"
                    : "'",
              }}
            >
              {Config.LiveTranscribeLanguageSelector && (
                <div
                  className={`language-selector ${
                    DataManager.instance.getAvailableTranslations(
                      this.state.language,
                    ).length == 0
                      ? "hide"
                      : ""
                  }`}
                  onMouseEnter={() => {
                    this.setState({
                      onSelectingTranslation: true,
                    });
                  }}
                  onMouseLeave={() => {
                    this.setState({
                      onSelectingTranslation: false,
                    });
                  }}
                >
                  {this.state.onSelectingTranslation
                    ? this.getLanguageOptions()
                    : ""}
                  <FloatOptionButton
                    className="on-panel"
                    icon={
                      translationModel
                        ? translationModel.iconUrl
                        : translationIcon
                    }
                    label={
                      translationModel
                        ? t("translate_to", {
                            name: t(translationModel.targetLangCode),
                          })
                        : t("exit_translation")
                    }
                  />
                </div>
              )}
              {this.state.mode === Mode.Caption ? (
                ""
              ) : (
                <FloatOptionButton
                  id="caption-mode-btn"
                  className="on-panel"
                  icon={captionIcon}
                  label={t("subtitle_mode")}
                  onClick={this.onClickCaptionMode.bind(this)}
                />
              )}
            </div>

            {/* for loading */}
            {this.state.pipelineState <= PipelineState.Connecting ? (
              <div className="connecting-mask">
                <div className="mask-message">
                  <img src={connectingImg} style={{ height: "85px" }} />
                  <div style={{ textAlign: "center" }}>
                    {this.state.pipelineState === PipelineState.Closing
                      ? t("ending")
                      : t("connecting")}
                  </div>
                </div>
              </div>
            ) : null}

            {/* for error message */}
            {this.state.pipelineState === PipelineState.Recording &&
            this.state.sentences.length > 0 &&
            this.state.serviceStatus !== ServiceStatus.Normal ? (
              <SiteMessageBox
                ref={this._messageBox}
                color={
                  this.state.serviceStatus === ServiceStatus.BadConnection
                    ? MessageBoxColor.Orange
                    : MessageBoxColor.Red
                }
                message={
                  this.state.serviceStatus === ServiceStatus.BadConnection
                    ? t("network_unstable")
                    : t("alert_disconnect_check_network_status")
                }
              />
            ) : (
              ""
            )}
            {/* for save message */}
            {this.state.pipelineState === PipelineState.AskSave ? (
              <SiteMessageBox
                ref={this._messageBox}
                color={MessageBoxColor.Default}
                message={t("save_record_file")}
                actions={[
                  {
                    text: i18n.t("no"),
                    onClick: () => {
                      Utils.analyticsEvent({
                        category: "Live Recording",
                        action: "Clicked Not Save",
                        value: Math.floor(this.state.recordTime / 1000),
                        lang: this.state.language,
                      });
                      this.saving(false);
                    },
                  },
                  {
                    text: i18n.t("yes"),
                    onClick: () => {
                      Utils.analyticsEvent({
                        category: "Live Recording",
                        action: "Clicked Save",
                        value: Math.floor(this.state.recordTime / 1000),
                        lang: this.state.language,
                      });
                      this.saving(true);
                    },
                  },
                ]}
              />
            ) : (
              ""
            )}
            {/* for done message */}
            {this.state.pipelineState === PipelineState.SaveDone ? (
              Config.EnableBackToListWhenLiveStreamingFinished ? (
                <SiteMessageBox
                  ref={this._messageBox}
                  color={MessageBoxColor.Default}
                  message={t("back_to_list")}
                />
              ) : (
                <SiteMessageBox
                  ref={this._messageBox}
                  color={MessageBoxColor.Default}
                  message={t("continue_browsing_or")}
                  actions={[
                    {
                      text: i18n.t("view_list"),
                      onClick: () => {
                        Utils.analyticsEvent({
                          category: "Live Recording",
                          action: "Clicked Go To List",
                          value: Math.floor(this.state.recordTime / 1000),
                          lang: this.state.language,
                        });
                        window.location.replace(
                          `${window.location.origin}${Config.baseName}`,
                        );
                      },
                    },
                  ]}
                  onClick={() => {
                    Utils.analyticsEvent({
                      category: "Live Recording",
                      action: "Clicked Keep Browsing",
                      value: Math.floor(this.state.recordTime / 1000),
                      lang: this.state.language,
                    });
                    if (this._messageBox.current) {
                      this._messageBox.current.hide(() => {
                        this.setState({
                          pipelineState: PipelineState.WaitForClose,
                        });
                      });
                    } else {
                      this.setState({
                        pipelineState: PipelineState.WaitForClose,
                      });
                    }
                  }}
                />
              )
            ) : (
              ""
            )}

            {/* for multiple audio source detected */}
            <AudioSourceSelection
              show={this.state.audioSourceSelected}
              devices={this.audioDevices}
              onAudioDeviceSelected={this.onAudioDeviceSelected.bind(this)}
            />

            {/* for cation mode */}
            <CaptionWindow
              show={this.state.mode === Mode.Caption}
              language={this.state.language}
              translation={this.state.translation}
              sentences={this.state.sentences}
              currentSentence={this.state.currentSentence}
              currentSentenceTranslation={this.state.currentSentenceTranslation}
              onChangeTranslation={(translation) => {
                this.setState({
                  translation: translation,
                });
                if (translation) {
                  this.asr.useTranslantion(translation);
                  Utils.analyticsEvent({
                    category: "Live Recording",
                    action: "Choose Translation",
                    label: translation,
                    value: Math.floor(this.state.recordTime / 1000),
                    mode: Mode[this.state.mode],
                  });
                } else {
                  this.asr.stopTranslantion();
                  Utils.analyticsEvent({
                    category: "Live Recording",
                    action: "Close Translation",
                    label: translation,
                    value: Math.floor(this.state.recordTime / 1000),
                    mode: Mode[this.state.mode],
                  });
                }
              }}
              onChangeLayout={(layout) => {
                Utils.analyticsEvent({
                  category: "Live Recording",
                  action: "Change Layout",
                  label: CaptionLayout[layout],
                  value: Math.floor(this.state.recordTime / 1000),
                  mode: Mode[this.state.mode],
                });
              }}
              onChangeFontSize={(size) => {
                Utils.analyticsEvent({
                  category: "Live Recording",
                  action: "Change Font Size",
                  label: CaptionSize[size],
                  value: Math.floor(this.state.recordTime / 1000),
                  mode: Mode[this.state.mode],
                });
              }}
              onExit={() => {
                this.onEnterNormalMode();
              }}
            />
            <ErrorModal
              show={this.state.isOpenErrorModal}
              onClose={() =>
                window.location.replace(
                  `${window.location.origin}${Config.baseName}`,
                )
              }
              errorType={this.state.errorType}
            />
          </>
        )}
      </Translation>
    );
  }
}
