import { observable, action, computed } from "mobx";
import * as superagent from "superagent";
import Config from "./Config";
import Voice, { VoiceInfo, VoiceState } from "./Voice";
import StreamingTask from "./StreamingTask";
import User from "./User";
import Utils, { SiteMessage, RedirectTo } from "./Utils";
import Logger from "./Logger";

import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import utc from "dayjs/plugin/utc";
import i18n from "../i18n";

import planAImg from "../assets/img/plan-a.png";
import planBImg from "../assets/img/plan-b.png";
import planCImg from "../assets/img/plan-c.png";

dayjs.extend(duration);
dayjs.extend(utc);

export interface ASRModels {
  live: ASRModel[];
  uploadFile: ASRModel[];
  /** Whisper */
  uploadFilePremium: ASRModel[];
  uploadYoutube: ASRModel[];
  liveStream: ASRModel[];
  [key: string]: ASRModel[];
}

export enum VoiceLang {
  Chinese = "zh",
  Taiwanese = "tw", //current no use
  ChineseAndTaiwanese = "zhtw",
  ChineseAndEnglishAndTaiwanese = "zhentw",
  ChineseAndEnglish = "zhen",
  English = "en",
  Japanese = "ja",
  Cantonese = "yue",
}

export interface ASRModel {
  id: VoiceLang;
  name: string;
  shortName: string;
  translations: TranslationModel[];
}

export interface TranslationModel {
  id: string;
  targetLangCode: string;
  name: string;
  shortName: string;
  iconUrl: string;
}

export interface VoiceList {
  list: Voice[];
  folderId: string;
  folderName: string;
  folderType: FolderType;
  unfinishCnt: number;
  totalCnt: number;
  listSize: number;
  listOffset: number;
  maxOffset: any;
}

export interface FolderInfo {
  eid: string;
  name: string;
}

//Sync with backend payment.ts
export enum PaymentState {
  Ongoing,
  Completed,
  Failed,
}

//Sync with backend payment.ts
export enum PaymentType {
  Buy,
  Free,
  Refund,
  Coupon,
}

//Sync with backend payment.ts
export interface PaymentInfo {
  id: string;
  desc: string;
  price: number;
  quota: number;
  state: PaymentState;
  type: PaymentType;
  ctime: number;
  error_message?: string;
}

//Sync with backend user.ts
export interface QuotaDetail {
  remaining_quota: number;
  ongoing_quota: number;
  valid_quota: number;
  valid_until: number;
  subscription_quota: number;
  free_quota: number;
  free_until: number;
}

export enum FolderType {
  All,
  Shared,
  Recent,
  Uncategorized,
  Folder,
  Deleted,
}

export interface FolderMeta {
  title: string;
  pathname: string;
}

export const folderMap = new Map([
  [FolderType.All, { pathname: "/all", title: i18n.t("all_transcripts") }],
  [FolderType.Shared, { pathname: "/shared", title: i18n.t("share_with_me") }],
  [FolderType.Recent, { pathname: "/recent", title: i18n.t("recents") }],
  [
    FolderType.Uncategorized,
    { pathname: "/", title: i18n.t("my_transcripts") },
  ],
  [FolderType.Folder, { pathname: "/folder", title: i18n.t("my_folder") }],
  [FolderType.Deleted, { pathname: "/deleted", title: i18n.t("trash_bin") }],
]);

export let getFolderTypeFromPathname = function (pathname: string) {
  let result: FolderType = undefined;
  folderMap.forEach(function (value, key) {
    if (pathname.endsWith(value.pathname)) {
      result = key;
    }
  });
  return result;
};

export enum AccountTab {
  setting = 0,
  purchaseHistory = 1,
  plans = 2,
  yatingAPI = 3,
}

export interface Plan {
  id: "A" | "B" | "C";
  originPrice: number;
  duration: number;
  finalPrice: number;
  discountWording: string;
  color: "main" | "green" | "blue";
  desc: string;
  icon: any;
}

export const Plans: Plan[] = [
  {
    id: "A",
    originPrice: 160,
    duration: 1,
    finalPrice: 160,
    discountWording: "plan_a_discount_wording",
    color: "main",
    desc: "1_hour",
    get icon() {
      return planAImg;
    },
  },
  {
    id: "B",
    originPrice: 480,
    duration: 3,
    finalPrice: 456,
    discountWording: "plan_b_discount_wording",
    color: "green",
    desc: "3_hours",
    get icon() {
      return planBImg;
    },
  },
  {
    id: "C",
    originPrice: 1600,
    duration: 10,
    finalPrice: 1440,
    discountWording: "plan_c_discount_wording",
    color: "blue",
    desc: "10_hours",
    get icon() {
      return planCImg;
    },
  },
];

export default class DataManager {
  private static _instance: DataManager = null;

  initialized: boolean = false;

  @observable _asrModels: ASRModels = undefined;
  @observable quotaDetail: QuotaDetail;
  @observable folderList: FolderInfo[];
  @observable streamingTask: StreamingTask;

  @observable voices: VoiceList = {
    list: [],
    folderId: undefined,
    folderName: undefined,
    folderType: undefined,
    unfinishCnt: 0,
    totalCnt: -1,
    listSize: 25,
    listOffset: 0,
    get maxOffset(): number {
      return Math.max(0, Math.ceil(this.totalCnt / this.listSize) - 1);
    },
  };

  @observable payment: {
    list: PaymentInfo[];
    totalCnt: number;
    listSize: number;
    listOffset: number;
    maxOffset: any;
  } = {
    list: [],
    totalCnt: -1,
    listSize: 25,
    listOffset: 0,
    get maxOffset(): number {
      return Math.ceil(this.totalCnt / this.listSize) - 1;
    },
  };

  @observable reqList: {
    folderList?: superagent.SuperAgentRequest;
    voiceList?: superagent.SuperAgentRequest;
    quota?: superagent.SuperAgentRequest;
    payment?: superagent.SuperAgentRequest;
    asrModels?: superagent.SuperAgentRequest;
  } = {};

  @observable isPolling: boolean = false;
  @observable isScreenshotMode: boolean = false;

  pollingList: {
    voiceList?: number;
  } = {};

  static get instance() {
    if (!DataManager._instance) {
      DataManager._instance = new DataManager();
      DataManager._instance.initialized = true;
      DataManager._instance.queryAvailableASRModels();
      DataManager._instance.queryQuotaDetail();
      DataManager._instance.queryFolderList();
    }

    return DataManager._instance;
  }

  static removeInstance() {
    if (DataManager._instance) {
      let manager = DataManager._instance;

      manager.cancelPollingVoiceList();
      manager._asrModels = undefined;
      manager.quotaDetail = undefined;
      manager.folderList = undefined;
      manager.streamingTask = undefined;
      manager.voices.list = [];
      manager.voices.folderId = undefined;
      manager.voices.folderType = undefined;
      manager.voices.totalCnt = 0;
      manager.initialized = false;
    }

    DataManager._instance = null;
  }

  @computed
  get ready() {
    return (
      this.initialized &&
      "undefined" !== typeof this.quotaDetail &&
      "undefined" !== typeof this.folderList
    );
  }

  getASRModel(lang: string): ASRModel | undefined {
    let listKeys = Object.keys(this.asrModels);
    for (let i = 0; i < listKeys.length; i++) {
      let asrModelList = this.asrModels[listKeys[i]];
      const result = asrModelList.find((asrModel) => asrModel.id == lang);
      if (result) return result;
    }
    return undefined;
  }

  getAvailableTranslations(lang: string): TranslationModel[] {
    let result: TranslationModel[] = [];
    this.asrModels.live.forEach((language) => {
      if (language.id == lang) {
        result = language.translations;
      }
    });
    return result;
  }

  getTranslationModel(
    lang: string,
    trans: string,
  ): TranslationModel | undefined {
    let result: TranslationModel = undefined;
    this.getAvailableTranslations(lang).forEach((translation) => {
      if (translation.targetLangCode == trans) {
        result = translation;
      }
    });
    return result;
  }

  getLanguageName(lang: string) {
    let asrModel = this.getASRModel(lang);
    if (asrModel) {
      return asrModel.name;
    }
    return "";
  }

  getLanguageShortName(lang: string) {
    let asrModel = this.getASRModel(lang);
    if (asrModel) {
      return asrModel.shortName;
    }
    return "";
  }

  @action
  updateCurrentLocation(path: string, search: string) {
    if (!this.ready) {
      setTimeout(() => {
        this.updateCurrentLocation(path, search);
      }, 100);
      return;
    }
    let page = Number.parseInt(Utils.getValueFromUrlQuery(search, "page")) || 1;
    path = path.replace(`${Config.baseName}`, "/");
    let folderName: string = undefined;
    let folderId: string = undefined;
    let notInFolder: boolean = false;
    let folderType: FolderType = getFolderTypeFromPathname(path);
    if (folderType === FolderType.Folder) {
      let feid = Utils.getValueFromUrlQuery(search, "eid");
      let found = false;
      if (feid) {
        this.folderList.forEach((f) => {
          if (f.eid === feid) {
            found = true;
            folderName = f.name;
          }
        });
      }
      folderId = found ? feid : undefined;
    } else if (folderType !== undefined) {
      let foldermeta = folderMap.get(folderType);
      folderId = foldermeta.pathname;
      folderName = foldermeta.title;
    } else {
      notInFolder = true;
      folderType = undefined;
    }

    if (!notInFolder && !folderId) {
      // folder not found
      RedirectTo.instance.redirectTo(RedirectTo.FrontPage);
      return;
    }

    if (
      folderId === "/recent" ||
      folderId !== this.voices.folderId ||
      page !== this.voices.listOffset + 1
    ) {
      // reload file list if
      // "in recent opened folder" or
      // changed folder or
      // changed page
      if (!notInFolder) {
        Utils.analyticsPageView(path === "/" ? "/webapp" : path, {
          folder_id: folderId,
          folder_name: folderName,
          page: page,
        });
      }

      // load files
      this.voices.folderId = folderId;
      this.voices.folderName = folderName;
      this.voices.folderType = folderType;
      this.voices.list = [];
      this.voices.listOffset = page - 1;

      this.isPolling = false;
      this.queryVoiceList();
    }
  }

  @action
  queryAvailableASRModels() {
    if (this.reqList.asrModels) {
      this.reqList.asrModels.abort();
    }

    this.reqList.asrModels = Utils.getApi("get", "/config/input_methods").set(
      "Accept-Language",
      i18n.language,
    );

    let success = false;
    this.reqList.asrModels
      .then((res) => {
        //更新資料
        const inputMethods = res.body.data.inputMethods;
        if (!inputMethods) {
          return;
        }
        this.setASRModels(this.arrangeSupportASRModels(inputMethods));
        success = true;
      })
      .catch((err) => {
        if (Utils.isAborted(err)) {
          Logger.info("query available ASR models aborted");
          success = true;
        } else {
          Logger.error(`query available ASR models error ${err}`, {
            errorMessage: err.message,
            errorName: err.name,
          });
        }
      })
      .finally(() => {
        this.reqList.asrModels = null;
        if (!success && this.initialized && User.isLogined) {
          //影響語言選項
          setTimeout(this.queryAvailableASRModels.bind(this), 5000);
        }
      });
  }

  private arrangeSupportASRModels(inputMethods: any) {
    let asrModels: ASRModels = {
      live: [],
      uploadFile: [],
      uploadFilePremium: [],
      uploadYoutube: [],
      liveStream: [],
    };
    inputMethods.forEach((inputMethod: any) => {
      inputMethod.languages.forEach((language: any) => {
        if (!asrModels[inputMethod.type]) {
          asrModels[inputMethod.type] = [];
        }
        asrModels[inputMethod.type].push({
          id: language.id,
          name: language.name,
          shortName: language.shortName || "",
          translations: this.mapTranslationModel(language.translations),
        });
      });
    });
    return asrModels;
  }

  private mapTranslationModel = (translations: any[]): TranslationModel[] =>
    translations.map((translation: any) => ({
      id: translation.id,
      targetLangCode: translation.id.split("/")[1],
      name: translation.name,
      shortName: translation.shortName || "",
      iconUrl: translation.iconUrl,
    }));

  @action
  private setASRModels(asrModels: ASRModels) {
    this._asrModels = asrModels;
    localStorage.setItem("asrModels", JSON.stringify(asrModels));
  }

  @computed
  get asrModels(): ASRModels {
    if (!this._asrModels) {
      try {
        this._asrModels = JSON.parse(localStorage.getItem("asrModels")) || {
          live: [],
          uploadFile: [],
          uploadYoutube: [],
          liveStream: [],
        };
      } catch (err) {
        Logger.error(`parse asr models in localStorage error ${err}`, {
          errorMessage: err.message,
          errorName: err.name,
        });
        this._asrModels = {
          live: [],
          uploadFile: [],
          uploadFilePremium: [],
          uploadYoutube: [],
          liveStream: [],
        };
      }
    }
    return this._asrModels;
  }

  @action
  queryQuotaDetail() {
    if (this.reqList.quota) {
      this.reqList.quota.abort();
    }

    this.reqList.quota = Utils.getApi("post", "/db/user/quota");
    this.reqList.quota
      .then((res) => {
        //更新資料
        if (res.body.success) {
          this.quotaDetail = res.body.detail;
        } else {
          Logger.error(`query user quota error`, {
            error: res.body.error,
          });
        }
      })
      .catch((err) => {
        if (Utils.isAborted(err)) {
          Logger.info("query user quota aborted");
        } else {
          Logger.error(`query user quota error ${err}`, {
            errorMessage: err.message,
            errorName: err.name,
          });
        }
      })
      .finally(() => {
        this.reqList.quota = null;
      });
  }

  @action
  queryStreamingTask(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let req = Utils.getApi("post", "/streaming").send();

      let success = false;
      req
        .then((res) => {
          if (res.body.success) {
            if (res.body.data) {
              this.streamingTask = new StreamingTask(res.body.data);
            }
            success = true;
          }
        })
        .catch((err) => {
          Logger.error(`get streaming task error ${err}`, {
            fail_msg: err,
          });
        })
        .finally(() => {
          resolve(success);
        });
    });
  }

  @action
  createStreamingTask(
    asrLang: string,
    ytStreamKey?: string,
    ytIngestionUrl?: string,
    fbStreamKey?: string,
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let req = Utils.getApi("put", "/streaming/create").send({
        asrLang: asrLang,
        ytStreamKey: ytStreamKey,
        ytIngestionUrl: ytIngestionUrl,
        fbStreamKey: fbStreamKey,
      });

      let response = {
        success: false,
        error: null as any,
      };
      req
        .then((res) => {
          if (res.body.success && res.body.data) {
            this.streamingTask = new StreamingTask(res.body.data);
            response.success = true;
          }
        })
        .catch((err) => {
          response.error = err;
          Logger.error(`create streaming task error ${err}`, {
            fail_msg: err,
          });
        })
        .finally(() => {
          resolve(response);
        });
    });
  }

  @action
  deleteStreamingTask(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let req = Utils.getApi("put", "/streaming/delete").send();

      let success = false;
      req
        .then((res) => {
          success = res.body.success;
          if (success) {
            this.streamingTask = undefined;
          }
        })
        .catch((err) => {
          Logger.error(`delete streaming task error ${err}`, {
            fail_msg: err,
          });
        })
        .finally(() => {
          resolve(success);
        });
    });
  }

  @action
  queryFolderList() {
    if (this.reqList.folderList) {
      this.reqList.folderList.abort();
    }

    this.reqList.folderList = Utils.getApi("post", "/db/folder/list").send();
    this.reqList.folderList
      .then((res) => {
        //更新資料
        if (res.body.success) {
          let list: FolderInfo[] = [];

          res.body.list.forEach((p: any) => {
            let info: FolderInfo = {
              eid: p.eid,
              name: p.name,
            };
            if (
              this.voices.folderType === FolderType.Folder &&
              this.voices.folderId === p.eid
            ) {
              this.voices.folderName = p.name;
            }

            list.push(info);
          });

          list.sort((a, b) => {
            return a.name.localeCompare(b.name);
          });
          this.folderList = list;
        } else {
          Logger.error(`query folder list error`, {
            error: res.body.error,
          });
        }
      })
      .catch((err) => {
        if (Utils.isAborted(err)) {
          Logger.info("query folder list aborted");
        } else {
          Logger.error(`query folder list error ${err}`, {
            errorMessage: err.message,
            errorName: err.name,
          });
        }
      })
      .finally(() => {
        this.reqList.folderList = null;
      });
  }

  @action
  createFolder(name: string) {
    Utils.getApi("post", "/db/folder/create")
      .send({
        name: name,
      })
      .then((res) => {
        Utils.analyticsEvent({
          category: "Folder",
          action: "Create Folder",
          label: res.body.success ? "Success" : "Failed",
          name: name,
        });

        if (res.body.success) {
          this.queryFolderList();
        }
      })
      .catch((err) => {
        Logger.error(`Create folder error ${err}`, {
          name: name,
          fail_msg: err,
        });
      });
  }

  @action
  renameFolder(folderId: string, newName: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (!newName || newName.trim().length === 0) {
        resolve(false);
        return;
      }
      let req = Utils.getApi("put", "/db/folder/update").send({
        folder_eid: folderId,
        name: newName.trim(),
      });

      let success = false;
      req
        .then((res) => {
          Utils.analyticsEvent({
            category: "Folder",
            action: "Rename Folder",
            label: res.body.success ? "Success" : "Failed",
            new_name: newName,
          });
          if (res.body.success) {
            success = true;
            this.queryFolderList();
          }
        })
        .catch((err) => {
          Logger.error(`Rename folder error ${err}`, {
            name: name,
            fail_msg: err,
          });
        })
        .finally(() => {
          resolve(success);
        });
    });
  }

  @action
  deleteFolder(folderId: string) {
    Utils.getApi("put", "/db/folder/delete")
      .send({
        folder_eid: folderId,
      })
      .then((res) => {
        Utils.analyticsEvent({
          category: "Folder",
          action: "Delete Folder",
          label: res.body.success ? "Success" : "Failed",
          folder_id: folderId,
        });
        if (res.body.success) {
          SiteMessage.instance.showMessage(i18n.t("folder_is_deleted"));
          this.queryFolderList();
          if (this.voices.folderId === folderId) {
            RedirectTo.instance.redirectTo(RedirectTo.FrontPage);
          }
        }
      })
      .catch((err) => {
        Logger.error(`Delete folder error ${err}`, {
          folder_id: folderId,
          fail_msg: err,
        });
      });
  }

  @action
  queryVoiceList() {
    // 暫停 polling 等到取得最新的 list 後再做
    this.cancelPollingVoiceList();

    let url = "/db/voice/list/uncategorized";
    let query: any = {
      limit: this.voices.listSize,
      offset: this.voices.listOffset * this.voices.listSize,
    };

    if (!this.voices.folderId) {
      // folder not found
      return;
    } else if (this.voices.folderId == "/") {
    } else if (this.voices.folderId == "/shared") {
      url = "/db/voice/list/sharedwithme";
    } else if (this.voices.folderId == "/recent") {
      url = "/db/voice/list/recent";
    } else if (this.voices.folderId == "/all") {
      url = "/db/voice/list/all";
    } else if (this.voices.folderId == "/deleted") {
      url = "/db/voice/list/deleted";
    } else {
      // folder
      url = "/db/folder/voices";
      query.folder_eid = this.voices.folderId;
    }

    this.reqList.voiceList = Utils.getApi("post", url).send(query);

    this.reqList.voiceList
      .then((res) => {
        //更新資料
        if (res.body.success) {
          let list: Voice[] = [];
          let file_in_progress = 0;

          res.body.voices.forEach((v: any) => {
            let info: VoiceInfo = {
              eid: v.eid,
              state: v.state,
              is_owner: v.is_owner,
              owner: v.owner,
              permission: v.permission,
              name: v.meta.originalName,
              displayName: v.meta.displayName,
              langCode: v.meta.langCode ? v.meta.langCode : "zh",
              duration: v.meta.duration,
              quota_used: v.quota_used,
              preparing: v.preparing,
              speaker_progress: v.speaker_progress,
              summary_progress: v.summary_progress,
              ctime: dayjs.unix(v.ctime).format("YYYY-MM-DD HH:mm:ss"),
              dtime:
                v.dtime > 0
                  ? dayjs.duration(dayjs().diff(dayjs.unix(v.dtime))).asDays()
                  : undefined,
              tags: v.tags,
              error: v.error,
              errorCode: v.errorCode,
            };

            if (v.progress) {
              info.progress = {
                progress: v.progress.progress,
                state: v.progress.state,
                isRetry: v.progress.isRetry || false,
              };
            }

            if (
              (info.speaker_progress && info.speaker_progress < 100) ||
              (info.summary_progress && info.summary_progress < 100)
            ) {
              file_in_progress++;
            }

            let voice = Voice.cacheVoiceFromInfo(info);
            list.push(voice);
          });

          this.voices.list = list;
          this.voices.totalCnt = res.body.voice_total_cnt;
          this.voices.unfinishCnt =
            (res.body.voice_unfinish_cnt || 0) + file_in_progress;
        } else {
          Logger.error(`get voice list error`, {
            error: res.body.error,
          });
        }
      })
      .catch((err) => {
        if (Utils.isAborted(err)) {
          Logger.info("get voice list aborted");
        } else {
          Logger.error(`get voice list error ${err}`, {
            errorMessage: err.message,
            errorName: err.name,
          });
        }
      })
      .finally(() => {
        this.reqList.voiceList = undefined;
        if (this.initialized && User.isLogined) {
          this.pollingList.voiceList = setTimeout(
            this.pollingVoiceList.bind(this),
            10000,
          );
        }
      });
  }

  @action
  moveVoices(
    fileEids: string[],
    folderId: string,
    page: string,
    showMessage: boolean,
  ) {
    let url = "/db/folder/add_voices";
    let query = {
      eids: fileEids,
      folder_eid: folderId.length > 0 ? folderId : undefined,
    };
    Utils.getApi("put", url)
      .send(query)
      .then((res) => {
        Utils.analyticsEvent({
          category: "Folder",
          action: "Move File",
          label: res.body.success ? "Success" : "Failed",
          value: fileEids.length,
          to_folder_id: folderId,
          page: page,
        });
        if (res.body.success) {
          if (showMessage) {
            SiteMessage.instance.showMessage(i18n.t("moved_to_folder"));
          }
          this.queryVoiceList();
        }
      })
      .catch((err) => {
        Logger.error(`Move file to folder error ${err}`, {
          file_count: fileEids.length,
          to_folder_id: folderId,
          fail_msg: err,
          page: page,
        });
      });
  }

  @action
  deleteVoices(
    fileEids: string[],
    page: string,
    showMessage: boolean,
    redirect?: string,
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let req;
      if (User.isLogined) {
        req = Utils.getApi("put", "/db/voice/list/delete").send({
          eids: fileEids,
        });
      } else {
        //only use for 法務部
        req = Utils.getApi("put", "/unauthed/db/voice/delete").send({
          eid: fileEids[0],
        });
      }

      req
        .then((res) => {
          Utils.analyticsEvent({
            category: "Delete",
            action: "Delete result",
            label: res.body.success ? "Success" : "Failed",
            value: fileEids.length,
            page: page,
          });
          if (res.body.success) {
            resolve(true);
            if (showMessage) {
              SiteMessage.instance.showMessage(i18n.t("moved_to_trash"));
            }
            this.queryVoiceList();
            if (redirect && redirect.length > 0) {
              RedirectTo.instance.redirectTo(redirect);
            }
          } else {
            resolve(false);
          }
        })
        .catch((err) => {
          Logger.error(`Delete files error ${err}`, {
            file_count: fileEids.length,
            fail_msg: err,
            page: page,
          });
          resolve(false);
        });
    });
  }

  @action
  restoreVoices(eids: string[]): Promise<boolean> {
    return new Promise((resolve, reject) => {
      Utils.getApi("put", "/db/voice/restore")
        .send({
          eids: eids,
        })
        .then((res) => {
          Utils.analyticsEvent({
            category: "Delete",
            action: "Restore",
            label: res.body.success ? "Success" : "Failed",
            value: eids.length,
          });
          if (res.body.success) {
            SiteMessage.instance.showMessage(i18n.t("restored"));
            this.queryVoiceList();
          }
          resolve(res.body.success);
        })
        .catch((err) => {
          Logger.error(`Restore files error ${err}`, {
            value: eids.length,
            fail_msg: err,
          });
          resolve(false);
        });
    });
  }

  @action
  deleteForeverVoices(eids?: string[]): Promise<boolean> {
    const all = eids === undefined || eids === null;
    return new Promise((resolve, reject) => {
      Utils.getApi("put", "/db/voice/delete_forever")
        .send({
          eids: eids,
          all: all,
        })
        .then((res) => {
          Utils.analyticsEvent({
            category: "Delete",
            action: "Delete Forever",
            label: res.body.success ? "Success" : "Failed",
            value: eids ? eids.length : 0,
            all: all,
          });
          if (res.body.success) {
            SiteMessage.instance.showMessage(
              all ? i18n.t(`emptied_trash`) : i18n.t(`deleted_all_permanently`),
            );
            this.queryVoiceList();
          }
          resolve(res.body.success);
        })
        .catch((err) => {
          Logger.error(`Delete files forever error ${err}`, {
            file_count: eids ? eids.length : undefined,
            all: all,
            fail_msg: err,
          });
          resolve(false);
        });
    });
  }

  @action
  cancelVoice(voice: Voice): Promise<boolean> {
    return new Promise((resolve, reject) => {
      Utils.getApi("put", "/db/voice/cancel")
        .send({
          eid: voice.eid,
        })
        .then((res) => {
          Utils.analyticsEvent({
            category: "Delete",
            action: "Cancel result",
            label: res.body.success ? "Success" : "Failed",
            duration: voice.duration ? voice.duration : -1, //-1 表示沒有voice 沒有時間長度
            file_name: voice.name,
          });
          if (res.body.success) {
            this.queryVoiceList();
          }
          resolve(res.body.success);
        })
        .catch((err) => {
          Logger.error(`Cancel uploaded file error ${err}`, {
            duration: voice.duration ? voice.duration : -1, //-1 表示沒有voice 沒有時間長度
            file_name: voice.name,
            fail_msg: err,
          });
          resolve(false);
        });
    });
  }

  @action
  queryPaymentList() {
    if (this.reqList.payment) {
      this.reqList.payment.abort();
    }

    this.reqList.payment = Utils.getApi("post", "/payment/list").send({
      filter: {
        state: [PaymentState.Completed, PaymentState.Failed],
        limit: this.payment.listSize,
        offset: this.payment.listOffset * this.payment.listSize,
      },
    });

    this.reqList.payment
      .then((res) => {
        //更新資料
        if (res.body.success) {
          let list: PaymentInfo[] = [];

          res.body.list.forEach((p: any) => {
            let info: PaymentInfo = {
              id: p.id,
              desc: p.desc,
              price: p.price,
              quota: p.quota,
              state: p.state,
              type: p.type,
              ctime: p.ctime,
              error_message: p.error_message,
            };

            list.push(info);
          });

          this.payment.list = list;
          this.payment.totalCnt = res.body.total_cnt;
        } else {
          Logger.error(`get user payment list error`, {
            error: res.body.error,
          });
          setTimeout(() => {
            this.queryPaymentList();
          }, 1000);
        }
      })
      .catch((err) => {
        if (Utils.isAborted(err)) {
          Logger.info("get user payment list aborted");
        } else {
          Logger.error(`get user payment list error ${err}`, {
            errorMessage: err.message,
            errorName: err.name,
          });
          setTimeout(() => {
            this.queryPaymentList();
          }, 1000);
        }
      })
      .finally(() => {
        this.reqList.payment = null;
      });
  }

  @action
  setScreenshotMode(value: boolean) {
    this.isScreenshotMode = value;
  }

  cancelPollingVoiceList() {
    if (this.pollingList.voiceList) {
      clearTimeout(this.pollingList.voiceList);
      this.pollingList.voiceList = 0;
    }

    if (this.reqList.voiceList) {
      this.reqList.voiceList.abort();
      this.reqList.voiceList = undefined;
    }
  }

  pollingVoiceList() {
    if (!this.initialized) {
      return;
    }

    this.cancelPollingVoiceList();

    let idList =
      this.voices && this.voices.list
        ? this.voices.list
            .filter(
              (v) =>
                VoiceState.Pending == v.state ||
                VoiceState.Ongoing == v.state ||
                VoiceState.Recording == v.state,
            )
            .map((v) => v.eid)
        : [];

    if (this.voices.folderId == "/deleted") {
      //垃圾桶不需要 polling
      return;
    }
    if (0 == idList.length && 0 == this.voices.unfinishCnt) {
      //沒有需要 polling 狀態的
      return;
    }

    // TODO: 應該要找到好的時機更新
    this.isPolling = true;
    this.queryQuotaDetail();
    this.queryVoiceList();
  }

  public getVoiceCount() {
    let url = "/db/voice/list/count";
    return Utils.getApi("post", url)
      .send()
      .then((res) => {
        if (res.body.success) {
          return res.body.count;
        } else {
          Logger.error(`get voices count error`, {
            error: res.body.error,
          });
          throw new Error(res.body.error);
        }
      })
      .catch((err) => {
        if (Utils.isAborted(err)) {
          Logger.info("get voices count aborted");
        } else {
          Logger.error(`get voices count error ${err}`, {
            errorMessage: err.message,
            errorName: err.name,
          });
        }
        throw new Error(err);
      });
  }
}
