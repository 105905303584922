import React from "react";
import { UserIcon } from "./Component";

import DataManager from "../DataManager";
import Voice, { Permission, Speakers } from "../Voice";
import Utils from "../Utils";
import i18n from "../../i18n";
import { Translation } from "react-i18next";

import "dayjs/locale/zh-tw";
import dayjs from "dayjs";
import Config from "../Config";

import speakerLoadingIcon from "../../assets/img/animation_speaker_match.gif";

interface Props {
  file?: Voice;
  onClickTag: (tag: string) => void;
  onUpdateSpeakerNames: (updates: { [key: number]: string }) => void;
  onRematchSpeaker: () => void;
}

interface State {}

export default class FileInfoWidget extends React.Component<Props, State> {
  private onKeyDownHeader(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.keyCode == 13) {
      // enter
      (event.target as HTMLElement).blur();
    } else if (event.keyCode == 27) {
      // esc
      (event.target as HTMLElement).innerText = this.props.file.name;
      (event.target as HTMLElement).blur();
    }
  }

  private onBlurFileTitle(event: React.ChangeEvent<HTMLInputElement>) {
    let newName = event.target.innerText ? event.target.innerText.trim() : "";
    if (newName.length > 0 && this.props.file.name !== newName) {
      this.props.file.updateFileName(newName, "Edit Page").then((success) => {
        event.target.innerText = this.props.file.name;
      });
    } else {
      event.target.innerText = this.props.file.name;
    }
  }

  private getCreateTime(): string {
    if (i18n.language === "zh-TW") {
      return dayjs(this.props.file.ctime)
        .locale("zh-tw")
        .format("YY/MM/DD ddd hh:mm A");
    }
    return dayjs(this.props.file.ctime).format("DD/MM/YY ddd hh:mm A");
  }

  private getTime(): string {
    return Utils.transferMilliSecondToTime(this.props.file.duration * 1000);
  }

  private getOwnerName(): string {
    let name = this.props.file.owner?.name || this.props.file.owner?.email;
    return `${name}${this.props.file.is_owner ? ` (${i18n.t("you")})` : ""}`;
  }

  private getTags(): React.ReactElement {
    if (!this.props.file.tags || this.props.file.tags.length === 0) {
      return null;
    }

    return (
      <div className="tags-container">
        {this.props.file.tags.map((tag, idx) => {
          return (
            <div
              key={idx}
              className="tag"
              onClick={() => {
                this.props.onClickTag(tag);
              }}
            >
              {tag}
            </div>
          );
        })}
      </div>
    );
  }

  private getSpeakers(): React.ReactElement {
    const speakers = this.props.file.speakersHasSpoken;
    const hasSpeakers = speakers && Object.keys(speakers).length > 0;

    return (
      <Translation ns="file">
        {(t) => (
          <div className="speakers-container">
            {!hasSpeakers
              ? null
              : Object.keys(speakers).map((key, idx) => {
                  const id = Number.parseInt(key);
                  return (
                    <div key={idx} className="speaker">
                      <UserIcon
                        imgUrl={""}
                        name={speakers[id]}
                        email={""}
                        size="25px"
                        fontSize="14px"
                      />
                      <div
                        className="speaker-name"
                        contentEditable={
                          this.props.file.permission >= Permission.Edit
                        }
                        suppressContentEditableWarning={true}
                        placeholder={speakers[id]}
                        onBlur={(event) => {
                          const name = event.target.textContent.trim();
                          if (name.length === 0) {
                            event.target.textContent = speakers[id];
                          } else if (
                            name !== speakers[id] &&
                            name !==
                              t("default_speaker_name", {
                                id: speakers[id].slice(2),
                              })
                          ) {
                            // update user name
                            let data: Speakers = {};

                            if (
                              name.startsWith("Speaker") &&
                              /^(\-|\+)?([0-9]+|Infinity)$/.test(name.slice(8))
                            ) {
                              data[id] = `語者${name.slice(8)}`;
                            } else {
                              data[id] = name;
                            }

                            this.props.onUpdateSpeakerNames(data);
                          }
                        }}
                        onKeyDown={(event) => {
                          if (event.keyCode === 13) {
                            // enter
                            (event.target as HTMLDivElement).blur();
                          } else if (event.keyCode === 27) {
                            //esc
                            (event.target as HTMLDivElement).innerText =
                              speakers[id];
                            (event.target as HTMLDivElement).blur();
                          }
                        }}
                      >
                        {Utils.showSpeaker(speakers[id])}
                      </div>
                    </div>
                  );
                })}
            {this.props.file.speakerProcessProgress < 100 ? (
              <div className="speaker rematching">
                <span>{i18n.t("speaker_processing")}</span>
                <img
                  style={{
                    width: "20px",
                    marginLeft: "10px",
                  }}
                  src={speakerLoadingIcon}
                />
              </div>
            ) : this.props.file.permission < Permission.Edit ? null : (
              <div
                className="speaker rematch-btn"
                onClick={() => {
                  this.props.onRematchSpeaker();
                }}
              >
                {t("enter_speaker_here", { appName: i18n.t(Config.APP_NAME) })}
              </div>
            )}
          </div>
        )}
      </Translation>
    );
  }

  render() {
    if (!this.props.file) {
      return null;
    }

    return (
      <Translation ns="file">
        {(t) => (
          <>
            <div
              className="file-title"
              suppressContentEditableWarning={true}
              contentEditable={this.props.file.canEdit}
              onBlur={this.onBlurFileTitle.bind(this)} // defocus => update
              onKeyDown={this.onKeyDownHeader.bind(this)}
            >
              {this.props.file.name}
            </div>
            <div className="file-meta-container">
              <div className="file-meta-item">
                <div className="title">{t("record_on")}</div>
                <div className="content number">{this.getCreateTime()}</div>
              </div>
              <div className="file-meta-item">
                <div className="title">{t("length")}</div>
                <div className="content number">{`${this.getTime()} ${
                  this.props.file.lang.length > 0
                    ? DataManager.instance.getLanguageName(this.props.file.lang)
                    : ""
                }`}</div>
              </div>
              <div className="file-meta-item">
                <div className="title">{i18n.t("owner")}</div>
                <div className="content">
                  <div className="flex">
                    <UserIcon
                      imgUrl={this.props.file.owner?.picture}
                      name={this.props.file.owner?.name}
                      email={this.props.file.owner?.email}
                      size="25px"
                      fontSize="14px"
                    />
                    <div className="user-name">{this.getOwnerName()}</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="file-meta-container long-content">
              <div className="file-meta-item">
                <div className="title">{t("tags")}</div>
                <div className="content">{this.getTags()}</div>
              </div>
              <div className="file-meta-item">
                <div className="title">{i18n.t("participants")}</div>
                <div className="content">{this.getSpeakers()}</div>
              </div>
            </div>
          </>
        )}
      </Translation>
    );
  }
}
