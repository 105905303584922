import React from "react";
import Modal from "semantic-ui-react/dist/commonjs/modules/Modal";
import Popup from "semantic-ui-react/dist/commonjs/modules/Popup";
import { faTimes } from "@fortawesome/free-solid-svg-icons/faTimes";
import { Color2, hex2RGBA } from "../GlobalDefine";
import { ModalHeader, ProgressBar, FontIcon } from "../ui/Component";
import FileUploader, { FileUploadStatus } from "../UploadFile";
import { Translation, useTranslation } from "react-i18next";

import "../../assets/scss/UploadFileWindow.scss";
import fileIcon from "../../assets/img/icon-recording.svg";
import uploadOKIcon from "../../assets/img/icon-upload-ok.svg";
import uploadFailedIcon from "../../assets/img/icon-upload-failure.svg";

export default class UploadFileWindow extends React.Component<
  {
    files?: File[];
    status?: FileUploadStatus[];
    progress?: number[];
    isCompleted: boolean;
    totalProgress: number;
    failedCount: number;
    onCancel: (idx: number) => void;
    onCancelAll: () => void;
    onCloseInCompleted: () => void;
  },
  {
    showDetail: boolean;
  }
> {
  constructor(props: any) {
    super(props);

    this.state = {
      showDetail: false,
    };
  }

  render() {
    if (
      this.props.files &&
      this.props.files.length > 0 &&
      this.props.status &&
      this.props.progress
    ) {
      if (
        this.props.isCompleted &&
        this.props.failedCount === 0 &&
        !this.state.showDetail
      ) {
        setTimeout(() => {
          this.props.onCloseInCompleted();
        }, 100);
        return null;
      }

      return (
        <Translation ns="upload">
          {(t) => (
            <>
              <div
                className="upload-file-staus-window-container"
                style={{
                  display: this.state.showDetail ? "none" : "",
                }}
              >
                <div
                  className="upload-file-staus-window small"
                  onClick={() => {
                    this.setState({
                      showDetail: true,
                    });
                  }}
                >
                  <div className="status">
                    <div>
                      {t("upload_files_success_number", {
                        length: this.props.files.length,
                      })}
                      {this.props.failedCount > 0
                        ? `，${t("upload_files_fail_number", {
                            failedCount: this.props.failedCount,
                          })}`
                        : ""}
                    </div>
                    <div className="action">{t("upload_detail")}</div>
                  </div>
                  <div className="progress-container">
                    <div
                      className="progress"
                      style={{
                        width: `${this.props.totalProgress}%`,
                      }}
                    />
                  </div>
                </div>
              </div>
              <Modal open={this.state.showDetail} size="small" dimmer={true}>
                <ModalHeader
                  title={`${t("upload_files_success_number", { length: this.props.files.length })}${
                    this.props.failedCount > 0
                      ? `，${t("upload_files_fail_number", {
                          failedCount: this.props.failedCount,
                        })}`
                      : ""
                  }`}
                  textAlign="left"
                  color="white"
                  style={{
                    background: Color2.black100,
                    borderBottom: "none",
                    fontSize: "14px",
                    fontWeight: "normal",
                  }}
                  onClose={() => {
                    this.setState({
                      showDetail: false,
                    });
                    if (this.props.isCompleted) {
                      this.props.onCloseInCompleted();
                    }
                  }}
                />
                {this.props.isCompleted ? null : (
                  <div className="cancel-all-container">
                    <div
                      className="cancel-all"
                      onClick={() => {
                        this.props.onCancelAll();
                      }}
                    >
                      {t("cancel_all")}
                    </div>
                  </div>
                )}
                <div className="upload-item-list">
                  {this.props.files.map((file, idx) => {
                    const status = this.props.status[idx];
                    return (
                      <div key={idx} className="upload-item">
                        <img src={fileIcon} />
                        <div className="file-name">{file.name}</div>
                        <div className="file-status">
                          {status === FileUploadStatus.Uploading ||
                          status === FileUploadStatus.Pending ||
                          status === FileUploadStatus.Idle ? (
                            <>
                              <ProgressBar
                                percent={
                                  status === FileUploadStatus.Uploading
                                    ? this.props.progress[idx]
                                    : 0
                                }
                                className={`progress${
                                  100 === this.props.progress[idx]
                                    ? " animation"
                                    : ""
                                }
                                                            `}
                              />
                              <FontIcon
                                className="action-cancel"
                                icon={faTimes}
                                onClick={() => {
                                  this.props.onCancel(idx);
                                }}
                              />
                            </>
                          ) : (
                            <StatusErrorMessage status={status} />
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </Modal>
            </>
          )}
        </Translation>
      );
    } else {
      return null;
    }
  }
}

function StatusErrorMessage({ status }: { status: FileUploadStatus }) {
  const { t } = useTranslation("upload");
  const { t: commonT } = useTranslation("common");
  let icon: string;
  let message = "";
  switch (status) {
    case FileUploadStatus.OverSize:
      icon = uploadFailedIcon;
      message = t("file_size_exceed", { maxGB: FileUploader.maxGB });
      break;
    case FileUploadStatus.TooManyInQueue:
      icon = uploadFailedIcon;
      message = t("too_many_pending_file");
      break;
    case FileUploadStatus.WrongFormat:
      icon = uploadFailedIcon;
      message = t("incompatible_format");
      break;
    case FileUploadStatus.UploadInvalid:
      icon = uploadFailedIcon;
      message = t("upload_file_message", { maxGB: FileUploader.maxGB });
      break;
    case FileUploadStatus.UploadFailed:
      icon = uploadFailedIcon;
      message = t("unstable_network_message");
      break;
    case FileUploadStatus.UploadCancelled:
      message = commonT("cancelled");
      break;
    case FileUploadStatus.UploadSuccess:
      icon = uploadOKIcon;
      break;
    default:
      break;
  }

  if (icon && message.length > 0) {
    return (
      <Popup
        basic
        on="hover"
        position="top center"
        inverted
        style={{
          borderRadius: 0,
          background: hex2RGBA(Color2.black80, 0.85),
          color: Color2.white,
          fontSize: "14px",
          zIndex: 99999,
        }}
        trigger={<img src={icon} className="icon" />}
        content={<div>{message}</div>}
      />
    );
  } else if (icon) {
    return <img src={icon} className="icon" />;
  } else {
    return <div className="text">{message}</div>;
  }
}
