import React from "react";

import "../../assets/scss/YatingButton.scss";

interface Props {
  disabled?: boolean;
  className?: string;
  color: "main" | "red" | "blue" | "green" | "black";
  variant: "primary" | "secondary" | "outline" | "text";
  size: "large" | "small";
  onClick?: () => void;
}
export default class YatingButton extends React.Component<
  React.PropsWithChildren<Props>,
  any
> {
  render() {
    return (
      <button
        className={`${this.props.className || ""} button-size-${this.props.size} button-variant-${
          this.props.variant
        } button-color-${this.props.color}`}
        onClick={() => {
          if (this.props.onClick) {
            this.props.onClick();
          }
        }}
        disabled={this.props.disabled ? true : false}
      >
        {this.props.children}
      </button>
    );
  }
}
