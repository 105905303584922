import React from "react";
import Player from "react-player";
import Modal from "semantic-ui-react/dist/commonjs/modules/Modal";
import { ButtonColor } from "../GlobalDefine";
import { ModalHeader, ModalActions, Hint } from "../ui/Component";
import DataManager from "../DataManager";
import Utils from "../Utils";
import Logger from "../Logger";
import i18n from "../../i18n";
import { Translation } from "react-i18next";

import "../../assets/scss/UploadYoutubeWindow.scss";

import videoUrlParser from "js-video-url-parser";
import videoIcon from "../../assets/img/icon-video.svg";

export default class UploadYoutubeWindow extends React.Component<
  {
    show: boolean;
    onClose: () => void;
    uploadFileLang: string;
  },
  {
    ytUrl: string;
    inputUrl: string;
    uploading: boolean;
    hint?: {
      type: "error" | "warning" | "info" | "loading";
      msg: string;
      notClosable: boolean;
    };
  }
> {
  constructor(props: any) {
    super(props);

    this.state = {
      ytUrl: "",
      inputUrl: "",
      uploading: false,
    };
  }

  private handleUrlChange(input: string) {
    const info = videoUrlParser.parse(input);

    if (undefined !== info && "youtube" === info.provider) {
      this.setState({
        inputUrl: input,
        ytUrl: input,
      });
    } else {
      this.setState({
        inputUrl: input,
        ytUrl: "",
      });
    }
  }

  private handleUpload(lang: string) {
    if ("" === this.state.ytUrl) {
      Logger.error("no availabe youtube url");
      return;
    }

    this.setState({
      uploading: true,
      hint: {
        type: "loading",
        msg: i18n.t("process_youtube_video", { ns: "upload" }),
        notClosable: true,
      },
    });

    Utils.getApi("post", "/upload/yt")
      .send({
        url: this.state.ytUrl,
        lang: lang,
      })
      .timeout({
        response: 3 * 60 * 1000,
        deadline: 3 * 60 * 1000,
      })
      .then((res: any) => {
        if (
          res.body.success &&
          Array.isArray(res.body.valid) &&
          0 < res.body.valid.length
        ) {
          const file = res.body.valid[0];

          Utils.analyticsEvent({
            category: "Upload",
            action: "Upload success",
            type: "youtube",
            language: lang,
            duration: file.duration,
          });
          Utils.analyticsUserPropertiesAddValue([
            {
              property: `Upload success - yt - ${lang}`,
              value: 1,
            },
          ]);
          this.handleUrlChange("");
          this.setState({
            hint: {
              type: "loading",
              msg: i18n.t("start_process_youtube_video", { ns: "upload" }),
              notClosable: false,
            },
          });

          DataManager.instance.queryVoiceList();
        } else {
          Utils.analyticsEvent({
            category: "Upload",
            action: "Upload failed",
            type: "youtube",
            language: lang,
          });
          Utils.analyticsUserPropertiesAddValue([
            {
              property: `Upload failed - yt - ${lang}`,
              value: 1,
            },
          ]);

          if (-1 === res.body.errCode) {
            this.setState({
              hint: {
                type: "warning",
                msg: i18n.t("too_many_pending_file"),
                notClosable: false,
              },
            });
          } else {
            this.setState({
              hint: {
                type: "error",
                msg: i18n.t("unable_process_video"),
                notClosable: false,
              },
            });
          }
        }
      })
      .catch((err: any) => {
        Logger.error("upload youtube url fail: ", err);
        this.setState({
          hint: {
            type: "error",
            msg: i18n.t("server_busy"),
            notClosable: false,
          },
        });
      })
      .finally(() => {
        this.setState({
          uploading: false,
        });
      });
  }

  render() {
    const disabled = "" === this.state.ytUrl || this.state.uploading;

    return (
      <Translation ns="upload">
        {(t) => (
          <Modal open={this.props.show} size="tiny" dimmer="blurring">
            <ModalHeader
              title={t("transcribing_youtube")}
              textAlign="left"
              onClose={this.props.onClose}
            />
            <div className="upload-youtube-container">
              <div>
                {this.state.hint ? (
                  <Hint
                    inline
                    hide={() => {
                      this.setState({
                        hint: undefined,
                      });
                    }}
                    type={this.state.hint.type}
                    notClosable={this.state.hint.notClosable}
                  >
                    {this.state.hint.msg}
                  </Hint>
                ) : (
                  <input
                    value={this.state.inputUrl}
                    type="url"
                    onChange={(e) => this.handleUrlChange(e.target.value)}
                    autoComplete="off"
                    placeholder={t("enter_youtube_url")}
                    disabled={this.state.uploading}
                  />
                )}
              </div>
              {"" === this.state.ytUrl ? (
                <div className="no-preview-container">
                  <div style={{ margin: "auto" }}>
                    <img src={videoIcon} />
                    <div>{t("video_preview")}</div>
                  </div>
                </div>
              ) : (
                <div className="preview-container">
                  <Player
                    controls
                    onError={() => {
                      this.setState({
                        ytUrl: "",
                      });
                    }}
                    width="100%"
                    height="100%"
                    url={this.state.ytUrl}
                  />
                </div>
              )}
            </div>
            <ModalActions
              actions={[
                {
                  color: ButtonColor.Orange,
                  content: i18n.t("upload"),
                  onClick: () => {
                    Utils.analyticsEvent({
                      category: "New Recording",
                      action: "Import from Youtube Click Language",
                      label: this.props.uploadFileLang,
                      type: "Youtube",
                    });
                    this.handleUpload(this.props.uploadFileLang);
                  },
                },
              ]}
            />
          </Modal>
        )}
      </Translation>
    );
  }
}
