import React from "react";
import Modal from "semantic-ui-react/dist/commonjs/modules/Modal";
import Dropdown from "semantic-ui-react/dist/commonjs/modules/Dropdown";
import Switch from "@material-ui/core/Switch";
import { saveAs } from "file-saver";
import { ModalHeader, ModalActions } from "./Component";
import Voice, { Permission, VoiceSentenceLine, Speakers } from "../Voice";
import Utils from "../Utils";
import Logger from "../Logger";
import { ButtonColor } from "../GlobalDefine";

import dayjs from "dayjs";
import i18n from "../../i18n";
import { Translation } from "react-i18next";
import Config from "../Config";
import SelectionButton from "./SelectionButton";
import { xor, uniqBy } from "lodash";
import { TranslationLang } from "../Voice";
import { VoiceLang } from "../DataManager";
import User, { UserLevel } from "../User";
import { convertSummaryToText, getAISummary } from "./GPTSummary";

import "../../assets/scss/ExportWindow.scss";
import questionIcon from "../../assets/img/icon-question-small.svg";

enum ExportFormat {
  TXT,
  ODT,
  PDF,
  DOCX,
  SRT,
  CSV,
}

const exportFormatOptions: {
  name: string;
  value: string;
}[] = [
  { name: `${i18n.t("txt")} (.txt)`, value: ExportFormat[ExportFormat.TXT] },
  { name: `${i18n.t("odt")} (.odt)`, value: ExportFormat[ExportFormat.ODT] },
  { name: "PDF (.pdf)", value: ExportFormat[ExportFormat.PDF] },
  { name: "Word (.docx)", value: ExportFormat[ExportFormat.DOCX] },
  { name: `${i18n.t("srt")} (.srt)`, value: ExportFormat[ExportFormat.SRT] },
  { name: "CSV (.csv)", value: ExportFormat[ExportFormat.CSV] },
];

class SlideText extends React.Component<{
  hidden?: boolean;
  title: string;
  desc: string;
}> {
  static defaultProps = {
    hidden: false,
  };

  render() {
    if (this.props.hidden) {
      return null;
    }
    return (
      <div className="export-option-container">
        <div className="export-option-title">{this.props.title}</div>
        <div className="export-option-desc">{this.props.desc}</div>
      </div>
    );
  }
}

class SlideBar extends React.Component<{
  hidden?: boolean;
  text: string;
  checked: boolean;
  onClick: () => void;
}> {
  static defaultProps = {
    hidden: false,
  };

  render() {
    if (this.props.hidden) {
      return null;
    }
    return (
      <div className="export-option-container">
        <div className="export-option-title">{this.props.text}</div>
        <Switch
          className="slide-bar"
          checked={this.props.checked}
          onClick={this.props.onClick}
        />
      </div>
    );
  }
}

interface ExportWindowProps {
  show: boolean; // show this window or not
  folder_eid?: string; // for export folder
  folder_name?: string; // for export folder
  file?: Voice; // for export file & preview when export folder or multiple files
  files?: Voice[]; // for export multiple files
  ownerPage: string; // for logging
  onClose: () => void; // trigger when clicked close
  onExport: () => void; // trigger after export successfully
}

interface ExportWindowState {
  preViewContent: VoiceSentenceLine[];

  // general setting
  selectFormat: ExportFormat;
  exportName: string;

  // content setting
  isExportTranscription: boolean;
  isExportSummary: boolean;
  isExportAiSummary: boolean;

  // there are too many options, we split to two pages
  firstPage: boolean;

  // export setting
  isExportTags: boolean;
  isExportSpeaker: boolean;
  isExportTimestamp: boolean;
  isExportMergeSameSpeaker: boolean;
  isExportMerge: boolean;

  // export srt file setting
  isExportAutoBreakLine: boolean;
  maxNumLines: number;
  maxCharNumPerLine: number;
  isRemoveRedundancy: boolean;

  // show message
  message: string;
  // is calling exporting document api
  exporting: boolean;

  // export language options
  exportLanguages: TranslationLang[];
  translationConfig: {
    to: TranslationLang[];
    includeOriginal: boolean;
  };

  /** For export plain text */
  textContentPrerender: string;
}

export default class ExportWindow extends React.Component<
  ExportWindowProps,
  ExportWindowState
> {
  private _previewWindow: React.RefObject<HTMLTextAreaElement>;

  constructor(props: ExportWindowProps) {
    super(props);
    this.state = {
      preViewContent: this.props.file?.utterances.slice() || [],
      selectFormat: ExportFormat.PDF,
      exportName: "",
      isExportTranscription: true,
      isExportSummary: true,
      isExportAiSummary: true,
      firstPage: true,
      isExportTags: true,
      isExportSpeaker: true,
      isExportTimestamp: true,
      isExportMergeSameSpeaker: true,
      isExportMerge: false,
      isExportAutoBreakLine: false,
      maxNumLines: 1,
      maxCharNumPerLine: 13,
      isRemoveRedundancy: false,
      message: "",
      exporting: false,
      exportLanguages: [],
      translationConfig: {
        to: [],
        includeOriginal: false,
      },
      textContentPrerender: "",
    };
    this._previewWindow = React.createRef<HTMLTextAreaElement>();
  }

  componentDidMount() {
    this.fetchExportLanguages();
    if (this.props.file) {
      this.updatePreViewContent();
    }
  }

  componentDidUpdate(
    prevProps: ExportWindowProps,
    prevState: ExportWindowState,
  ) {
    this.setDefaultExportLanguageForFormatSRT(prevProps);

    if (
      (this.props.file && prevProps.file !== this.props.file) ||
      (this.props.files && prevProps.files !== this.props.files) ||
      (this.props.show && !prevProps.show)
    ) {
      this.setState({
        exportName:
          this.props.files && this.props.files.length > 0
            ? i18n.t("export_number_transcripts", {
                date: dayjs().format("YYYY-MM-DD"),
                number: this.props.files.length,
              })
            : this.props.folder_name || this.props.file.name,
        firstPage: true,
      });
      this.updatePreViewContent();
    }

    // update preview text content
    if (
      this.props.file !== prevProps.file ||
      this.state.isExportSummary !== prevState.isExportSummary ||
      this.state.isExportAiSummary !== prevState.isExportAiSummary ||
      this.state.preViewContent !== prevState.preViewContent ||
      this.state.isExportMerge !== prevState.isExportMerge ||
      this.state.isExportTranscription !== prevState.isExportTranscription ||
      this.state.isExportTimestamp !== prevState.isExportTimestamp ||
      this.state.isExportSpeaker !== prevState.isExportSpeaker
    ) {
      this.generateContent().then((textContentPrerender) => {
        this.setState({ textContentPrerender });
      });
    }

    return true;
  }

  private get isNextStepDisabled() {
    // check Next button is disabled or not
    const {
      selectFormat,
      translationConfig,
      isExportTranscription,
      isExportSummary,
      isExportAiSummary,
    } = this.state;
    if (selectFormat === ExportFormat.SRT) {
      // when srt, selectedLanguages.length must > 0
      return !(
        translationConfig.includeOriginal || translationConfig.to.length > 0
      );
    } else {
      return !isExportTranscription && !isExportSummary && !isExportAiSummary;
    }
  }

  private get isExportInFirstPage() {
    const { selectFormat, translationConfig } = this.state;
    const { folder_eid, file, files } = this.props;
    // srt 才需要判斷是否在第一步就顯示匯出
    if (selectFormat !== ExportFormat.SRT) {
      return false;
    }
    // 第一步匯出的條件
    if (folder_eid || (files && files.length > 0)) {
      // rule1: folder or files 直接匯出
      return true;
    }
    // rule2: 沒有勾選原語言
    const isIncludeOriginalLanguage = translationConfig.includeOriginal;
    if (!isIncludeOriginalLanguage) {
      return true;
    }
    // rule3: 有勾原語言 但除了 zh* 以外
    const isAutoBreaklineSupported = file.isAutoBreaklineSupported;
    if (!isAutoBreaklineSupported) {
      return true;
    }
    // rule4: 有勾原語言 但選了其他翻譯語言
    const isOtherLanguages = translationConfig.to.length > 0;
    if (isOtherLanguages) {
      return true;
    }
    return false;
  }

  private get exportBody() {
    return {
      format: ExportFormat[this.state.selectFormat].toLowerCase(),
      summary: this.state.isExportSummary,
      aiSummary: this.state.isExportAiSummary,
      transcription: this.state.isExportTranscription,
      tags: this.state.isExportTags,
      speaker: this.state.isExportSpeaker,
      timestamp: this.state.isExportTimestamp,
      mergeSameSpeaker: this.state.isExportMergeSameSpeaker,
      merge: this.state.isExportMerge,
      maxLines: this.state.isExportAutoBreakLine
        ? this.state.maxNumLines
        : undefined,
      maxChars: this.state.isExportAutoBreakLine
        ? this.state.maxCharNumPerLine
        : undefined,
      removeRedundancy: this.state.isRemoveRedundancy
        ? this.state.isRemoveRedundancy
        : undefined,
      autoBreakLanguageConfig: this.state.isExportAutoBreakLine
        ? [
            {
              language:
                this.props.file?.lang === VoiceLang.English
                  ? VoiceLang.English
                  : VoiceLang.Chinese,
              maxLength: this.state.maxCharNumPerLine,
            },
          ]
        : undefined,
      translationConfig: this.state.translationConfig,
      enableCallType: User.instance.profile.email.includes("ailabs"),
    };
  }

  private get exportBodyLog() {
    return {
      format: ExportFormat[this.state.selectFormat].toLowerCase(),
      summary: this.state.isExportSummary,
      aiSummary: this.state.isExportAiSummary,
      transcription: this.state.isExportTranscription,
      tags: this.state.isExportTags,
      speaker: this.state.isExportSpeaker,
      timestamp: this.state.isExportTimestamp,
      mergeSameSpeaker: this.state.isExportMergeSameSpeaker,
      merge: this.state.isExportMerge,
      isExportAutoBreakLine: this.state.isExportAutoBreakLine,
      maxNumLines: this.state.maxNumLines,
      maxCharNumPerLine: this.state.maxCharNumPerLine,
      removeRedundancy: this.state.isRemoveRedundancy,
    };
  }

  private get exportName(): string {
    if (this.state.exportName && this.state.exportName.trim().length > 0) {
      return this.state.exportName.trim();
    } else if (this.props.folder_name) {
      return this.props.folder_name;
    } else if (this.props.files && this.props.files.length > 0) {
      return i18n.t("export_number_transcripts", {
        date: dayjs().format("YYYY-MM-DD"),
        number: this.props.files.length,
      });
    } else {
      return this.props.file.name;
    }
  }

  private get speakers(): Speakers {
    return this.props.file?.speakers;
  }

  private setDefaultExportLanguageForFormatSRT(prevProps: ExportWindowProps) {
    // srt 設定預設匯出語言
    const { folder_eid, file, files } = this.props;

    if (folder_eid) {
      // 選 folder 時 default 不勾選
    } else if (files && prevProps.files !== files) {
      // multiple files
      const defaultLangs = this.props.files.map((file) => {
        const regex = /^zh.*/;
        const fileLang = file.lang.replace(regex, "zh-Hant") as TranslationLang;
        return fileLang;
      });
      const defaultLangsUniq = uniqBy(defaultLangs, (lang) => lang);
      this.setState({
        translationConfig: { to: defaultLangsUniq, includeOriginal: false },
      });
    } else if (file && prevProps.file !== file) {
      // single file
      const regex = /^zh.*/;
      const defaultLang = file.lang.replace(
        regex,
        "zh-Hant",
      ) as TranslationLang;
      this.setState({
        translationConfig: { to: [defaultLang], includeOriginal: false },
      });
    }
  }

  private onExport() {
    let event: any = {
      category: "Export",
      action: "Download",
      format: ExportFormat[this.state.selectFormat].toLowerCase(),
      include_speaker: this.state.isExportSpeaker,
      page: this.props.ownerPage,
    };
    if (this.state.selectFormat !== ExportFormat.SRT) {
      event.include_tags = this.state.isExportTags;
      event.include_transcription = this.state.isExportTranscription;
      event.include_summary = this.state.isExportSummary;
      event.include_ai_summary = this.state.isExportAiSummary;

      event.include_timestamp = this.state.isExportTimestamp;
      event.merge_same_speaker = this.state.isExportMergeSameSpeaker;
      event.merge_all = this.state.isExportMerge;
    } else {
      event.max_lines = this.state.maxNumLines;
      event.char_per_line = this.state.maxCharNumPerLine;
      event.remove_redundancy = this.state.isRemoveRedundancy;
    }

    if (this.props.folder_eid) {
      this.onExportFolder();
      event.folder_eid = this.props.folder_eid;
      event.folder_name = this.props.folder_name;
    } else if (this.props.files && this.props.files.length > 0) {
      this.onExportFiles();
      event.file_count = this.props.files.length;
    } else {
      this.onExportFile();
      event.duration = this.props.file.duration ? this.props.file.duration : -1; //-1 表示沒有voice 沒有時間長度
      event.file_name = this.props.file.name;
      event.is_owner = this.props.file.is_owner;
      event.permission = Permission[this.props.file.permission];
    }

    Utils.analyticsEvent(event);
  }

  private getFileExtension() {
    const { selectFormat, translationConfig } = this.state;
    let fileExtension = "." + ExportFormat[selectFormat].toLowerCase();
    const originSrt = translationConfig.includeOriginal ? 1 : 0;
    const translationCount = translationConfig.to.length;
    const isMultipleSrtFiles = originSrt + translationCount > 1;
    if (selectFormat === ExportFormat.SRT && isMultipleSrtFiles) {
      fileExtension = ".zip";
    }
    return fileExtension;
  }

  private onExportFile() {
    const fileName = this.exportName;

    if (
      this.state.selectFormat === ExportFormat.PDF ||
      this.state.selectFormat === ExportFormat.ODT ||
      this.state.selectFormat === ExportFormat.DOCX ||
      this.state.selectFormat === ExportFormat.SRT ||
      this.state.selectFormat === ExportFormat.CSV
    ) {
      this.setState({
        exporting: true,
      });
      Utils.getApi("post", "/db/voice/download/document")
        .send({
          eid: this.props.file.eid,
          ...this.exportBody,
        })
        .responseType("blob")
        .then((res) => {
          this.setState({
            exporting: false,
          });
          if (res.body) {
            const blob = new Blob([res.body], {
              type: "text/odt/pdf/docx/csv;charset=UTF-8",
            });
            const fileExtension = this.getFileExtension();
            saveAs(
              blob,
              fileName.replace(/[\\/:"*?<>|]+/gi, "_") + fileExtension,
            );
            this.props.onExport();
          } else {
            alert(i18n.t("export_failed"));
          }
        })
        .catch((err) => {
          this.setState({
            exporting: false,
          });
          alert(i18n.t("export_failed"));
          Logger.error(`export file error ${err}`, {
            is_owner: this.props.file.is_owner,
            permission: Permission[this.props.file.permission],
            errorMessage: err.message,
            errorName: err.name,
            eid: this.props.file.eid,
            ...this.exportBodyLog,
          });
        });
    } else {
      this.generateContent().then((result) => {
        // Adding UTF-8 BOM to string/Blob https://stackoverflow.com/questions/17879198/adding-utf-8-bom-to-string-blob
        const blob = new Blob(["\ufeff" + result], { type: "text/plain" });
        saveAs(
          blob,
          fileName + "." + ExportFormat[this.state.selectFormat].toLowerCase(),
        );
        this.props.onExport();
      });
    }
  }

  private onExportFiles() {
    const fileName = this.exportName;
    let eids: string[] = [];
    this.props.files.forEach((file) => {
      eids.push(file.eid);
    });

    this.setState({
      exporting: true,
    });
    Utils.getApi("post", "/db/voice/download/files")
      .timeout({
        response: 100 * 60 * 1000, //等 100 分鐘
        deadline: 100 * 60 * 1000,
      })
      .send({
        eids: eids,
        ...this.exportBody,
      })
      .responseType("blob")
      .then((res) => {
        this.setState({
          exporting: false,
        });
        if (res.body) {
          const blob = new Blob([res.body]);
          saveAs(blob, fileName + ".zip");
          this.props.onExport();
        } else {
          alert(i18n.t("export_failed"));
        }
      })
      .catch((err) => {
        this.setState({
          exporting: false,
        });
        alert(i18n.t("export_failed"));
        Logger.error(`export files error ${err}`, {
          errorMessage: err.message,
          errorName: err.name,
          eids: eids,
          ...this.exportBodyLog,
        });
      });
  }

  private onExportFolder() {
    const fileName = this.exportName;

    this.setState({
      exporting: true,
    });
    Utils.getApi("post", "/db/folder/download")
      .timeout({
        response: 100 * 60 * 1000, //等 100 分鐘
        deadline: 100 * 60 * 1000,
      })
      .send({
        folder_eid: this.props.folder_eid,
        ...this.exportBody,
      })
      .responseType("blob")
      .then((res) => {
        this.setState({
          exporting: false,
        });
        if (res.body) {
          const blob = new Blob([res.body]);
          saveAs(blob, fileName + ".zip");
          this.props.onExport();
        } else {
          alert(i18n.t("export_failed"));
        }
      })
      .catch((err) => {
        this.setState({
          exporting: false,
        });
        alert(i18n.t("export_failed"));
        Logger.error(`export folder error ${err}`, {
          errorMessage: err.message,
          errorName: err.name,
          folderEid: this.props.folder_eid,
          folderName: this.props.folder_name,
          ...this.exportBodyLog,
        });
      });
  }

  private fetchExportLanguages() {
    // TODO: fetch export languages options api
    const exportLanguages = Object.values(TranslationLang);
    this.setState({
      exportLanguages: exportLanguages,
    });
  }

  private updatePreViewContent() {
    let custom = false;
    if (this.state.selectFormat !== ExportFormat.SRT) {
      if (this.state.isExportMerge) {
        this.setState({
          preViewContent: this.mergeAllSentence(),
        });
        custom = true;
      } else if (this.state.isExportMergeSameSpeaker) {
        this.setState({
          preViewContent: this.mergeSentenceWithSameSpeaker(),
        });
        custom = true;
      }
    }

    if (!custom) {
      this.setState({
        preViewContent: this.props.file.utterances.slice(),
      });
    }
  }

  private mergeSentenceWithSameSpeaker() {
    const orgContent: VoiceSentenceLine[] = JSON.parse(
      JSON.stringify(this.props.file.utterances),
    );
    const preViewContent: VoiceSentenceLine[] = [];
    let index = 0;
    orgContent.forEach((item) => {
      if (preViewContent.length == 0) {
        preViewContent.push(item);
        index = 0;
      } else if (
        preViewContent[index].speaker_id &&
        item.speaker_id &&
        preViewContent[index].speaker_id === item.speaker_id
      ) {
        preViewContent[index].content = preViewContent[index].content.trim();
        item.content = item.content.trim();
        if (
          preViewContent[index].content.length > 0 &&
          item.content.length > 0 &&
          !preViewContent[index].content.endsWith("。") &&
          !preViewContent[index].content.endsWith("，") &&
          !preViewContent[index].content.endsWith("！") &&
          !preViewContent[index].content.endsWith("？") &&
          !preViewContent[index].content.endsWith("、")
        ) {
          preViewContent[index].content += "，";
        }
        preViewContent[index].content += item.content;
        preViewContent[index].endTime = item.endTime;
      } else {
        preViewContent.push(item);
        index++;
      }
    });
    return preViewContent;
  }

  private mergeAllSentence() {
    const orgContent: VoiceSentenceLine[] = JSON.parse(
      JSON.stringify(this.props.file.utterances),
    );
    const sentences: string[] = [];
    orgContent.forEach((item) => {
      sentences.push(item.content);
    });
    const preViewContent: VoiceSentenceLine[] = [];
    let content = "";
    sentences.forEach((item) => {
      content = content.trim();
      item = item.trim();
      if (
        content.length > 0 &&
        item.length > 0 &&
        !content.endsWith("。") &&
        !content.endsWith("，") &&
        !content.endsWith("！") &&
        !content.endsWith("？") &&
        !content.endsWith("、")
      ) {
        content += "，";
      }
      content += item;
    });
    preViewContent.push({
      content: content,
      speaker_id: -1,
      startTime: 0,
      endTime: 0,
    });
    return preViewContent;
  }

  private async generateContent(): Promise<string> {
    let result = this.props.file.name;
    result += "\n\n";

    if (this.state.isExportTags && this.props.file.tags.length > 0) {
      result += `${i18n.t("keywords")}\n`;
      result += this.props.file.tags.join("、");
      result += "\n";
    }
    if (this.state.isExportSpeaker) {
      let speakers = Object.values(this.props.file.speakersHasSpoken);
      if (speakers.length > 0) {
        result += `\n${i18n.t("participants")}\n`;
        result += speakers.join("、");
        result += "\n";
      }
    }

    result += "\n";

    if (this.state.isExportSummary) {
      let summary = this.props.file.summary;
      if (summary.length > 0) {
        result += `================\n${i18n.t("highlight")}\n\n`;
        summary.forEach((section, index) => {
          if (section.title.length > 0) {
            result += section.title + "\n\n";
          }
          section.utterances.forEach((startTime, idx) => {
            if (!this.state.isExportMerge && this.state.isExportSpeaker) {
              result += `${section.speakers[idx]}\n`;
            }
            result += section.contents[idx];
            result += "\n\n";
          });

          result += "\n\n";
        });
      }
    }

    if (this.props.file.is_premium && this.state.isExportAiSummary) {
      try {
        const data = await getAISummary(this.props.file?.eid);
        if (data) {
          result += `================\n${convertSummaryToText(data)}\n\n`;
        }
      } catch (e) {
        console.warn(e);
      }
    }

    if (this.state.isExportTranscription) {
      result += `================\n${i18n.t("transcripts")}\n\n`;
      this.state.preViewContent.forEach((item, index) => {
        if (this.state.isExportMerge) {
          result += item.content;
          result += "\n";
        } else {
          if (this.state.isExportSpeaker || this.state.isExportTimestamp) {
            if (this.state.isExportSpeaker) {
              result +=
                item.speaker_id >= 0 && this.speakers[item.speaker_id]
                  ? `${this.speakers[item.speaker_id]} `
                  : "";
            }
            if (this.state.isExportTimestamp) {
              result += Utils.transferMilliSecondToTime(
                item.startTime,
                true,
                true,
              );
            }
            result += "\n";
          }
          result += item.content;
          result += "\n";
        }
        result += "\n";
      });
    }

    if (Config.AddPromotionWhenExport) {
      result += `${i18n.t("use_yating_speech_to_text")} https://asr.yating.tw/`;
    }

    return result;
  }

  private copyToClipboard() {
    this._previewWindow.current.select();
    document.execCommand("copy");
    if (window.getSelection) {
      window.getSelection().removeAllRanges();
    } else if (document.getSelection) {
      document.getSelection().removeAllRanges();
    }

    let event: any = {
      category: "Export",
      action: "Copy to clipboard",
      is_owner: this.props.file.is_owner,
      permission: Permission[this.props.file.permission],
      duration: this.props.file.duration ? this.props.file.duration : -1, //-1 表示沒有voice 沒有時間長度
      file_name: this.props.file.name,
      format: ExportFormat[this.state.selectFormat].toLowerCase(),
      include_speaker: this.state.isExportSpeaker,
      page: this.props.ownerPage,
    };
    if (this.state.selectFormat !== ExportFormat.SRT) {
      event.include_tags = this.state.isExportTags;
      event.include_timestamp = this.state.isExportTimestamp;
      event.merge_same_speaker = this.state.isExportMergeSameSpeaker;
      event.merge_all = this.state.isExportMerge;
    }
    Utils.analyticsEvent(event);
  }

  private showMessage(message: string) {
    this.setState({
      message: message,
    });
    setTimeout(
      function () {
        this.setState({
          message: "",
        });
      }.bind(this),
      1500,
    );
  }

  private getExportFormatUI(): React.ReactElement {
    return (
      <Translation ns="exportFile">
        {(t) => (
          <div className="export-section">
            <div className="export-section-title">{t("export_format")}</div>
            <div className="export-section-table">
              <div className="export-option-row">
                <div>{t("format")}</div>
                <div>
                  <Dropdown
                    selection
                    fluid
                    direction="left"
                    options={exportFormatOptions.map((value, idx) => {
                      return {
                        text: value.name,
                        value: value.value,
                      };
                    })}
                    value={ExportFormat[this.state.selectFormat]}
                    onChange={(event, data) => {
                      let value = data.value.toString();
                      this.setState(
                        {
                          selectFormat:
                            ExportFormat[value as keyof typeof ExportFormat],
                        },
                        () => {
                          this.updatePreViewContent();
                        },
                      );
                    }}
                  />
                </div>
              </div>
              <div className="export-option-row">
                <div>{t("file_name")}</div>
                <div>
                  <input
                    type="txt"
                    value={this.state.exportName}
                    onChange={(event) => {
                      this.setState({
                        exportName: event.target.value,
                      });
                    }}
                    onBlur={() => {
                      this.setState({
                        exportName: this.exportName,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </Translation>
    );
  }

  private getExportContentSelectUI(): React.ReactElement {
    if (this.state.selectFormat === ExportFormat.CSV) {
      return null;
    }
    if (this.state.selectFormat === ExportFormat.SRT) {
      return (
        <Translation ns="exportFile">
          {(t) => (
            <>
              <div className="export-section">
                <div className="export-section-title">
                  {t("export_language")}
                </div>
                <div className="export-section-grid ">
                  <SelectionButton
                    title={t("original_language")}
                    checked={this.state.translationConfig.includeOriginal}
                    onClick={() => {
                      this.setState((prevState) => ({
                        translationConfig: {
                          ...prevState.translationConfig,
                          includeOriginal:
                            !this.state.translationConfig.includeOriginal,
                        },
                      }));
                    }}
                  />
                </div>
              </div>
              <div className="export-section">
                <div className="export-section-title">
                  {t("export_language")}
                </div>
                <div className="export-section-grid ">
                  {this.state.exportLanguages.map(
                    (lang: TranslationLang, index) => {
                      const langsKey =
                        `langs.${lang}` as unknown as TemplateStringsArray;
                      const title = i18n.exists(`exportFile:${langsKey}`)
                        ? t(langsKey)
                        : lang;
                      return (
                        <SelectionButton
                          key={index}
                          title={title}
                          checked={this.state.translationConfig.to.includes(
                            lang,
                          )}
                          onClick={() => {
                            this.setState((prevState) => ({
                              translationConfig: {
                                ...prevState.translationConfig,
                                to: xor(this.state.translationConfig.to, [
                                  lang,
                                ]),
                              },
                            }));
                          }}
                        />
                      );
                    },
                  )}
                </div>
              </div>
            </>
          )}
        </Translation>
      );
    }

    const isInternalUser = User.instance.level === UserLevel.AILabs;

    return (
      <Translation ns="exportFile">
        {(t) => (
          <div className="export-section">
            <div className="export-section-title">{t("export_content")}</div>
            <div className="export-section-grid">
              <SelectionButton
                title={i18n.t("transcripts")}
                checked={this.state.isExportTranscription}
                onClick={() => {
                  this.setState({
                    isExportTranscription: !this.state.isExportTranscription,
                  });
                }}
              />
              <SelectionButton
                title={i18n.t("highlight")}
                checked={this.state.isExportSummary}
                onClick={() => {
                  this.setState({
                    isExportSummary: !this.state.isExportSummary,
                  });
                }}
              />
              {isInternalUser && (
                <SelectionButton
                  title={i18n.t("summarization")}
                  checked={this.state.isExportAiSummary}
                  onClick={() => {
                    this.setState({
                      isExportAiSummary: !this.state.isExportAiSummary,
                    });
                  }}
                />
              )}
            </div>
          </div>
        )}
      </Translation>
    );
  }

  private getAutoBreaklineUI(): React.ReactElement {
    const { t } = i18n;
    const { selectFormat, isExportAutoBreakLine, maxCharNumPerLine } =
      this.state;
    const { file } = this.props;
    const { isAutoBreaklineSupported, isUtteranceRealignNeed } = file;
    if (
      selectFormat !== ExportFormat.SRT ||
      !isAutoBreaklineSupported ||
      !file
    ) {
      return null;
    } else if (isUtteranceRealignNeed) {
      return (
        <SlideText
          desc={t(
            "exportFile:Auto_sentence_break_not_applicable_to_edited_sentences",
          )}
          title={t("exportFile:auto_sentence_break")}
        />
      );
    } else {
      return (
        <>
          <SlideBar
            checked={isExportAutoBreakLine}
            onClick={() => {
              this.setState({
                isExportAutoBreakLine: !isExportAutoBreakLine,
              });
            }}
            text={t("exportFile:auto_sentence_break")}
          />
          {!isExportAutoBreakLine ? null : (
            //TODO: select lang in step 1
            <>
              <div className="export-option-container">
                <div className="export-option-title">
                  {t("exportFile:word_limit_per_line")}
                  <span className="limit">（5-100）</span>
                </div>
                <input
                  className="number"
                  onChange={(event) => {
                    let value = Number.parseInt(event.target.value);
                    if (value) {
                      value = Math.max(5, value);
                      value = Math.min(100, value);
                      this.setState({
                        maxCharNumPerLine: value,
                      });
                    }
                  }}
                  type="number"
                  value={maxCharNumPerLine}
                />
              </div>
            </>
          )}
        </>
      );
    }
  }

  private getExportOptionsUI(): React.ReactElement {
    return (
      <Translation ns="exportFile">
        {(t) => (
          <div className="export-section can-close">
            <div className="export-section-title">
              <div>
                <span>{t("export_detail")}</span>
                <div className="export-tooltip">
                  <img src={questionIcon} />
                  <span className="tooltiptext">{t("export_detail_tips")}</span>
                </div>
              </div>
            </div>
            <SlideBar
              hidden={
                this.state.selectFormat === ExportFormat.SRT ||
                this.state.selectFormat === ExportFormat.CSV
              }
              text={t("show_keywords")}
              checked={this.state.isExportTags}
              onClick={() => {
                this.setState({
                  isExportTags: !this.state.isExportTags,
                });
              }}
            />
            <SlideBar
              hidden={
                this.state.selectFormat === ExportFormat.SRT ||
                this.state.isExportMerge
              }
              text={t("show_speaker")}
              checked={this.state.isExportSpeaker}
              onClick={() => {
                this.setState(
                  {
                    isExportSpeaker: !this.state.isExportSpeaker,
                  },
                  () => {
                    this.updatePreViewContent();
                  },
                );
              }}
            />
            {this.getAutoBreaklineUI()}
            <SlideBar
              hidden={
                this.state.selectFormat === ExportFormat.SRT ||
                this.state.isExportMerge
              }
              text={t("show_timestamp")}
              checked={this.state.isExportTimestamp}
              onClick={() => {
                this.setState(
                  {
                    isExportTimestamp: !this.state.isExportTimestamp,
                  },
                  () => {
                    this.updatePreViewContent();
                  },
                );
              }}
            />
            <SlideBar
              hidden={
                this.state.selectFormat === ExportFormat.SRT ||
                this.state.isExportMerge ||
                this.state.selectFormat === ExportFormat.CSV
              }
              text={t("merge_text_by_speaker")}
              checked={this.state.isExportMergeSameSpeaker}
              onClick={() => {
                this.setState(
                  {
                    isExportMergeSameSpeaker:
                      !this.state.isExportMergeSameSpeaker,
                  },
                  () => {
                    this.updatePreViewContent();
                  },
                );
              }}
            />
            <SlideBar
              hidden={
                this.state.selectFormat === ExportFormat.SRT ||
                this.state.selectFormat === ExportFormat.CSV
              }
              text={t("merge_text")}
              checked={this.state.isExportMerge}
              onClick={() => {
                this.setState(
                  {
                    isExportMerge: !this.state.isExportMerge,
                  },
                  () => {
                    this.updatePreViewContent();
                  },
                );
              }}
            />
          </div>
        )}
      </Translation>
    );
  }

  private getExportPreviewUI(): React.ReactElement {
    if (this.state.selectFormat === ExportFormat.SRT) {
      return (
        this.props.file.isRemoveRedundancySupported && (
          <Translation ns="exportFile">
            {(t) => (
              <div className="export-section">
                <div className="export-section-title">
                  <div>{t("collate_subtitle")}</div>
                </div>
                <div className="export-section-grid auto-fit">
                  <SelectionButton
                    title={t("filler_word")}
                    tooltiptext={t("filler_word_tips")}
                    checked={this.state.isRemoveRedundancy}
                    isAutoFit={true}
                    onClick={() => {
                      this.setState({
                        isRemoveRedundancy: !this.state.isRemoveRedundancy,
                      });
                    }}
                  />
                </div>
              </div>
            )}
          </Translation>
        )
      );
    } else if (this.state.selectFormat === ExportFormat.CSV) {
      return (
        <Translation ns="exportFile">
          {(t) => (
            <div className="export-section can-close">
              <div className="export-section-title">
                <div>{t("csv_mode_no_preview")}</div>
              </div>
            </div>
          )}
        </Translation>
      );
    } else {
      return (
        <Translation>
          {(t) => (
            <div className="export-section can-close">
              <div className="export-section-title">
                <div>{i18n.t("preview")}</div>
                <div
                  className="copy-to-clipboard-btn"
                  onClick={(evt) => {
                    this.copyToClipboard();
                    this.showMessage(i18n.t("copied_to_clipboard"));
                  }}
                >
                  {i18n.t("copy_to_clipboard")}
                </div>
              </div>
              <textarea
                readOnly
                ref={this._previewWindow}
                className="export-preview-window small-scroller"
                value={this.state.textContentPrerender}
              />
            </div>
          )}
        </Translation>
      );
    }
  }

  render() {
    if (!this.props.file) {
      return null;
    }

    return (
      <Translation>
        {(t) => (
          <Modal open={this.props.show} size="mini" dimmer="blurring">
            <ModalHeader
              title={t("export_setting")}
              textAlign="left"
              onClose={() => {
                this.props.onClose();
              }}
            />
            <div className="export-container">
              {this.state.firstPage ? (
                <>
                  {this.getExportFormatUI()}
                  {this.getExportContentSelectUI()}
                </>
              ) : (
                <>
                  {this.getExportOptionsUI()}
                  {this.getExportPreviewUI()}
                </>
              )}
            </div>
            {this.state.message.length > 0 ? (
              <div className="export-message-box">{this.state.message}</div>
            ) : (
              ""
            )}
            {this.state.firstPage ? (
              // export 第一步驟增加 isExportInFirstPage 邏輯判斷下一步或直接匯出
              this.isExportInFirstPage ? (
                <ModalActions
                  actions={[
                    {
                      color: ButtonColor.Orange,
                      content: this.state.exporting
                        ? t("exporting")
                        : t("export"),
                      style: {
                        width: "100px",
                      },
                      disabled: this.isNextStepDisabled || this.state.exporting,
                      onClick: this.onExport.bind(this),
                    },
                  ]}
                />
              ) : (
                <ModalActions
                  actions={[
                    {
                      color: ButtonColor.Orange,
                      disabled: this.isNextStepDisabled,
                      content: t("next"),
                      style: {
                        width: "100px",
                      },
                      onClick: () => {
                        this.setState({
                          firstPage: false,
                        });
                      },
                    },
                  ]}
                />
              )
            ) : (
              <ModalActions
                actions={[
                  {
                    color: ButtonColor.Default,
                    content: t("go_back"),
                    style: {
                      width: "100px",
                    },
                    onClick: () => {
                      this.setState({
                        firstPage: true,
                      });
                    },
                  },
                  {
                    color: ButtonColor.Orange,
                    content: this.state.exporting
                      ? t("exporting")
                      : t("export"),
                    style: {
                      width: "100px",
                    },
                    disabled: this.state.exporting,
                    onClick: this.onExport.bind(this),
                  },
                ]}
              />
            )}
          </Modal>
        )}
      </Translation>
    );
  }
}
